import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../../utils/codeSnippet/cloud/Aws';
import vpc from '../../../assets/images/vpc.jpeg'
import natgateway from '../../../assets/images/nat-gateway.jpg'
import NACL from '../../../assets/images/NACL.jpg'
import NACL1 from '../../../assets/images/NACL1.jpg'
import statefullVSstateless from '../../../assets/images/statefullVSstateless.jpg'
import securityGroups from '../../../assets/images/securityGroups.jpg'

function Vpc () {
return (
    <CCard className="mb-4">
      <CCardHeader><strong>VPC</strong></CCardHeader>
			<CCardBody>				
				<CAccordion flush>
				<CAccordionItem itemKey={1}>
						<CAccordionHeader>VPC Steps</CAccordionHeader>
						<CAccordionBody>   
							<ol>
								<li>
									<p>VPC</p>
									<ul>
										<li><p>public_subnet_01 -> us-east-1a</p></li>	
										<li><p>public_subnet_02 -> us-east-1b</p></li>	
										<li><p>private_subnet_01 -> us-east-1a</p></li>	
										<li><p>private_subnet_02 -> us-east-1b</p></li>	
									</ul>	
								</li>
								<li>
									<p>Internet_Gateway</p>
									<ul><li><p>Attached to VPC</p></li></ul>
								</li>
								<li>
									<p>Nat_Gateway</p>
									<ul>
										<li><p>Select any public subnet (public_subnet_01 or public_subnet_02)</p></li>
										<li><p>Select / Create Elastic IP address</p></li>
									</ul>
								</li>
								<li>
									<p>Route_Table</p>
									<ul>
										<li>
											<p>Public_Route_Table</p>
											<ul>
												<li>
													<p>Attached to VPC</p>
												</li>
												<li>
													<p>Routes</p>
													<ul>
														<li><p>By default the "Routes" is for local (1.0.0.0/16 -> local)</p></li>
														<li>
															<p>Add Routes</p>
															<ul>
																<li><p>0.0.0.0/0 -> Internet_Gateway</p></li>	
															</ul>	
														</li>
													</ul>
												</li>
												<li>
													<p>Subnet_Associations</p>
													<ul>
														<li>
															<p>Edit Subnet_Associations</p>
															<ul>
																<li><p>Select public_subnet_01 & public_subnet_02</p></li>
															</ul>
														</li>
													</ul>
												</li>
											</ul>
										</li>
										<li>
											<p>Private_Route_Table</p>
											<ul>
												<li>
													<p>Attached to VPC</p>
												</li>
												<li>
													<p>Routes</p>
													<ul>
														<li><p>By default the "Routes" is for local (1.0.0.0/16 -> local)</p></li>
														<li>
															<p>Add Routes</p>
															<ul>
																<li><p>0.0.0.0/0 -> Nat_Gateway</p></li>	
															</ul>	
														</li>
													</ul>
												</li>
												<li>
													<p>Subnet_Associations</p>
													<ul>
														<li>
															<p>Edit Subnet_Associations</p>
															<ul>
																<li><p>Select private_subnet_01 & private_subnet_02</p></li>
															</ul>
														</li>
													</ul>
												</li>
											</ul>
										</li>
									</ul>
								</li>					
							</ol>
						</CAccordionBody>
					</CAccordionItem>   
					<CAccordionItem itemKey={11}>
						<CAccordionHeader>VPC</CAccordionHeader>
						<CAccordionBody>   
							<ul>
								<li><p>When you create VPC, you must specify the range of IPv4 address for the VPC in the form of classless Inter-Domain Routing (CIDR) block, for ex: 10.0.0.0/16</p></li>
								<li><p>You can use network ACLs (access control lists) and security groups to control inbound and outbound network traffic.</p></li>
								<li><p>VPC supports both IPv4 and IPv6 addressing.</p></li>
								<li><p>In default you can create upto 5 VPCs per region</p></li>
							</ul>  
							<p>CIDR - an IP address is followed by a slash (/) and a number that represents the prefix length. For example, the CIDR notation for the IP address 192.168.0.0 with a subnet mask of 255.255.255.0 is 192.168.0.0/24, indicating that the first 24 bits of the IP address are the network address and the remaining 8 bits are the host address.</p>

							<p>10.0.0.0/16 -> 2^(32-16) => 2^16 = 2 × 2 × 2 × 2 × 2 × 2 × 2 × 2 × 2 × 2 × 2 × 2 × 2 × 2 × 2 × 2  = 65,536
									Range will be 10.0.0.0 to 10.0.255.255</p>
							<img src={vpc} width="100%" height="100%" />    
							<p/>
							<h4><strong>CIDR block</strong></h4>
							<p>The CIDR block provides a way to divide a large IP address space into smaller, more manageable subnets. For example, a network administrator may use CIDR blocks to divide an organization's IP address space into multiple subnets based on department, location, or other criteria.</p>
							<p>example, the CIDR notation for the IP address 192.0.2.0 with a subnet mask of 255.255.255.0 is 192.0.2.0/24.</p>
						</CAccordionBody>
					</CAccordionItem>   
					<CAccordionItem itemKey={2}>
						<CAccordionHeader>Router Table</CAccordionHeader>
						<CAccordionBody>   
							<p>A routing table in AWS contains a set of rules, called routes, that determine how network traffic is forwarded to different destinations. Each route specifies the destination network, the target (the next-hop router or internet gateway), and the network interface that the traffic should be forwarded to.</p>
							<p>Your VPC has an implicit router, and you use route tables to control where network traffic is directed. Each subnet in your VPC must be associated with a route table, which controls the routing for the subnet (subnet route table). You can explicitly associate a subnet with a particular route table. Otherwise, the subnet is implicitly associated with the main route table. A subnet can only be associated with one route table at a time, but you can associate multiple subnets with the same subnet route table.</p>
						</CAccordionBody>
					</CAccordionItem>  
					<CAccordionItem itemKey={3}>
						<CAccordionHeader>NAT Gateway</CAccordionHeader>
						<CAccordionBody>   
							<p>A NAT gateway is a Network Address Translation (NAT) service. You can use a NAT gateway so that instances in a private subnet can connect to services outside your VPC but external services cannot initiate a connection with those instances.</p>
							<p>When you create a NAT gateway, you specify one of the following connectivity types:</p>
							<ul>
								<li><p><b>Public </b>– (Default) Instances in private subnets can connect to the internet through a public NAT gateway, but cannot receive unsolicited inbound connections from the internet. You create a public NAT gateway in a public subnet and must associate an elastic IP address with the NAT gateway at creation. You route traffic from the NAT gateway to the internet gateway for the VPC. Alternatively, you can use a public NAT gateway to connect to other VPCs or your on-premises network. In this case, you route traffic from the NAT gateway through a transit gateway or a virtual private gateway.</p></li>
								<li><p><b>Private</b> – Instances in private subnets can connect to other VPCs or your on-premises network through a private NAT gateway. You can route traffic from the NAT gateway through a transit gateway or a virtual private gateway. You cannot associate an elastic IP address with a private NAT gateway. You can attach an internet gateway to a VPC with a private NAT gateway, but if you route traffic from the private NAT gateway to the internet gateway, the internet gateway drops the traffic.</p></li>
							</ul>
							<img src={natgateway} width="100%" height="100%" />    
						</CAccordionBody>
					</CAccordionItem>  
					<CAccordionItem itemKey={4}>
						<CAccordionHeader>NACL - Network access control list</CAccordionHeader>
						<CAccordionBody>   
							<p>A network access control list (ACL) allows or denies specific inbound or outbound traffic at the subnet level. You can use the default network ACL for your VPC, or you can create a custom network ACL for your VPC with rules that are similar to the rules for your security groups in order to add an additional layer of security to your VPC.</p>   
							<ul>
								<li><p>Your VPC automatically comes with a modifiable default network ACL. By default, it allows all inbound and outbound IPv4 traffic and, if applicable, IPv6 traffic.</p></li>
								<li><p>Each subnet in your VPC must be associated with a network ACL. If you don't explicitly associate a subnet with a network ACL, the subnet is automatically associated with the default network ACL.</p></li>
								<li><p>You can create a custom network ACL and associate it with a subnet to allow or deny specific inbound or outbound traffic at the subnet level.</p></li>
								<li><p>You can associate a network ACL with multiple subnets. However, a subnet can be associated with only one network ACL at a time. When you associate a network ACL with a subnet, the previous association is removed.</p></li>
							</ul>
							<img src={NACL} width="100%" height="100%" /> 
							<p></p>   
							<img src={NACL1} width="100%" height="100%" />    
						</CAccordionBody>
					</CAccordionItem>  
					<CAccordionItem itemKey={5}>
						<CAccordionHeader>StateFull vs StateLess</CAccordionHeader>
						<CAccordionBody>   						
							<img src={statefullVSstateless} width="100%" height="100%" />    
						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={5}>
						<CAccordionHeader>Security Groups</CAccordionHeader>
						<CAccordionBody>   						
							<img src={securityGroups} width="100%" height="100%" />    
						</CAccordionBody>
					</CAccordionItem>
				</CAccordion>
      </CCardBody>
    </CCard>        
  )
}

export default Vpc