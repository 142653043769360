import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Bean';

function Bean () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>@Bean</strong></CCardHeader>
        <CCardBody>
          <p>Spring Bean annotation is usually declared in <NavLink  to="/springBoot/Configuration">@Configuration</NavLink> classes methods.</p>
          <p>Bean is used to explicitly declare a single bean, rather than letting Spring do it automatically. It decouples the declaration of the bean from the class definition and lets you create and configure beans exactly how you choose.</p>

          <p>@Bean is a method-level annotation. We generally use it to configure beans in Java code (if you are not using XML configuration) and then call it from a class using the ApplicationContext.getBean() method.</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>      
          <p>Spring <strong>@Bean</strong> annotation indicates that a method produces a bean to be managed by the Spring container. Spring <strong>@Bean</strong> method can be created within <strong>@Configuration</strong> and <strong>@Component</strong> classes. The default scope of a bean is singleton. The <strong>@Bean</strong> annotation can be used in conjunction with annotations such as <strong>@Scope, @Lazy, @DependsOn, @Primary</strong> etc.</p>   
          <p><strong>@Scope</strong></p>
          <p>Default scope of a bean is singleton. We can change bean scope using @Scope annotation. Bean scopes are prototype, request, session etc. Find a sample example to change a bean scope to prototype.</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>   
          <p><strong>@Lazy</strong></p>
          <p>@Lazy indicates whether a bean is to be lazily initialized.</p>   
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>  
          <p><strong>@DependsOn</strong></p>
          <p>@DependsOn specifies the beans on which the current bean depends.</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>  
          <p><strong>@Primary</strong></p>
          <p>@Primary indicates that a bean should be given preference when multiple candidates are qualified to autowire a single-valued dependency.</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>  
        </CCardBody>
      </CCard>        
    )
}

export default Bean