export const codeSnippet = {
  code1: `version: '2'
services:
  zookeeper:
    image: wurstmeister/zookeeper:latest
    container_name: zookeeper
    ports:
      - "2181:2181"
  kafka-brocker-1:
    image: wurstmeister/kafka:latest
    container_name: kafka
    ports:
      - "9092:9092"
    expose:
      - "9093"
    environment:
      KAFKA_ADVERTISED_LISTENERS: INSIDE://kafka:9093,OUTSIDE://localhost:9092
      KAFKA_LISTENER_SECURITY_PROTOCOL_MAP: INSIDE:PLAINTEXT,OUTSIDE:PLAINTEXT
      KAFKA_LISTENERS: INSIDE://0.0.0.0:9093,OUTSIDE://0.0.0.0:9092
      KAFKA_INTER_BROKER_LISTENER_NAME: INSIDE
      KAFKA_ZOOKEEPER_CONNECT: zookeeper:2181
      KAFKA_CREATE_TOPICS: "topic-1:3:1,topic-2:5:1"`,
  code2: `docker-compose down
docker-compose up -d
docker ps

/* running kafka in background */
docker-compose -f docker-compose.yml up -d

/* find all the sh files for kafka */
docker exec -it kafka /bin/sh
cd /opt/
cd kafka
cd bin

/* List all the topics */
kafka-topics.sh --list --bootstrap-server localhost:9092

/* Describe the topics */
kafka-topics.sh --describe --bootstrap-server localhost:9092 --topic <your-topic-name>`,
  code3: `@Configuration
public class KafkaProducerConfig {

    @Bean
    public ProducerFactory<String, String> producerFactory() {
        Map<String, Object> configProps = new HashMap<>();
        configProps.put(ProducerConfig.BOOTSTRAP_SERVERS_CONFIG, "localhost:9092");
        configProps.put(ProducerConfig.KEY_SERIALIZER_CLASS_CONFIG, StringSerializer.class.getName());
        configProps.put(ProducerConfig.VALUE_SERIALIZER_CLASS_CONFIG, StringSerializer.class.getName());
        return new DefaultKafkaProducerFactory<>(configProps);
    }

    @Bean
    public KafkaTemplate<String, String> kafkaTemplate() {
        return new KafkaTemplate<>(producerFactory());
    }
}`,
  code4: `@Service
public class KafkaProducer {

    private final KafkaTemplate<String, String> kafkaTemplate;

    @Autowired
    public KafkaProducer(KafkaTemplate<String, String> kafkaTemplate) {
        this.kafkaTemplate = kafkaTemplate;
    }

    public void produceMessage(String topic, String message) {
        kafkaTemplate.send(topic, message);
        System.out.println("Produced message: " + message);
    }
}`,
  code5: `@RestController
@RequestMapping("/kafka")
public class KafkaController {

    private final KafkaProducer kafkaProducer;

    @Autowired
    public KafkaController(KafkaProducer kafkaProducer) {
        this.kafkaProducer = kafkaProducer;
    }

    @GetMapping("/produce")
    public String produceMessage() {
        LocalDateTime currentDateTime = LocalDateTime.now();
        LocalDateTime dateWithMinSec = currentDateTime.withSecond(0).withNano(0);
        String message = "Current Date and Time: " + currentDateTime;

        kafkaProducer.produceMessage("topic-1", message);
        return "Message sent to Kafka";
    }
}`,
  code6: `@Configuration
@EnableKafka
public class KafkaConsumerConfig {

    @Bean
    public ConsumerFactory<String, String> consumerFactory() {
        Map<String, Object> props = new HashMap<>();
        props.put(ConsumerConfig.BOOTSTRAP_SERVERS_CONFIG, "localhost:9092");
        props.put(ConsumerConfig.GROUP_ID_CONFIG, "my-consumer-group");
        props.put(ConsumerConfig.KEY_DESERIALIZER_CLASS_CONFIG, StringDeserializer.class.getName());
        props.put(ConsumerConfig.VALUE_DESERIALIZER_CLASS_CONFIG, StringDeserializer.class.getName());
        props.put(ConsumerConfig.AUTO_OFFSET_RESET_CONFIG, "latest"); // Set to "latest" to read only the latest message // earliest
        return new DefaultKafkaConsumerFactory<>(props);
    }

    @Bean
    public ConcurrentKafkaListenerContainerFactory<String, String> kafkaListenerContainerFactory() {
        ConcurrentKafkaListenerContainerFactory<String, String> factory = new ConcurrentKafkaListenerContainerFactory<>();
        factory.setConsumerFactory(consumerFactory());
        return factory;
    }
}`,
  code7: `@Service
public class KafkaSubscriber {

    @KafkaListener(topics = "topic-1", groupId = "my-consumer-group")
    public void consume(String message) {
        System.out.println("Received message: " + message);
    }
}`,
  code8: ``,
  code9: ``,
  code10: ``,
  code11: ``,
  code12: ``,
  code13: ``,
  code14: ``,
  code15: ``,
  code16: ``,
}

