import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/UseState';

function UseState () {
    return (
        <CCard className="mb-4">
			    <CCardHeader><strong>UseState</strong></CCardHeader>
            <CCardBody>
              <CCard>
                <CCardBody>
                  <p>Introduction to <NavLink  to="/react/hooks" active>hooks</NavLink></p>
                  <p>it's important that dont mutate state directly</p>
                </CCardBody>
              </CCard><br/>
              <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
              <p><strong>First name & last name updated into single state</strong></p>
              <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
              <p><strong>useState update eg:1</strong></p>
              <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
              <p><strong>useState update eg:2</strong></p>
              <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
              <p><strong>useState update eg:3</strong></p>
              <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
              <p><strong>useState update eg:4</strong></p>
              <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
              <h5><strong>Why is setState giving me the wrong value?</strong></h5>
              <p>Calls to setState are asynchronous - don’t rely on this.state to reflect the new value immediately after calling setState. Pass an updater function instead of an object if you need to compute values based on the current state</p>
              <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
              <p>See below for how to fix this problem.</p>
              <p>Pass a function instead of an object to setState to ensure the call always uses the most updated version of state (see below).</p>
              <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
            </CCardBody>
      </CCard>
        
    )
}

export default UseState