export const codeSnippet = {
	code1: `import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FooComponent } from './foo/foo.component';
import { BarComponent } from './bar/bar.component';

const routes: Routes = [
  { path: 'home', component: FooComponent },
  { path: 'bar', component: BarComponent },
  { path: '', redirectTo:'/home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }`,
  code2: `@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
  })
  export class AppModule { }`,
  code3: `@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})`,
  code4: `const routes: Routes = [
  { path: 'foo', component: FooComponent, pathMatch: 'full' },
  { path: 'bar', component: BarComponent, pathMatch: 'prefix' },
];`,
  code5: `import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

<router-outlet name="Header"></router-outlet>
<router-outlet name="adminHeader"></router-outlet>
<router-outlet></router-outlet>

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', children:
    [
      { path: '', component: HeaderComponent, outlet: 'adminHeader'},      
      { path: '', component: DashBoardComponent, canActivate: [AuthGuard]},
    ]
  },   
  { path: '**', redirectTo: '' }, 
];
export const routing: ModuleWithProviders = RouterModule.forRoot(routes);`,
  code6:`<span [routerLink]="['/user','1']" >User 1</span> { path: "user/:id", component: UserComponent }`,
  code7: `this.router.navigate(['/home/events', event.id]);
this.router.navigate( ['MyCompB', {id: "someId", id2: "another ID"}]);`,
  code8: `import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-detail',
  template: '
    <h1>User Detail</h1>
    <p>User ID: {{ userId }}</p>
  '
})
export class UserDetailComponent {
  userId: string;

  constructor(private route: ActivatedRoute) {
    this.userId = this.route.snapshot.paramMap.get('id');
  }

  constructor(private route: ActivatedRoute) {
    this.route.paramMap.subscribe(paramMap => {
      this.userId = paramMap.get('id');
    });
  }
}`
}