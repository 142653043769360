import React from "react";
import { CCard, CCardHeader, CCardBody} from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/java/Stream'


function Stream () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Stream</strong></CCardHeader>
			<CCardBody>
				<p>In Java, a stream is a sequence of elements that can be processed in parallel or sequentially. The java.util.stream package, which was introduced in Java 8, provides a powerful and expressive API for performing functional-style operations on collections of data.</p> <p>A stream is a sequence of elements that can be processed in parallel or sequentially.</p>
				<p>Benefits of the Java Stream API:</p>
				<ul>
					<li><p><b>Readability:</b> Stream operations are designed to be expressive and concise, making the code easier to understand and maintain.</p></li>
					<li><p><b>Functional Programming:</b> Streams support functional programming principles like immutability, higher-order functions, and lazy evaluation.</p></li>
					<li><p><b>Parallelism:</b> Many stream operations can be executed in parallel, leveraging multi-core processors and improving performance for large datasets.</p></li>
					<li><p><b>Reduction of Boilerplate Code:</b> Streams eliminate the need for traditional loops and manual iteration, reducing boilerplate code.</p></li>
				</ul>

				<ol>
					<li>
						<p><b>Filtering</b></p>
						<p>Filtering elements based on a condition.</p>
						<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
					</li>
					<li>
						<p><b>Mapping</b></p>
						<p>Transforming elements using a function.</p>
						<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
					</li>
					<li>
						<p><b>Sorting</b></p>
						<p>Sorting elements based on a comparator.</p>
						<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
					</li>
					<li>
						<p><b>Reduction</b></p>
						<p>Aggregating elements using operations like sum, min, max.</p>
						<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
					</li>	
					<li>
						<p><b>Grouping and Partitioning</b></p>
						<p>Grouping elements by a property or partitioning them based on a condition.</p>
						<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
					</li>				
					<li>
						<p><b>Parallel Processing</b></p>
						<p>Leveraging parallel streams for concurrent execution of operations.</p>
						<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter>
					</li>																		
				</ol>

				<p>From a collection:</p>
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
				<p>From a array:</p>
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>

				<p>A stream is a lazily evaluated, which means that the elements of the stream are not processed until a terminal operation is performed.</p>
				<p>Once you have a stream, you can perform various operations on it, such as filtering, mapping, and reducing.</p>
				<p>Here's an example of using the filter(), map() and forEach() methods on a stream:</p>
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
				<p>in this example, the filter() method is used to filter out all odd numbers from the stream, the map() method is used to map each even number to its square, and the forEach() method is used to print each resulting number to the console.</p>
				<p>The filter() method takes a Predicate functional interface, which is a boolean-valued function of one argument and returns a stream that contains elements that match the given predicate.</p>
				<p>The map() method takes a Function functional interface, which is a function that takes one argument and returns a result and returns a new stream that contains the results of applying the given function to the elements of this stream.</p>
				<p>The forEach() method takes a Consumer functional interface, which is an operation that accepts a single input argument and returns no result. It's used to perform a specific action on each element of the stream.</p>
				<p>You can also chain multiple intermediate operations together and then call a terminal operation to trigger the evaluation of the entire stream pipeline.</p>
			</CCardBody>
	</CCard>
			
	)
}

export default Stream