import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Repository';

function Repository () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>@Repository</strong></CCardHeader>
      <CCardBody>
        <p>@Repository is a Spring annotation that is used to indicate that a class is a repository component. Repository components are typically used to encapsulate data access logic and perform tasks such as querying a database or interacting with a data store.</p>

        <p>In Spring, repository components are typically created as singleton beans and are managed by the Spring container. They can be injected into other components, such as services or controllers, using dependency injection.</p>

        <p>Here is an example of how you might use @Repository in a Spring application:</p>

        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
        <p>In this example, the UserRepository class is annotated with @Repository, indicating that it is a repository component. It has a dependency on a JdbcTemplate, which is injected into it using @Autowired. The UserRepository class contains data access logic for saving new users to a database.</p>
        
      </CCardBody>
    </CCard>        
  )
}

export default Repository