import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  // CTableHead,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/java/Iteration'


function Iteration() {
	return (
		<>
		<CCard className="mb-4">
			<CCardHeader><strong>Iteration</strong></CCardHeader>
			<CCardBody>
				<p>In Java, iteration refers to the process of iterating over a collection of elements, such as a list or an array, and performing an operation on each element.</p>

				<p>There are several ways to iterate over a collection of elements in Java:</p>

				<ol>
					<li>
						<p>Using a for loop:</p>
						<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

					</li>
					<li>
						<p>Using a for-each loop:</p>
						<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
					</li>
					<li>
						<p>Using an iterator:</p>
						<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
					</li>
					<li>
						<p>Using the forEach method of a stream:</p>
						<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
					</li>															
				</ol>
				<p>Each of these approaches has its own trade-offs and is suited to different situations. For example, the for loop and the for-each loop are simple and easy to use, but they do not provide any built-in support for parallel processing. On the other hand, the forEach method of a stream supports parallel processing and can be more efficient in certain situations, but it requires more setup and is not as straightforward to use.</p>
			</CCardBody>
		</CCard>
		<CCard>
			<CCardHeader><strong>Iteration</strong></CCardHeader>
			<CCardBody>
				<p>An Iterator in Java is an interface that provides a way to iterate over elements of a collection or data structure without exposing the underlying implementation. It is part of the Java Collections Framework and is commonly used to traverse elements of collections such as lists, sets, and maps.</p>
				<ul>
					<li><p><b>boolean hasNext():</b> Returns true if there are more elements to iterate over, otherwise returns false.</p></li>
					<li><p><b>E next():</b> Returns the next element in the collection and advances the iterator to the next position. Throws NoSuchElementException if there are no more elements.</p></li>
					<li><p><b>void remove():</b> Removes the last element returned by the iterator from the underlying collection. This method is optional and may not be supported by all iterators.</p></li>
				</ul>
				<p>Iterators are <b>memory-efficient</b> because they typically don't load all elements into memory at once. This is particularly useful when working with large collections or when the data structure is being read from a source like a file or a stream.</p>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
			</CCardBody>
		</CCard>
	</>
	)
}

export default Iteration;