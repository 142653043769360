export const codeSnippet = {
	code1: `<input [value]="propertyName">`,
  code2: `<button (click)="methodName()">Click me</button>`,
  code3: `import { Component } from '@angular/core';

@Component({
  selector: 'app-my-component',
  template: '
    <input [value]="name">
    <input [(ngModel)]="name"/>  // two way bidning
    <button (click)="sayHello()">Say Hello</button>
  '
})
export class MyComponent {
  name = 'John';

  sayHello() {
    alert('Hello \$\{this.name}');
  }
}`
}