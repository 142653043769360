export const codeSnippet = {
  code1: `@Configuration
public class AppConfig {
  @Bean 
  public Employee employee() {
    return new Employee();
  }
  @Bean
  public Address address() {
    return new Address();
  }
}`,
  code2: `@Configuration
public class AppConfig {
  @Bean
  @Scope("prototype")
  public BeanA getBeanA() {
    return new BeanA();
  }
} `,
  code3: `@Configuration
public class AppConfig {
  @Bean
  @Scope("prototype")
  @Lazy(true)
  public BeanA getBeanA() {
    return new BeanA();
  }
} `,
  code4: `@Configuration
public class AppConfig {
  @Bean
  @Scope("prototype")
  @DependsOn("myBeanB")
  public BeanA getBeanA() {
    return new BeanA();
  }
  @Bean("myBeanB")
  public BeanB getBeanB() {
    return new BeanB();
  }	
} `,
  code5: `@Configuration
  public class AppConfig {
    @Bean
    @Primary
    public BeanA getBeanA() {
      return new BeanA();
    }
  } `
}

