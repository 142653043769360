export const codeSnippet = {
  code1: `@Component
public class MyService {
  @Value("\${my.property}")
  private String myProperty;

  public void doSomething() {
    // use myProperty here
  }
}`,
  code2: `@Component
public class MyService {
  private final String myProperty;

  public MyService(@Value("\${my.property}") String myProperty) {
    this.myProperty = myProperty;
  }

  public void doSomething() {
    // use myProperty here
  }
}`
}

