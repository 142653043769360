import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  // CTableHead,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
	import SyntaxHighlighter from 'react-syntax-highlighter';
	import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
	import { codeSnippet } from '../../utils/codeSnippet/springBoot/Async-http-client'
		
function AsyncProcess () {
	return (
		<>
			<CCard className="mb-4">
				<CCardHeader><strong>Async-http-client</strong></CCardHeader>
				<CCardBody>
					<p>Netty is a non-blocking framework. AsyncHttpClient (AHC) is a library build on top of Netty, with the purpose of easily executing HTTP requests and processing responses asynchronously.</p>
					<p>The Async HTTP Client library allows you to execute HTTP requests asynchronously, which means that you can make multiple requests concurrently without blocking the execution thread. It uses the Java NIO API to achieve this non-blocking behavior.</p>
					<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
					<p>The most straightforward method of obtaining the HTTP client is by using the Dsl class. The static asyncHttpClient() method returns an AsyncHttpClient object:</p>
					<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
					<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
					<p><b>Example 1</b></p>
					<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
				</CCardBody>
		</CCard>
	</>
	)
}

export default AsyncProcess