import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/Styles';


function Styles () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Styles</strong></CCardHeader>
			<CCardBody>
				<p><strong>Inline Styles:</strong></p>
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>		
				<p><strong>CSS Modules:</strong></p>
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>		
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>		
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>		

			</CCardBody>
		</CCard>			
	)
}

export default Styles