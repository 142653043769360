import React from "react";
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Miscellaneous';
import { NavLink } from 'react-router-dom'


function SBMiscellaneous() {
    return (
      <>
        <CCard className="mb-4">
          <CCardHeader><strong>Miscellaneous</strong></CCardHeader>
          <CCardBody>                
            <CAccordion flush>
            <CAccordionItem itemKey={1}>
                <CAccordionHeader>Spring Boot advantages</CAccordionHeader>
                <CAccordionBody> 
                  <ol>
                    <li><p><b>Auto-configuration: </b>Spring Boot automatically configures and sets up your Spring application based on the dependencies you have added to your project. This eliminates the need for manual configuration, saving you time and effort.</p></li>
                    <li><p><b>Standalone Applications: </b>Spring Boot allows you to create standalone, executable Java applications that can be run from the command line.</p></li>
                    <li><p><b>Embedded servers: </b>Spring Boot includes embedded servers like Tomcat, Jetty, and Undertow, which eliminates the need to deploy your application to a separate application server.</p></li>
                    <li><p><b>Production-ready features: </b>Spring Boot includes production-ready features such as metrics, health checks, and externalized configuration, which help you build and operate production-grade applications.</p></li>
                    <li><p><b>Simplified Maven or Gradle configuration: </b>Spring Boot provides a simplified Maven or Gradle configuration, which makes it easy to manage dependencies and build your application.</p></li>
                    <li><p><b>Large community: </b>Spring Framework has a large and active community, which means there is a lot of support and resources available for developers</p></li>
                  </ol>
                </CAccordionBody>
              </CAccordionItem>
              
              <CAccordionItem itemKey={1.1}>
                <CAccordionHeader>How Spring Boot works internally during the application startup</CAccordionHeader>
                <CAccordionBody>                
                  <p>When you start a Spring Boot application, several processes occur internally to bootstrap and initialize the application. Here is an overview of how Spring Boot works internally during the application startup:</p>
                  <ul>
                    <li><p>Main Class Execution: The execution of a Spring Boot application starts with the execution of the main class, which contains the main method.</p></li>
                    <li><p>Classpath Scanning: Spring Boot scans the classpath to identify various components and configurations. It looks for classes annotated with specific annotations like @SpringBootApplication, @Configuration, @ComponentScan, and @EnableAutoConfiguration.</p></li>
                    <li><p>Auto-Configuration: Spring Boot performs auto-configuration based on the dependencies and configurations available in the classpath. It automatically configures various components, such as database connections, web servers, caching, and security, based on the detected dependencies</p></li>
                    <li><p>Bean Initialization: Spring Boot initializes and manages beans defined in the application context. It identifies classes annotated with @Component, @Service, @Controller, and other related annotations. It creates bean instances, resolves dependencies, and injects them into other beans as needed.</p></li>
                    <li><p>Application Context Creation: Spring Boot creates an application context that serves as the central container for managing beans and providing dependency injection. The application context is responsible for initializing and wiring the beans together.</p></li>
                    <li><p>Externalized Configuration: Spring Boot loads configuration properties from various sources, such as application.properties, application.yml, environment variables, and command-line arguments. It binds these properties to the corresponding Java objects and makes them available for use throughout the application.</p></li>
                    <li><p>Embedded Server Setup: If your Spring Boot application includes a web component, Spring Boot configures and starts an embedded web server, such as Tomcat, Jetty, or Undertow. It sets up the server with default configurations, such as port number, context path, and servlet mappings.</p></li>
                    <li><p>Application Lifecycle Events: Spring Boot publishes various lifecycle events during the application startup. These events allow you to hook into different stages of the application startup process and perform custom logic if needed.</p></li>
                    <li><p>Application Ready: Once the initialization process is complete, Spring Boot signals that the application is ready to serve requests. It may display useful information, such as the server's URL and other relevant details, in the application logs.</p></li>                  
                  </ul>
                  <p>By following these internal processes, Spring Boot simplifies the development and deployment of Java applications, providing a streamlined experience while leveraging the power and flexibility of the underlying Spring Framework.</p>
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={101}>
                <CAccordionHeader>Designing microservices</CAccordionHeader>
                <CAccordionBody>    
                  <ul>
                    <li><p>Design the API contracts: Design clear and consistent API contracts for each microservice. Use technologies like REST or GraphQL to define the endpoints, request/response payloads, and data formats.</p></li>  
                    <li><p>Choose communication mechanisms: Decide on the communication mechanisms between microservices. Spring Boot supports various options such as synchronous HTTP-based communication (REST), asynchronous messaging (e.g., RabbitMQ, Apache Kafka), or event-driven architectures (using Spring Cloud Stream).</p></li>  
                    <li><p>Ensure fault tolerance and resilience: Implement fault tolerance mechanisms like circuit breakers (e.g., Netflix Hystrix) and retries to handle failures and maintain system resilience. Use tools like Spring Cloud Circuit Breaker for circuit-breaking patterns.</p></li>  
                    <li><p>Implement security: Apply appropriate security measures to protect microservices and their interactions. Utilize Spring Security to implement authentication, authorization, and other security features.</p></li>  
                    <li><p>Implement monitoring and observability: Incorporate monitoring and observability capabilities to gather metrics, logs, and traces from microservices. Use tools like Spring Cloud Sleuth, Zipkin, or Prometheus to monitor the health and performance of the system.</p></li>  
                    <li><p>Data Management: Decide how data will be managed within each microservice. Each microservice can have its own dedicated database or use separate schemas within a shared database. Consider data consistency, replication, and scalability requirements.</p></li>  
                    <li><p>Deployment and Scalability: Containerize your microservices using technologies like Docker and use container orchestration platforms like Kubernetes for deployment and scaling. This enables easy scaling, versioning, and management of microservices.</p></li>  
                  </ul>             
                </CAccordionBody>
              </CAccordionItem> 
              <CAccordionItem itemKey={2}>
                <CAccordionHeader>How does dependency injection work in Spring Boot?</CAccordionHeader>
                <CAccordionBody>                
                  <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
                </CAccordionBody>
              </CAccordionItem> 
              <CAccordionItem itemKey={3}>
                <CAccordionHeader>What is an application context in Spring Boot and what is its role?</CAccordionHeader>
                <CAccordionBody>                
                  <p>In Spring Boot, the Application Context is a central container for managing beans and their dependencies. It is responsible for configuring, initializing, and wiring together all the beans in a Spring Boot application.</p>

                  <p>The Application Context is created when a Spring Boot application starts up, and it loads all the beans defined in the application, as well as any additional beans provided by Spring Boot. These beans are configured based on the annotations, XML files, or Java configuration classes provided in the application.</p>

                  <p>The Application Context can be accessed in various ways, such as through the @Autowired annotation or by implementing the ApplicationContextAware interface. Once you have access to the Application Context, you can use it to retrieve beans, get properties, or register new beans.</p>

                  <p>In summary, the Application Context is a fundamental part of a Spring Boot application, and it serves as a container for managing and wiring together all the beans in the application.</p>
                </CAccordionBody>
              </CAccordionItem> 
              <CAccordionItem itemKey={4}>
                <CAccordionHeader>How do you configure a Spring Boot application?</CAccordionHeader>
                <CAccordionBody>                
                  <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
                </CAccordionBody>
              </CAccordionItem> 
              <CAccordionItem itemKey={5}>
                <CAccordionHeader>How do you implement caching in a Spring Boot application?</CAccordionHeader>
                <CAccordionBody>                
                  <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
                </CAccordionBody>
              </CAccordionItem>  
              <CAccordionItem itemKey={6}>
                <CAccordionHeader>ApplicationRunner</CAccordionHeader>
                <CAccordionBody>           
                  <p>In Spring Boot, the ApplicationRunner interface is used to perform certain tasks right after the Spring Boot application has started. It allows you to execute custom logic or perform initialization tasks when the application is ready to handle requests.</p>
                  <p>To use ApplicationRunner, you need to implement the interface and override its run() </p>     
                  <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}
                  </SyntaxHighlighter>
                  <p>In the above example, MyApplicationRunner is a component annotated with @Component to make it a Spring bean. It implements the ApplicationRunner interface and overrides the run() method. Any logic or initialization tasks you write within the run() method will be executed when the application starts.</p>

                  <p>The run() method receives an ApplicationArguments object, which provides access to the command-line arguments passed to the application. You can use this object if you need to access and process command-line arguments in your ApplicationRunner.</p>

                  <p>Spring Boot will automatically detect the ApplicationRunner bean and execute its run() method when the application starts up. You can have multiple ApplicationRunner beans in your application, and they will be executed in the order specified by the Spring container.</p>

                  <p>Using ApplicationRunner is a convenient way to perform specific tasks after the application has started, such as loading initial data, setting up caches, initializing connections, or scheduling background tasks.</p>
                </CAccordionBody>
              </CAccordionItem>   
              <CAccordionItem itemKey={7}>
                <CAccordionHeader>Mappings</CAccordionHeader>
                <CAccordionBody>           
                  <ul>
                    <li>
                      <p>@RequestMapping: This is the most generic and commonly used mapping annotation. It can be applied at the class level to define a base URL path for all methods in the controller, as well as at the method level to handle specific HTTP requests. For example:</p>
                      <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>    
                    </li>
                    <li>
                      <p>@GetMapping, @PostMapping, @PutMapping, @DeleteMapping: These annotations are shortcuts for @RequestMapping with specific HTTP methods. They provide a more concise and expressive way of mapping methods to specific HTTP methods. For example:</p>
                      <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>   
                      </li>
                    <li>
                      <p>@PathVariable: This annotation is used to extract values from the URI path and bind them to method parameters. It allows you to access dynamic values in the URL. For example:</p>
                      <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>   
                      </li>
                    <li>
                      <p>@RequestParam: This annotation is used to extract query parameters from the URL and bind them to method parameters. It allows you to access query parameters passed in the URL. For example:</p>
                      <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>   
                      </li>
                  </ul>
                </CAccordionBody>
              </CAccordionItem>   
              <CAccordionItem itemKey={8}>
                <CAccordionHeader>Reading large CSV file in chunks</CAccordionHeader>
                <CAccordionBody>                             
                   <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>   
                </CAccordionBody>
              </CAccordionItem> 
              <CAccordionItem itemKey={9}>
                <CAccordionHeader>Streaming thousands of records in a Spring Boot API</CAccordionHeader>
                <CAccordionBody>
                  <p>The StreamingResponseBody interface in Spring Framework is used to provide streaming data directly to the HTTP response. It's often used to efficiently handle large data transfers without loading everything into memory at once.</p>                             
                   <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter> 
                   <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter>   
                </CAccordionBody>
              </CAccordionItem>  
              <CAccordionItem itemKey={10}>
                <CAccordionHeader>To accept a JSON file and process it </CAccordionHeader>
                <CAccordionBody>
                  <ol>
                    <li>
                      <p><b>Read JSON File:</b></p>
                      <p>Use a library like Jackson or Gson to read the JSON data from the file into a Java object.</p>
                    </li>                    
                    <li>
                      <p><b>Process JSON Data</b></p>
                      <p>Once you have the JSON data in a Java object, you can process it as needed based on your application's requirements.</p>
                    </li>
                  </ol>
                  <p>Here's an example using the Jackson library, which is commonly used for JSON processing:</p>
                  <ol>
                    <li>
                      <p><b>Add Jackson Dependency</b></p>
                      <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter>
                    </li>
                    <li>
                      <p><b>Create Java Objects to Represent JSON Structure:</b></p>
                      <p>Let's assume you have a JSON file containing an array of objects with properties name and age. Create Java classes that mirror the JSON structure:</p>
                      <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter>
                    </li>
                    <li>
                      <p><b>Read and Process JSON File:</b></p>
                      <p>Now, you can read the JSON file and process its content using Jackson:</p>
                      <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code13}</SyntaxHighlighter>
                    </li>                   
                  </ol>
                  <strong>Processing large JSON files</strong>
                  <ol>
                    <li>
                      <p><b>Streaming JSON Parsing:</b></p>
                      <p>Instead of loading the entire JSON file into memory, use streaming JSON parsers that process the JSON data incrementally as it's read from the file. Libraries like Jackson and Gson offer streaming API options for this purpose.</p>
                    </li>
                    <li>
                      <p><b>Use Jackson Streaming API:</b></p>
                      <p>The Jackson library provides a streaming API that allows you to process JSON data without loading the entire content into memory. The main classes to use are <b>JsonParser</b> and <b>JsonGenerator</b>.</p>
                    </li>
                    <li>
                      <p><b>Process Data Incrementally:</b></p>
                      <p>While parsing the JSON, process each JSON element (object, array, etc.) incrementally and only keep relevant data in memory.</p>
                    </li>
                  </ol>
                  <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code14}</SyntaxHighlighter>
                  <p>In this example, the JsonParser processes the JSON data incrementally without loading it all into memory. It reads each JSON token one at a time and extracts the necessary data.</p>
                </CAccordionBody>
              </CAccordionItem>         
            </CAccordion>
          </CCardBody>
        </CCard>
      </>        
    )
}

export default SBMiscellaneous;