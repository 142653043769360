export const codeSnippet = {
  code1: `@Repository
public class UserRepository {
  private final JdbcTemplate jdbcTemplate;

  @Autowired
  public UserRepository(JdbcTemplate jdbcTemplate) {
    this.jdbcTemplate = jdbcTemplate;
  }

  public User save(User user) {
    jdbcTemplate.update("INSERT INTO users (username, password) VALUES (?, ?)", user.getUsername(), user.getPassword());
    return user;
  }
}`
}

