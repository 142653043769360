export const codeSnippet = {
  code1: `minikube start
miniube dashboard`,
  code2: `# Use a base image with Java and the desired Alpine Linux version
FROM adoptopenjdk:11-jdk-hotspot

# Set the working directory in the container
WORKDIR /app

# Copy the Spring Boot executable JAR file to the container
COPY target/my-application.jar ./my-application.jar

# Expose the desired port for the Spring Boot application
EXPOSE 8080

# Set the command to run the Spring Boot application
CMD ["java", "-jar", "my-application.jar"]`,
  code2000: `eval $(minikube docker-env)`,
  code3: `docker build -t <imageName>:<tag> .
docker build -t my-first-k8s-app:1.0 .`,
  code4: `docker run -p 8080:8080 <imageName>:<tag>
docker run -p 8080:8080 my-first-k8s-app:1.0`,
  code5: `docker exec -it <container_name_or_id> bash`,
  code6: `kubectl config set-context --current --namespace=default`,
  code6000: `spring:
  application:
    name: \${PROJECT_NAME}
server:
  port: 8080

admin-user:
  name: \${user_name}
  password: \${user_password}`,
  code6001: `mvn clean install -DPROJECT_NAME=your-project-name -Duser_password=adminPassword123456 -Duser_name=adminUser`,
  code6002: `apiVersion: v1
kind: ConfigMap
metadata:
  name: my-configmap
data:
  userName: adminUser`,
  code6003: `kubectl apply -f k8s-configmap.yaml`,
  code6004: `kubectl get configMap`,
  code6005: `apiVersion: v1
kind: Secret
metadata:
  name: my-secret
data:
  password: YWRtaW5QYXNzd29yZDEyMzQ1    # echo -n 'adminPassword12345' | base64 (Base64-encoded value)`,
  code6006: `kubectl apply -f k8s-secret.yaml`,
  code6007: `kubectl get secret`,
  code7: `apiVersion: apps/v1
kind: Deployment
metadata:
  name: my-spring-boot-app
spec:
  replicas: 2
  selector:
    matchLabels:
      app: my-spring-boot-app
  template:
    metadata:
      labels:
        app: my-spring-boot-app
    spec:
      containers:
        - name: my-first-k8s-app
          image: my-first-k8s-app:1.1
          imagePullPolicy: IfNotPresent
          ports:
            - containerPort: 8080
          env:
            - name: PROJECT_NAME
              value: Docker Kubernetes Application
            - name: user_name
              valueFrom:
                configMapKeyRef:
                  name: my-configmap # name of the configMap file - k8s-configmap.yaml
                  key: userName
            - name: user_password
              valueFrom:
                secretKeyRef:
                  name: my-secret # name of the secret file - k8s-secret.yaml
                  key: password`,
  code8: `kubectl apply -f <deployment-file-name>.yaml `,
  code9: `kubectl describe pod <podName>`,
  code10: `kubectl get deployment // my-spring-boot-app
kubectl get replicaset // my-spring-boot-app-759d6849f
kubectl get pod // my-spring-boot-app-759d6849f-f578z`,
  code11: `apiVersion: v1
kind: Service
metadata:
  name: my-spring-boot-service
spec:
  selector:
    app: my-spring-boot-app
  ports:
    - protocol: "TCP"
      port: 8080
      targetPort: 8080
  type: LoadBalancer`,
  code12: `kubectl get service`,
  code13: `minikube service <serviceName>
minikube service my-spring-boot-service`,
  code14: ``,
  code15: ``,
  code16: ``,
  code17: ``,
  code18: ``,
  code19: ``,
  code20: ``,
  code21: ``,
  code22: ``,
}

