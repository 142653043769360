export const codeSnippet = {
  code1: `import java.util.concurrent.Executor;

import org.springframework.boot.SpringApplication;
import org.springframework.boot.autoconfigure.SpringBootApplication;
import org.springframework.context.annotation.Bean;
import org.springframework.scheduling.annotation.EnableAsync;
import org.springframework.scheduling.concurrent.ThreadPoolTaskExecutor;

@SpringBootApplication
@EnableAsync
public class Application {

    public static void main(String[] args) {
      SpringApplication.run(Application.class, args);
    }

    @Bean
    public Executor taskExecutor() {
      ThreadPoolTaskExecutor executor = new ThreadPoolTaskExecutor();
      executor.setCorePoolSize(2);
      executor.setMaxPoolSize(4);
      executor.setQueueCapacity(500);
      executor.setThreadNamePrefix("GithubLookup-");
      executor.initialize();
      return executor;
    }
}`,
  code2: `import org.springframework.scheduling.annotation.Async;
import org.springframework.stereotype.Service;

@Service
public class MyService {

    @Async("taskExecutor")
    public void doSomething() {
      // Do something
    }
}`
}

