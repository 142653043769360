import React from "react";
import { NavLink } from 'react-router-dom'
import { CRow, CCol, CCard, CCardBody, CCardHeader, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import CommonDashboard from "../../components/CommonDashboard";

function JavaBootDashboard () {
	return (
		<>
		<CommonDashboard />
		<CCard className="mb-4">
			<CCardBody>                
				<CAccordion flush>
					<CAccordionItem itemKey={1}>
						<CAccordionHeader>Java Version</CAccordionHeader>
						<CAccordionBody>                
							<p>Java 8 and Java 11 are both major releases of the Java platform, but they introduce significant changes and improvements.</p>
							<p>Java 8 was first released in March 2014 and it introduced several new features and improvements, including:</p>
							<ul>
								<li><p>Lambda expressions: Java 8 added support for functional programming through the introduction of lambda expressions, which allow for the creation of anonymous functions.</p></li>
								<li><p>Streams: Java 8 introduced a new Streams API, which allows for functional-style operations on collections of data.</p></li>
								<li><p>Date and Time API: Java 8 introduced a new Date and Time API, which is more intuitive and efficient than the previous java.util.Date and java.util.Calendar classes.</p></li>
								<li><p>Default and static methods in interfaces: Java 8 allows for the definition of default and static methods in interfaces, which provide additional flexibility for developers.</p></li>
							</ul>
							<p>Java 11, released in September 2018, brought some significant changes and improvements, including:</p>
							<ul>
								<li><p>Removal of Java EE and CORBA modules: Java 11 removed Java EE and CORBA modules from the Java SE Platform, which required applications that were depending on these modules to migrate.</p></li>
								<li><p>String encapsulation: Java 11 introduced string encapsulation, which makes strings read-only and unmodifiable, to improve the security and safety of the Java platform.</p></li>
								<li><p>HTTP Client: Java 11 introduced a new HTTP client that improves the performance and ease of use of making HTTP requests.</p></li>
								<li><p>Improved Garbage collector and memory management: Java 11 introduced the Epsilon GC, a no-op garbage collector, and also make improvements on other GCs to reduce GC pause time.</p></li>
								<li><p>Ahead-of-time (AOT) Compilation: Java 11 introduced AOT compilation which allows for the ahead-of-time compilation of Java code, improving the startup and overall performance of Java applications.</p></li>
							</ul>
							<p>As a general rule, Java 8 will be more stable, being longer in the market, but Java 11 brings some useful features and improvements that are useful for some specific scenarios. It's worth considering the use-case of your application and what features you want to utilize while deciding on which version of Java to use.</p>
						</CAccordionBody>
					</CAccordionItem>           
				</CAccordion>
			</CCardBody>
		</CCard>
		<CCard className="mb-4">
			<CCardHeader><strong>Pending</strong></CCardHeader>
			<CCardBody>                
				<CAccordion flush>
					<CAccordionItem itemKey={1}>
						<CAccordionHeader>Java</CAccordionHeader>
						<CAccordionBody>                
							<ol>
								<li><p>Try Catch - Throw - Exception</p></li>
								<li><p>Stack Memory and Heap</p></li>
								<li><p>Simple thread pool factory</p></li>
								<li><p>Websocket</p></li>
							</ol>
						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={2}>
						<CAccordionHeader>React</CAccordionHeader>
						<CAccordionBody>                
							<ol>
								<li><p>Redux Toolkit</p></li>
							</ol>
						</CAccordionBody>						
					</CAccordionItem>            
				</CAccordion>
			</CCardBody>
		</CCard>
	</>
	)
}

export default JavaBootDashboard