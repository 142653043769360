export const codeSnippet = {
  code01: `@ConfigurationProperties(prefix = "app")
public class AppProperties {

  private String name;
  private String version;
  private String description;

  // Getters and setters for the properties

}`,
  code02:`app.name=My App
app.version=1.0.0
app.description=A simple app`,
  code03:`@Component
public class MyBean {

  @Autowired
  private AppProperties appProperties;

  // Use the appProperties fields in your bean

}`,
  code1: `email=test@mkyong.com
thread-pool=12`,
  code2: `@Component
@PropertySource("classpath:global.properties")
public class GlobalProperties {

  @Value('\${thread-pool}\')
  private int threadPool;

  @Value('\${email}\')
  private String email;
  
  //getters and setters
}`,
  code3: `import org.springframework.boot.context.properties.ConfigurationProperties;
@Component
@PropertySource("classpath:global.properties")
@ConfigurationProperties   or @ConfigurationProperties('alias name') and remove @PropertySource("classpath:global.properties")
public class GlobalProperties {

  private int threadPool;
  private String email;

  //getters and setters
}`,
code4: `#Logging
logging.level.org.springframework.web=ERROR
logging.level.com.mkyong=DEBUG

#Global
email=test@mkyong.com
thread-pool=10

#App
app.menus[0].title=Home
app.menus[0].name=Home
app.menus[0].path=/
app.menus[1].title=Login
app.menus[1].name=Login
app.menus[1].path=/login

app.compiler.timeout=5
app.compiler.output-folder=/temp/

app.error=/error/`,
  code5: `@Component
@ConfigurationProperties("app") // prefix app, find app.* values
public class AppProperties {

  private String error;
  private List<Menu> menus = new ArrayList<>();
  private Compiler compiler = new Compiler();

  public static class Menu {
    private String name;
    private String path;
    private String title;

    //getters and setters

    @Override
    public String toString() {
      return "Menu{" +
        "name='" + name + '\'' +
        ", path='" + path + '\'' +
        ", title='" + title + '\'' +
        '}';
    }
  }

  public static class Compiler {
    private String timeout;
    private String outputFolder;

    //getters and setters

    @Override
    public String toString() {
      return "Compiler{" +
        "timeout='" + timeout + '\'' +
        ", outputFolder='" + outputFolder + '\'' +
        '}';
    }
  }
  //getters and setters
}`,
  code6: `@Component
@ConfigurationProperties // no prefix, find root level values.
public class GlobalProperties {

  private int threadPool;
  private String email;

//getters and setters
}`
}

