import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Service';

function Service () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>@Async</strong></CCardHeader>
      <CCardBody>
        <p>@Service is a Spring annotation that is used to indicate that a class is a service component. Service components are typically used to encapsulate business logic and perform tasks that are specific to the application.</p>

        <p>In Spring, service components are typically created as singleton beans and are managed by the Spring container. They can be injected into other components, such as controllers or repositories, using dependency injection.</p>

        <p>Here is an example of how you might use @Service in a Spring application:</p>

        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
        <p>In this example, the UserService class is annotated with @Service, indicating that it is a service component. It has a dependency on a UserRepository, which is injected into it using @Autowired. The UserService class contains business logic for creating and saving new users.</p>
        
      </CCardBody>
    </CCard>        
  )
}

export default Service