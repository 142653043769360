import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/UseRef';

function Introduction () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>Introduction</strong></CCardHeader>
        <CCardBody>          
          <p><strong>Skip Test</strong>: mvn package -DskipTests</p>
          <p><strong>Spring boot run using maven</strong>: mvn spring-boot:run</p>
          <p><strong>Spring boot run using intellij</strong>: spring-boot:run -Dspring-boot.run.profiles=local</p>
          <p><strong>Spring boot package using intellij</strong>: spring-boot:clean spring-boot:repackage -Dspring-boot.run.profiles=prod</p>
          
          
        </CCardBody>
      </CCard>        
    )
}

export default Introduction