import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/angular/ShareData';

function ShareData () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Sharing Data</strong></CCardHeader>
      <CCardBody>    
        <p>In Angular, data can be shared between components using a variety of techniques, such as:</p>      
        <ol>
          <li><p>Using a shared service: A shared service can be used to store data and make it available to multiple components.</p></li>
          <li><p>Using the @Input() and @Output() decorators: These decorators can be used to pass data from a parent component to a child component and vice versa.</p></li>
        </ol>
        <b><p>@Input()</p></b>
        <p>The @Input() decorator in Angular is used to pass data from a parent component to a child component. It allows a child component to receive data from its parent component.</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
        <p>This is the child component, it receives a message from the parent component.</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
        <p>This is the parent component, it has a property parentMessage and it passes the value of this property to the child component using the [message] attribute.</p>
        <p>In this example, the ParentComponent is the parent of the ChildComponent, and it passes the value of the parentMessage property to the ChildComponent using the [message] attribute. The ChildComponent then displays the message using the &lt;p&gt; element in its template.</p>
        <p>The @Input() decorator is used to define the message property in the child component, and the [message] attribute is used to bind the value of the parentMessage property to the message property in the child component.</p>
        <p>Please note that, if you want to pass a complex object, you need to create a new instance of that object to avoid reference issues.</p>

        <b><p>@Output()</p></b>
        <p>The @Output() decorator in Angular is used to pass data from a child component to a parent component. It allows a child component to emit an event and send data to its parent component.</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
        <p>This is the child component, it emits an event when the button is clicked and sends a message.</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
        <p>This is the parent component, it listens to the event emitted by the child component and receives the message.</p>
        <p>In this example, the ChildComponent has a button that when clicked, emits an event called messageEvent. This event sends a message to the parent component. The ParentComponent listens to this event using the (messageEvent) attribute and receives the message using the receiveMessage function. The function assigns the message to a property called message, which is displayed in the template using the &lt;p&gt; element.</p>
        <p>The @Output() decorator is used to define the messageEvent property in the child component, and the (messageEvent) attribute is used to bind the event to the receiveMessage function in the parent component.</p>
        <p>Please note that, the EventEmitter is used to create an event that can be emitted. The type parameter of the EventEmitter is used to specify the type of data that will be emitted with the event.</p>
        <b><p>Shared service</p></b>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>


      </CCardBody>
    </CCard>        
  )
}

export default ShareData;