export const codeSnippet = {
    code1: `useEffect(callback[, dependencies]);`,
    code2: `let controller = new AbortController();
let signal = controller.signal;
    
// The party that performs a cancelable operation
// gets the "signal" object
// and sets the listener to trigger when controller.abort() is called
signal.addEventListener('abort', () => alert("abort!"));

// The other party, that cancels (at any point later):
controller.abort(); // abort!

// The event triggers and signal.aborted becomes true
alert(signal.aborted); // true`,
    code3: `let controller = new AbortController();
fetch(url, {
    signal: controller.signal
});`,
    code4: `controller.abort();`,
    code5: `// abort in 1 second
let controller = new AbortController();
setTimeout(() => controller.abort(), 1000);

try {
    let response = await fetch('/article/fetch-abort/demo/hang', {
        signal: controller.signal
    });
} catch(err) {
    if (err.name == 'AbortError') { // handle abort()
        alert("Aborted!");
    } else {
        throw err;
    }
}`
}

