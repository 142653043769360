import React from "react";
import { CCard, CCardHeader, CCardBody} from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/java/Lambdas'


function Lambdas () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Lambdas</strong></CCardHeader>
			<CCardBody>
				<strong><p>Advantages</p></strong>
				<ol>
					<li><p>Improved readability: Lambda expressions can make your code more readable by replacing complex anonymous inner classes with a more concise and expressive syntax. This can make it easier to understand what your code is doing, especially when dealing with functional interfaces.</p></li>
					<li><p>Concise code: Lambda expressions allow you to write code that is more compact, as you don't need to write as much boilerplate code. This can make your code easier to write and maintain, and it can also improve performance by reducing the amount of bytecode that needs to be generated.</p></li>
					<li><p>Improved performance: The use of lambda expressions can improve the performance of your code by allowing the JVM to use more advanced optimization techniques. This is because lambda expressions enable more efficient use of parallel processing, which can take advantage of multi-core processors and improve the performance of your code.</p></li>
					<li><p>Functional programming support: Lambda expressions provide better support for functional programming, which is a programming paradigm that emphasizes the use of functions as first-class citizens, and supports the use of immutable data. This can help make your code more predictable and easier to reason about.</p></li>
					<li><p>Improved type inference: The use of lambda expressions can improve the type inference in your code, making it easier to write code that is both type-safe and more readable.</p></li>
					<li><p>Better API design: By using functional interfaces and lambda expressions, the API designers can create APIs that are more expressive and easier to use, which in turn can improve the usability and readability of the API.</p></li>
				</ol>
				<p>In summary, lambda expressions provide a more expressive and concise syntax for functional interfaces, and improve the performance of your code, making it more readable, maintainable, and adaptable to the functional paradigm.</p>
				<strong><p>Lambda Expressions</p></strong>
				<p>Lambda expressions basically express instances of functional interfaces (<b>An interface with single abstract method is called functional interface</b>. An example is java.lang.Runnable). lambda expressions implement the only abstract function and therefore implement functional interfaces</p>
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
				
				<p>Here are some common use cases for lambda expressions in Java:</p>
				<ul>
					<li>
						<p>Iterating through a collection: Lambda expressions can be used in conjunction with the forEach() method to perform an operation on each element of a collection.</p>
						<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
					</li>
					<li>
						<p>Stream processing: The Stream API, which was introduced in Java 8, allows for functional-style operations on collections of data. Lambda expressions can be used to define the operations to be performed on the elements of the stream.</p>
						<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
					</li>
					<li>
						<p>Parallel processing: Lambda expressions can be used to apply an operation to each element of a collection in parallel, using the parallelStream() method.</p>
						<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
					</li>
				</ul>				
			</CCardBody>
	</CCard>
			
	)
}

export default Lambdas