import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
import sellingPut1 from '../../assets/images/sellingPut1.png'
import sellingPut2 from '../../assets/images/sellingPut2.png'

function SellingPut () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Selling a Put - Short Put - Credit Received</strong></CCardHeader>
			<CCardBody>
				<p><strong>Obligation to buy</strong> the underlying asset at a specific price on or before a specific date.</p>
				<p><strong>Sell 1 deep OTM Puts.</strong></p>
				<ui>
					<li>Bullish</li>
					<li style={{color: 'green'}}><strong>Positive Theta.</strong></li>
					<li>Limited Profit.</li>
					<li>Unlimited Loss.</li>
					<li>Avoid selling close to the market and in <strong>low volatility (<span style={{color: 'green'}}>low volatility is good</span>).</strong></li>
					<li>Avoid selling close to the market (expiration), will get more premium <strong style={{color: 'green'}}>(theta affect)</strong></li>
				</ui><br/>
				<p><strong>Break-even point = Put Strike - Premium Received</strong></p>
				<p>Let's take a look at a short put's Greek exposures to help explain the strategy:</p>

				<CTable bordered>
					<CTableBody>
						<CTableRow>
							<CTableHeaderCell scope="row">Delta</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'green'}}>Positive</CTableHeaderCell>
							<CTableDataCell><strong>Put prices decrease when the stock price rises</strong>, benefitting put sellers. When the stock price falls, put prices increase, which leads to losses for put sellers.</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">Gamma</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'red'}}>Negative</CTableHeaderCell>
							<CTableDataCell>A short put's position delta gets closer to 0 as the stock increases and closer to +100 as the stock price falls.</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">Theta</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'green'}}>Positive</CTableHeaderCell>
							<CTableDataCell>The extrinsic value of options decays over time, leading to decreasing option prices (good for put sellers).</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">Vega</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'red'}}>Negative</CTableHeaderCell>
							<CTableDataCell>Increases in IV indicate an increase in option prices, which is harmful to put sellers. <strong>Decreases in IV indicate falling option prices, which is great for put sellers.</strong></CTableDataCell>
						</CTableRow>
					</CTableBody>
				</CTable><br/>
				
				<img src={sellingPut1} width="48%" style={{padding: '5px'}}/>
				<img src={sellingPut2} width="48%" style={{padding: '5px'}}/>
				<br/><br/>

			</CCardBody>
	</CCard>
			
	)
}

export default SellingPut