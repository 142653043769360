import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'

function LongCallVerticals () {
	return (
		<CCard className="mb-4">
		<CCardHeader><strong>Long Call Verticals (Bullish/Neutral) (Debit Spread)</strong></CCardHeader>
		<CCardBody>
			<p>Other Names: <strong>Long Call Spread, Bull Call Spread, Debit Call Spread, Buying a Call Spread</strong></p>
			<strong>Potential Entry:</strong>
			<ul>
				<li>Stock Bouncing off a support level.</li>
					<ul>
						<li>Breakout above resistance</li>		
					</ul>
			</ul>			
			<strong>Stock Selection:</strong>				
			<ul>
				<li>Stock that appears to be Bullish</li>
				<li>Stock with higher <span style={{color: 'green', fontWeight: 'bold'}}>trading volume</span> (open interest / volume) -> <span style={{fontWeight: 'bold'}}>1m+</span></li>		
				<li>Option with 'tight' <span style={{color: 'green', fontWeight: 'bold'}}>bid/ask price</span></li>
				<li>When <span style={{color: 'green', fontWeight: 'bold'}}>Delta</span> between <span style={{color: 'green', fontWeight: 'bold'}}>.20 & .40</span></li>		
				<li><span style={{color: 'green', fontWeight: 'bold'}}>IV</span> should be {'<'} 20 </li>
				<li>At expiration, the option should move to ITM.</li>					
				<li>Strike price should be <span style={{color: 'green', fontWeight: 'bold'}}>30 - 40</span> days</li>
			</ul>
			<strong>Strategy:</strong>				
			<ul>
				<li>Buy an At-the-money (ATM) <strong>Call</strong>. or ITM (but less profit, more safe)</li>
				<li>Sell an Out-of-the-money (OTM) <strong>Call.</strong></li>
			</ul>
			<p><strong>Max profit:</strong> Difference between Strike price - Debit Paid.</p>
			<p><strong>Max Loss:</strong> Debit Paid.</p>
			<p><strong>Expected Break Even:</strong> Long Put Strike Price - Debit Paid.</p>

			<p>% of return calculation</p>
			<CTable bordered>
				<CTableBody>
					<CTableRow>
						<CTableHeaderCell scope="row">
							= Debit /  (Difference between strikes - Debit paid)<br/>
							= Max Profit / Max Loss
						</CTableHeaderCell>							
					</CTableRow>
				</CTableBody>
			</CTable>
			<br/><br/>
		</CCardBody>
	</CCard>			
	)
}

export default LongCallVerticals