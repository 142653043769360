export const codeSnippet = {
  code1:`import React from 'react'; 
// Creating the context object and passing the default values.
const authContext = React.createContext({status:null,login:()=>{}});
    
export default authContext;`,
  code2:`import React, { useState } from "react";
import Auth from "./Auth";
import AuthContext from "./auth-context";
    
const App = () => {
  //using the state to dynamicallly pass the values to the context
  
  const [authstatus, setauthstatus] = useState(false);
  const loginHandler = () => {
    setauthstatus(true);
  };
  return (
  <React.Fragment>
    <AuthContext.Provider value={{ status: authstatus, login: loginHandler }}>
      <Auth />
    </AuthContext.Provider>
  </React.Fragment>
  );
};
export default App;`,
  code3:`import React, { useContext } from "react";
import AuthContext from "./auth-context";
    
const Auth = () => {
  // Now all the data stored in the context can
  // be accessed with the auth variable
  const auth = useContext(AuthContext);
  console.log(auth.status);
  return (
    <div>
      <h1>Are you authenticated?</h1>
      {auth.status ?
        <p>Yes you are</p>
      :
        <p>Nopes</p>
      }
      <button onClick={auth.login}>Click To Login</button>
    </div>
  );
};
export default Auth;`,
    code4:``,
    code5:``,
    code6:``,
    code7:``,
    code8:``,
}