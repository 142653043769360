import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/javascript/Es6';

function Es6 () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>ES6</strong></CCardHeader>
      <CCardBody>
        <p><strong>Export & Import</strong></p>
        <p>person.js</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
        <p>utility.js</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
        <p>app.js</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>

        <p><strong>Spread Operator</strong></p>
        <p>Spread operator is used to split up array elements or object properties. const newArray = [...oldArray,1,2] const newObject = {"{.."}oldObject, newPro:5{"}"} Spread operator can be used in many cases,like when we want to expand,copy,concat</p>
        <p><b>Concat</b></p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
        <p><b>Copy(like splice method)</b></p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
        <p><b>Expand</b></p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
        <p><strong>Expand</strong></p>
        <p>In ECMAScript 6 (ES6), the rest operator allows you to represent an indefinite number of arguments as an array. The rest operator is represented by three dots (...) and is used to represent the remaining elements of an array after the first few have been taken.</p>
        <p>Here's an example of how you might use the rest operator to sum all the elements of an array:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
        <p>In this example, the rest operator is used to capture all the elements of the numbers array and store them in the numbers variable. The function then iterates over the numbers array and sums all the elements.</p>
        <p>The rest operator is often used in combination with the spread operator, which allows you to expand an array into its individual elements.</p>
        <p>For example:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
        <p>Here, the spread operator is used to expand the numbers array into its individual elements, which are then passed to the sum function as separate arguments.</p>
        <p>Example 2</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter>
        <p><strong>Destructuring</strong></p>
        <p>In ECMAScript 6 (ES6), destructuring allows you to extract values from arrays, or properties from objects, and bind them to variables.</p>
        <p>Here's an example of array destructuring:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter>
        <p>In this example, the values of the numbers array are extracted and bound to the variables a, b, and c, in that order.</p>
        <p>You can also use destructuring to extract only certain elements from an array, and to assign default values to the extracted elements:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter>
        <p>Here, the second element of the numbers array is skipped, and the third element is extracted and assigned to the c variable. In the second example, the f variable is assigned a default value of 0 if the third element of the numbers array is not present.</p>
        <p>You can also use destructuring to extract values from objects:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter>
        <p>In this example, the name and age properties of the person object are extracted and bound to the name and age variables, respectively. The address property is also extracted, but it is itself an object, so you can use destructuring again to extract the city and country properties of the address object.</p>
        <p>Destructuring is a powerful and concise way to extract values from arrays and objects and bind them to variables. It can make your code more readable and easier to maintain.</p>
      </CCardBody>
    </CCard>        
  )
}

export default Es6;