import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/UseEffect';

function UseEffect () {

    return (
        <CCard className="mb-4">
			<CCardHeader><strong>UseEffect</strong></CCardHeader>
            <CCardBody>
                <CCard>
                    <CCardBody>
                        <p>Introduction to <NavLink  to="/react/hooks" active>hooks</NavLink></p>
                        <blockquote>
                          <p>If you’re familiar with React class lifecycle methods, you can think of <code className="gatsby-code-text">useEffect</code> Hook as <code className="gatsby-code-text">componentDidMount</code>, <code className="gatsby-code-text">componentDidUpdate</code>, and <code className="gatsby-code-text">componentWillUnmount</code> combined.</p>
                        </blockquote>
                    </CCardBody>
                </CCard><br/>
                <p>A functional React component uses props and/or state to calculate the output. If the functional component makes calculations that don't target the output value, then these calculations are named side-effects.</p>
                <p>Examples of side-effects are fetch requests, manipulating DOM directly, using timer functions like setTimeout(), and more.</p>
				        <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
                <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
                <ul>
                  <li>
                    <em>callback is the function containing the side-effect logic. callback is executed right after changes were being pushed to DOM.</em>
                  </li>
                  <li>
                    <p>dependencies is an optional array of dependencies. useEffect() executes callback only if the dependencies have changed between renderings.</p>
                  </li>
                </ul>
                <strong>The side-effect runs after every rendering.</strong>
                <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
                <strong>The side-effect runs once after the initial rendering.</strong>
                <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
                <strong>The side-effect runs only when any depenendecy value changes. [prop1, prop2, ..., state1, state2]</strong>
                <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>

                <h5><strong>Component lifecycle</strong></h5>
                <strong>1. Component did mount</strong>
                <p>Use an empty dependencies array to invoke a side-effect once after component mounting:</p>
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>

                <strong>2. Component did update</strong>
                <p>Each time the side-effect uses props or state values, you must indicate these values as dependencies:</p>
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter>
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter>
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter>

                <h5><strong>useEffect cleanup function</strong></h5>
                <p>useEffect cleanup is a function in the useEffect Hook that allows us to tidy up our code before our component unmounts. When our code runs and reruns for every render, useEffect also cleans up after itself using the cleanup function.</p>

                <p>We have 2 routes, user and membe. If user route is called, it will call the API to get user details. if you call member route before user API call completes, we get the error</p>

                <img src="https://blog.logrocket.com/wp-content/uploads/2021/10/Warning-error.png" width="100%"/>
                <p></p>
                <p>More about <NavLink  to="/react/abortController" active>AbortController</NavLink></p>
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter>

            </CCardBody>
      </CCard>
        
    )
}

export default UseEffect