export const codeSnippet = {
  code1: `import java.util.LinkedList;
import java.util.Queue;

public class QueueExample {
  public static void main(String[] args) {
    // Create a queue
    Queue<String> queue = new LinkedList<>();

    // Add elements to the queue
    queue.add("apple");
    queue.add("banana");
    queue.add("cherry");

    // Check if the queue is empty
    System.out.println(queue.isEmpty()); // Outputs false

    // Get the head of the queue (does not remove the element)
    System.out.println(queue.peek()); // Outputs "apple"

    // Remove the head of the queue
    System.out.println(queue.poll()); // Outputs "apple"

    // Iterate over the elements in the queue
    for (String s : queue) {
      System.out.println(s);
    }
  }
}`,
  code2: `false
apple
apple
banana
cherry`
}

