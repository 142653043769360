export const codeSnippet = {
  code1: `@SpringBootApplication
@EnableAutoConfiguration
public class MyApplication {
  public static void main(String[] args) {
    SpringApplication.run(MyApplication.class, args);
  }
}`,
}

