import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  // CTableHead,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
	import { NavLink } from "react-router-dom";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/java/Thread'


function Thread() {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Thread</strong></CCardHeader>
			<CCardBody>		
				<p>In Java, you can create a new thread in two ways: by extending the <b>Thread class</b> or by implementing the <b>Runnable interface.</b></p>
				<h4><p>Runnable interface</p></h4>
				<p>In Java, the Runnable interface is a functional interface that provides a single abstract method run(). The run() method specifies the code that should be executed when a thread is started.</p>	
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
				<p>The start() method on the Thread object starts a new thread and calls the run() method of the Runnable object in the new thread.</p>
				<h5><p>Runnable using Lambda</p></h5>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
				<h4><p>Thread Class</p></h4>
				<p>In Java, the Thread class is a fundamental class for representing and managing threads. A thread is a lightweight unit of execution that runs concurrently with other threads within a program.</p>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
				<h4><p>Executors</p></h4>
				<p>Executors is a class in Java that provides a convenient way to create and manage threads using thread pools. A thread pool is a collection of worker threads that are managed by a thread scheduler. The thread scheduler is responsible for allocating threads from the pool to run tasks, and for maintaining the pool of available threads.</p>
				<p><b>Using a thread pool can be more efficient than creating a new thread for each task, because it allows you to reuse existing threads, which can be faster and consume less memory. In addition, thread pools provide a way to control the number of threads in your application, which can help you manage system resources more effectively.</b></p>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
				<h5><p>Executors</p></h5>
				<p>The Executors class provides several static factory methods for creating Executor objects:</p>
				<ul>
					<li><p><b>newFixedThreadPool:</b> creates a fixed-size thread pool, where the number of worker threads is specified as a parameter. Tasks are executed in a first-in, first-out order.</p></li>
					<li><p><b>newCachedThreadPool:</b> creates an expandable thread pool, where the number of worker threads is determined dynamically based on the current number of tasks and the availability of idle threads. This type of thread pool is appropriate for applications that require a large number of short-lived tasks.</p></li>
					<li><p><b>newSingleThreadExecutor:</b>creates a single-threaded executor, where only one worker thread is used to execute tasks. Tasks are executed in a first-in, first-out order.</p></li>
					<li><p><b>newScheduledThreadPool:</b>creates a thread pool that can be used to schedule tasks to be executed after a specified delay, or to be executed periodically.</p></li>
					<li><p><b>newWorkStealingPool:</b>creates a thread pool that uses a work-stealing algorithm to balance the load between worker threads. This type of thread pool is appropriate for applications that have a large number of short-lived tasks.</p></li>
				</ul>
				<h5><p>Deciding the number of threads to use</p></h5>
				<p>When deciding the number of threads to use in a newFixedThreadPool, you should consider several factors, such as the number of cores available in your system, the type of tasks being executed, and the amount of memory available to the system.</p>
				<ol>
					<li><p>Number of cores: The number of threads in the pool should be equal to or less than the number of cores available in the system. This will ensure that the threads can be executed in parallel, providing optimal performance.</p></li>
					<li><p>Type of tasks: If the tasks are CPU-bound, then the number of threads should be equal to the number of cores, as this will allow the CPU to be fully utilized. If the tasks are I/O-bound, then the number of threads should be higher than the number of cores, as this will allow the threads to be executed in parallel, improving the overall performance.</p></li>
					<li><p>Memory usage: The number of threads in the pool should be limited by the amount of memory available to the system. Each thread requires a certain amount of memory, and if the pool is too large, it could cause the system to run out of memory and crash.</p></li>
				</ol>
				<p>In general, a good starting point is to use a number of threads that is equal to the number of cores available in the system.</p>
				<h5><p>Find the number of cores in your system</p></h5>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
				<h5><p> submit and execute</p></h5>
				<ul>
					<li><p>The submit method returns a Future object that can be used to retrieve the result of the task. The Future interface provides a way to check if the task has completed, wait for its completion, and retrieve the result of the task. This can be useful when you need to perform some action after the task has completed, or when you need to retrieve the result of the task for further processing.</p></li>
					<li><p>The execute method, on the other hand, does not return a Future object. It simply submits the task for execution and returns immediately. This method is useful when you do not need to retrieve the result of the task or perform any action after the task has completed.</p></li>
				</ul>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
				<h5><p>Future</p></h5>
				<p>In Java, a Future object represents the result of an asynchronous computation. It acts as a placeholder for the result of a task that is executed in the background by another thread.</p>
				<p>A Future object can be obtained by submitting a task to an ExecutorService. The task can be implemented using a Runnable or a Callable object, depending on whether the task returns a result or not. Once the task is submitted, the Future object is returned immediately, allowing the submitting thread to continue executing other tasks. The result of the task can be obtained later, when it is available, by calling the get method on the Future object.</p>
				<p>The Future object provides methods to check the status of the task, such as isDone or isCancelled, which can be used to determine whether the task has completed or been cancelled. It also provides a cancel method, which allows the task to be cancelled if it has not yet started or if it is in progress.</p>
				<p>The use of Future objects can be useful when implementing asynchronous processing, as they provide a way to control and manage the execution of background tasks, and to obtain the results of these tasks when they are ready. This can be useful in improving the performance and responsiveness of an application, as it allows tasks to be executed in the background, freeing up the main thread to perform other tasks.</p>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
			</CCardBody>
	</CCard>
			
	)
}

export default Thread;