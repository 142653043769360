export const codeSnippet = {
  code1: `const person = {
  name: 'max'
}
export default person`,
  code2: `export const clean = () => { .... }
export const baseDate = 10;`,
  code3: `import person from './person.js'    // We can use person/per or any name here. because in person.js file we used default export 
import prs from './person.js'

import {baseDate} from './utility.js'
import {clean} from './utility.js'
import {baseData as bd} from './utility.js'
import * as bundled from './utility.js' // this will be object(bundled)`,
  code4: `// normal array concat() method 
let arr = [1,2,3]; 
let arr2 = [4,5]; 
  
arr = arr.concat(arr2); 
  
console.log(arr); // [ 1, 2, 3, 4, 5 ]


// spread operator doing the concat job 
let arr = [1,2,3]; 
let arr2 = [4,5]; 
  
arr = [...arr,...arr2]; 
console.log(arr); // [ 1, 2, 3, 4, 5 ]`,
  code5: `// copying without the spread operator 
let arr = ['a','b','c']; 
let arr2 = arr; 
  
console.log(arr2); // [ 'a', 'b', 'c' ] 


// changed the original array 
let arr = ['a','b','c']; 
let arr2 = arr; 
  
arr2.push('d'); 
  
console.log(arr2); 
console.log(arr); // even affected the original array(arr) 


let arr = ['a','b','c']; 
let arr2 = [...arr]; 
  
console.log(arr); // [ 'a', 'b', 'c' ] 
  
arr2.push('d'); //inserting an element at the end of arr2 
  
console.log(arr2); // [ 'a', 'b', 'c', 'd' ] 
console.log(arr); // [ 'a', 'b', 'c' ] `,
  code6: `// normally used expand method 
let arr = ['a','b']; 
let arr2 = [arr,'c','d']; 
  
console.log(arr2); // [ [ 'a', 'b' ], 'c', 'd' ] 


// expand using spread operator 
  
let arr = ['a','b']; 
let arr2 = [...arr,'c','d']; 
  
console.log(arr2); // [ 'a', 'b', 'c', 'd' ] `,
  code7: `function sum(...numbers) {
  let total = 0;
  for (const number of numbers) {
    total += number;
  }
  return total;
}

console.log(sum(1, 2, 3, 4, 5));  // 15`,
  code8: `const numbers = [1, 2, 3, 4, 5];
console.log(sum(...numbers));  // 15`,
  code9: `const details = {
  firstName: 'Code',
  lastName: 'Burst',
  age: 22
};
const { age, ...restOfTheDetails } = details;
console.log(age, restOfTheDetails); // 22 { firstName: 'Code', lastName: 'Burst' }`,
  code10: `const numbers = [1, 2, 3];
const [a, b, c] = numbers;
console.log(a);  // 1
console.log(b);  // 2
console.log(c);  // 3`,
  code11: `const numbers = [1, 2, 3];
const [a, , c] = numbers;
console.log(a);  // 1
console.log(c);  // 3

const [d, e, f = 0] = numbers;
console.log(d);  // 1
console.log(e);  // 2
console.log(f);  // 3`,
  code12: `const person = {
  name: 'John',
  age: 30,
  address: {
    city: 'New York',
    country: 'USA'
  }
};

const { name, age, address } = person;
console.log(name);  // 'John'
console.log(age);  // 30
console.log(address);  // { city: 'New York', country: 'USA' }

const { city, country } = person.address;
console.log(city);  // 'New York'
console.log(country);  // 'USA'`
}

