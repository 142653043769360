export const codeSnippet = {
	code1: `import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MyComponent } from './my.component';
import { MyService } from './my.service';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule
  ],
  declarations: [
    MyComponent
  ],
  exports: [
    MyComponent
  ],
  providers: [
    MyService
  ],
  bootstrap: [MyComponent]
})
export class MyModule { }`,
  code2:`<p [innerText]="title"></p>

// in the component
export class MyComponent {
  title = 'Hello World';
}`,
  code3: `<button (click)="onClick()">Click me</button>

// in the component
export class MyComponent {
  onClick() {
    console.log('Button was clicked');
  }
}`,
  code4: `<input [(ngModel)]="title">
  
// in the component
export class MyComponent {
  title = 'Hello World';
}`,
  code5: `<textarea [(ngModel)]="description"></textarea>
<select [(ngModel)]="selectedOption">
  <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
</select>

// in the component
export class MyComponent {
  description = '';
  selectedOption = '';
  options = [
    { value: 'option-1', label: 'Option 1' },
    { value: 'option-2', label: 'Option 2' },
    { value: 'option-3', label: 'Option 3' }
  ];
}`,
  code6: `@Component({
selector: 'app-my-component',
template: '
  <p>{{ message }}</p>
'
})
export class MyComponent {
  @Input() message: string;
}`,
  code7: `<ul>
<li *ngFor="let item of items">{{ item }}</li>
</ul>`,
  code8: `@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective {
  @Input() appHighlight: string;

  constructor(private el: ElementRef) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.highlight(this.appHighlight);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.highlight(null);
  }

  private highlight(color: string) {
    this.el.nativeElement.style.backgroundColor = color;
  }
}`,
  code9:`{{ user?.name }}`,
  code10: `{{ user?.address?.city }}`,
  code11: `{{ user?.getName() }}`,
  code12: `import { Component, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-my-component',
  template: '{{message}}'
})
export class MyComponent {
  message: string;

  constructor(private cdr: ChangeDetectorRef) {
    setTimeout(() => {
      this.message = 'Hello World';
      this.cdr.detectChanges();
    }, 5000);
  }
}`,
  code13: `import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-my-component',
  template: '{{message}}',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyComponent {
  message = 'Hello World';
}`,
  code14: `import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private globalVariable: string;

  setGlobalVariable(value: string) {
    this.globalVariable = value;
  }

  getGlobalVariable() {
    return this.globalVariable;
  }
}`,
  code1402: `import { Component } from '@angular/core';
import { GlobalService } from 'path/to/global.service';

@Component({
  selector: 'app-component1',
  template: '
    <button (click)="setGlobal()">Set Global Variable</button>
  '
})
export class Component1 {
  constructor(private globalService: GlobalService) {}

  setGlobal() {
    this.globalService.setGlobalVariable('Value from Component1');
  }
}`,
code1403: `import { Component } from '@angular/core';
import { GlobalService } from 'path/to/global.service';

@Component({
  selector: 'app-component2',
  template: '
    <p>Global Variable: {{ globalService.getGlobalVariable() }}</p>
  '
})
export class Component2 {
  constructor(private globalService: GlobalService) {}
}`,
  code1404: `export const environment = {
  production: false,
  globalVariable: 'Initial Value'
};`,
  code1405: `import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';

@NgModule({
  // ...
})
export class AppModule {
  // ...
}`,
  code1406: `import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-component',
  template: '
    <p>Global Variable: {{ globalVariable }}</p>
  '
})
export class Component {
  globalVariable: string = environment.globalVariable;
}`,
  code15: `import { Component, ViewChild } from '@angular/core';
import { ChildComponent } from './child.component';

@Component({
  selector: 'parent-component',
  template: '
    <child-component></child-component>
  '
})
export class ParentComponent {
  @ViewChild(ChildComponent) childComponent: ChildComponent;

  ngAfterViewInit() {
    // Access child component properties or methods
    this.childComponent.doSomething();
  }
}`,
  code1501: `import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'my-component',
  template: '<button #myButton>Click me!</button>'
})
export class MyComponent {
  @ViewChild('myButton', { static: true }) buttonRef!: ElementRef;

  ngAfterViewInit() {
    console.log(this.buttonRef.nativeElement.innerText); // logs 'Click me!'
  }
}`,
  code16: `import { Component, ViewChildren, QueryList } from '@angular/core';
import { ChildComponent } from './child.component';

@Component({
  selector: 'parent-component',
  template: '
    <child-component></child-component>
    <child-component></child-component>
    <child-component></child-component>
  '
})
export class ParentComponent {
  @ViewChildren(ChildComponent) childComponents: QueryList<ChildComponent>;

  ngAfterViewInit() {
    // Access child components using QueryList methods
    this.childComponents.forEach(child => child.doSomething());
  }
}`,
  code1601: `import { Component, ViewChildren, QueryList } from '@angular/core';

@Component({
  selector: 'my-component',
  template: '
    <div class="item">Item 1</div>
    <div class="item">Item 2</div>
    <div class="item">Item 3</div>
  '
})
export class MyComponent {
  @ViewChildren('.item') itemElements!: QueryList<ElementRef>;

  ngAfterViewInit() {
    this.itemElements.forEach(item => console.log(item.nativeElement.innerText));
    // logs 'Item 1', 'Item 2', 'Item 3'
  }
}`,
  code17: `// Parent Component
@Component({
  selector: 'parent-component',
  template: '
    <div>
      <h1>Parent Component</h1>
      <child-component>
        <h2>Content from Parent</h2>
        <p>This is some additional content.</p>
      </child-component>
    </div>
  '
})
export class ParentComponent {}

// Child Component
@Component({
  selector: 'child-component',
  template: '
    <div>
      <h2>Child Component</h2>
      <ng-content></ng-content>
    </div>
  '
})
export class ChildComponent {}`,
  code18: `import { Component } from '@angular/core';
@Component({
  selector: 'my-component',
  template: '
    <div class="title">
      <ng-content select=".header"></ng-content>
    </div>
    <div class="content">
      <ng-content select=".body"></ng-content>
    </div>
  '
})
export class MyComponent {}

--------------

import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  template: '
    <my-component>
      <h1 class="header">My Title</h1>
      <p class="body">My Content</p>
    </my-component>
  '
})
export class AppComponent {}`,
  code19: `import { Component, ContentChild, ElementRef } from '@angular/core';

@Component({
  selector: 'my-component',
  template: '<ng-content></ng-content>'
})
export class MyComponent {
  @ContentChild('myElement', { static: true }) elementRef!: ElementRef;

  ngAfterContentInit() {
    console.log(this.elementRef.nativeElement.textContent);
  }
}`,
  code20: `import { Component, ContentChildren, QueryList } from '@angular/core';

@Component({
  selector: 'my-component',
  template: '<ng-content></ng-content>'
})
export class MyComponent {
  @ContentChildren('myElement') elements!: QueryList<ElementRef>;

  ngAfterContentInit() {
    this.elements.forEach(element => console.log(element.nativeElement.textContent));
  }
}`
}