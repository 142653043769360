import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import vega from '../../assets/images/vega1.png'


function Vega () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Vega</strong></CCardHeader>
			<CCardBody>
				<p>The estimated change in am option price with a 1% change in IV</p>
				<ui>
					<li>Volatility increases, option prices increases and vice versa</li>
					<li>Vega is higher in ATM and longer term option</li>
				</ui><br/>
				<strong>IV</strong><br/>
				<ui>
					<li>Sell when IV is high</li>
					<li>Buy when IV is low</li>
				</ui><br/>				
				<img src={vega} width="30%" />
			</CCardBody>
	</CCard>
			
	)
}

export default Vega