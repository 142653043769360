import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Primary';
import PrimaryQualifier from "../../components/springBoot/PrimaryQualifier";


function Primary() {

    return (
      <>
      <CCard className="mb-4">
        <CCardBody>
        <CAccordion flush>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>@Primary VS @Qualifier</CAccordionHeader>
            <CAccordionBody>                
              <PrimaryQualifier />
            </CAccordionBody>
          </CAccordionItem>
          </CAccordion>
        </CCardBody>
      </CCard>
      <CCard className="mb-4">
			  <CCardHeader><strong>@Primary</strong></CCardHeader>
        <CCardBody>
          <p>@Primary is a Spring annotation that is used to indicate that a bean should be given precedence when multiple beans of the same type are present in the application context. It can be used in combination with @Autowired to specify which bean should be injected when multiple beans of the same type are eligible for injection.</p>
          <p>For example, consider the following code:</p>
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>    
          <p>In this example, both ServiceA and ServiceB depend on a DataSource bean. However, if there are multiple DataSource beans in the application context, Spring will not know which one to inject into each service. To resolve this ambiguity, you can use the @Primary annotation to specify which bean should be injected as the primary bean:</p>
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>    
          <p>In this revised example, ServiceA will be injected with the DataSource bean that is marked as @Primary, while ServiceB will be injected with the remaining DataSource bean.</p>

          <p>You can also use the @Primary annotation on @Bean methods to specify that a particular bean should be given precedence when multiple beans of the same type are present:</p>
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>    
          <p>In this example, the primaryDataSource bean is marked as @Primary, so it will be given precedence over the secondaryDataSource bean when both are eligible for injection.</p>
        </CCardBody>
      </CCard>  
      </>      
    )
}

export default Primary