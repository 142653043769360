import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/angular/LifeCycle';

function LifeCycle () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>LifeCycle</strong></CCardHeader>
      <CCardBody>   
        <p>In Angular, a component has a lifecycle that it goes through from creation to destruction. The Angular framework provides several lifecycle hooks that allow developers to perform certain actions at specific points in a component's lifecycle. These hooks are methods that are invoked by the Angular framework at certain points in the component's lifecycle.</p>

        <p>The main lifecycle hooks are:</p>             
        <ol>
          <li><p>ngOnChanges: This event is fired whenever a data-bound input property value changes. It is triggered before <b>ngOnInit</b>.</p></li>
          <li><p>ngOnInit: This event is fired once when the component is initialized, after <b>ngOnChanges</b> has been called for the first time. This is a good place to perform any initializations, such as fetching data from a remote server.</p></li>
          <li><p>ngDoCheck: This event is fired during every change detection cycle. It is used to detect and respond to changes that Angular does not recognize automatically.</p></li>
          <li><p>ngAfterContentInit: This event is fired after any content is projected into the component. It is a good place to perform any initialization that requires access to the child components.</p></li>
          <li><p>ngAfterContentChecked: This event is fired after the content of the component has been checked. It is a good place to perform any checks that need to be run after the child components have been checked.</p></li>
          <li><p>ngAfterViewInit: This event is fired after the component's view has been initialized. It is a good place to perform any initialization that requires access to the view, such as initializing third-party plugins.</p></li>
          <li><p>ngAfterViewChecked: This event is fired after the view has been checked. It is a good place to perform any checks that need to be run after the view has been updated.</p></li>
          <li><p>ngOnDestroy: This event is fired just before the component is destroyed. It is a good place to perform any cleanup, such as unsubscribing from observables and releasing resources.</p></li>
        </ol>
        <p>Additionally, to understand the component lifecycle, it's important to have a good understanding of Angular's <NavLink to="/angular/core"><b>change detection mechanism</b></NavLink> and how it works.</p>
        <CAccordion flush>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>ngOnChanges</CAccordionHeader>
            <CAccordionBody>
              <p>ngOnChanges is an Angular lifecycle hook that is called when any data-bound input property of a component changes. This method receives a <b>SimpleChanges</b> object that contains the previous and current values of the changed input properties.</p>
              <p>The ngOnChanges hook is useful when you need to respond to changes in the input properties of a component and perform some action based on the new value. For example, you might use ngOnChanges to update the component's state or trigger some other action in response to a change in an input property.</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
              <p>In this example, the ngOnChanges method logs any changes to the myInput input property of the component. The method receives a SimpleChanges object that contains information about the previous and current values of the input property, which can be used to perform any necessary actions in response to the change.</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code111}</SyntaxHighlighter>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter>
              <p>It's important to note that, the ngOnChanges method is called before the ngOnInit method and after the first change detection cycle. Therefore, you should use this method for any logic that needs to be executed before the component is fully initialized.</p>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>ngOnInit</CAccordionHeader>
            <CAccordionBody>
              <p>ngOnInit is a lifecycle hook in Angular that is called after the component has been constructed and its inputs have been initialized. It is typically used to perform any initialization that needs to be done before the component is rendered.</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
              <p>In this example, the MyComponentComponent component implements the OnInit interface and defines a ngOnInit method. This method is called after the component has been constructed and its inputs have been initialized.</p>
              <p>You can use ngOnInit to perform any initialization that needs to be done before the component is rendered, such as fetching data from a server or setting default values for properties.</p>
              <p>It's important to note that ngOnInit is only called once, when the component is first created. If you need to perform initialization every time the component is shown, you should use the ngOnChanges hook instead.</p>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={3}>
            <CAccordionHeader>ngDoCheck</CAccordionHeader>
            <CAccordionBody>
              <p>In Angular, the ngDoCheck lifecycle hook is a method that is called during every change detection cycle and is a good place to put custom change detection logic.</p>
              <p>This hook is called after the default change detection mechanism of Angular has run and allows a component to detect and act upon changes that Angular cannot or will not detect on its own.</p>
              <p>Here is an example of how to use the ngDoCheck hook in a component:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
              <p>In this example, the MyComponent implements the DoCheck lifecycle hook. The ngDoCheck method is used to detect changes in the name property and update the message property accordingly.</p>
              <p>It's important to note that, the ngDoCheck method is called for each change detection cycle and it can be called multiple times, so it's important to keep the logic inside the method as simple and efficient as possible.</p>
              <p>Also, it's important to use ngDoCheck only when it's necessary, as it can increase the change detection cycles, which can lead to performance issues.</p>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={4}>
            <CAccordionHeader>ngAfterContentInit</CAccordionHeader>
            <CAccordionBody>
              <p>In Angular, the ngAfterContentInit lifecycle hook is a method that is called after the component's content has been initialized. This hook is called after the default change detection mechanism of Angular has run, and the component's child directives and content are fully initialized.</p>
              <p>This hook is particularly useful for scenarios where the component's content is projected from the parent component and the child component needs to perform some initialization logic after the content is fully initialized.</p>
              <p>Here is an example of how to use the ngAfterContentInit hook in a component:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
              <p>In this example, the MyComponent implements the AfterContentInit lifecycle hook. The ngAfterContentInit method is used to update the message property after the component's content is initialized. The component's content is projected from the parent component and is displayed using the &lt;ng-content&gt;	 element.</p>
              <p>It's important to note that, the ngAfterContentInit method is called only once during the lifecycle of a component and is called after the first change detection cycle.</p>
              <p>Also, it's important to use ngAfterContentInit only when it's necessary, as it can increase the change detection cycles, which can lead to performance issues.</p>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={5}>
            <CAccordionHeader>ngAfterContentChecked</CAccordionHeader>
            <CAccordionBody>
              <p>In Angular, the ngAfterContentChecked lifecycle hook is a method that is called after the component's content has been checked. This hook is called after the default change detection mechanism of Angular has run, and the component's child directives and content have been checked for changes.</p>
              <p>This hook is particularly useful for scenarios where the component's content is projected from the parent component and the child component needs to perform some logic after the content is checked for changes.</p>
              <p>Here is an example of how to use the ngAfterContentChecked hook in a component:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
              <p>In this example, the MyComponent implements the AfterContentChecked lifecycle hook. The ngAfterContentChecked method is used to update the message property after the component's content is checked for changes. The component's content is projected from the parent component and is displayed using the &lt;ng-content&gt; element.</p>
              <p>It's important to note that, the ngAfterContentChecked method is called multiple times during the lifecycle of a component, and each time the change detection cycle is run.</p>
              <p>Also, it's important to use ngAfterContentChecked only when it's necessary, as it can increase the change detection cycles, which can lead to performance issues.</p>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={6}>
            <CAccordionHeader>ngAfterViewInit</CAccordionHeader>
            <CAccordionBody>
              <p>In Angular, the ngAfterViewInit lifecycle hook is a method that is called after the component's views have been initialized. This hook is called after the default change detection mechanism of Angular has run, and the component's child directives and views are fully initialized.</p>
              <p>This hook is particularly useful for scenarios where the component has its own template and the child components need to perform some initialization logic after the views are fully initialized.</p>
              <p>Here is an example of how to use the ngAfterViewInit hook in a component:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
              <p>In this example, the MyComponent implements the AfterViewInit lifecycle hook. The ngAfterViewInit method is used to update the message property after the component's views are initialized. The component has a child component &lt;app-child-component&gt; which is accessed using the ViewChild decorator.</p>
              <p>It's important to note that, the ngAfterViewInit method is called only once during the lifecycle of a component and is called after the first change detection cycle.</p>
              <p>Also, it's important to use ngAfterViewInit only when it's necessary, as it can increase the change detection cycles, which can lead to performance issues.</p>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={7}>
            <CAccordionHeader>ngAfterViewChecked</CAccordionHeader>
            <CAccordionBody>
              <p>In Angular, the ngAfterViewChecked lifecycle hook is a method that is called after the component's views have been checked for changes. This hook is called after the default change detection mechanism of Angular has run, and the component's child directives and views have been checked for changes.</p>
              <p>This hook is particularly useful for scenarios where the component has its own template and the child components need to perform some logic after the views are checked for changes.</p>
              <p>Here is an example of how to use the ngAfterViewChecked hook in a component:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
              <p>In this example, the MyComponent implements the AfterViewChecked lifecycle hook. The ngAfterViewChecked method is used to update the message property after the component's views are checked for changes. The component has a child component &lt;app-child-component &gt; which is accessed using the ViewChild decorator.</p>
              <p>It's important to note that, the ngAfterViewChecked method is called multiple times during the lifecycle of a component, and each time the change detection cycle is run.</p>
              <p>Also, it's important to use ngAfterViewChecked only when it's necessary, as it can increase the change detection cycles, which can lead to performance issues.</p>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={8}>
            <CAccordionHeader>ngOnDestroy</CAccordionHeader>
            <CAccordionBody>
              <p>In Angular, the ngOnDestroy lifecycle hook is a method that is called just before a component is destroyed by Angular. This hook allows a component to perform any cleanup logic that needs to be executed before the component is removed from the DOM.</p>
              <p>This hook is particularly useful for scenarios where a component has set up any listeners or subscriptions that need to be cleaned up before the component is destroyed.</p>
              <p>Here is an example of how to use the ngOnDestroy hook in a component:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
              <p>In this example, the MyComponent implements the OnInit and OnDestroy lifecycle hooks. The ngOnInit method is used to set up a subscription to an observable, and the ngOnDestroy method is used to unsubscribe from the observable before the component is destroyed.</p>
              <p>It's important to note that, the ngOnDestroy method is called only once during the lifecycle of a component and is called just before the component is destroyed.</p>
              <p>Also, it's important to use ngOnDestroy only when it's necessary, as it can be used for cleaning up any resources or listeners that were set up by the component to prevent memory leaks.</p>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>

      </CCardBody>
    </CCard>        
  )
}

export default LifeCycle;