export const codeSnippet = {
    code1: `@Configuration
@EnableCaching
public class CacheConfig {
    // additional cache configuration here
}`,
	code2: `@Cacheable("products")
public Product findProductById(Long id) {
// method implementation
}` 
}

