import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/DataBase';
import jpa_cascade from '../../assets/images/jpa_cascade.jpg'
import manyToMany from '../../assets/images/manyToMany.jpg'

function DataBase () {

    return (
      <CCard className="mb-4">
        <CCardHeader><strong>DataBase</strong></CCardHeader>
        <CCardBody>                
          <CAccordion flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader>JDBC</CAccordionHeader>
              <CAccordionBody>    
                <p>JDBC stands for Java Database Connectivity, which is a standard Java API for database-independent connectivity between the Java programming language and a wide range of databases..</p>
                <h4>1. spring-boot-starter-jdbc</h4>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
                <strong>BookRepository.java</strong>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
                <strong>Book.java</strong>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
                <strong>JdbcBookRepository.java</strong>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
                <p><strong><i style={{'color': 'red'}}>jdbcTemplate.queryForObject, populates a single object</i></strong></p>
                <p><strong>NamedParameterJdbcTemplate</strong></p>
                <p>The NamedParameterJdbcTemplate adds support for named parameters in steads of classic placeholder ? argument.</p>
                <strong>namedParameterJdbcBookRepository</strong>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
                <strong>application.properties</strong>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
                <p>In JDBC, the <b>connectionTimeout</b> property specifies the maximum time in seconds that the driver will wait while establishing a database connection. If the connection cannot be established within the specified timeout period, an exception will be thrown.</p>
                <p><b>maximumPoolSize</b> refers to the maximum number of database connections that can be created and maintained in the connection pool.</p>
                <h4>2. jdbc.Driver</h4>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
                <p>Create a Spring Bean for the DataSource:</p>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code91}</SyntaxHighlighter>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code101}</SyntaxHighlighter>

                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter>

              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={2}>
              <CAccordionHeader>ORM</CAccordionHeader>
              <CAccordionBody>    
                <p>ORM (Object-Relational Mapping) in Java refers to the technique of mapping data between relational databases and object-oriented programming languages. It provides a way to interact with databases using objects and classes instead of writing raw SQL queries.</p>
                <p>There are several popular ORM frameworks available for Java, such as Hibernate, TopLink, MyBatis, EclipseLink, and many more. </p>
                <p>ORM frameworks typically provide the following features:</p>
                <ul>
                  <li><p>Object-Relational Mapping: The framework maps database tables to Java classes and their properties to table columns. It handles the translation of objects to SQL statements and vice versa.</p></li>
                  <li><p>CRUD Operations: ORM frameworks provide convenient methods for performing Create, Read, Update, and Delete operations on objects, abstracting away the need to write explicit SQL queries</p></li>
                  <li><p>Querying: ORM frameworks offer querying mechanisms to retrieve data from the database using an object-oriented syntax. This allows developers to use criteria-based queries, JPQL (Java Persistence Query Language), or SQL-like queries.</p></li>
                  <li><p>Transaction Management: ORM frameworks provide transaction management capabilities, ensuring that changes to the database are atomic and consistent. They handle the creation, committing, and rolling back of database transactions</p></li>
                  <li><p>Caching: ORM frameworks often include caching mechanisms to improve performance by reducing database round trips. They cache objects in memory, reducing the need to fetch data from the database repeatedly.</p></li>
                </ul>
              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={3}>
              <CAccordionHeader>Hibernate</CAccordionHeader>
              <CAccordionBody>    
                <p></p>
              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={4}>
              <CAccordionHeader>JPA</CAccordionHeader>
              <CAccordionBody>    
                <p><h4>@Entity</h4></p>
                <p>The @Entity annotation is a key annotation in the Java Persistence API (JPA). It is used to mark a Java class as an entity, indicating that <b>it is mapped to a database table.</b></p>
                <p>Each instance of the entity class represents a row in the corresponding database table, and the attributes of the entity class are mapped to columns in the table.</p>
                
                <p><h4>@Table</h4></p>
                <p>The @Table annotation is used in Java Persistence API (JPA) to specify the details of the database table to which an entity is mapped. It allows you to customize the table name, schema, and other table-specific properties.</p>
                <ul>
                  <li><p>Name: You can use the name attribute to specify the name of the database table to which the entity is mapped. By default, JPA uses the entity class name as the table name.</p></li>
                  <li><p>Schema: The schema attribute is used to specify the schema name of the table. It is optional and can be used when working with multiple schemas in a database.</p></li>
                  <li><p>Catalog: The catalog attribute is used to specify the catalog name of the table. It is optional and is typically used when working with multiple catalogs in a database.</p></li>
                  <li><p>Unique Constraints: You can define unique constraints on one or more columns of the table using the uniqueConstraints attribute. This allows you to enforce uniqueness on specific combinations of column values.</p></li>
                  <li><p>Indexes: The indexes attribute can be used to define indexes on one or more columns of the table. Indexes can improve query performance by allowing faster data retrieval based on indexed columns.</p></li>
                </ul>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code13}</SyntaxHighlighter>

                <p><h4>@Transient</h4></p>
                <p>In JPA (Java Persistence API), the @Transient annotation is used to mark a field in an entity class as transient, meaning that it should not be persisted or mapped to a database column.</p>
                <p>@GeneratedValue annotation to specify the primary key generation strategy for an entity.</p>
                <ul>
                  <li><p>Field Exclusion: When you annotate a field with @Transient, JPA ignores that field during the persistence process. It will not be considered for database mapping or inclusion in SQL statements.</p></li>
                  <li><p>Non-Persistent Field: By marking a field as @Transient, you indicate that the field's value should not be stored in the database. This is useful for fields that are derived from other persisted fields, calculated at runtime, or temporary in nature.</p></li>                  
                </ul>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code15}</SyntaxHighlighter>

                <p><h4>@Embedded</h4></p>
                <p>In JPA (Java Persistence API), the @Embedded annotation is used to indicate that an attribute within an entity class should be treated as an embedded object. An embedded object represents a composition of multiple attributes within an entity.</p>
                <p>When you annotate an attribute with @Embedded, JPA treats that attribute as a value type and maps its individual fields/columns to the same table as the entity class that contains it.</p>
              
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code16}</SyntaxHighlighter>
                <p>In the example above, the Employee entity class contains an embedded object Address marked with @Embedded. This indicates that the Address class should be stored as a part of the Employee entity.</p>
                <p>JPA will map the fields of the Address class (street, city, state, and zipCode) to columns in the same table as the Employee entity. The column names will be derived from the attribute names within the Address class.</p>
                <p>By using @Embedded, you can group related attributes together and store them as a single unit within the same table, making your entity structure more organized and maintaining the object-oriented nature of your code.</p>
                <p>Note that @Embedded can also be used with collections, allowing you to embed a collection of objects within an entity.</p>

                <p><h4>@JoinColumn</h4></p>
                <p>The @JoinColumn annotation is used in JPA (Java Persistence API) to specify the mapping of a foreign key column in a relational database table. It is typically used in association mappings, such as @OneToOne, @OneToMany, and @ManyToOne, to define the relationship between entities and how they are linked through the database schema.</p>
                <p>The @JoinColumn annotation can be applied to the owning side of the relationship, where the foreign key column resides.</p>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code17}</SyntaxHighlighter>
                <p>In this example, the Employee entity has a many-to-one relationship with the Department entity. The @JoinColumn annotation is used to specify the foreign key column that links the Employee table to the Department table. In this case, the foreign key column is named "department_id".</p>
                <p>By default, the @JoinColumn annotation uses the name of the target entity's primary key column as the foreign key column. However, you can specify a custom column name by using the name attribute of @JoinColumn.</p>
                <p>Using @JoinColumn allows you to define the mapping of the foreign key column and customize its name, allowing you to establish the relationship between entities in the database schema.</p>

                <p><h4>Cascade</h4></p>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code18}</SyntaxHighlighter>
                <img src={jpa_cascade} width="100%" height="100%" />

                <br/><br/><p><h4>Bi-Directional</h4></p>
                
                <ul>
                  <li>
                    <p>mapped_by tells hibernate</p>
                    <ul>
                      <li><p>look at the employeeDetails property in the Employee class</p></li>
                      <li><p>use information from the Employee class @JoinColumn</p></li>
                    </ul>
                  </li>                  
                </ul>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code19}</SyntaxHighlighter>
                
                <p><h4>Fetch strategies -> "Eager" and "Lazy" </h4></p>
                <b>Eager Fetching:</b>                
                <p>With Eager fetching, associated entities are loaded immediately along with the main entity during the initial database query. This means that all related entities are retrieved from the database and fully populated into the entity object graph.</p>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code20}</SyntaxHighlighter>
                <b>Lazy Fetching:</b>
                <p>With Lazy fetching, associated entities are not loaded immediately when querying the main entity. Instead, they are loaded only when they are accessed for the first time. This means that the associated entities are fetched from the database on-demand as needed.</p>

                <p><h4>@Transactional</h4></p>
                <p>When you annotate a method with @Transactional, Spring creates a transaction around that method, and the transaction will be committed when the method completes successfully or rolled back if an exception occurs. If the method is part of a class annotated with @Transactional, the same transactional behavior will be applied to all public methods of that class.</p>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code21}</SyntaxHighlighter>
                <p>In this example, the updateEmployeeAndDetails method is annotated with @Transactional. If any exception occurs during the update operations, the transaction will be rolled back, and both the Employee and EmployeeDetails entities will remain unchanged in the database.</p>

                <p><h4>ManyToMany</h4></p>
                <img src={manyToMany} width="100%" height="100%" /><br/><br/>

                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code22}</SyntaxHighlighter>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code23}</SyntaxHighlighter>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code24}</SyntaxHighlighter>

              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={5}>
              <CAccordionHeader>Spring Data JPA</CAccordionHeader>
              <CAccordionBody>    
                <p>Spring Data JPA uses Hibernate as the underlying ORM (Object-Relational Mapping) provider. Hibernate is one of the most popular and widely used ORM frameworks in the Java ecosystem, and it provides robust support for mapping Java objects to relational databases.</p>
              </CAccordionBody>
            </CAccordionItem>                                
          </CAccordion>
      </CCardBody>
    </CCard>      
  )
}

export default DataBase