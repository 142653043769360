import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Profile';

function Profile () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Spring Profiles</strong></CCardHeader>
      <CCardBody>
        <p>Spring profiles are a way to define different configurations for different environments in a Spring Boot application. Profiles allow you to customize the configuration of your application for different environments, such as development, staging, and production, and enable you to switch between these configurations easily.</p>

        <p>To use profiles in a Spring Boot application, you can define properties in your configuration files (such as application.properties) and use the <strong>spring.profiles.active</strong> property to specify which profile is active. For example:</p>
        <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>      
        <p>In this example, the <strong>spring.profiles.active</strong> property is set to dev, so the properties in the <strong>application-dev.properties</strong> file will be used. If the <strong>spring.profiles.active</strong> property were set to prod, the properties in the <strong>application-prod.properties</strong> file would be used instead.</p>

        <p>You can also use profiles to enable or disable certain beans or configurations in your application. For example:</p>
        <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>    

        <h5><strong>There are several ways to dynamically set the Spring profile in a Spring application:</strong></h5>  <br/>

        <ol>
          <li>
            <p>You can specify the active profile using the <strong>spring.profiles.active</strong> property in a configuration file (such as application.properties) or as a command-line argument when starting the application. For example:</p>
            <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>    
          </li>
          <li>
            <p>You can set the active profile programmatically by calling the setDefaultProfiles method on the SpringApplication class. For example:</p>
            <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter> 
          </li>
          <li>
            <p>You can set the active profile using the <strong>SPRING_PROFILES_ACTIVE</strong> environment variable. For example:</p>
            <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter> 
            </li>
          <li>
            <p>You can set the active profile using the <strong>spring.profiles.active</strong> property in the <strong>spring.config.name</strong> configuration file. For example:</p>
            <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter> 
            </li>
        </ol>

      </CCardBody>
    </CCard>        
  )
}

export default Profile