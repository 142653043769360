export const codeSnippet = {
  code1: `minikube start`,
  code2: `minikube stop`,
  code3: `minikube delete  `,
  code4: `minikube dashboard  `,
  code5: `kubectl config use-context minikube  `,
  code6: `minikube logs`,
  code666: `kubectl api-resources`,
  code7: `kubectl get nodes`,
  code777: `kubectl run my-pod --image=nginx:latest`,
  code7777: `kubectl top pods`,
  code8: `kubectl create deployment <deployment-name> --image=<image-name>  `,
  code9: `kubectl get all // get all the components
kubectl get deployment // nginx-deployment
kubectl get replicaset // nginx-deployment-794f656f8b
kubectl get pod // nginx-deployment-794f656f8b-vvzs5
kubectl get pod -o wide // get more info
kubectl get deployment my-deployment -o yaml // output the YAML
kubectl get deployment <deployment-name> -o yaml > deployment.yaml // This will save the YAML representation of the deployment to a file named "deployment.yaml".`,
  code10: `kubectl config get-contexts
kubectl config set-context --current --namespace=default  `,
  code11: `kubectl delete all --all  // To delete all resources in a Kubernetes cluster
kubectl delete deployment <deployment-name>`,
  code12: `apiVersion: apps/v1
kind: Deployment
metadata:
  name: my-spring-boot-app
  labels:
      app: my-spring-boot-app
spec:
  replicas: 3
  selector:
    matchLabels:
      app: my-spring-boot-app
  template:
    metadata:
      labels:
        app: my-spring-boot-app
    spec:
      securityContext:
        runAsUser: 1000
      containers:
        - name: my-spring-boot-container
          image: your-docker-image:tag
          securityContext:
            runAsUser: 1000
            runAsGroup: 602
          ports:
            - containerPort: 8080
          livenessProbe:
            httpGet:
              path: /actuator/health
              port: 8080
            initialDelaySeconds: 30
            periodSeconds: 10     
          readinessProbe:
            httpGet:
              path: /actuator/health
              port: 8080
            initialDelaySeconds: 10
            periodSeconds: 5
          resources:
            requests:
              cpu: "0.5"
              memory: "512Mi"
            limits:
              cpu: "1"
              memory: "1Gi"`,
  code13: `kubectl apply -f deployment.yaml  `,
  code14: `kubectl port-forward <pod-name> <local-port>:<pod-port>
  kubectl port-forward <service-name> <local-port>:<service-port>`,
  code15: `apiVersion: networking.k8s.io/v1
kind: Ingress
metadata:
  name: my-ingress
spec:
  rules:
    - host: example.com
      http:
        paths:
          - path: /app1
            pathType: Prefix
            backend:
              service:
                name: service1
                port:
                  number: 80
          - path: /app2
            pathType: Prefix
            backend:
              service:
                name: service2
                port:
                  number: 80
    - host: subdomain.example.com
      http:
        paths:
          - path: /
            pathType: Prefix
            backend:
              service:
                name: service3
                port:
                  number: 80`,
  code16: `apiVersion: projectcontour.io/v1
kind: HTTPProxy
metadata:
  name: my-http-proxy
spec:
  virtualhost:
    fqdn: example.com
  routes:
    - conditions:
        - prefix: /app1
      services:
        - name: service1
          port: 80
    - conditions:
        - prefix: /app2
      services:
        - name: service2
          port: 80`,
  code17: `apiVersion: networking.k8s.io/v1
kind: Ingress
metadata:
  name: my-ingress
spec:
  rules:
    - host: example.com
      http:
        paths:
          - path: /app1
            pathType: Prefix
            backend:
              service:
                name: app1-service
                port:
                  number: 80
          - path: /app2
            pathType: Prefix
            backend:
              service:
                name: app2-service
                port:
                  number: 80`,
  code18: `apiVersion: projectcontour.io/v1
kind: HTTPProxy
metadata:
  name: my-httpproxy
spec:
  virtualhost:
    fqdn: example.com
  routes:
    - match: /app1/*
      services:
        - name: app1-service
          port: 80
    - match: /app2/*
      services:
        - name: app2-service
          port: 80`,
  code19: `apiVersion: autoscaling/v2beta2
kind: HorizontalPodAutoscaler
metadata:
  name: my-app-hpa
spec:
  scaleTargetRef:
    apiVersion: apps/v1
    kind: Deployment
    name: my-app-deployment
  minReplicas: 1
  maxReplicas: 5
  metrics:
    - type: Resource
      resource:
        name: cpu
        target:
          type: Utilization
          averageUtilization: 50`,
  code20: `apiVersion: batch/v1
kind: CronJob
metadata:
  name: my-cronjob
spec:
  schedule: "0 * * * *"  # Run every hour
  jobTemplate:
    spec:
      backoffLimit: 3  # Maximum 3 retries
      template:
        spec:
          containers:
          - name: my-job
            image: my-job-image:latest
            command: ["/bin/sh", "-c", "echo Hello from CronJob"]
          restartPolicy: OnFailure`,
  code21: `apiVersion: networking.k8s.io/v1
kind: NetworkPolicy
metadata:
  name: my-network-policy
spec:
  podSelector:
    matchLabels:
      app: my-app
  policyTypes:
  - Ingress
  ingress:
  - from:
    - podSelector:
        matchLabels:
          role: backend
    ports:
    - protocol: TCP
      port: 8080`,
  code21_01: `apiVersion: networking.k8s.io/v1
kind: NetworkPolicy
metadata:
  name: egress-policy
spec:
  podSelector:
    matchLabels:
      app: frontend
  policyTypes:
  - Egress
  egress:
  - to:
    - ipBlock:
        cidr: 203.0.113.1/32  # Specific destination IP address
    ports:
    - protocol: TCP
      port: 80  # Destination port`,
  code22: `apiVersion: rbac.authorization.k8s.io/v1
kind: Role
metadata:
  name: my-role
  namespace: my-namespace
rules:
- apiGroups: ["", "extensions", "apps"]
  resources: ["pods", "deployments", "services"]
  verbs: ["get", "list", "create", "update", "delete"]`,
  code23: `apiVersion: rbac.authorization.k8s.io/v1
kind: RoleBinding
metadata:
  name: my-role-binding
  namespace: my-namespace
roleRef:
  kind: Role
  name: my-role
  apiGroup: rbac.authorization.k8s.io
subjects:
- kind: User
  name: john@example.com
- kind: Group
  name: marketing-team
- kind: ServiceAccount
  name: my-service-account
  namespace: my-namespace`,
  code24: `apiVersion: kustomize.config.k8s.io/v1beta1
kind: Kustomization

resources:
- deployment.yaml
- service.yaml

patchesStrategicMerge:
- patch-deployment.yaml

namePrefix: dev-`,
  code25: `apiVersion: kustomize.config.k8s.io/v1beta1
kind: Kustomization

bases:
- ../base-resources/app1
- ../base-resources/app2
- ../common-resources

patchesStrategicMerge:
- patches/app1-patch.yaml
- patches/app2-patch.yaml

commonLabels:
  app: my-app`,
  code26: `@RestController
@RequestMapping("/reviews")
public class ReviewController {

    @Autowired
    private CommentService commentService;

    @GetMapping("/{productId}")
    public String getReviews(@PathVariable String productId) {
        // Retrieve reviews for the given product ID
        // Incorporate comments using the CommentService
        String comments = commentService.getComments(productId);
        return "Reviews for Product " + productId + ": " + comments;
    }
}`,
  code27: `server:
port: 8080

spring:
application:
  name: review-app`,
  code28: `@RestController
@RequestMapping("/comments")
public class CommentController {

    @GetMapping("/{productId}")
    public String getComments(@PathVariable String productId) {
        // Retrieve comments for the given product ID
        return "Comments for Product " + productId + ": [Comment1, Comment2, Comment3]";
    }
}`,
  code29: `server:
port: 8081

spring:
application:
  name: comment-app`,
  code30: `apiVersion: networking.istio.io/v1alpha3
kind: VirtualService
metadata:
  name: review-vs
spec:
  hosts:
  - "*"
  gateways:
  - springboot-gateway
  http:
  - match:
    - uri:
        prefix: "/reviews"
    route:
    - destination:
        host: review-app
        port:
          number: 8080
---
apiVersion: networking.istio.io/v1alpha3
kind: VirtualService
metadata:
  name: comment-vs
spec:
  hosts:
  - "*"
  gateways:
  - springboot-gateway
  http:
  - match:
    - uri:
        prefix: "/comments"
    route:
    - destination:
        host: comment-app
        port:
          number: 8081`,
  code31: ``,

}

