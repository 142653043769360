import React, { useState, useContext, useRef } from 'react'
import { AppSidebar, AppFooter, AppHeader } from '../layout'
import AuthContext from '../../store/auth-context';
import printContext from '../../store/print-context'
import { useReactToPrint } from 'react-to-print';

const Layout = (props) => {
  const [sidebar, setSidebar] = useState({type: 'set', sidebarShow: true})
  const authCtx = useContext(AuthContext);

  const componentPrintRef = useRef();

  const sideBarHandler = (val) => {
    setSidebar((preState) => {
      return {
        ...preState,
        type: 'set',
        sidebarShow: val,
      }
    })
  }

  const selectprint = useReactToPrint({
    content: () => componentPrintRef.current,
  });

  return (
    <>
      { authCtx.isLoggedIn && (
        <div>
          <AppSidebar sideBarHandler={sideBarHandler} sidebar={sidebar.sidebarShow} />
          <div className="wrapper d-flex flex-column min-vh-100 bg-light">
            <printContext.Provider value={{ print: selectprint }} >
              <AppHeader sideBarHandler={sideBarHandler} sidebar={sidebar.sidebarShow} />
              <div className="body flex-grow-1 px-3">
                <main ref={componentPrintRef}>{props.children}</main>
              </div>
              <AppFooter />
            </printContext.Provider>
          </div>
        </div>
      )}
      { !authCtx.isLoggedIn && (
        <main>{props.children}</main>
      )}
    </>
  )
}
export default Layout
