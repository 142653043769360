import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Component';

function Component () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>@Component</strong></CCardHeader>
        <CCardBody>
          <p>@Component is a generic stereotype for any Spring-managed component or bean. It is used to mark a class as a component that can be auto-detected by Spring's component scanning mechanism.</p>

          <p>Here's an example of how to use @Component:</p>

          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

          <p>By default, the component will be registered with the Spring application context under the class name with the first letter lowercased. So in this example, the component would be registered as "myComponent".</p>

          <p>You can also specify a different name for the component by using the value attribute of the @Component annotation:</p>

          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

          <p>In this example, the component would be registered as "myCustomName".</p>

          <p>You can then use dependency injection to inject the component into other beans:</p>

          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>

          <p>@Component is a broad stereotype and can be used for any type of component in your application. Spring also provides more specific stereotypes such as @Repository for DAO classes, @Service for service classes, and @Controller for controller classes in a Spring MVC application.</p>
        </CCardBody>
      </CCard>        
    )
}

export default Component