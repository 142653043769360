export const codeSnippet = {
	code1: `import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private data = {};

  setData(key: string, value: any) {
    this.data[key] = value;
  }

  getData(key: string) {
    return this.data[key];
  }
}`,
  code2: `import { Component } from '@angular/core';
import { DataService } from './data.service';

@Component({
  selector: 'app-first-component',
  template: '
    <button (click)="setData()">Set Data</button>
  '
})
export class FirstComponent {
  constructor(private dataService: DataService) {}

  setData() {
    this.dataService.setData('key', 'value');
  }
}`,
  code3: `import { Component } from '@angular/core';
import { DataService } from './data.service';

@Component({
  selector: 'app-second-component',
  template: '
    <p>{{data}}</p>
  '
})
export class SecondComponent {
  data = '';

  constructor(private dataService: DataService) {
    this.data = dataService.getData('key');
  }
}`,
  code4: `import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-child',
  template: '
    <p>{{message}}</p>
  '
})
export class ChildComponent {
  @Input() message: string;
}`,
  code5: `import { Component } from '@angular/core';

@Component({
  selector: 'app-parent',
  template: '
    <app-child [message]="parentMessage"></app-child>
  '
})
export class ParentComponent {
  parentMessage = 'Hello from the parent!';
}`,
  code6: `import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-child',
  template: '
    <button (click)="sendMessage()">Send Message</button>
  '
})
export class ChildComponent {
  @Output() messageEvent = new EventEmitter<string>();

  sendMessage() {
    this.messageEvent.emit('Hello from the child!');
  }
}`,
  code7: `import { Component } from '@angular/core';

@Component({
  selector: 'app-parent',
  template: '
    <app-child (messageEvent)="receiveMessage($event)"></app-child>
    <p>{{message}}</p>
  '
})
export class ParentComponent {
  message = '';

  receiveMessage(message: string) {
    this.message = message;
  }
}`

}