import Dashboard from './pages/Dashboard'
import UseState from './pages/react/UseState'
import Hooks from './pages/react/Hooks'
import Login from './pages/Login'
import UseEffect from './pages/react/UseEffect'
import AbortController from './pages/react/AbortController'
import Miscellaneous from './pages/react/Miscellaneous'
import UseRef from './pages/react/UseRef'
import UseReducer from './pages/react/UseReducer'
import UseContext from './pages/react/UseContext'
import UseMemo from './pages/react/UseMemo'
import UseCallback from './pages/react/UseCallback'
import Introduction from './pages/springBoot/Introduction'
import Ioc from './pages/springBoot/Ioc'
import SpringBootApplication from './pages/springBoot/SpringBootApplication'
import ConfigurationProperties from './pages/springBoot/ConfigurationProperties'
import Configuration from './pages/springBoot/Configuration'
import Bean from './pages/springBoot/Bean'
import Page500 from './pages/Page500'
import Page404 from './pages/Page404'
import Collection from './pages/java/Collection'
import List from './pages/java/List'
import Delta from './pages/options/Delta'
import Gamma from './pages/options/Gamma'
import Theta from './pages/options/Theta'
import Vega from './pages/options/Vega'
import IV from './pages/options/IV'
import Meaning from './pages/english/Meaning'
import BidAsk from './pages/options/BidAsk'
import BuyingCall from './pages/options/BuyingCall'
import BuyingPut from './pages/options/BuyingPut'
import SellingCall from './pages/options/SellingCall'
import SellingPut from './pages/options/SellingPut'
import Forms from './pages/react/Forms'
import CustomHooks from './pages/react/CustomHooks'
import ShortCallVerticals from './pages/options/ShortCallVerticals'
import ShortPutVerticals from './pages/options/ShortPutVerticals'
import LongPutVerticals from './pages/options/LongPutVerticals'
import LongCallVerticals from './pages/options/LongCallVerticals'
import Exceptions from './pages/java/Exception'
import EnableAutoConfiguration from './pages/springBoot/EnableAutoConfiguration'
import ComponentScan from './pages/springBoot/ComponentScan'
import RestController from './pages/springBoot/RestController'
import Controller from './pages/springBoot/Controller'
import PropertySource from './pages/springBoot/PropertySource'
import Component from './pages/springBoot/Component'
import Autowired from './pages/springBoot/Autowired'
import Async from './pages/springBoot/Async'
import SBMiscellaneous from './pages/springBoot/Miscellaneous'
import EnableCaching from './pages/springBoot/EnableCaching'
import Qualifier from './pages/springBoot/Qualifier'
import Primary from './pages/springBoot/Primary'
import Service from './pages/springBoot/Service'
import Repository from './pages/springBoot/Repository'
import Value from './pages/springBoot/Value'
import Profile from './pages/springBoot/Profile'
import Http from './pages/springBoot/Http'
import Set from './pages/java/Set'
import Map from './pages/java/Map'
import Queue from './pages/java/Queue'
import iteration from './pages/java/Iteration'
import Iteration from './pages/java/Iteration'
import Polymorphism from './pages/java/Polymorphism'
import Mockito from './pages/springBoot/Mockito'
import Es6 from './pages/javascript/Es6'
import NgModule from './pages/angular/Core'
import Core from './pages/angular/Core'
import Router from './pages/angular/Router'
import SpringBootDashboard from './pages/springBoot/SpringBootDashboard'
import JavaBootDashboard from './pages/java/JavaBootDashboard'
import JavaMiscellaneous from './pages/java/Miscellaneous'
import Lambdas from './pages/java/Lambdas'
import Stream from './pages/java/Stream'
import LifeCycle from './pages/angular/LifeCycle'
import Binding from './pages/angular/Binding'
import Bindings from './pages/angular/Binding'
import Guards from './pages/angular/Guards'
import Ineterceptor from './pages/angular/Ineterceptor'
import LazyLoading from './pages/angular/LazyLoading'
import Resolve from './pages/angular/Resolve'
import ShareData from './pages/angular/ShareData'
import Environment from './pages/angular/Environment'
import AngularForms from './pages/angular/Forms'
import Javascript from './pages/javascript/Javascript'
import JavascriptDashboard from './pages/javascript/JavascriptDashboard'
import Terraform from './pages/cloud/terraform/Introduction'
import One from './pages/behavioral/one'
import Thread from './pages/java/Thread'
import SpringThread from './pages/springBoot/Thread'
import Vpc from './pages/cloud/aws/Vpc'
import AsyncProcess from './pages/java/Async'
import Resilience4j from './pages/springBoot/Resilience4j'
import Callable from './pages/java/Callable'
import Fragments from './pages/react/Fragments'
import ReactRouter from './pages/react/Router'
import Styles from './pages/react/Styles'
import DataBase from './pages/springBoot/DataBase'
import DockerDashboard from './pages/cloud/docker/DockerDashboard'
import Commands from './pages/cloud/docker/Commands'
import KubernetesDashboard from './pages/cloud/kubernetes/KubernetesDashboard'
import KubernetesCommands from './pages/cloud/kubernetes/Commands'
import Example from './pages/cloud/kubernetes/Example'
import Mongodb from './pages/springBoot/Mongodb'
import Ecs from './pages/cloud/aws/ecs'
import KafkakaDashboard from './pages/kafka/KafkaDashboard'


const routes = [
  { path: '/', exact: true, name: '', element: Dashboard },
  { path: '/login', name: 'Login', element: Login },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/react/hooks', name: 'Hooks', element: Hooks },
  { path: '/react/useState', name: 'UseState', element: UseState },
  { path: '/react/useEffect', name: 'UseEffect', element: UseEffect },
  { path: '/react/useRef', name: 'UseRef', element: UseRef },
  { path: '/react/useReducer', name: 'UseReducer', element: UseReducer },
  { path: '/react/useContext', name: 'UseContext', element: UseContext },
  { path: '/react/useMemo', name: 'UseMemo', element: UseMemo },
  { path: '/react/useCallback', name: 'UseCallback', element: UseCallback },
  { path: '/react/abortController', name: 'AbortController', element: AbortController},
  { path: '/react/miscellaneous', name: 'Miscellaneous', element: Miscellaneous},
  { path: '/react/forms', name: 'Forms', element: Forms},
  { path: '/react/customHooks', name: 'Forms', element: CustomHooks},
  { path: '/react/fragments', name: 'Fragments & Portals', element: Fragments},
  { path: '/react/router', name: 'Router', element: ReactRouter},
  { path: '/react/styles', name: 'Styles', element: Styles},

  { path: '/springBoot/dashboard', name: 'Home', element: SpringBootDashboard},
  { path: '/springBoot/intro', name: 'Introduction', element: Introduction},
  { path: '/springBoot/ioc', name: 'IOC', element: Ioc},
  { path: '/springBoot/springBootApplication', name: 'SpringBootApplication', element: SpringBootApplication},
  { path: '/springBoot/configurationProperties', name: 'ConfigurationProperties', element: ConfigurationProperties},
  { path: '/springBoot/configuration', name: 'Configuration', element: Configuration},
  { path: '/springBoot/bean', name: 'Bean', element: Bean},
  { path: '/springBoot/enableAutoConfiguration', name: 'EnableAutoConfiguration', element: EnableAutoConfiguration},
  { path: '/springBoot/componentScan', name: 'ComponentScan', element: ComponentScan},
  { path: '/springBoot/restController', name: 'RestController', element: RestController},
  { path: '/springBoot/controller', name: 'Controller', element: Controller},
  { path: '/springBoot/propertySource', name: 'PropertySource', element: PropertySource},
  { path: '/springBoot/component', name: 'Component', element: Component},
  { path: '/springBoot/autowired', name: 'Autowired', element: Autowired},
  { path: '/springBoot/async', name: 'Async', element: Async},
  { path: '/springBoot/miscellaneous', name: 'Miscellaneous', element: SBMiscellaneous},
  { path: '/springBoot/enableCaching', name: 'EnableCaching', element: EnableCaching},
  { path: '/springBoot/qualifier', name: 'Qualifier', element: Qualifier},
  { path: '/springBoot/primary', name: 'Primary', element: Primary},
  { path: '/springBoot/service', name: 'Service', element: Service},
  { path: '/springBoot/repository', name: 'Repository', element: Repository},
  { path: '/springBoot/value', name: 'Value', element: Value},
  { path: '/springBoot/profile', name: 'Profile', element: Profile},
  { path: '/springBoot/http', name: 'Http', element: Http},
  { path: '/springBoot/mockito', name: 'Mockito', element: Mockito},
  { path: '/springBoot/thread', name: 'Thread', element: SpringThread},
  { path: '/springBoot/async-http-client', name: 'Async', element: AsyncProcess},
  { path: '/springBoot/resilience4j', name: 'Resilience4j', element: Resilience4j},
  { path: '/springBoot/dataBase', name: 'DataBase', element: DataBase},
  { path: '/springBoot/mongodb', name: 'MongoDB', element: Mongodb},

  { path: '/java/dashboard', name: 'Home', element: JavaBootDashboard},
  { path: '/java/collection', name: 'Collection', element: Collection},
  { path: '/java/list', name: 'List', element: List},
  { path: '/java/exception', name: 'Collection', element: Exceptions},
  { path: '/java/set', name: 'Set', element: Set},
  { path: '/java/map', name: 'Map', element: Map},
  { path: '/java/queue', name: 'Queue', element: Queue},
  { path: '/java/iteration', name: 'Iteration', element: Iteration},
  { path: '/java/polymorphism', name: 'Polymorphism', element: Polymorphism},
  { path: '/java/miscellaneous', name: 'miscellaneous', element: JavaMiscellaneous},
  { path: '/java/lambdas', name: 'Lambdas', element: Lambdas},
  { path: '/java/stream', name: 'Stream', element: Stream},
  { path: '/java/thread', name: 'Thread', element: Thread},
  { path: '/java/callable', name: 'Callable', element: Callable},

  { path: '/javascript/dashboard', name: 'Home', element: JavascriptDashboard},
  { path: '/javascript/es6', name: 'Forms', element: Es6},
  { path: '/javascript/javascript', name: 'Javascript', element: Javascript},

  { path: '/angular/core', name: 'Core', element: Core},
  { path: '/angular/lifeCycle', name: 'LifeCycle', element: LifeCycle},
  { path: '/angular/router', name: 'Router', element: Router},
  { path: '/angular/lazyLoading', name: 'Lazy Loading', element: LazyLoading},
  { path: '/angular/guards', name: 'Guards', element: Guards},
  { path: '/angular/ineterceptor', name: 'Ineterceptor', element: Ineterceptor},
  { path: '/angular/binding', name: 'Binding', element: Bindings},
  { path: '/angular/resolve', name: 'Resolve', element: Resolve},
  { path: '/angular/shareData', name: 'Sharing Data', element: ShareData},
  { path: '/angular/environment', name: 'Environment', element: Environment},
  { path: '/angular/form', name: 'Form', element: AngularForms},

  { path: '/options/delta', name: 'Delta', element: Delta},
  { path: '/options/gamma', name: 'Gamma', element: Gamma},
  { path: '/options/theta', name: 'Theta', element: Theta},
  { path: '/options/vega', name: 'Theta', element: Vega},
  { path: '/options/iv', name: 'IV', element: IV},
  { path: '/options/buyingCall', name: 'Buying a Call', element: BuyingCall},
  { path: '/options/buyingPut', name: 'Buying a Put', element: BuyingPut},
  { path: '/options/sellingCall', name: 'Selling a Call', element: SellingCall},
  { path: '/options/sellingPut', name: 'Selling a Put', element: SellingPut},
  { path: '/options/bidask', name: 'Bid Ask', element: BidAsk},
  { path: '/options/shortCallVerticals', name: 'Short Call Verticals', element: ShortCallVerticals},
  { path: '/options/shortPutVerticals', name: 'Short Put Verticals', element: ShortPutVerticals},
  { path: '/options/longPutVerticals', name: 'Long Put Verticals', element: LongPutVerticals},
  { path: '/options/longCallVerticals', name: 'Long Call Verticals', element: LongCallVerticals},

  { path: '/english/meaning', name: 'Meaning', element: Meaning},

  { path: '/cloud/terraform', name: 'Terraform', element: Terraform},
  { path: '/cloud/aws/vpc', name: 'VPC', element: Vpc},
  { path: '/cloud/aws/ecs', name: 'ECS', element: Ecs},
  { path: '/cloud/docker/dashboard', name: 'DashBoard', element: DockerDashboard},
  { path: '/cloud/docker/commands', name: 'Commands', element: Commands},
  { path: '/cloud/kubernetes/dashboard', name: 'DashBoard', element: KubernetesDashboard},
  { path: '/cloud/kubernetes/commands', name: 'Commands', element: KubernetesCommands},
  { path: '/cloud/kubernetes/example', name: 'Example', element: Example},

  { path: '/behavioral/dashboard', name: 'Javascript', element: One},

  { path: '/kafka/dashboard', name: 'Kafka', element: KafkakaDashboard},

  { path: '*', name: '', element: Page404 },
]

export default routes
