import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Controller';

function Controller () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>@Controller</strong></CCardHeader>
        <CCardBody>
          <p>@Controller is a Spring annotation that is used to mark a class as a controller in the MVC (Model-View-Controller) architecture. It is used to mark a class as a controller and to map incoming HTTP requests to specific methods in the controller.</p>

          <p>Here is an example of a simple controller with @Controller:</p>

          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

          <p>his controller defines a single GET endpoint at /hello that returns a view name "hello". The @Controller annotation indicates that this class serves as a controller and the @GetMapping annotation maps the GET request to the sayHello() method.</p>

          <p>The sayHello() method takes a Model object as a parameter. The Model object is used to store data that will be used by the view to render the response. In this example, the sayHello() method adds a message attribute to the model with the value "Hello, World!"</p>

          <p>Note that @Controller does not automatically write the return value to the response body. If you want to automatically convert the return value to the appropriate format (e.g. JSON, XML) and write it to the response body, you can use the @RestController annotation instead. <NavLink to="/springBoot/configuration">@RestController</NavLink> is a convenience annotation that combines the functionality of @Controller and @ResponseBody.</p>
        </CCardBody>
      </CCard>        
    )
}

export default Controller