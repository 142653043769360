import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/RestController';

function RestController () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>@RestController</strong></CCardHeader>
        <CCardBody>
          <p>@RestController is a Spring annotation that is used to mark a class as a controller with REST endpoints. It is a combination of the <NavLink to="/springBoot/configuration">@Controller</NavLink> and @ResponseBody annotations, which means that it is used for creating RESTful web services.</p>

          <p>The @Controller annotation is used to mark a class as a controller, while the @ResponseBody annotation is used to mark a method as a request handler that generates a response body. When the @ResponseBody annotation is used, the return value of the method is written directly to the HTTP response</p>
          <p>Here is an example of a simple REST controller with a single endpoint using @RestController:</p>

          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

          <p>This controller has a single endpoint that can be accessed using the HTTP GET method at the /hello path. When this endpoint is accessed, the sayHello() method will be called and the string "Hello, World!" will be written to the HTTP response.</p>
        </CCardBody>
      </CCard>        
    )
}

export default RestController