import React, { useState, useCallback } from 'react';
import UserPool from '../UserPool';


let logoutTimer;


const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
});

// const calculateRemainingTime = (expirationTime) => {
//   const currentTime = new Date().getTime();
//   const adjExpirationTime = new Date(expirationTime).getTime();

//   const remainingDuration = adjExpirationTime - currentTime;

//   return remainingDuration;
// };

const retrieveStoredToken = () => {
//   const storedToken = localStorage.getItem('token');
//   const storedExpirationDate = localStorage.getItem('expirationTime');

//   const remainingTime = calculateRemainingTime(storedExpirationDate);

// //   if (remainingTime <= 3600) {
// //     localStorage.removeItem('token');
// //     localStorage.removeItem('expirationTime');
// //     return null;
// //   }




const user = "CognitoIdentityServiceProvider."+UserPool.getClientId()+".LastAuthUser";
const userDetails = localStorage.getItem(user);
const tokenItem = "CognitoIdentityServiceProvider."+UserPool.getClientId()+"."+userDetails+".accessToken";
const storedToken = localStorage.getItem(tokenItem);


  return {
    token: storedToken
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  
  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }

  const [token, setToken] = useState(initialToken);

  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {

    const user = "CognitoIdentityServiceProvider."+UserPool.getClientId()+".LastAuthUser";
    const userDetails = localStorage.getItem(user);
    const tokenItem = "CognitoIdentityServiceProvider."+UserPool.getClientId()+"."+userDetails;
    localStorage.removeItem("CognitoIdentityServiceProvider."+UserPool.getClientId()+".LastAuthUser");
    localStorage.removeItem(tokenItem+".clockDrift");
    localStorage.removeItem(tokenItem+".idToken");
    localStorage.removeItem(tokenItem+".refreshToken");
    localStorage.removeItem(tokenItem+".accessToken");
    window.location.href="/";
  }, []);

  const loginHandler = (token, expirationTime) => {

    const user = "CognitoIdentityServiceProvider."+UserPool.getClientId()+".LastAuthUser";
    const userDetails = localStorage.getItem(user);
    const tokenItem = "CognitoIdentityServiceProvider."+UserPool.getClientId()+"."+userDetails+".accessToken";
    token = localStorage.getItem(tokenItem);
  
    setToken(token);
    // localStorage.setItem('token', token);
    // localStorage.setItem('expirationTime', expirationTime);

    // const remainingTime = calculateRemainingTime(expirationTime);

    // logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

//   useEffect(() => {
//     if (tokenData) {
//       console.log(tokenData.duration);
//       logoutTimer = setTimeout(logoutHandler, tokenData.duration);
//     }
//   }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

//   console.log(contextValue)

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
