import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  // CTableHead,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
	import { NavLink } from "react-router-dom";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/java/Map'


function Map() {
	return (
		<>
			<CCard className="mb-4">
				<CCardHeader><strong>MAP</strong></CCardHeader>
				<CCardBody>
					<b>HashMap</b>
					<p>In Java, a Map is an interface in the java.util package that represents a mapping of keys to values. It is a collection of key-value pairs, where each key is unique and is used to locate the corresponding value.</p>

					<p>Here is an example of how you might use a Map in Java:</p>
					<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

					<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

					<p>There are several implementation classes available for the Map interface in the Java Standard Library, including HashMap, TreeMap, and LinkedHashMap. Each of these implementation classes has its own characteristics and trade-offs in terms of performance and functionality.</p>

					<b>LinkedHashMap</b>
					<p>LinkedHashMap is a class in Java that implements the Map interface. It extends the HashMap class and maintains a doubly-linked list of entries to ensure the order of insertion. This means that when you iterate over a LinkedHashMap, the <b>elements are returned in the order they were inserted.</b></p>
					<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>

				</CCardBody>
			</CCard>
			<CCard className="mb-4">
				<CCardHeader><strong>ConcurrentHashMap</strong></CCardHeader>
				<CCardBody>
					<p>ConcurrentHashMap is a highly efficient thread-safe version of the standard HashMap in Java. It provides concurrent access to a hash table, making it suitable for scenarios where multiple threads need to access and modify the map concurrently without the need for external synchronization.</p>

					<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>


				</CCardBody>
		</CCard>
	</>

	)
}

export default Map;