import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/kafka/Kafka';

function KafkakaDashboard () {

  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Dashboard</strong></CCardHeader>
			<CCardBody>
				<p>In a Kafka cluster, multiple brokers work together to form a distributed and fault-tolerant system. Each broker is identified by a unique broker ID and may run on a separate machine or as a separate process on the same machine.</p>

				<p>A Kafka cluster typically consists of multiple brokers, producers that publish messages to topics, consumers that subscribe to topics and process messages, and ZooKeeper for coordination and metadata management.</p>

				<CAccordion flush>
				<CAccordionItem itemKey={1}>
						<CAccordionHeader>Architecture</CAccordionHeader>
						<CAccordionBody>   	
							<p>Apache Kafka has a distributed architecture that is designed for scalability, fault tolerance, and high throughput. The architecture consists of several key components, each serving a specific role in the messaging system. Here's an overview of the main components in the Kafka architecture:</p>						
							<ol>
								<li>
									<p><b>Producer:</b></p>
									<ul>
										<li>
											<p><b>Responsibility:</b>Producers are responsible for publishing messages (records) to Kafka topics</p>
											</li>
										<li>											
											<p><b>Operation:</b> Producers send messages to a specific topic, and Kafka ensures that the messages are distributed across the partitions of the topic.</p>
										</li>
									</ul>	
								</li>
								<li>
								<p><b>Topic:</b></p>
									<ul>
										<li>
											<p><b>Responsibility</b>: A topic is a logical channel or feed name to which messages are sent by producers and from which messages are consumed by consumers.</p>
											</li>
										<li>											
											<p><b>Partitioning:</b> Each topic can be divided into multiple partitions to allow for parallel processing and distribution of messages.</p>
										</li>
									</ul>	
								</li>
								<li>
								<p><b>Partition:</b></p>
									<ul>
										<li>
											<p><b>Responsibility:</b> Partitions are the basic unit of parallelism and scalability in Kafka. Each partition is an ordered, immutable sequence of messages.</p>
											</li>
										<li>											
											<p><b>Replication:</b> Partitions can be replicated across multiple brokers for fault tolerance. Each partition has one leader and multiple replicas.</p>
										</li>
									</ul>	
								</li>
								<li>
									<p><b>Broker:</b></p>
									<ul>
										<li>
											<p><b>Responsibility:</b> Brokers are Kafka server instances responsible for storing and serving messages. A Kafka cluster is composed of multiple brokers.</p>
											</li>
										<li>											
											<p><b>Storage:</b> Brokers store messages in partitions on disk and serve them to consumers.</p>
										</li>
									</ul>	
								</li>
								<li>
									<p><b>Consumer:</b></p>
									<ul>
										<li>
											<p><b>Responsibility:</b> Consumers subscribe to topics and consume messages. Each consumer reads from one or more partitions, and Kafka ensures that messages are evenly distributed among consumers.</p>
											</li>
										<li>											
											<p><b>Offsets:</b> Consumers maintain an offset, indicating the position in the partition from which they have consumed messages.</p>
										</li>
									</ul>	
								</li>
								<li>
									<p><b>Consumer Group::</b></p>
									<ul>
										<li>
											<p><b>Responsibility:</b> Consumers can be organized into consumer groups for parallel processing of messages. Each partition is consumed by only one consumer within a group.</p>
											</li>
										<li>											
											<p><b>Scaling:</b> Consumer groups allow horizontal scaling by adding more consumers to handle larger workloads.</p>
										</li>
									</ul>	
								</li>
								<li>
									<p><b>ZooKeeper:</b></p>
									<ul>
										<li>
											<p><b>Responsibility:</b> ZooKeeper is used for distributed coordination and management of Kafka brokers. It helps in maintaining metadata, leader election, and detecting broker failures.</p>
											</li>
										<li>											
											<p><b>Configurations:</b> While Kafka is moving towards removing its dependency on ZooKeeper, it is still used for certain operational tasks and metadata storage.</p>
										</li>
									</ul>	
								</li>
								<li>
									<p><b>Controller:</b></p>
									<ul>
										<li>
											<p><b>Responsibility:</b> One broker in the Kafka cluster serves as the controller. The controller is responsible for leadership election, partition reassignment, and other administrative tasks.</p>
											</li>
										<li>											
											<p><b>Failover:</b> If the current controller fails, another broker will take over the role.</p>
										</li>
									</ul>	
								</li>
								<li>
									<p><b>Log Segment::</b></p>
									<ul>
										<li>
											<p><b>Responsibility:</b> The log is the fundamental storage abstraction in Kafka. A log is divided into segments, and each segment contains a fixed number of messages.</p>
											</li>
										<li>											
											<p><b>Retention:</b> Logs have retention policies, determining how long messages are retained before they are deleted.</p>
										</li>
									</ul>	
								</li>
								<li>
									<p><b>Replication Factor:</b></p>
									<ul>
										<li>
											<p><b>Responsibility:</b> The replication factor defines the number of replicas for each partition. It determines how many copies of each partition exist in the Kafka cluster.</p>
										</li>
										<li>
											<p><b>Fault Tolerance:</b> A higher replication factor provides better fault tolerance.</p>
										</li>
									</ul>	
								</li>
							</ol>
							
							
							<img src="" width="50%" height="50%" /><br/><br/>  
							
						</CAccordionBody>
				</CAccordionItem>
				<CAccordionItem itemKey={2}>
						<CAccordionHeader>Kafka Docker</CAccordionHeader>
						<CAccordionBody>   	
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
							<p><b>KAFKA_CREATE_TOPICS:</b> "topic-name:partitions:replication-factor[,topic-name:partitions:replication-factor,...]"</p>
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
						</CAccordionBody>
				</CAccordionItem>		
				<CAccordionItem itemKey={3}>
					<CAccordionHeader>Kafka Spring boot</CAccordionHeader>
					<CAccordionBody>   	
						<b><p>Producer</p></b>
						<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
						<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
						<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
						<b><p>Consumer</p></b>
						<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
						<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
					</CAccordionBody>
				</CAccordionItem>							
				</CAccordion>
			</CCardBody>
    </CCard>        
  )
}

export default KafkakaDashboard;