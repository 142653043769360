import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../../utils/codeSnippet/cloud/Docker';
import vpc from '../../../assets/images/vpc.jpeg'


function DockerDashboard () {

  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Dashboard</strong></CCardHeader>
			<CCardBody>
				<p><h4>Docker Container</h4></p>
				<p>Container is a running environment for <b>IMAGE</b></p>
				<p>In Docker, a container is a lightweight and isolated runtime environment that encapsulates an application and its dependencies. It provides a consistent and portable environment for running software, ensuring that the application runs the same way regardless of the underlying infrastructure.</p>
				<p>A container is created from a Docker image, which is a read-only template that includes everything needed to run an application, such as the code, runtime, libraries, and system tools. The image defines the initial state of the container.</p>

				<p><h4>Docker Image</h4></p>
				<p>A Docker image is a portable and self-contained package that contains everything needed to run an application, including the code, runtime, libraries, dependencies, and system tools. It serves as a template for creating Docker containers.</p>
				<p>Docker images are built from a set of instructions called a Dockerfile. The Dockerfile defines the steps to assemble the image, specifying the base image, adding dependencies, configuring the environment, and setting up the application. It is a text file that follows a specific syntax and includes commands such as <b>FROM, RUN, COPY, EXPOSE</b>, and <b>CMD.</b></p>

				<p><h4>Docker networking</h4></p>
				<p>Docker provides networking functionality to connect containers and enable communication between them. Docker networking allows containers to communicate with each other and with the outside world. Here are some key concepts related to Docker networking:</p>
				<ul>
					<li>
						<p>Default Bridge Network: When you install Docker, it creates a default bridge network named bridge. Containers connected to the default bridge network can communicate with each other using IP addresses. By default, Docker containers are attached to this bridge network if no specific network is specified during container creation.</p></li>
					<li>
						<p>User-defined Bridge Networks: Docker allows you to create your own user-defined bridge networks. User-defined bridge networks provide better isolation and control over network traffic between containers. You can create a user-defined bridge network using the docker network create command.</p>
						<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter> 
						<p>Replace <b>network_name</b> with the desired name for your network.Containers connected to the same user-defined bridge network can communicate with each other using their container names as hostnames</p>
					</li>
					<li><p>Network Drivers: Docker supports different network drivers that provide various networking features and capabilities. The default bridge network uses the bridge driver, but there are other drivers available, such as overlay, host, macvlan, and none. Each driver has its own use cases and configuration options.</p></li>
					<li><p>Container DNS: Docker automatically provides DNS resolution for containers connected to a network. Containers can resolve each other's names using DNS. By default, </p></li>
					<li>
						<p>Network Inspection: You can use the docker network inspect command to get detailed information about a specific network.</p>
						<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter> 
						Replace <b>network_name_or_id</b> with the name or ID of the network you want to inspect.
						</li>
				</ul>
				<p><h4>Docker Compose</h4></p>
				<p>Docker Compose is a tool that allows you to define and manage multi-container Docker applications. It uses a YAML file to specify the services, networks, and volumes required for your application, making it easy to orchestrate and run your application across multiple containers.</p>
				<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter> 
				<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code13}</SyntaxHighlighter> 
				<p><b>Example 2</b></p>
				<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code130000}</SyntaxHighlighter> 
				<p>In this example, we have three services defined: frontend, backend, and db. The frontend service is responsible for the React frontend, the backend service represents the Node.js backend, and the db service is a MySQL database.</p>
				<p>Each service has a build configuration that specifies the Dockerfile location for building the corresponding image. The ports section maps the container's ports to the host machine's ports for accessing the services. The depends_on field ensures that the backend service starts before the frontend service to establish the dependency.</p>
				<p>You'll need to create separate Dockerfiles for the frontend and backend services. Here's an example of a Dockerfile for the frontend:</p>
				<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code130001}</SyntaxHighlighter> 
				<p>And here's an example of a Dockerfile for the backend:</p>
				<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code130002}</SyntaxHighlighter> 
				<p>To use this Docker Compose file, create separate directories for the frontend and backend, and place the respective Dockerfile and application code in each directory. Make sure the application code and Docker Compose file are in the same directory. Then, navigate to that directory in your terminal and run:</p>
				<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code130003}</SyntaxHighlighter> 
				<p>This command will build the Docker images and start the containers for the frontend, backend, and database services. You can access the React frontend at http://localhost:3000 and interact with the Node.js backend at http://localhost:8080.</p>

				<p><h4>Dockerfile</h4></p>
				<p>A Dockerfile is a text file that contains a set of instructions for building a Docker image. It defines the environment and configuration needed to run an application inside a Docker container. </p>
				<ul>
					<li><p><b>FROM:</b> Specifies the base image to use for the container. In this example, we are using the official Python 3.9 image from Docker Hub.</p></li>
					<li><p><b>WORKDIR:</b> Sets the working directory inside the container where subsequent instructions will be executed. In this case, we set it to /app.</p></li>
					<li><p><b>COPY:</b> Copies files from the host machine to the container's file system. In the example, we copy the requirements.txt file to the working directory.</p></li>
					<li><p><b>RUN:</b> Executes a command during the build process. Here, we install the Python dependencies specified in the requirements.txt file.</p></li>
					<li><p><b>COPY:</b> Copies the application code from the host machine to the container's file system.</p></li>
					<li><p><b>EXPOSE:</b> Specifies the port that the container will listen on at runtime. It doesn't publish the port to the host machine; it's purely informational. In this case, we expose port 8000.</p></li>
					<li><p><b>CMD:</b> Specifies the default command to run when the container starts. Here, we run the app.py file using the Python interpreter.</p></li>
				</ul>
				<h5>Node</h5>
				<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code14}</SyntaxHighlighter> 
				<h5>Java</h5>
				<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code16}</SyntaxHighlighter>
				<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code17}</SyntaxHighlighter>
				<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code18}</SyntaxHighlighter>

				{/* <CAccordion flush>
				<CAccordionItem itemKey={1}>
						<CAccordionHeader>Example 1</CAccordionHeader>
						<CAccordionBody>   							
						</CAccordionBody>
					</CAccordionItem>
				</CAccordion> */}
			</CCardBody>
    </CCard>        
  )
}

export default DockerDashboard;