import React from "react";
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Qualifier';
import PrimaryQualifier from "../../components/springBoot/PrimaryQualifier";

function Qualifier() {

    return (
      <>
      <CCard className="mb-4">
        <CCardBody>
        <CAccordion flush>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>@Primary VS @Qualifier</CAccordionHeader>
            <CAccordionBody>                
              <PrimaryQualifier />
            </CAccordionBody>
          </CAccordionItem>
          </CAccordion>
        </CCardBody>
      </CCard>
      <CCard className="mb-4">
			  <CCardHeader><strong>@Qualifier</strong></CCardHeader>
        <CCardBody>
          <p>The @Qualifier annotation is used in Spring to disambiguate bean references <strong>when there are multiple beans of the same type</strong>. It can be used in conjunction with @Autowired to specify which bean should be injected into a field or method.</p>
          <p>For example, consider the following configuration:</p>
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>    
          <p>In this example, there are two beans of type AccountService: primaryAccountService and secondaryAccountService. If you want to inject the secondaryAccountService bean into a field or method, you can use @Qualifier as follows:</p>  
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter> 
          <p>This will cause the secondaryAccountService bean to be injected into the accountService field. If you omit the @Qualifier annotation, the primaryAccountService bean will be injected instead.</p>

          <p>You can also use @Qualifier with method arguments in a @Bean method to specify which bean should be passed as an argument. For example:</p>
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter> 
          <p>This will cause the secondaryAccountService bean to be passed as an argument to the userService method.</p>
        </CCardBody>
      </CCard>  
      <CCard className="mb-4">
        <CCardBody>
          <p>Animal.java</p>
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>

          <p>Cow.java</p>
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>

          <p>Pig.java</p>
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>

          <p>Farm.java</p>
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>

          <p>DemoApplication.java</p>
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>

          <p><strong>@Qualifier</strong> tells Spring which type of <strong>Animal</strong> should be injected as a dependency to the <strong>Farm</strong> class.Remember the @Autowired annotation tells Spring to inject an instance of Animal.</p>
          <p>Remember the <strong>@Autowired</strong> annotation tells Spring to inject an instance of <strong>Animal</strong>.</p>

          <p>Since both <strong>Cow</strong> and <strong>Pig</strong> implement <strong>Animal</strong>, Spring must know which implementation to inject or "autowire".</p>

          <p><strong>@Qualifier</strong> specifies which implementation to use.</p>

          <p>Remember that Spring manages an application context. This application context is a collection of Spring managed beans. When you have two or more beans of the same type, you must "qualify" which bean to use when using <strong>@Autowired</strong> annotation.</p>

          <p>If you were to run the example without @Qualifier, the following exception would be thrown...</p>
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter>
        </CCardBody>
      </CCard>
      </>      
    )
}

export default Qualifier