export const codeSnippet = {
  code1: `terraform {
  required_providers {
    aws = {
      source  = "hashicorp/aws"
      version = "~> 4.0"
    }
  }
}`,
  code2: `provider "aws" {
  region = "us-east-1"
}`,
  code3: `terraform state show <resource_type.resource_name>`,
  code4: `variable "region" {
  type    = string
  default = "us-west-2"
}

variable "instance_count" {
  type    = number
  default = 1
}`,
  code5: `resource "aws_instance" "example" {
  count         = var.instance_count
  instance_type = "t2.micro"
  ami           = "ami-12345678"
  region        = var.region
}`,
  code6: `terraform apply -var="region=us-east-1" -var="instance_count=3"  `,
  code7: `terraform apply -var-file="variables.tfvars"  `,
  code8: `region         = "us-east-1"
instance_count = 3`,
  code9:`variable "region" {
  type    = string
  default = "us-west-2"
}`,
  code10:`variable "instance_count" {
  type    = number
  default = 1
}`,
  code11:`variable "enable_logging" {
  type    = bool
  default = true
}`,
  code12:`variable "colors" {
  type    = list(string)
  default = ["red", "green", "blue"]
}`,
  code13:`variable "user" {
  type    = map(string)
  default = {
    name  = "John"
    age   = "30"
    email = "john@example.com"
  }
}`,
  code14:`variable "person" {
  type = object({
    name    = string
    age     = number
    address = object({
      city    = string
      country = string
    })
  })
  default = {
    name    = "John"
    age     = 30
    address = {
      city    = "New York"
      country = "USA"
    }
  }
}`,
  code15:`variable "unique_values" {
  type    = set(string)
  default = ["red", "green", "blue"]
}`,
  code16:`variable "person_details" {
  type    = tuple([string, number, string])
  default = ["John", 30, "New York"]
}`,
  code17:`variable "dynamic_value" {
  type = any
  default = "Some dynamic value"
}`,
  code18:`locals {
  region_name  = "us-west-2"
  instance_type = "t2.micro"
  tags = {
    environment = "production"
    app_version = "1.0.0"
  }
}`,
  code19:`resource "aws_instance" "example" {
  ami           = "ami-12345678"
  instance_type = local.instance_type
  tags          = local.tags
}`,
  code20: `output "instance_ip" {
  value = aws_instance.example.public_ip
}

output "subnet_ids" {
  value = aws_subnet.example.*.id
}`,
  code21: `resource "aws_s3_bucket" "example" {
  bucket = var.bucket_name
  acl    = "private"
}`,
  code22:`variable "bucket_name" {
  type        = string
  description = "The name of the S3 bucket"
}`,
  code23:`module "s3" {
  source      = "./modules/s3_bucket"
  bucket_name = "example-bucket"
}`,
  code24: `data "aws_instances" "example" {
  filter {
    name   = "tag:Environment"
    values = ["production"]
  }
}

resource "aws_instance" "example_instance" {
  # Instance configuration goes here

  depends_on = [data.aws_instances.example]
}
  
output "instance_ids" {
  value = data.aws_instances.example.ids
}

output "instance_count" {
  value = length(data.aws_instances.example.ids)
}`,
  code25: `terraform {
  required_providers {
    aws = {
      source  = "hashicorp/aws"
      version = ">= 3.0"
    }
    azure = {
      source  = "hashicorp/azure"
      version = ">= 2.0"
    }
  }
}`
}

