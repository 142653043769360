export const codeSnippet = {
  code1: `import java.util.HashSet;
import java.util.Set;

public class SetExample {
  public static void main(String[] args) {
    // Create a set
    Set<String> set = new HashSet<>();

    // Add elements to the set
    set.add("apple");
    set.add("banana");
    set.add("cherry");
    set.add("apple"); // This element will not be added to the set, because sets do not allow duplicates

    // Iterate over the elements in the set
    for (String s : set) {
      System.out.println(s);
    }
  }
}`,
  code2: `apple
banana
cherry`,
  code3:`import java.util.LinkedHashSet;
import java.util.Set;

public class LinkedHashSetExample {
  public static void main(String[] args) {
    // Create a linked hash set
    Set<String> set = new LinkedHashSet<>();

    // Add elements to the set
    set.add("apple");
    set.add("banana");
    set.add("cherry");
    set.add("apple"); // This element will not be added to the set, because sets do not allow duplicates

    // Check if an element is in the set
    System.out.println(set.contains("apple")); // Outputs true
    System.out.println(set.contains("orange")); // Outputs false

    // Remove an element from the set
    set.remove("banana");

    // Iterate over the elements in the set
    for (String s : set) {
      System.out.println(s);
    }
  }
}`,
  code4: `true
false
apple
cherry`
}

