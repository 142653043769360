import React, { useState, useContext } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom';
import Layout from './components/layout/layout';
import './scss/style.scss';
import routes from './routes';
import projectContext from './store/project-context'
import AuthContext from './store/auth-context';
import Login from './pages/Login';

function App() {

  const [project, setProject] = useState(() => { // this will only render the first time
    return localStorage.getItem('project')? localStorage.getItem('project'): 'react';
  });
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();


  const selectProject = (item) => {
    localStorage.setItem("project", item);
    setProject(item);
    navigate(`/dashboard`, { replace: true })
  };

  return (  
    <projectContext.Provider value={{ project: project, selectProject: selectProject }}>   
      <Layout>
        <Routes>
          { authCtx.isLoggedIn && (
            routes.map((route, idx) => {
                return (
                  route.element && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      element={<route.element />}
                    />
                  )
                )
              })
            )}
            {!authCtx.isLoggedIn && (
              <Route path='/auth' key='login' exact='true' element={<Login />} />
            )}
            {!authCtx.isLoggedIn && (
              <Route path='*' key='login' exact='true' element={<Login />} />
            )}

        </Routes>
      </Layout>
    </projectContext.Provider> 
  )
}

export default App
