import React, {useRef} from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/UseCallback';
import UseMemoUseCallback from "../../components/react/UseMemoUseCallback";

function UseCallback () {
  const memoCallback = useRef();

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>UseCallback</strong></CCardHeader>
        <CCardBody>
          <CCard>
            <CCardBody>
              <p>Introduction to <NavLink  to="/react/hooks" active>hooks</NavLink></p>
              <p>Difference between UseMemo and UseCallback <span style={{cursor: 'pointer', color: 'blue'}} onClick={()=>memoCallback.current.scrollIntoView()} href='#'>click here</span></p>
            </CCardBody>
          </CCard><br/>
          <p>Let us first understand what useCallback is. useCallback is a hook that will return a memoized version of the callback function that only changes if one of the dependencies has changed.</p>
          <p>Memoization is a way to cache a result so that it doesn’t need to be computed again. This can boost performance.</p>
          <p>add1 and add2 are functions that add two numbers. They’ve been created by the add function. In JavaScript, function objects are regular objects. Although add1 and add2 have the same code, they are two different function objects. Comparing them evaluates to false.</p>
          <p>That’s how JavaScript objects work. Each object (including a function object) is equal to itself, i.e. add1 === add1.</p>
          <SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>  
          <p>The functions add1 and add2 share the same code source but they are different function objects. Comparing them (add1 === add2) will always return false.</p>
          <SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>  
          <p>In this example, the ParentComponent renders a list of ChildComponents and passes a callback handleClick to each of them. The handleClick callback references data as a dependency.</p>
          <p>Without using useCallback, every time ParentComponent re-renders, a new instance of handleClick would be created, causing all the ChildComponent to re-render. This can cause unnecessary re-renders and may lead to poor performance, especially if there are a large number of ChildComponents.</p>
          <p>By using useCallback, React will only re-create the handleClick callback when data changes. So, when ParentComponent re-renders and data hasn't changed, the handleClick callback will still be the same instance as before, so the ChildComponents will not re-render.</p>
          <UseMemoUseCallback reference={memoCallback} />
        </CCardBody>
      </CCard>        
    )
}

export default UseCallback