export const codeSnippet = {
  code1: `@Controller
public class MyController {

  @GetMapping("/hello")
  public String sayHello(Model model) {
    model.addAttribute("message", "Hello, World!");
    return "hello";
  }

}`,
}

