import React from "react";
import { NavLink } from 'react-router-dom'
import { CRow, CCol, CCard, CCardBody, CCardHeader, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import CommonDashboard from "../../components/CommonDashboard";

function JavascriptDashboard () {
	return (
		<>
		<CommonDashboard />
		<CCard className="mb-4">
			<CCardBody>                
				<CAccordion flush>
					<CAccordionItem itemKey={1}>
						<CAccordionHeader>-------</CAccordionHeader>
						<CAccordionBody>                
							<p>3.0.1</p>
						</CAccordionBody>
					</CAccordionItem>           
				</CAccordion>
			</CCardBody>
		</CCard>
		<CCard className="mb-4">
			<CCardHeader><strong>Pending</strong></CCardHeader>
			<CCardBody>                
				<CAccordion flush>
					<CAccordionItem itemKey={1}>
						<CAccordionHeader>----</CAccordionHeader>
						<CAccordionBody>                
							<ol>
								<li><p>Try Catch - Throw - Exception</p></li>
								<li><p>Stack Memory and Heap</p></li>
								<li><p>Simple thread pool factory</p></li>
								<li><p>Websocket</p></li>
							</ol>
						</CAccordionBody>
					</CAccordionItem>    
				</CAccordion>
			</CCardBody>
		</CCard>
	</>
	)
}

export default JavascriptDashboard