import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import iv from '../../assets/images/iv1.png'


function IV () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Implied Volatility</strong></CCardHeader>
			<CCardBody>
				<p>Implied volatility is a prediction of how much the price of a security will move over a given period of time. It's most often used to price options contracts.</p>			
				<p>IV doesn't predict the direction in which the price change will proceed. For example, <strong>high volatility means a large price swing, but the price could swing upward (very high), downward (very low), or fluctuate between the two directions. Low volatility means that the price likely won't make broad, unpredictable changes.</strong></p>
				<p>If a stock has a price of $100 and an implied volatility of 30%, that means its price will most likely stay between $70 and $130 over the course of the next year.</p>
 				<p>Low implied volatility environments tell us that the market isn’t expecting the stock price to move much from the current stock price over the course of a year. Whereas, a high implied volatility environment tells us that the market is expecting large movements from the current stock price over the course of the next twelve months.</p>
				<ul>
					<li>IV is often used to price options contracts where high implied volatility results in options with higher premiums and vice versa.</li>
					<li>Implied volatility usually <strong>increases</strong> in bearish markets and <strong>decreases</strong> when the market is bullish.</li>
					<li>High Implied Volatility = Higher Option prices</li>
					<li>Low Implied Volatility = Lower Option prices</li>
				</ul>
				For Bearish
				<ul>
					<li>Implied Volatility Down - Short Call / Selling a Call (Vega will be -ve)</li>
					<li>Implied Volatility Up - Long Put / Buying a Put (Vega will be +ve)</li>
				</ul>
				<img src={iv} width="40%" /><br/><br/>
				<h5>Determines IV are relatively high or low (IV Rank OR IV Percentile.)</h5>
				<p><strong>IV Rank</strong> : Ranks the current implied volatility against the historical implied volatility range (IV high - IV low), typically over a one-year period.</p>
				<p><strong>IV Percentile</strong> : Tells you the percentage of time in which the stock’s implied volatility was lower than the current implied volatility over a historical period, typically once year</p>
				<ul>
					<li><strong>IV percentile of 10%</strong>:  Means over the past year, the stocks implied volatility has been below its current implied volatility only 10% of time. So most of the time the stocks implied volatility is above its current level</li>
					<li><strong>IV percentile of 95%</strong> : Means over the past year, the stocks implied volatility has been below its current implied volatility 95 percent of time. In other words, the current implied volatility is extremely high because only 5% of the days over the past years the stock has seen implied volatility that is higher than its current level</li>
				</ul>


			</CCardBody>
	</CCard>
			
	)
}

export default IV