export const codeSnippet = {
  code1: `import React from 'react';
const MyComponent = () => {
  return (
    <React.Fragment>
      <h1>Hello</h1>
      <p>This is a fragment example.</p>
    </React.Fragment>
  );
};`,
    code2: `import React from 'react';
const MyComponent = () => {
  return (
    <>
      <h1>Hello</h1>
      <p>This is a fragment example.</p>
    </>
  );
};`,
  code3: `import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ children }) => {
  return ReactDOM.createPortal(
    <div className="modal">
      {children}
    </div>,
    document.getElementById('modal-root') // Specify the target DOM element
  );
};

const App = () => {
  return (
    <div>
      <h1>React Portal Example</h1>
      <Modal>
        <p>This content is rendered outside the main component hierarchy.</p>
      </Modal>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));`
}

