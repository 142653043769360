export const codeSnippet = {
	code1: `import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isAuthenticated();
  }
}`,
  code2: `import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }`,
  code3: `import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormComponent } from './form.component';

@Injectable({ providedIn: 'root' })
export class FormGuard implements CanDeactivate<FormComponent> {
  canDeactivate(component: FormComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot) {
    return component.canDeactivate() || window.confirm('Are you sure you want to leave the page without saving changes?');
  }
}`,
  code4: `import { Routes, RouterModule } from '@angular/router';
import { FormComponent } from './form.component';
import { FormGuard } from './form.guard';

const routes: Routes = [
  { path: 'form', component: FormComponent, canDeactivate: [FormGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }`,
  code5: `import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivateChild {
  constructor(private authService: AuthService) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isAdmin();
  }
}`,
  code6: `import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AdminGuard } from './admin.guard';

const routes: Routes = [
  { path: 'admin', component: AdminComponent, canActivateChild: [AdminGuard], children: [
    { path: 'users', component: UsersComponent },
    { path: 'settings', component: SettingsComponent }`,
  code7: `import { Injectable } from '@angular/core';
import { CanLoad, Route } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class PremiumGuard implements CanLoad {
  constructor(private authService: AuthService) {}

  canLoad(route: Route) {
    return this.authService.isPremium();
  }
}`,
  code8: `import { Routes, RouterModule, Route } from '@angular/router';
import { PremiumComponent } from './premium.component';
import { PremiumGuard } from './premium.guard';

const routes: Routes = [
  { path: 'premium', loadChildren: './premium.module#PremiumModule', canLoad: [PremiumGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }`
}