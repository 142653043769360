export const codeSnippet = {
  code1: `int[] numbers = {1, 2, 3, 4, 5};
for (int i = 0; i < numbers.length; i++) {
  int number = numbers[i];
  System.out.println(number);
}`,
  code2: `List<String> words = Arrays.asList("apple", "banana", "cherry");
for (String word : words) {
  System.out.println(word);
}`,
  code3:`Set<Integer> numbers = new HashSet<>(Arrays.asList(1, 2, 3, 4, 5));
Iterator<Integer> iterator = numbers.iterator();
while (iterator.hasNext()) {
  int number = iterator.next();
  System.out.println(number);
}`,
  code4: `Map<String, Integer> map = new HashMap<>();
map.put("apple", 1);
map.put("banana", 2);
map.put("cherry", 3);
map.entrySet().stream().forEach(entry -> System.out.println(entry.getKey() + ": " + entry.getValue()));`,
  code5: `import java.util.ArrayList;
import java.util.Iterator;
import java.util.List;

public class IteratorExample {
  public static void main(String[] args) {
    List<String> colors = new ArrayList<>();
    colors.add("Red");
    colors.add("Green");
    colors.add("Blue");

    Iterator<String> iterator = colors.iterator();

    while (iterator.hasNext()) {
      String color = iterator.next();
      System.out.println(color);
    }
  }
}`
}

