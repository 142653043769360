export const codeSnippet = {
    code1: `import React, { useState } from 'react';

function initialize() {
  return 0;
}
function Example() {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);	// this will  render every time
  const [message, setMessage] = useState(''); // this will  render every time
  const [count1, setCount1] = useState(() => { // this will only render the first time
    return 0;
  });
  const [count2, setCount2] = useState(()=>initialize()); // this will only render the first time

  return (
    <div>
      <p>You clicked {count} times</p>
      <p><strong>{message}</strong></p>
      <input type="text" value={message} placeholder="Enter a message" onChange={e => setMessage(e.target.value)} />
      <button onClick={() => setCount(count + 1)}>Click me</button>
    </div>
  );
}`,
    code2: `import { useState } from "react"
function App() {
  const [name, setName] = useState({ firstName: "", lastName: "" })
  const updateName = e => {
    const fieldName = e.target.name;

    setName(existingValues => ({
      ...existingValues,
      [fieldName]: e.target.value,
    }))
  }

  return (
    <div className="App">
      <div>
        <label htmlFor="firstName">First Name: </label>
        <input type="text" name="firstName" value={name.firstName} onChange={updateName} />
      </div>
      <div>
        <label htmlFor="lastName">Last Name: </label>
        <input type="text" name="lastName" value={name.lastName} onChange={updateName} />
      </div>
      <div>
        Name is: {name.firstName} {name.lastName}
      </div>
    </div>
  )
}`,
    code2: `const [data, setData]  = useState({
data: [],
  loaded: false,
  placeholder: 'Loading' 
});
setData(prevState => ({ ...prevState, data: result}));`,
    code3: `const [data, setData]  = useState({
  data: [],
  loaded: false,
  placeholder: 'Loading' 
});
setData(prevState => ({ ...prevState, data: result}));`,
    code4: `const [user, setUser] = useState({id: 1, name: ''});

// create a variable that produces a new value so we can use that new value to update state 
const updateItem = {
  // here we are creating a copy of the current state using the spread syntax
  ...user,
  // after we copy the state, we can add new properties and/or new values to the copied state
  name: "Joe"
};
// no we want to update the state with the new value we created 
setUser(updateItem);`,
    code5: `// Object.assign method
// create a copy of the user object (clone an object using Object.assign())
const updatedObject = Object.assign({}, user)
// change the copied object property "name"
updatedObject.name = "Joe"
// set the new state 
setUser(updatedObject)`,
    code6: `const [myArray, setMyArray] = useState([1, 2, 3]);
const newElement = 4;
setMyArray(oldArray => [...oldArray, newElement]);`,
    code7: `incrementCount() {
  // Note: this will *not* work as intended.
  this.setState({count: this.state.count + 1});
}

handleSomething() {
  // Let's say 'this.state.count' starts at 0.
  this.incrementCount();
  this.incrementCount();
  this.incrementCount();
  // When React re-renders the component, 'this.state.count' will be 1, but you expected 3.

  // This is because 'incrementCount()' function above reads from 'this.state.count',
  // but React doesn't update 'this.state.count' until the component is re-rendered.
  // So 'incrementCount()' ends up reading 'this.state.count' as 0 every time, and sets it to 1.

  // The fix is described below!
}`,
    code8: `incrementCount() {
  this.setState((state) => {
    // Important: read 'state' instead of 'this.state' when updating.
    return {count: state.count + 1}
  });
}
      
handleSomething() {
  // Let's say 'this.state.count' starts at 0.
  this.incrementCount();
  this.incrementCount();
  this.incrementCount();

  // If you read 'this.state.count' now, it would still be 0.
  // But when React re-renders the component, it will be 3.
}`

}

