import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import delta from '../../assets/images/delta1.png'


function Delta () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Delta</strong></CCardHeader>
			<CCardBody>
				<p>Delta is the theoretical estimate of how much an option's value may change given a $1 move UP or DOWN in the underlying security.</p>
				<ui><li>Delta tends to increase as you get closer to expiration for near or at-the-money options.</li></ui><br/>
				<p>Delta can also be seen as the probability of the option expiring in the money (ITM). Which is why an ATM option has delta of around 0.5 (50-50 chance), deep ITM options have delta closer to 1 and deep OTM options have delta closer to 0.</p>
				<img src={delta} width="30%" />
			</CCardBody>
	</CCard>
			
	)
}

export default Delta