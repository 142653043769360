import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Resilience4j';

function Resilience4j () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>Resilience4j</strong></CCardHeader>
        <CCardBody>
          <p>Resilience4j is an open-source library for building resilient, fault-tolerant applications in Java. It provides a set of components that can be used to implement resilience patterns such as circuit breaker, rate limiter, retry, bulkhead, and timeout. It is also compatible with Java libraries and frameworks such as Spring.</p>
          <p>Resilience4j provides a number of features including:</p>
          <ul>
            <li><p><b>Circuit Breaker:</b> Protects against cascading failures by failing fast when a downstream service is not responding or is experiencing issues.</p></li>
            <li><p><b>Rate Limiter:</b> Controls the rate at which requests are sent to a downstream service to prevent overloading it.</p></li>
            <li><p><b>Retry:</b> Retries a failed request a configurable number of times to improve the chances of success.</p></li>
            <li><p><b>Bulkhead:</b> Limits the number of concurrent requests to a downstream service to prevent overload and improve system stability.</p></li>
            <li><p><b>Timeout:</b> Sets a maximum time for a request to complete to prevent blocking and improve system responsiveness.</p></li>
          </ul>

          <CAccordion flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader><b>Resilience4j VS Hystrix</b></CAccordionHeader>
              <CAccordionBody>    
                <p>One of the main differences is that Hystrix has been in maintenance mode since 2018, with no further development or bug fixes being done. In contrast, Resilience4j is an actively maintained library with new features and bug fixes being released regularly.</p>
                <p>Another difference is that Hystrix is a more complex library with a larger number of features, whereas Resilience4j is designed to be lightweight and easy to use. Resilience4j provides a simpler API and a smaller set of features, which makes it more approachable for developers who are new to implementing resilience patterns.</p>
                <p>Resilience4j also offers better integration with reactive programming models and frameworks, with support for reactive streams and reactive types such as CompletableFuture, RxJava, and Reactor. Hystrix, on the other hand, is more focused on traditional <b>synchronous programming</b> models.</p>
                <p>Finally, Resilience4j is designed to work well in modern microservices architectures, with support for <b>distributed tracing and metrics using popular tools such as Prometheus and Grafana</b>. Hystrix, while still usable in microservices architectures, is less well-suited for this type of environment.</p>            
              </CAccordionBody>
            </CAccordionItem>         
          </CAccordion>
          <br/>
          <p><h5><u>Circuit Breaker</u></h5></p>
          <p><b>Closed: </b>When the circuit breaker is in the closed state, requests are allowed to flow through to the service. The circuit breaker counts the number of failed requests and successful requests, and if the failure rate exceeds a configured threshold, it opens the circuit breaker and moves to the open state.</p>
          <p><b>Open: </b>In the open state, requests are blocked, and an error response is returned immediately without contacting the service. The circuit breaker remains in the open state for a configurable amount of time (the wait duration) to allow the service to recover. During this time, the circuit breaker periodically allows a limited number of requests to flow through to the service to check if it has recovered.</p>
          <p><b>Half-Open: </b>When the wait duration has passed, the circuit breaker moves to the half-open state, where a limited number of requests are allowed to flow through to the service to check if it has recovered. If these requests succeed, the circuit breaker moves back to the closed state, and requests are allowed to flow through normally. If any of these requests fail, the circuit breaker moves back to the open state and starts the wait duration again.</p>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
          <p><h5><u>Retry</u></h5></p>
          <p>Using @Retry, you can decorate a method with retry logic, which means that if an exception occurs while executing the method, the method will be retried a specified number of times before giving up and propagating the exception.</p>
          <p>To use @Retry, you need to add the Resilience4j library to your project and annotate the method you want to apply retry logic to with the @Retry annotation. You can configure the number of retries, the delay between retries, and other retry-related settings using the @Retry annotation parameters.</p>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
          <p>In this example, we are configuring the retry settings for the exampleRetry configuration, setting the maximum number of attempts to 3 and the wait duration between retries to 500 milliseconds.</p>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
          <p>When the exampleMethod() method is called, Resilience4j will automatically apply the retry logic according to the configuration specified in the configuration file.</p>

          


        </CCardBody>
      </CCard>        
    )
}

export default Resilience4j