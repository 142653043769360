import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/PropertySource';

function PropertySource () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>@PropertySource</strong></CCardHeader>
        <CCardBody>
          <p>@PropertySource is a Spring annotation used to specify a properties file to be loaded into the Spring Environment. It can be used to specify a file on the classpath, or a file in the file system.</p>

          <p>Here's an example of how to use @PropertySource to load a properties file from the classpath:</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
          <p>Here's an example of how to use @PropertySource to load a properties file from the file system:</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
          <p>You can also specify multiple property sources by using the @PropertySources annotation:</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
          <p>Once the properties are loaded, you can use the Spring Environment to access the values of the properties. For example:</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
        </CCardBody>
      </CCard>        
    )
}

export default PropertySource