export const codeSnippet = {
  code1: `<dependency>
  <groupId>org.springframework.boot</groupId>
  <artifactId>spring-boot-starter-jdbc</artifactId>
</dependency>

<!-- MySQL JDBC driver   // Commented because spring-boot-starter-jdbc is what we need.
<dependency>
  <groupId>mysql</groupId>
  <artifactId>mysql-connector-java</artifactId>
</dependency>
-->`,
  code2: `package com.mkyong.repository;
import com.mkyong.Book;
import java.math.BigDecimal;
import java.util.List;
import java.util.Optional;

public interface BookRepository {

    int count();
    int save(Book book);
    int update(Book book);
    int deleteById(Long id);
    List<Book> findAll();
    List<Book> findByNameAndPrice(String name, BigDecimal price);
    Optional<Book> findById(Long id);
    String getNameById(Long id);
}`,
  code3: `package com.mkyong;
import java.math.BigDecimal;
public class Book {
    private Long id;
    private String name;
    private BigDecimal price;
    //... setters getters constructors...
}`,
  code4: `package com.mkyong.repository;
import com.mkyong.Book;
import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.jdbc.core.JdbcTemplate;
import org.springframework.jdbc.core.namedparam.NamedParameterJdbcTemplate;
import org.springframework.stereotype.Repository;
import java.math.BigDecimal;
import java.util.List;
import java.util.Optional;

@Repository
public class JdbcBookRepository implements BookRepository {

  // Spring Boot will create and configure DataSource and JdbcTemplate
  // To use it, just @Autowired
    @Autowired
    private JdbcTemplate jdbcTemplate;

    @Override
    public int count() {
        return jdbcTemplate
                .queryForObject("select count(*) from books", Integer.class);
    }

    @Override
    public int save(Book book) {
        return jdbcTemplate.update(
                "insert into books (name, price) values(?,?)",
                book.getName(), book.getPrice());
    }

    @Override
    public int update(Book book) {
        return jdbcTemplate.update(
                "update books set price = ? where id = ?",
                book.getPrice(), book.getId());
    }


    @Override
    public int deleteById(Long id) {
        return jdbcTemplate.update(
                "delete books where id = ?",
                id);
    }

    @Override
    public List<Book> findAll() {
        return jdbcTemplate.query(
                "select * from books",
                (rs, rowNum) ->
                        new Book(
                                rs.getLong("id"),
                                rs.getString("name"),
                                rs.getBigDecimal("price")
                        )
        );
    }

    // jdbcTemplate.queryForObject, populates a single object
    @Override
    public Optional<Book> findById(Long id) {
        return jdbcTemplate.queryForObject(
                "select * from books where id = ?",
                new Object[]{id},
                (rs, rowNum) ->
                        Optional.of(new Book(
                                rs.getLong("id"),
                                rs.getString("name"),
                                rs.getBigDecimal("price")
                        ))
        );
    }

    @Override
    public List<Book> findByNameAndPrice(String name, BigDecimal price) {
        return jdbcTemplate.query(
                "select * from books where name like ? and price <= ?",
                new Object[]{"%" + name + "%", price},
                (rs, rowNum) ->
                        new Book(
                                rs.getLong("id"),
                                rs.getString("name"),
                                rs.getBigDecimal("price")
                        )
        );
    }

    @Override
    public String getNameById(Long id) {
        return jdbcTemplate.queryForObject(
                "select name from books where id = ?",
                new Object[]{id},
                String.class
        );
    }
}`,
  code5: `package com.mkyong.repository;
import com.mkyong.Book;
import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.jdbc.core.namedparam.BeanPropertySqlParameterSource;
import org.springframework.jdbc.core.namedparam.MapSqlParameterSource;
import org.springframework.jdbc.core.namedparam.NamedParameterJdbcTemplate;
import org.springframework.stereotype.Repository;
import java.math.BigDecimal;
import java.util.List;
import java.util.Optional;

@Repository
public class NamedParameterJdbcBookRepository extends JdbcBookRepository {

    @Autowired
    private NamedParameterJdbcTemplate namedParameterJdbcTemplate;

    @Override
    public int update(Book book) {
        return namedParameterJdbcTemplate.update(
                "update books set price = :price where id = :id",
                new BeanPropertySqlParameterSource(book));
    }

    @Override
    public Optional<Book> findById(Long id) {
        return namedParameterJdbcTemplate.queryForObject(
                "select * from books where id = :id",
                new MapSqlParameterSource("id", id),
                (rs, rowNum) ->
                        Optional.of(new Book(
                                rs.getLong("id"),
                                rs.getString("name"),
                                rs.getBigDecimal("price")
                        ))
        );
    }

    @Override
    public List<Book> findByNameAndPrice(String name, BigDecimal price) {

        MapSqlParameterSource mapSqlParameterSource = new MapSqlParameterSource();
        mapSqlParameterSource.addValue("name", "%" + name + "%");
        mapSqlParameterSource.addValue("price", price);

        return namedParameterJdbcTemplate.query(
                "select * from books where name like :name and price <= :price",
                mapSqlParameterSource,
                (rs, rowNum) ->
                        new Book(
                                rs.getLong("id"),
                                rs.getString("name"),
                                rs.getBigDecimal("price")
                        )
        );
    }

}`,
  code6: `logging.level.org.springframework=info
#logging.level.org.springframework.jdbc=DEBUG
logging.level.com.mkyong=INFO
logging.level.com.zaxxer=DEBUG
logging.level.root=ERROR

spring.datasource.hikari.connectionTimeout=20000
spring.datasource.hikari.maximumPoolSize=5

logging.pattern.console=%-5level %logger{36} - %msg%n

## MySQL
#spring.datasource.url=jdbc:mysql://192.168.1.4:3306/test
#spring.datasource.username=mkyong
#spring.datasource.password=password

# Oracle
#spring.datasource.url=jdbc:oracle:thin:@localhost:1521:orcl
#spring.datasource.username=system
#spring.datasource.password=Password123`,
code7: `<dependency>
  <groupId>org.springframework.boot</groupId>
  <artifactId>spring-boot-starter-jdbc</artifactId>
</dependency>
<dependency>
  <groupId>mysql</groupId>
  <artifactId>mysql-connector-java</artifactId>
</dependency>`,
  code8: `spring.datasource.url=jdbc:mysql://localhost:3306/mydatabase
spring.datasource.username=dbuser
spring.datasource.password=dbpass
spring.datasource.driver-class-name=com.mysql.cj.jdbc.Driver`,
  code9: `import org.springframework.context.annotation.Bean;
import org.springframework.context.annotation.Configuration;
import org.springframework.jdbc.datasource.DriverManagerDataSource;
import javax.sql.DataSource;

@Configuration
public class DatabaseConfig {
    @Bean
    public DataSource dataSource() {
        DriverManagerDataSource dataSource = new DriverManagerDataSource();
        dataSource.setUrl("jdbc:mysql://localhost:3306/mydatabase");
        dataSource.setUsername("dbuser");
        dataSource.setPassword("dbpass");
        dataSource.setDriverClassName("com.mysql.cj.jdbc.Driver");
        return dataSource;
    }
}`,
  code91: `private final JdbcTemplate jdbcTemplate;
private final NamedParameterJdbcTemplate namedParameterJdbcTemplate;

@Autowired
public UserRepository(DataSource dataSource) {
    this.jdbcTemplate = new JdbcTemplate(dataSource);
    this.namedParameterJdbcTemplate = new NamedParameterJdbcTemplate(dataSource);
}`,
  code10: `String sql = "SELECT * FROM users WHERE age > ?";
List<User> users = jdbcTemplate.query(sql, new Object[] { 18 }, (resultSet, rowNum) -> {
    User user = new User();
    user.setId(resultSet.getLong("id"));
    user.setName(resultSet.getString("name"));
    // Map other columns to user properties
    return user;
});`,
  code101: `String sql = "SELECT * FROM users WHERE age > :age";
Map<String, Object> paramMap = new HashMap<>();
paramMap.put("age", 18);
List<User> users = namedParameterJdbcTemplate.query(sql, paramMap, (resultSet, rowNum) -> {
    User user = new User();
    user.setId(resultSet.getLong("id"));
    user.setName(resultSet.getString("name"));
    // Map other columns to user properties
    return user;
});`,
  code11: `public List<User> getUsers() {
  String sql = "SELECT * FROM users";
  return jdbcTemplate.query(sql, (resultSet, rowNum) -> {
      User user = new User();
      user.setId(resultSet.getLong("id"));
      user.setName(resultSet.getString("name"));
      // Map other columns to user properties
      return user;
  });
}`,
  code12: `public void insertUser(User user) {
  String sql = "INSERT INTO users (id, name, age) VALUES (?, ?, ?)";
  jdbcTemplate.update(sql, user.getId(), user.getName(), user.getAge());
}

public User getUserById(int id) {
  String sql = "SELECT * FROM users WHERE id = ?";
  return jdbcTemplate.queryForObject(sql, new Object[]{id}, (resultSet, rowNum) -> {
      User user = new User();
      user.setId(resultSet.getInt("id"));
      user.setName(resultSet.getString("name"));
      user.setAge(resultSet.getInt("age"));
      return user;
  });
}

public void updateUser(User user) {
  String sql = "UPDATE users SET name = :name, age = :age WHERE id = :id";
  Map<String, Object> paramMap = new HashMap<>();
  paramMap.put("name", user.getName());
  paramMap.put("age", user.getAge());
  paramMap.put("id", user.getId());
  namedParameterJdbcTemplate.update(sql, paramMap);
}`,
  code13: `@Entity
@Table(name = "employees",
        schema = "hr",
        indexes = {
            @Index(name = "idx_first_name", columnList = "first_name"),
            @Index(name = "idx_last_name", columnList = "last_name")
        },
        uniqueConstraints = {
          @UniqueConstraint(name = "uk_email", columnNames = "email"),
          @UniqueConstraint(name = "uk_employee_code", columnNames = "employee_code")
      }
  )
public class Employee {
    @Id
    @GeneratedValue(strategy = GenerationType.IDENTITY)
    private Long id;

    @Column(name = "first_name")
    private String firstName;

    @Column(name = "last_name")
    private String lastName;

    // ...
}`,
  code14: `@Entity
@Table(name = "employees")
public class Employee {
    @Id
    @GeneratedValue(strategy = GenerationType.IDENTITY)
    private Long id;

    // Other entity fields and methods...

    // Getters and setters...
}`,
  code15: `import javax.persistence.Entity;
import javax.persistence.Id;
import javax.persistence.Transient;

@Entity
public class Product {
    @Id
    private Long id;
    
    private String name;
    
    private double price;
    
    @Transient
    private double discountedPrice; // Transient field that won't be persisted
    
    // Constructors, getters, setters
    
    // Getter and Setter for discountedPrice
    public double getDiscountedPrice() {
        // Perform some calculations to derive the discounted price
        return price * 0.8; // Assume a discount of 20%
    }
    
    // Other methods, equals(), hashCode(), etc.
}`,
  code16: `import javax.persistence.*;
@Entity
public class Employee {
    @Id
    private Long id;
    private String name;

    @Embedded
    private Address address;

    // ... constructor, getters and setters
}

@Embeddable
public class Address {
    private String street;
    private String city;
    private String state;
    private String zipCode;

    // ... constructor, getters and setters
}`,
  code17: `@Entity
public class Employee {
    @Id
    private Long id;
    private String name;

    @ManyToOne
    @JoinColumn(name = "department_id") // Specifies the foreign key column name
    private Department department;

    // ... constructor, getters and setters
}

@Entity
public class Department {
    @Id
    private Long id;
    private String name;

    // ... constructor, getters and setters
}`,
code18: `@Entity
public class Employee {
    @Id
    private Long id;
    private String name;

    @OneToMany(cascade=CascadeType.ALL) // By default no operation are cascaded
    @JoinColumn(name = "employee_details_id") // Specifies the foreign key column name
    private EmployeeDetails employeeDetails;

    // ... constructor, getters and setters
}

@Entity
public class EmployeeDetails {
    @Id
    private Long id;
    private String address;

    // ... constructor, getters and setters
}`,
code19: `@Entity
public class Employee {
    @Id
    private Long id;
    private String name;

    @OneToMany(cascade=CascadeType.ALL) // By default no operation are cascaded
    @JoinColumn(name = "employee_details_id") // Specifies the foreign key column name
    private EmployeeDetails employeeDetails;

    // ... constructor, getters and setters
}

@Entity
public class EmployeeDetails {
    @Id
    private Long id;
    private String address;
    @OneToMany(mapped_by=employeeDetails, cascade=CascadeType.ALL) 
    private Employee employee;

    // ... constructor, getters and setters
}`,
  code20:`@Entity
public class Order {
    // Other attributes and mappings
    
    @OneToMany(fetch = FetchType.EAGER)
    private List<OrderItem> orderItems;
}`,
  code21: `@Service
public class EmployeeService {
  @Autowired
  private EmployeeRepository employeeRepository;

  @Autowired
  private EmployeeDetailsRepository employeeDetailsRepository;

  @Transactional
  public void updateEmployeeAndDetails(Employee employee, EmployeeDetails employeeDetails) {
    try {
      // Update Employee
      employeeRepository.save(employee);

      // Update EmployeeDetails
      employeeDetailsRepository.save(employeeDetails);

      // If any error happens between these operations, the transaction will be rolled back
      // and the changes will be reverted.
    } catch (Exception ex) {
      // Handle the exception or log the error here
      throw new RuntimeException("Error occurred during database update.", ex);
    }
  }
}`,
  code22:`spring.datasource.driver-class-name=com.mysql.cj.jdbc.Driver
spring.datasource.url = jdbc:mysql://localhost:3306/javatechie
spring.datasource.username = root
spring.datasource.password = Password
spring.jpa.show-sql = true
spring.jpa.hibernate.ddl-auto = update
spring.jpa.properties.hibernate.dialect = org.hibernate.dialect.MySQL5Dialect
server.port=9191`,
  code23:`@Entity
@Table(name = "STUDENT_TBL")
@Setter
@Getter
@AllArgsConstructor
@NoArgsConstructor
public class Student {

  @Id
  @GeneratedValue(strategy = GenerationType.IDENTITY)
  private Long id;
  private String name;
  private int age;
  private String dept;

  @ManyToMany(fetch = FetchType.LAZY, cascade = CascadeType.ALL)
  @JoinTable(name = "STUDENT_COURSE_TABLE",
    joinColumns = {
            @JoinColumn(name = "student_id", referencedColumnName = "id")
    },
    inverseJoinColumns = {
            @JoinColumn(name = "course_id", referencedColumnName = "id")
    }
  )
  @JsonManagedReference
  private Set<Course> courses;

}`,
  code24:`@Entity
@Table(name = "COURSE_TBL")
@Setter
@Getter
@AllArgsConstructor
@NoArgsConstructor
public class Course {

  @Id
  @GeneratedValue
  private Long id;
  private String title;
  private String abbreviation;
  private int modules;
  private double fee;

  @ManyToMany(mappedBy = "courses",fetch = FetchType.LAZY)
  @JsonBackReference
  private Set<Student> students;
}`,
}

