import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/UseRef';

function UseRef () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>useState</strong></CCardHeader>
        <CCardBody>
          <CCard>
            <CCardBody>
              <p>Introduction to <NavLink  to="/react/hooks" active>hooks</NavLink></p>
            </CCardBody>
          </CCard><br/>
          <p><em>Refs provide a way to access DOM nodes or React elements created in the render method.</em></p>
          <p>In the typical React dataflow, props are the only way that parent components interact with their children. To modify a child, you re-render it with new props. However, there are a few cases where you need to imperatively modify a child outside of the typical dataflow. The child to be modified could be an instance of a React component, or it could be a DOM element. For both of these cases, React provides an escape hatch.</p>
          <SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
          <strong>When to Use Refs</strong>
          <ul>
            <li>Managing focus, text selection, or media playback.</li>
            <li>Triggering imperative animations.</li>
            <li>Integrating with third-party DOM libraries.</li>
          </ul> 
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
          <p>Texbox focus</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

      
        </CCardBody>
      </CCard>        
    )
}

export default UseRef