import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/angular/Binding';

function Bindings () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Router</strong></CCardHeader>
      <CCardBody>    
        <p>In Angular, binding is the process of connecting a component's template with its class. Angular uses binding to synchronize the component's view with its state. There are two types of binding in Angular: <strong>property binding and event binding.</strong></p>
        <p>Property binding is used to bind a property of a DOM element to a property of a component class. It allows you to set the value of a DOM property, such as the value of an input field, based on a component's property. The syntax for property binding is as follows:</p>      
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
        <p>Event binding is used to bind a DOM event, such as a button click, to a method of a component class. It allows you to execute logic in the component class when a specific event occurs in the template. The syntax for event binding is as follows:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
        Here is an example of how to use property binding and event binding in a component:
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
        <p>In this example, the component's template includes an input field and a button. The input field's value is bound to the component's name property using property binding, and the button's click event is bound to the component's sayHello method using event binding. When the button is clicked, the sayHello method is executed and the alert message is displayed.</p>
        <p>It's important to note that the square bracket notation is used for property binding and the round parentheses notation is used for event binding in Angular.</p>
        <p>Binding is an essential part of Angular and it allows you to create a dynamic, interactive user interface that reflects the state of the application.</p>

      </CCardBody>
    </CCard>        
  )
}

export default Bindings;