import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import gamma from '../../assets/images/gamma1.png'


function Gamma () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Gamma</strong></CCardHeader>
			<CCardBody>
				<p>Is the rate of change in an option's delta per one-point move in the underlying asset's price. Delta is how much an option's premium (price) will change given a one-point move in the underlying asset's price.</p>
				<ui>
					<li>At-the-money options have the most gamma exposure.</li>
					<li>In-the-money and out-of-the-money options have the least amount of gamma exposure.</li>
				</ui><br/>
				<p>The gamma is the highest when the strike price is very close to the stock price i.e. in case of ATM options. That is the time when the impact on the delta is the maximum. As the options become very deep ITM or deep OTM, the impact on delta is minimal. Therefore, the gamma curve will reflect that. It will be more of a bell shaped curve and as you go deep OTM or deep ITM, the bell curve starts becoming flat.</p>
				<img src={gamma} width="30%" />
			</CCardBody>
	</CCard>
			
	)
}

export default Gamma