export const codeSnippet = {
  code1: `@Service
public class UserService {
  private final UserRepository userRepository;

  @Autowired
  public UserService(UserRepository userRepository) {
    this.userRepository = userRepository;
  }

  public User createUser(String username, String password) {
    User user = new User(username, password);
    return userRepository.save(user);
  }
}`
}

