export const codeSnippet = {
  code1: `@Service
public class MyService {

  private final MyRepository repository;

  @Autowired
  public MyService(MyRepository repository) {
    this.repository = repository;
  }

  public void doSomething() {
    repository.save();
  }
}`,
  code2: `@Service
public class MyService {

  @Autowired
  private MyRepository repository;

  public void doSomething() {
    repository.save();
  }
}`,
  code3: `@Service
public class MyService {

  private MyRepository repository;

  @Autowired
  public void setRepository(MyRepository repository) {
    this.repository = repository;
  }

  public void doSomething() {
    repository.save();
  }
}`,
  code4: `@Service
public class MyService {

  @Autowired
  @Qualifier("mainRepository")
  private MyRepository repository;

  public void doSomething() {
    repository.save();
  }
}`
}

