import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/angular/Guards';

function Guards () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Router</strong></CCardHeader>
      <CCardBody>    
        <p>Guards in Angular are used to protect routes and control access to certain parts of the application. They allow you to add logic to decide whether a user should be able to navigate to a certain route or not.</p>
        <p>There are several types of guards in Angular:</p>      
        <ol>
          <li>
            <p><strong>CanActivate:</strong> This guard is used to protect a route and decide whether a user should be allowed to activate it or not. It returns either true or false, indicating whether the route can be activated or not.</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
          </li>
          <li>
            <p><strong>CanDeactivate:</strong> This guard is used to control access to a route that is about to be deactivated. It returns either true or false, indicating whether the route can be deactivated or not..</p>
            <p>A class that implements the CanDeactivate interface must have a method called canDeactivate(). This method is called by the router when a user navigates away from a route that is protected by this guard. The method should return either true or false, indicating whether the route can be deactivated or not.</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
            <p>In this example, the FormGuard class implements the CanDeactivate interface and its canDeactivate() method calls the canDeactivate() method on the FormComponent component to check if the form is valid. If the form is valid, the method returns true and the route can be deactivated. If the form is not valid, the method displays a confirmation dialog and returns true if the user confirms, or false if the user cancels.</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
          </li>
          <li>
            <p><strong>CanActivateChild:</strong> This guard is used to protect child routes of a route. It works similar to the CanActivate guard, but it is only applied to child routes.</p>
            <p>A class that implements the CanActivateChild interface must have a method called canActivateChild(). This method is called by the router when a user navigates to a child route that is protected by this guard. The method should return either true or false, indicating whether the child route can be activated or not.</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
            <p>In this example, the AdminGuard class implements the CanActivateChild interface and its canActivateChild() method checks whether the user is an admin by calling the isAdmin() method on the AuthService service. If the user is an admin, the method returns true and the child route can be activated. If the user is not an admin, the method returns false and the user is redirected to the home page.</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
          </li>
          <li>
            <p><strong>CanLoad:</strong> This guard is used to prevent the lazy loading of a module. It returns either true or false, indicating whether the module should be loaded or not.</p>
          <p>Guards are typically used in conjunction with the Angular router to protect routes and control access to certain parts of the application. They can be used to implement authentication, authorization, and other security-related features in</p>
          <p>A class that implements the CanLoad interface must have a method called canLoad(). This method is called by the router when a user navigates to a route that has a lazy-loaded module configured in the loadChildren property. The method should return either an observable of true or false, a promise of true or false, or a boolean indicating whether the module can be loaded or not.</p>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
          <p>In this example, the PremiumGuard class implements the CanLoad interface and its canLoad() method checks whether the user is a premium user by calling the isPremium() method on the AuthService service. If the user is a premium user, the method returns true and the module can be loaded. If the user is not a premium user, the method returns false and the user is redirected to the home page.</p>
          <p>To protect a lazy-loaded module with a guard, you need to add the guard to the canLoad property of the route configuration. Here is an example of how to protect the premium module:</p>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
          </li>

        </ol>
      </CCardBody>
    </CCard>        
  )
}

export default Guards;