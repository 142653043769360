import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'

	import shortCallVerticals1 from '../../assets/images/shortCallVerticals1.png'
	import shortCallVerticals2 from '../../assets/images/shortCallVerticals2.png'
	

function ShortCallVerticals () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Short Call Verticals (Bearish / Neutral / Sideways) (Credit Spread)</strong></CCardHeader>
			<CCardBody>
				<p>Other Names: <strong>Short Call Spread, Bear Call Spread, Credit Call Spread, Selling a Call Spread</strong></p>
				<strong>Potential Entry:</strong>
				<ul>
					<li>Stock Bouncing off a resistance level.</li>
					<li>Entry above the recent resistance.</li>					
				</ul>
				<strong>Stock Selection:</strong>				
				<ul>
					<li>Stock that appears to be a <span style={{color: 'green', fontWeight: 'bold'}}>bearish or bearish/neutral</span> trend.</li>
					<li>Stock with higher <span style={{color: 'green', fontWeight: 'bold'}}>trading volume</span> (open interest / volume) -> <span style={{fontWeight: 'bold'}}>1m+</span></li>		
					<li>Option with ‘tight’ <span style={{color: 'green', fontWeight: 'bold'}}>bid/ask price</span></li>
					<li>When <span style={{color: 'green', fontWeight: 'bold'}}>Delta</span> between <span style={{color: 'green', fontWeight: 'bold'}}>.20 & .40</span></li>		
					<li><span style={{color: 'green', fontWeight: 'bold'}}>IV</span> should be >50 (<span style={{color: 'green', fontWeight: 'bold'}}>high Volatility</span> - No earning or event drive the volatility)</li>
					<li>Shorter term will be better (<span style={{color: 'green', fontWeight: 'bold'}}>Time Decay</span> will favor 20 - 50 days), should expired worthless</li>					
				</ul>
				<strong>Strategy:</strong>				
				<ul>
					<li>Sell Out-of-the-money (OTM) <span style={{fontWeight: 'bold'}}>Call.</span></li>
					<li>Buy Further Out-of-the-money (OTM) <span style={{fontWeight: 'bold'}}>Call.</span></li>
				</ul>
				<p><strong>Max profit:</strong> Credit Received</p>
				<p><strong>Max Loss:</strong> Difference between Strike price - Credit Received</p>
				<p><strong>Expected Break Even:</strong> Short Call Strike Price + Credit Received</p>

				<p>% of return calculation</p>
				<CTable bordered>
					<CTableBody>
						<CTableRow>
							<CTableHeaderCell scope="row">
								= Credit /  (Difference between strikes - Credit received)<br/>
								= Max Profit / Max Loss
							</CTableHeaderCell>							
						</CTableRow>
					</CTableBody>
				</CTable>

				<img src={shortCallVerticals1} width="100%" style={{padding: '5px'}}/><br/>
				<img src={shortCallVerticals2} width="100%" style={{padding: '5px'}}/>
				<br/><br/>
			</CCardBody>
	</CCard>			
	)
}

export default ShortCallVerticals