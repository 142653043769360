export const codeSnippet = {
  code1: `import { useState, useEffect, useRef  } from "react"
function App() {
	const [name, setName] = useState("")
	const renderCount = useRef(0)
	
	useEffect(() => {
		renderCount.current = renderCount.current + 1
	})
	return (
	<div className="App">
		<input ref={renderCount} type="text" name="name" onChange={e=>setName(e.target.value)} />
		<div> Name is: {name} </div>
		<div> I rendred {renderCount.current} times </div>
	</div>
	)
}`,
  code2: `<button onClick={()=> {
import ("../sum.js".then(module => {
	alert(module.sum(2,2))
	})
}}

export function sum(a, b){
	return a + b;
}`,
	code3: `import React, { Suspense } from 'react';
const OtherComponent = React.lazy(() => import('./OtherComponent'));
function MyComponent() {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<OtherComponent />
		</Suspense>
	);
}`,
	code4: `import Loadable from 'react-loadable';
const OtherComponent = Loadable({
	loader: () => import('./OtherComponent'),
	loading: () => <div>Loading...</div>,
});

function MyComponent() {
	return <OtherComponent />;
}`,
	code5:`import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const Home = lazy(() => import('./routes/Home'));
const About = lazy(() => import('./routes/About'));

const App = () => (
	<Router>
		<Suspense fallback={<div>Loading...</div>}>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about" element={<About />} />
			</Routes>
		</Suspense>
	</Router>
);`,
	code6:`// ManyComponents.js
export const MyComponent = /* ... */;
export const MyUnusedComponent = /* ... */;`,
	code7:`// MyComponent.js
export { MyComponent as default } from "./ManyComponents.js";`,
	code8:`// MyApp.js
import React, { lazy } from 'react';
const MyComponent = lazy(() => import("./MyComponent.js"));`,
	code9: `import React from 'react';

const MyComponent = ({text}) => {
	return <div>{text}</div>;
};

export default React.memo(MyComponent);`,
	code10: `import React from 'react';

const MyComponent = ({text, id}) => {
	return <div>{text}</div>;
};

const areEqual = (prevProps, nextProps) => {
	return prevProps.text === nextProps.text && prevProps.id === nextProps.id;
}

export default React.memo(MyComponent, areEqual);`,
	code11: `import React from 'react';
function withLoading(WrappedComponent) {
	return function LoadingHOC(props) {
		const { isLoading } = props;
		if (isLoading) {
			return <div>Loading...</div>;
		}
		return <WrappedComponent {...props} />;
	}
}
export default withLoading;`,
	code12: `import withLoading from './withLoading';

function MyComponent() {
	return <div>My component content</div>;
}

const MyLoadingComponent = withLoading(MyComponent);

// Now you can use MyLoadingComponent and pass the isLoading prop`,
	code13: `import React, { useEffect } from 'react';
import { useAuth } from './auth-context';

function withAuth(WrappedComponent) {
	return function AuthHOC(props) {
		const { user, logout } = useAuth();

		useEffect(() => {
			if (!user) {
				// redirect to login page
				props.history.push('/login');
			}
		}, [user, props.history]);

		return (
			<WrappedComponent user={user} logout={logout} {...props} />
		);
	}
}
export default withAuth;`,
	code14: `import withAuth from './withAuth';
function MyComponent({user, logout}) {
	return (
		<div>
			<div>Welcome, {user.name}</div>
			<button onClick={logout}>Logout</button>
		</div>
	);
}
const MyAuthComponent = withAuth(MyComponent);`,
  code15: `import React, { useState } from 'react';

function ControlledComponent() {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
    />
  );
}`,
  code16: `import React, { useRef } from 'react';

function UncontrolledComponent() {
  const inputRef = useRef();

  const handleClick = () => {
    const value = inputRef.current.value;
    console.log(value);
  };

  return (
    <div>
      <input type="text" ref={inputRef} />
      <button onClick={handleClick}>Submit</button>
    </div>
  );
}`,
	code17: `npm install node-sass react`,
	code18: `// MyComponent.scss
.container {
	background-color: blue;
	color: white;
	font-size: 16px;
}`,
	code19: `import React from 'react';
import './MyComponent.scss';

function MyComponent() {
	return <div className="container">Styled Component</div>;
}`,
	code20: `"scripts": {
	"start": "node-sass --watch src -o src"
}`,
	code21: `// _variables.scss
$primary-color: #007bff;
$font-size: 16px;`,
	code22: `// MyComponent.scss
@import './variables';

.container {
	background-color: $primary-color;
	font-size: $font-size;
}`,
	code23: `// MyComponent.scss
.container {
	background-color: blue;

	.title {
		font-size: 20px;
		color: white;
	}
}`,
	code24: `// _mixins.scss
@mixin button-styles {
	padding: 10px 20px;
	background-color: $primary-color;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}`,
	code25: `// MyComponent.scss
@import './mixins';

.my-button {
	@include button-styles;
}`

}

