import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilCursor,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilChevronTop,
  cilChevronBottom,
  cilChartLine,
  cilChevronDoubleDown,
  cilChevronDoubleUp
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = {
  "react": [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      active: true,
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: 'NEW',
      },
    },
    {
      component: CNavItem,
      name: 'State & Events',
      to: '/react/stateEvents',
      icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Conditional Lists',
      to: '/react/stateEvents',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Styles',
      to: '/react/styles',
      icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Fragments & Portals',
      to: '/react/fragments',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Router',
      to: '/react/router',
      icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Forms',
      to: '/react/forms',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Miscellaneous',
      to: '/react/miscellaneous',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    },
    {
      component: CNavTitle,
      name: 'Components',
    },
    {
      component: CNavGroup,
      name: 'Hooks',
      to: '/react/hooks',
      icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'useState',
          to: '/react/useState',
        },
        {
          component: CNavItem,
          name: 'useEffect',
          to: '/react/useEffect',
        },
        {
          component: CNavItem,
          name: 'useRef',
          to: '/react/useRef',
        },
        {
          component: CNavItem,
          name: 'useReducer',
          to: '/react/useReducer',
        },
        {
          component: CNavItem,
          name: 'useContext',
          to: '/react/useContext',
        },
        {
          component: CNavItem,
          name: 'useMemo',
          to: '/react/useMemo',
        },
        {
          component: CNavItem,
          name: 'useCallback',
          to: '/react/useCallback',
        },
        {
          component: CNavItem,
          name: 'useId',
          to: '/react/useId',
        },
        {
          component: CNavItem,
          name: 'Custom Hooks',
          to: '/react/customHooks',
        }
      ],
    },
    {
      component: CNavTitle,
      name: 'React-Redux',
    },
    {
      component: CNavGroup,
      name: 'Redux',
      to: '/react/redux',
      icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'useState',
          to: '/react/useState',
        }
      ],
    }
  ],
  "springBoot": [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: 'NEW',
      },
    },
    {
      component: CNavItem,
      name: 'Introduction',
      to: '/springBoot/intro',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: '',
      },
    }, {
      component: CNavItem,
      name: 'IOC',
      to: '/springBoot/ioc',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: '',
      },
    }, {
      component: CNavItem,
      name: 'Spring Profile',
      to: '/springBoot/profile',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: '',
      },
    }, {
      component: CNavItem,
      name: 'HTTP',
      to: '/springBoot/http',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: '',
      },
    }, {
      component: CNavItem,
      name: 'Thread',
      to: '/springBoot/thread',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: '',
      },
    }, {
      component: CNavItem,
      name: 'DataBase',
      to: '/springBoot/dataBase',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: '',
      },
    }, {
      component: CNavItem,
      name: 'Async-http-client',
      to: '/springBoot/async-http-client',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: '',
      },
    }, {
      component: CNavItem,
      name: 'Resilience4j',
      to: '/springBoot/resilience4j',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: '',
      },
    }, {
      component: CNavItem,
      name: 'Miscellaneous',
      to: '/springBoot/miscellaneous',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    },{
      component: CNavItem,
      name: 'Spring Cloud Stream',
      to: '/springBoot/miscellaneous',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    },{
      component: CNavItem,
      name: 'Mongo Db',
      to: '/springBoot/mongodb',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    },
    {
      component: CNavGroup,
      name: 'Bean',
      to: '/react/beans',
      icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: '@SpringBootApplication',
          to: '/springBoot/springBootApplication',
        },{
          component: CNavItem,
          name: '@RestController',
          to: '/springBoot/restController',
        },{
          component: CNavItem,
          name: '@Controller',
          to: '/springBoot/controller',
        },{
          component: CNavItem,
          name: '@Configuration',
          to: '/springBoot/configuration',
        },{
          component: CNavItem,
          name: '@EnableAutoConfiguration',
          to: '/springBoot/enableAutoConfiguration',
        }, {
          component: CNavItem,
          name: '@ComponentScan',
          to: '/springBoot/componentScan',
        }, {
          component: CNavItem,
          name: '@ConfigurationProperties',
          to: '/springBoot/configurationProperties',
        }, {
          component: CNavItem,
          name: '@Bean',
          to: '/springBoot/bean',
          
        }, {
          component: CNavItem,
          name: '@PropertySource',
          to: '/springBoot/propertySource',
          
        }, {
          component: CNavItem,
          name: '@Component',
          to: '/springBoot/component',
        },{
          component: CNavItem,
          name: '@Service',
          to: '/springBoot/service',
        },{
          component: CNavItem,
          name: '@Autowired',
          to: '/springBoot/autowired',
        },{
          component: CNavItem,
          name: '@Qualifier',
          to: '/springBoot/qualifier',
        },{
          component: CNavItem,
          name: '@Primary',
          to: '/springBoot/primary',
        },{
          component: CNavItem,
          name: '@Service',
          to: '/springBoot/service',
        },{
          component: CNavItem,
          name: '@Repository',
          to: '/springBoot/repository',
        },{
          component: CNavItem,
          name: '@Value',
          to: '/springBoot/value',
        }, {
          component: CNavItem,
          name: '@Async',
          to: '/springBoot/async',
        }, {
          component: CNavItem,
          name: '@EnableCaching',
          to: '/springBoot/enableCaching',
        }
      ]
    },
    {
      component: CNavGroup,
      name: 'Testing',
      to: '/springBoot/test',
      icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Mackito',
          to: '/springBoot/mockito',
        }
      ]
    }
  ],
  "java": [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      active: true,
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: 'NEW',
      },
    }, {
      component: CNavItem,
      name: 'Exception',
      to: '/java/exception',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    }, {
      component: CNavItem,
      name: 'Polymorphism',
      to: '/java/polymorphism',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    }, {
      component: CNavItem,
      name: 'Lambdas',
      to: '/java/lambdas',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    },{
      component: CNavItem,
      name: 'Stream',
      to: '/java/stream',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    },{
      component: CNavItem,
      name: 'Thread',
      to: '/java/thread',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    }, {
      component: CNavItem,
      name: 'Callable',
      to: '/java/callable',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    }, {
      component: CNavItem,
      name: 'Miscellaneous',
      to: '/java/miscellaneous',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    }, {
      component: CNavGroup,
      name: 'Collection',
      to: '/java/collection',
      icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Collection',
          to: '/java/collection',
        },{
          component: CNavItem,
          name: 'List',
          to: '/java/list',
        },{
          component: CNavItem,
          name: 'Set',
          to: '/java/set',
        },{
          component: CNavItem,
          name: 'Queue',
          to: '/java/queue',
        },{
          component: CNavItem,
          name: 'Map',
          to: '/java/map',
        },{
          component: CNavItem,
          name: 'Iteration',
          to: '/java/iteration',
        }
      ]
    }
  ],
  "javaScript": [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: 'NEW',
      },
    },
    {
      component: CNavItem,
      name: 'ES6',
      to: '/javascript/es6',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    }, {
      component: CNavItem,
      name: 'Javascript',
      to: '/javascript/javascript',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    }

  ],
  "angular": [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: 'NEW',
      },
    }, {
      component: CNavItem,
      name: 'Core',
      to: '/angular/core',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    }, {
      component: CNavItem,
      name: 'LifeCycle',
      to: '/angular/lifeCycle',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    }, {
      component: CNavItem,
      name: 'Form',
      to: '/angular/form',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    }, {
      component: CNavItem,
      name: 'Binding',
      to: '/angular/binding',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    }, {
      component: CNavItem,
      name: 'Router',
      to: '/angular/router',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    }, {
      component: CNavItem,
      name: 'Lazy Loading',
      to: '/angular/lazyLoading',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    }, {
      component: CNavItem,
      name: 'Guards',
      to: '/angular/guards',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    }, {
      component: CNavItem,
      name: 'Ineterceptor',
      to: '/angular/ineterceptor',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    }, {
      component: CNavItem,
      name: 'Resolve',
      to: '/angular/resolve',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    }, {
      component: CNavItem,
      name: 'Share data',
      to: '/angular/shareData',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    },  {
      component: CNavGroup,
      name: 'Miscellaneous',
      to: '/angular/miscellaneous',
      icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Environment',
          to: '/angular/environment',
        }, 
      ]
    }    
  ],  
  "testing": [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: 'NEW',
      },
    }
  ],  
  "options": [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: 'NEW',
      }
    },{
      component: CNavItem,
      name: 'IV',
      to: '/options/iv',
      icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
    },{
      component: CNavItem,
      name: 'Bid vs Ask',
      to: '/options/bidask',
      icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
    },
    {
      component: CNavGroup,
      name: 'Call & Put',
      to: '/options/verticals',
      icon: <CIcon icon={cilChartLine} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Buying a call',
          to: '/options/buyingCall',
          icon: <CIcon icon={cilChevronTop} customClassName="nav-icon" />,
        },{
          component: CNavItem,
          name: 'Selling a Call',
          to: '/options/sellingCall',
          icon: <CIcon icon={cilChevronBottom} customClassName="nav-icon" />,

        },{
          component: CNavItem,
          name: 'Buying a Put',
          to: '/options/buyingPut',
          icon: <CIcon icon={cilChevronBottom} customClassName="nav-icon" />,
        },{
          component: CNavItem,
          name: 'Selling a Put',
          to: '/options/sellingPut',
          icon: <CIcon icon={cilChevronTop} customClassName="nav-icon" />,
        }
      ]
    },{
      component: CNavGroup,
      name: 'Greeks',
      to: '/options/greeks',
      icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Delta',
          to: '/options/delta',
        },{
          component: CNavItem,
          name: 'Gamma',
          to: '/options/gamma',
        },{
          component: CNavItem,
          name: 'Theta',
          to: '/options/theta',
        },{
          component: CNavItem,
          name: 'Vega',
          to: '/options/vega',
        }
      ]
    },
    {
      component: CNavGroup,
      name: 'Verticals',
      to: '/options/verticals',
      icon: <CIcon icon={cilChartLine} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Short Call Verticals',
          to: '/options/shortCallVerticals',
          icon: <CIcon icon={cilChevronBottom} customClassName="nav-icon" />,
        },{
          component: CNavItem,
          name: 'Short Put Verticals',
          to: '/options/shortPutVerticals',
          icon: <CIcon icon={cilChevronTop} customClassName="nav-icon" />,

        },{
          component: CNavItem,
          name: 'Long Put Verticals',
          to: '/options/longPutVerticals',
          icon: <CIcon icon={cilChevronDoubleDown} customClassName="nav-icon" />,
        },{
          component: CNavItem,
          name: 'Long Call Verticals',
          to: '/options/longCallVerticals',
          icon: <CIcon icon={cilChevronDoubleUp} customClassName="nav-icon" />,
        }
      ]
    }
  ], 
  "english":[
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      active: true,
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: 'NEW',
      },
    },
    {
      component: CNavItem,
      name: 'Meaning',
      to: '/english/meaning',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />      
    }
  ],
  "cloud": [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: 'NEW',
      },
    }, {
      component: CNavGroup,
      name: 'AWS',
      to: '/cloud/aws',
      icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'VPC',
          to: '/cloud/aws/vpc',
        }, {
          component: CNavItem,
          name: 'ECS',
          to: '/cloud/aws/ecs',
        }, 
      ]
    }, {
      component: CNavGroup,
      name: 'Kubernetes',
      to: '/cloud/kubernetes',
      icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Dashboard',
          to: '/cloud/kubernetes/dashboard',
        },
        {
          component: CNavItem,
          name: 'Commands',
          to: '/cloud/kubernetes/commands',
        }, {
          component: CNavItem,
          name: 'Example',
          to: '/cloud/kubernetes/example',
        }
      ]
    }, {
      component: CNavGroup,
      name: 'Docker',
      to: '/cloud/docker',
      icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Dashboard',
          to: '/cloud/docker/dashboard',
        },
        {
          component: CNavItem,
          name: 'Commands',
          to: '/cloud/docker/commands',
        }, 
      ]
    }, {
      component: CNavItem,
      name: 'Terraform',
      to: '/cloud/terraform',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,      
    },
  ],
  "kafka": [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: 'NEW',
      },
    }, {
      component: CNavGroup,
      name: 'Kafka',
      to: '/kafka',
      icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Dashboard',
          to: '/kafka/dashboard',
        },
        {
          component: CNavItem,
          name: 'Commands',
          to: '/kafka/commands',
        }, {
          component: CNavItem,
          name: 'Example',
          to: '/kafka/example',
        }
      ]
    }
  ],
  "behavioral": [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/behavioral/dashboard',
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
      badge: {
        color: 'info',
        text: 'NEW',
      },
    }
  ]
}

export default _nav
