import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  // CTableHead,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
	import { NavLink } from "react-router-dom";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/java/Callable'


function Callable() {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Callable</strong></CCardHeader>
			<CCardBody>		
				<p>In Java, the java.util.concurrent.Callable interface is used to represent a task that can be executed asynchronously. It is similar to the Runnable interface but allows the task to return a result and throw exceptions.</p>			
				<p>The Callable interface has a single method called call(), which is responsible for performing the task and returning a result. Here's an example of how you can use the Callable interface with threads in Java:</p>	
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

			</CCardBody>
	</CCard>
			
	)
}

export default Callable;