import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  // CTableHead,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
	import { NavLink } from "react-router-dom";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/java/Queue'


function Queue() {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Set</strong></CCardHeader>
			<CCardBody>
				<p>In Java, a Queue is an interface in the java.util package that represents a collection of elements that are processed in a first-in, first-out (FIFO) order. A Queue allows you to add elements to the tail of the queue and remove elements from the head of the queue.</p>

				<p>Here is an example of how you might use a Queue in Java:</p>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

				<p>There are several implementation classes available for the Queue interface in the Java Standard Library, including LinkedList, PriorityQueue, and ArrayDeque. Each of these implementation classes has its own characteristics and trade-offs in terms of performance and functionality.</p>
			</CCardBody>
	</CCard>
			
	)
}

export default Queue;