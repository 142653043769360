import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'

import longPutVerticals1 from '../../assets/images/longPutVerticals1.png'
import longPutVerticals2 from '../../assets/images/longPutVerticals2.png'
import longPutVerticals3 from '../../assets/images/longPutVerticals3.png'
import longPutVerticals4 from '../../assets/images/longPutVerticals4.png'


function LongPutVerticals () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Long Put Verticals (Bearish/Neutral) (Debit Spread)</strong></CCardHeader>
			<CCardBody>
				<p>Other Names: <strong>Long Put Spread, Bear Put Spread, Debit Put Spread, Buying a Put Spread</strong></p>
				<strong>Potential Entry:</strong>
				<ul>
					<li>Stock Bouncing off a resistance level.</li>
						<ul>
							<li>Break through support</li>		
							<li><img src={longPutVerticals4} style={{height: '40px'}}/></li>					
						</ul>
				</ul>
				<ul>
					<li>To increase the risk & reward, widen up the distance between the strike price.</li>
					<li>To reduce the risk & reward, narrow the distance between the strike price.</li>
				</ul>
				<strong>Stock Selection:</strong>				
				<ul>
					<li>Stock that appears to be a bearish.</li>
					<li>Stock with higher <span style={{color: 'green', fontWeight: 'bold'}}>trading volume</span> (open interest / volume) -> <span style={{fontWeight: 'bold'}}>1m+</span></li>		
					<li>Option with 'tight' <span style={{color: 'green', fontWeight: 'bold'}}>bid/ask price</span></li>
					<li>When <span style={{color: 'green', fontWeight: 'bold'}}>Delta</span> between <span style={{color: 'green', fontWeight: 'bold'}}>.20 & .40</span></li>		
					<li><span style={{color: 'green', fontWeight: 'bold'}}>IV</span> should be {'<'} 20 </li>
					<li>At expiration, the option should move to ITM.</li>					
					<li>Strike price should be <span style={{color: 'green', fontWeight: 'bold'}}>30 - 40</span> days</li>
					<li><img src={longPutVerticals3} style={{height: '100px'}}/></li>
				</ul>
				<strong>Strategy:</strong>				
				<ul>
					<li>Buy an At-the-money (ATM) <strong>Put</strong>. or ITM (but less profit, more safe)</li>
					<li>Sell an Out-of-the-money (OTM) <strong>Put.</strong></li>
				</ul>
				<p><strong>Max profit:</strong> Difference between Strike price - Debit Paid.</p>
				<p><strong>Max Loss:</strong> Debit Paid.</p>
				<p><strong>Expected Break Even:</strong> Long Put Strike Price - Debit Paid</p>

				<p>% of return calculation</p>
				<CTable bordered>
					<CTableBody>
						<CTableRow>
							<CTableHeaderCell scope="row">
								= Debit /  (Difference between strikes - Debit paid)<br/>
								= Max Profit / Max Loss
							</CTableHeaderCell>							
						</CTableRow>
					</CTableBody>
				</CTable>

				<img src={longPutVerticals1} width="100%" style={{padding: '5px'}}/><br/>
				<img src={longPutVerticals2} width="100%" style={{padding: '5px'}}/>
				<br/><br/>
			</CCardBody>
		</CCard>	
	)
}

export default LongPutVerticals