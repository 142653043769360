export const codeSnippet = {
  code1: `https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/sample-templates-services-us-west-2.html`,
  code2: `provider "aws" {
  region = "us-east-1"
  access_key = ""
  secret_key = ""
}

resource "aws_security_group" "my_security_group" {
  egress = [
    {
      cidr_blocks      	= [ "0.0.0.0/0"]
      description      	= ""
      from_port        	= 0
      ipv6_cidr_blocks 	= []
      prefix_list_ids  	= []
      protocol         	= "-1"
      security_groups  	= []
      self             	= false
      to_port          	= 0
    }
  ]
  ingress = [
    {
      cidr_blocks      	= ["0.0.0.0/0"]
      description      	= ""
      from_port        	= 22
      ipv6_cidr_blocks 	= []
      prefix_list_ids  	= []
      protocol         	= "tcp"
      security_groups  	= []
      self             	= false
      to_port          	= 22
    }, {
      from_port         = 80
      to_port           = 80
      protocol          = "tcp"
      cidr_blocks       = ["0.0.0.0/0"]
      description      	= ""
      ipv6_cidr_blocks 	= []
      prefix_list_ids  	= []
      security_groups  	= []
      self             	= false
    }
  ]
  vpc_id 	         = "vpc-0ad715e7367cd8c17"
  tags = {
    Name = "my_security_group"
  }
}

resource "aws_ecs_cluster" "ecs_cluster" {
  name = "my_ecs_cluster"
}

resource "aws_iam_role" "ecs_task_execution_role" {
  name = "ecs-task-execution-role"
  assume_role_policy = <<EOF
{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Sid": "",
      "Effect": "Allow",
      "Principal": {
        "Service": "ecs-tasks.amazonaws.com"
      },
      "Action": "sts:AssumeRole"
    }
  ]
}
EOF
}

resource "aws_iam_role_policy_attachment" "ecs_task_execution_policy_attachment" {
  policy_arn = "arn:aws:iam::aws:policy/service-role/AmazonECSTaskExecutionRolePolicy"
  role       = aws_iam_role.ecs_task_execution_role.name
}

resource "aws_ecs_task_definition" "task_definition" {
  family                	 = "my_ecs_task_definition"
  execution_role_arn       = aws_iam_role.ecs_task_execution_role.arn
  network_mode             = "awsvpc"
  requires_compatibilities = ["FARGATE"]
  cpu    = ".5 vCPU"
  memory = "1 GB"
  container_definitions = file("\${path.module}/container_definitions.json")
}

resource "aws_ecs_service" "ecs_service" {
  name            = "my_ecs_service"
  cluster         = aws_ecs_cluster.ecs_cluster.id
  task_definition = aws_ecs_task_definition.task_definition.arn
  desired_count   = 2
  launch_type     = "FARGATE"

  network_configuration {
    subnets           = var.subnets
    security_groups   = [aws_security_group.my_security_group.id]
    assign_public_ip  = true
  }
}`,
  code3: `[
  {
    "name": "my-container",
    "image": "nginx:latest",
    "portMappings": [
      {
        "containerPort": 80,
        "hostPort": 80
      }
    ],
    "environment" : [
      {
        "name"  : "VAR_NAME_1",
        "value" : "Value1"
      },
      {
        "name"  : "VAR_NAME_2",
        "value" : "Value2"
      }
    ]
  }
]`
}

