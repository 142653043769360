import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  // CTableHead,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
	import { NavLink } from "react-router-dom";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Thread'


function SpringThread() {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Thread</strong></CCardHeader>
			<CCardBody>	
				<p>By default, the embedded Tomcat server in Spring Boot uses a thread pool with a maximum of 200 threads to handle incoming requests. This default setting can be configured using the <b>server.tomcat.max-threads</b> property in the application.properties file or through programmatic configuration.</p>	
				<h4><p>TaskExecutor</p></h4>
				<p>Spring Boot provides support for managing threads and executing tasks asynchronously through its TaskExecutor interface. A TaskExecutor is a simple interface that abstracts the task execution mechanism and provides a common way to execute tasks.</p>	
				<p>	To use a TaskExecutor in Spring Boot, you need to define a bean that implements the TaskExecutor interface and configure it in your application. You can either use the built-in ThreadPoolTaskExecutor implementation or define your own custom implementation.</p>	
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
				<p>In this example, we define a bean of type Executor that uses the ThreadPoolTaskExecutor implementation. We set the core pool size to 2, the maximum pool size to 4, and the queue capacity to 500. This means that there will be a maximum of 4 threads in the pool, and if all threads are busy, tasks will be added to a queue of size 500.</p>
				<p>In this example, the taskExecutor bean is defined with a core pool size of 2 and a maximum pool size of 4. The queue capacity is set to 500, meaning that if all 4 threads are busy and there are more than 500 tasks waiting to be executed, the executor will reject additional tasks.</p>
				<p>To execute tasks asynchronously, you can simply inject the TaskExecutor bean into your service and use it to execute tasks.</p>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
				<p>In this example, we use the @Async annotation to specify that the doSomething method should be executed asynchronously using the taskExecutor bean. When the doSomething method is called, it will be executed in a separate thread from the thread that called it.</p>
				<h5><p>setCorePoolSize</p></h5>
				<p>The setCorePoolSize method sets the number of threads to keep in the pool, even if they are idle. The size you set depends on your specific requirements and the workload of your application.</p>
				<ol>
					<li><p>If your tasks are short and quickly executed, you can set the core pool size to a smaller number, such as 2 or 3.</p></li>
					<li><p>If your tasks are long running and take a considerable amount of time to complete, you might want to set the core pool size to a larger number, such as 10 or 20.</p></li>
					<li><p>If your application experiences bursts of traffic, you can set the core pool size to a number that can handle the peak load.</p></li>
					<li><p>The core pool size should not be set too large, as this can lead to performance problems and resource consumption issues.</p></li>
				</ol>
				<h5><p>setMaxPoolSize</p></h5>
				<p>The setMaxPoolSize method sets the maximum number of threads that can be in the pool at any given time. This value sets an upper limit on the number of threads that can be created by the executor, even if there is a demand for more threads.</p>
				<ol>
					<li><p>f your tasks are short and quickly executed, you can set the maximum pool size to a smaller number, such as 10 or 20.</p></li>
					<li><p>If your tasks are long running and take a considerable amount of time to complete, you might want to set the maximum pool size to a larger number, such as 100 or 200.</p></li>
					<li><p>If your application experiences bursts of traffic, you can set the maximum pool size to a number that can handle the peak load.</p></li>
					<li><p>The maximum pool size should not be set too large, as this can lead to performance problems and resource consumption issues.</p></li>
				</ol>
			</CCardBody>
	</CCard>
			
	)
}

export default SpringThread;