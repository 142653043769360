import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
import buyingCall1 from '../../assets/images/buyingCall1.png'
import buyingCall2 from '../../assets/images/buyingCall2.png'
import buyingCall3 from '../../assets/images/buyingCall3.png'
import buyingCall4 from '../../assets/images/buyingCall4.png'

function BuyingCall () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Buying a Call (Long Call)</strong></CCardHeader>
			<CCardBody>
				<p>A call option gives the holder the right, but not the obligation, to buy the stock</p>
				<ul>
					<li>Bullish</li>
					<li style={{color: 'red'}}>Negative Theta</li>
					<li>Unlimited Profit</li>
					<li>Limited Loss</li>
					<li>Low POP% (Probability of profit)</li>
				</ul>
				<strong><p>Potential Entry: </p></strong>
				<ul>
					<li>Breaking above resistance (with high volume)</li>
					<li>Bouncing off support (bull flags)</li>				
				</ul>
				<strong><p>Stock Selection:  </p></strong>
				<ul>
					<li>Bullish</li>
					<li>Stock with higher trading volume (1m+)</li>			
					<li>Option with higher trading volume (open interest/volume) (1m+)</li>
					<li>Option with ‘tight’ bid/ask price</li>	
					<li><strong>Strike Selection: </strong>Choose ATM strike or first ITM strike</li>	
				</ul>
				<p><strong>Break-even point = Call Strike + Premium Paid</strong></p>
				<p>Let's take a look at a long call's Greek exposures to help explain the strategy:</p>

				<CTable bordered>
					<CTableBody>
						<CTableRow>
							<CTableHeaderCell scope="row">Delta</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'green'}}>Positive</CTableHeaderCell>
							<CTableDataCell>Call prices rise when the stock price increases, which benefits call buyers. When the stock price falls, call prices decrease (not good for call buyers).</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">Gamma</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'green'}}>Positive</CTableHeaderCell>
							<CTableDataCell>A long call position delta gets closer to +100 as the stock price increases and closer to 0 as the stock price decreases.</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">Theta</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'red'}}>Negative</CTableHeaderCell>
							<CTableDataCell>The extrinsic value of options decays over time, leading to decreasing option prices (bad for call buyers).</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">Vega</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'green'}}>Positive</CTableHeaderCell>
							<CTableDataCell><strong>Increases</strong> in IV indicate an increase in option prices, which is beneficial for call buyers. Decreases in IV indicate falling option prices, which is harmful to call owners.</CTableDataCell>
						</CTableRow>
					</CTableBody>
				</CTable><br/>
				<img src={buyingCall1} width="30%" style={{padding: '5px'}}/>
				<img src={buyingCall2} width="30%" style={{padding: '5px'}}/>
				<img src={buyingCall3} width="30%" style={{padding: '5px'}}/>
				<br/><br/>
				<img src={buyingCall4} width="100%"/>
			</CCardBody>
	</CCard>
			
	)
}

export default BuyingCall