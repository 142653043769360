import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/ConfigurationProperties';

function ConfigurationProperties () {

    return (
      <>
      <CCard className="mb-4">
			  <CCardHeader><strong>@ConfigurationProperties</strong></CCardHeader>
        <CCardBody>
          <p>@ConfigurationProperties is a Spring annotation that is used to bind external configuration properties to a class or bean. It allows you to define a class that contains fields for each configuration property that you want to bind, and then automatically populate those fields with the corresponding property values from your configuration source (e.g. application.properties, application.yml).</p>

          <p>Here is an example of a class annotated with @ConfigurationProperties:</p>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code01}</SyntaxHighlighter>

          <p>In this example, the AppProperties class has three fields: name, version, and description. The @ConfigurationProperties annotation specifies a prefix of "app", which means that the properties will be taken from the "app" prefix in the configuration source.</p>

          <p>For example, if you have the following properties in your configuration source:</p>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code02}</SyntaxHighlighter>

          <p>Then the fields in the AppProperties class will be automatically populated with these values when the application starts.</p>

          <p>To use the AppProperties class in your application, you can simply autowire it into your beans like this:</p>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code03}</SyntaxHighlighter>

        </CCardBody>
      </CCard>   
      <CCard className="mb-4">
        <CCardBody>
          <p>Spring Boot has many useful features including externalized configuration and easy access to properties defined in properties files</p>
          <p>Normally, we use the <NavLink  to="/springBoot/value">@Value</NavLink> to inject the .properties value one by one, this is good for small and simple structure .properties files. For example,</p>
          <em>global.properties</em>
          {/* <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter> */}
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
          <em>GlobalProperties.java</em>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
          <p>The equivalent in <strong>@ConfigurationProperties</strong></p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
          <p>application.properties</p>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
          <p>@ConfigurationProperties comes to rescue :</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
        </CCardBody>
      </CCard>  
      </>      
    )
}

export default ConfigurationProperties