import React, {useRef} from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/UseMemo';
import UseMemoUseCallback from "../../components/react/UseMemoUseCallback";

function UseMemo () {

  const memoCallback = useRef();

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>UseMemo</strong></CCardHeader>
        <CCardBody>
          <CCard>
            <CCardBody>
              <p>Introduction to <NavLink  to="/react/hooks" active>hooks</NavLink></p>
              <p>Difference between UseMemo and UseCallback <span style={{cursor: 'pointer', color: 'blue'}} onClick={()=>memoCallback.current.scrollIntoView()} href='#'>click here</span></p>
            </CCardBody>
          </CCard><br/>
          <p><em>useMemo is for memoizing values to avoid expensive calculations on every re-render.</em></p>
          <p>During initial rendering, <em>useMemo(compute, dependencies)</em> invokes compute, memoizes the calculation result, and returns it to the component.</p>
          <p>There are two problems that useMemo seeks to address:</p>
          <ul>
            <li>referential equality</li>
            <li>computationally expensive operations</li>
          </ul>
          <strong>Understanding Memoization</strong>
          <p>Memoization is an optimization technique that passes a complex function to be memoized. In memoization, the result is “remembered” when the same parameters are passed-in subsequently.</p>

          <CCard>
            <CCardBody>
              <p>useMemo can help the performance of an application by “remembering” expensive functions and preventing a re-render every time there is a change in the application.</p>

              <p>While performance can be improved by using this hook, it can also slow down your application if you overuse it. The more you use the hook, the more your application has to allocate memory.</p>            

              <p>useMemo() can be used to make sure that you only update the reference of that object whenever the actual contents of the object change.</p>

              <p>If you want to make sure that the reference of an object or an array is exactly the same as it was the last time you rendered it, if none of the actual values changed, you can use useMemo().</p>
            </CCardBody>
          </CCard><br/>

          <p>If we have a function compute 1 + 1, it will return 2. But if it uses memoization, the next time we run 1’s through the function, it won’t add them up; it will just remember the answer is 2 without executing the adding function.</p>

          <p>useMemo takes in a function and an array of dependencies.</p>

          <p>The dependencies act similar to arguments in a function. The dependency’s list are the elements useMemo watches: if there are no changes, the function result will stay the same. Otherwise, it will re-run the function. If they don’t change, it doesn’t matter if our entire component re-renders, the function won’t re-run but instead return the stored result. This can be optimal if the wrapped function is large and expensive. That is the primary use for useMemo.</p>

          <SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>      

          <p>Example 2</p>
          <SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>  

          <strong>Referential Equality</strong>    
          <p>When you try to compare two different variables in Javascript, it is going to compare their reference in the case of objects and arrays (because of their nature to point at an address).</p>
          <SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>  
          <p>Even though they have the exact same values in the object or array, they reference different places in memory.</p>

          <p>If your dependency is an object or an array, because of the behavior caused by referential equality, react will think of it as a new object or array because it is referencing a new place in memory every time, hence making the function (that that dependency is tied to) re-run because technically the dependency has changed.</p>

          <p>In order to make sure that our function (that the dependency depends on) only ever runs when our object’s value actually does get updated, we can use useMemo() on the object.</p>
          <SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>  
          <p>Without useMemo(), ‘themeStyles’ will be regarded as a new object every time, making the useEffect() run every time because technically its dependency has changed.</p>
          <SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>  
          <p>With useMemo(), ‘themeStyles’ will not be updated, to begin with, because ‘themeStyles’’ dependency [dark] did not change.</p>
          <p>Put otherwise, we will get the exact same reference for ‘themeStyles’ as we did the previous time our app was rendered.</p>
          <p>useEffect() will, therefore, in order to check if its dependency has been updated or not, be comparing the old ‘themeStyles’ with the supposedly new ‘themeStyles’.</p>
          <p>Since they will both be referencing the same object, useEffect() will not run because its dependency did not change.</p>
          <SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>  
          <p>In this example, the useMemo hook is used to memoize the filtered items. The callback function is the items.filter function, which filters the items based on the filter prop. The dependencies are the items and filter props. This means that the filteredItems will only be recalculated if either the items or filter props change.</p>
          <p>By using the useMemo hook, we can avoid re-filtering the items and re-rendering the entire list every time the component re-renders, which can improve the performance of our application.</p>
          <UseMemoUseCallback reference={memoCallback} />
        </CCardBody>
      </CCard>        
    )
}

export default UseMemo