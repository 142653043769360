import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/angular/Router';

function Router () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Router</strong></CCardHeader>
      <CCardBody>    
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
        <p>app-routing.module.ts</p>      
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
        <p>app.component.ts</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>

        <p>The ** path in the last route is a wildcard. The router will select this route if the requested URL doesn't match any paths for routes defined earlier in the configuration. This is useful for displaying a "404 - Not Found" page or redirecting to another route.</p>

        <p>app.module.ts</p>      
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>

        <strong><p>PathMatch</p></strong>
        <p>In Angular, the pathMatch property of a route definition is used to specify how the router should match the URL of a route to the URL of the location in the browser.</p>
        <p>There are two possible values for the pathMatch property:</p>
        <ol>
          <li><p>'full': This value requires that the entire URL in the browser's address bar matches the path of the route. If the path is '/foo' and the pathMatch is set to 'full', the router will only navigate to this route if the URL in the address bar is exactly '/foo'.</p></li>
          <li><p>'prefix': This value allows for a route's path to be a prefix of the URL in the browser's address bar. If the path is '/foo' and the pathMatch is set to 'prefix', the router will navigate to this route if the URL in the address bar starts with '/foo'.</p></li>
        </ol>
        <p>Here is an example of how you might use the pathMatch property in a route definition:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>

        <strong><p>Router-Outlet</p></strong>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>

        <strong><p>Router Parameter</p></strong>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>


      </CCardBody>
    </CCard>        
  )
}

export default Router;