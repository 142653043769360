import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import bidvsask from '../../assets/images/bidvsask.png'


function BidAsk () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Delta</strong></CCardHeader>
			<CCardBody>
				<p>The <strong>bid</strong> price refers to the highest price a buyer(broker) will pay for a security.
The ask price refers to the lowest price a seller(broker) will accept for a security.</p>
				<p>The <strong>ask</strong> price refers to the lowest price a seller(broker) will accept for a security</p>
				<ul>
					<li>The difference between these two prices is known as the <strong>spread</strong></li>
					<li>the smaller the spread, the greater the liquidity of the given security.</li>
					<li><strong>The bid price</strong> will almost always be <strong>lower than the ask price.</strong></li>
				</ul>
				<img src={bidvsask} width="50%" />
			</CCardBody>
	</CCard>
			
	)
}

export default BidAsk