export const codeSnippet = {
  code1: `import io.github.resilience4j.retry.annotation.Retry;
@Retry(name = "exampleRetry")
public void exampleMethod() {
    // method implementation
}`,
  code2: `resilience4j.retry.instances.exampleRetry.maxAttempts=3
resilience4j.retry.instances.exampleRetry.waitDuration=500ms`,
  code3: `@CircuitBreaker(name = SERVICE_A, fallbackMethod = "serviceAFallback")
@RequestMapping("/a")
public String serviceA(@RequestParam String id) {
  String url = BASE_URL + "b";
  return restTemplate.getForObject(
      url,
      String.class
  );
}`,
  code4: `public String serviceAFallback(String id, Exception e) {
  return id+" - This is a fallback method for Service A - "+ e;
}`,
  code5: `management:
  health:
    circuitbreakers:
      enabled: true
  endpoints:
    web:
      exposure:
        include: health
  endpoint:
    health:
      show-details: always

resilience4j:
  circuitbreaker:
    configs:
      default:
        ignoreExceptions:
          - org.springframework.web.client.HttpClientErrorException$BadRequest
    instances:
      serviceA:
        registerHealthIndicator: true
        eventConsumerBufferSize: 10
        failureRateThreshold: 50
        minimumNumberOfCalls: 5
        automaticTransitionFromOpenToHalfOpenEnabled: true
        waitDurationInOpenState: 5s
        permittedNumberOfCallsInHalfOpenState: 3
        slidingWindowSize: 10
        slidingWindowType: COUNT_BASED
        excludedStatusCodes: 400-499
        ignoreExceptions:
          - org.springframework.web.client.HttpClientErrorException$BadRequest
        fallbackDecorators:
          - fallbackDecorators:
              - predicate: excludedResponseStatuses
                fallbackMethod: null
  retry:
    instances:
      serviceA:
        registerHealthIndicator: true
        maxRetryAttempts: 5
        waitDuration: 10s
  ratelimiter:
    instances:
      serviceA:
        registerHealthIndicator: false
        limitForPeriod: 10
        limitRefreshPeriod: 10s
        timeoutDuration: 3s`
}

