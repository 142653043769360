import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Http';

function Http () {

    return (
      <CCard className="mb-4">
        <CCardHeader><strong>Miscellaneous</strong></CCardHeader>
        <CCardBody>                
          <CAccordion flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader>ResponseEntity</CAccordionHeader>
              <CAccordionBody>    
                <p>In Spring Boot, the ResponseEntity class is a convenient way to represent an HTTP response, including the status code, headers, and body. It is often used to return a response from a controller method in a Spring MVC application.</p>
                <p>Here is an example of how you might use ResponseEntity in a Spring Boot application:</p>            
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
                <p>In this example, the getCustomer method returns a ResponseEntity with a Customer object as the body and an HttpStatus of OK if the customer is found, or an HttpStatus of NOT_FOUND if the customer is not found.</p>
                <p>You can also use the ResponseEntity class to set custom headers and status codes in the response. For example:</p>
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
                <p>In this example, the createCustomer method returns a ResponseEntity with a Location header and an HttpStatus of CREATED after saving a new customer.</p>
              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={2}>
              <CAccordionHeader>@RequestMapping</CAccordionHeader>
              <CAccordionBody>    
                <p>In Spring Boot, the @RequestMapping annotation is used to map a request to a specific handler method. It can be used at the class level or the method level to specify the HTTP method, path, and other request parameters that a method should handle.</p>
                <p>Here is an example of how you might use @RequestMapping in a Spring Boot controller:</p>            
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
                <p>In this example, the CustomerController class is annotated with @RequestMapping("/customers") to handle requests to the /customers path. The getAllCustomers method is annotated with @GetMapping to handle GET requests, and the createCustomer method is annotated with @PostMapping to handle POST requests. The getCustomerById method is annotated with @GetMapping("&#123;id&#125;") to handle GET requests to the /customers/&#123;id&#125; path.</p>

                <p>You can also use the @RequestMapping annotation to specify other request parameters, such as the acceptable media types or query parameters. For example:</p>
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
              </CAccordionBody>
            </CAccordionItem>           
          </CAccordion>
      </CCardBody>
    </CCard>      
  )
}

export default Http