import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/Router';

function ReactRouter() {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>AbortController</strong></CCardHeader>
      <CCardBody>
          
          <p>In order to use React Router on the web you need to run npm i <b>react-router-dom</b> to install React Router.</p>
          <p>The easiest step by far is setting up your router. All you need to do is import the specific router you need (<b>BrowserRouter</b> for the web and NativeRouter for mobile) and wrap your entire application in that router.</p>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
          <i>Generally you will import your router in the index.js page of your application and it will wrap your App component. The router works just like a context in React and provides all the necessary information to your application so you can do routing and use all the custom hooks from React Router.</i>
          <strong>Defining Routes</strong>
          <p>The next step in React Router is to define your routes. This is generally done at the top level of your application, such as in the App component, but can be done anywhere you want.</p>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
          <strong>Navigation</strong>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
          <strong>Dynamic Routing</strong>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
          <strong>Nested Routes</strong>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
          <p>Path of the <b>index</b> Route is the same as the parent Route</p>
          <strong>Shared Layouts</strong>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
          <p>The way our new code will work is whenever we match a route inside the /book parent Route it will render the BooksLayout component which contains our shared navigation. Then whichever child Route is matched will be rendered wherever the <b>Outlet</b> component is placed inside our layout component. The <b>Outlet</b> component is essentially a placeholder component that will render whatever our current page's content is. This structure is incredibly useful and makes sharing code between routes incredibly easy.</p>
          <stront>Outlet Context</stront>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter>
          <strong>useRoutes Hook</strong>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter>
      </CCardBody>
    </CCard>
    )
}

export default ReactRouter;