export const codeSnippet = {
	code1: `import { Component, OnChanges, SimpleChanges } from '@angular/core';
@Component({
  selector: 'app-my-component',
  templateUrl: './my-component.component.html',
  styleUrls: ['./my-component.component.css']
})
export class MyComponent implements OnChanges {
  @Input() myInput: string;

  ngOnChanges(changes: SimpleChanges) {
    console.log('myInput changed from', changes.myInput.previousValue, 'to', changes.myInput.currentValue);
  }
}`,
code11: `import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-my-component',
  templateUrl: './my-component.component.html',
  styleUrls: ['./my-component.component.css']
})
export class MyComponentComponent implements OnChanges {
  myControl = new FormControl('');

  ngOnChanges(changes: SimpleChanges) {
    if (changes.myControl) {
      console.log('My control has changed:', changes.myControl.currentValue);
    }
  }
}`,
  code111: `<input type="text" [formControl]="myControl">`,
  code2: `import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-component',
  template: '{{message}}'
})
export class MyComponent implements OnInit {
  name: string;
  message: string;

  ngOnInit() {
    this.message = 'Hello, \$\{this.name}';
  }
}`,
  code3: `import { Component, DoCheck } from '@angular/core';

@Component({
  selector: 'app-my-component',
  template: '{{message}}'
})
export class MyComponent implements DoCheck {
  name: string;
  message: string;
  previousName: string;

  ngDoCheck() {
    if (this.name !== this.previousName) {
      this.message = 'Hello, \$\{this.name}';
      this.previousName = this.name;
    }
  }
}`,
  code4: `import { Component, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-my-component',
  template: '
    <ng-content></ng-content>
    {{message}}'
})
export class MyComponent implements AfterContentInit {
  message: string;

  ngAfterContentInit() {
    this.message = 'Content initialized';
  }
}`,
  code5: `import { Component, AfterContentChecked } from '@angular/core';

@Component({
  selector: 'app-my-component',
  template: '
    <ng-content></ng-content>
    {{message}}
  '
})
export class MyComponent implements AfterContentChecked {
  message: string;

  ngAfterContentChecked() {
    this.message = 'Content checked';
  }
}`,
  code6: `import { Component, AfterViewInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-my-component',
  template: '
    <app-child-component #childComponent></app-child-component>
    {{message}}
  '
})
export class MyComponent implements AfterViewInit {
  @ViewChild('childComponent', {static: false}) childComponent: ChildComponent;
  message: string;

  ngAfterViewInit() {
    this.message = 'View initialized: \$\{this.childComponent.name}';
  }
}`,
  code7: `import { Component, AfterViewChecked, ViewChild } from '@angular/core';

@Component({
  selector: 'app-my-component',
  template: '
    <app-child-component #childComponent [name]="name"></app-child-component>
    {{message}}
  '
})
export class MyComponent implements AfterViewChecked {
  @ViewChild('childComponent', {static: false}) childComponent: ChildComponent;
  name = 'John';
  message: string;

  ngAfterViewChecked() {
    this.message = 'View checked: \$\{this.childComponent.name}';
  }
}`,
  code8: `import { Component, OnInit, OnDestroy } from '@angular/core';
  import { Subscription } from 'rxjs';
  
@Component({
  selector: 'app-my-component',
  template: '{{message}}
})
export class MyComponent implements OnInit, OnDestroy {
  message: string;
  subscription: Subscription;

  ngOnInit() {
    this.subscription = someObservable.subscribe(data => this.message = data);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}`
}