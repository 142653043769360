import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Autowired';

function Autowired () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>@Autowired</strong></CCardHeader>
        <CCardBody>
          <p>In Spring, the @Autowired annotation is used to inject a bean into another bean. When you use @Autowired on a constructor, field, or setter method, Spring will automatically find and inject a matching bean from the application context.</p>
          <p>Here's an example of how you might use @Autowired in a Spring Boot application:</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

          <p>In this example, the MyService class has a constructor that takes a MyRepository as an argument. The @Autowired annotation indicates that a MyRepository bean should be injected when the MyService bean is created.</p>

          <p>You can also use @Autowired on a field or setter method to have Spring inject a bean into the field or invoke the setter method with the bean as an argument. For example:</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>

          <p>By default, @Autowired will try to find a matching bean in the application context by its type. You can also use the @Qualifier annotation to specify a specific bean by its unique name.</p>

          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>

        </CCardBody>
      </CCard>        
    )
}

export default Autowired