import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu, cilPrint } from '@coreui/icons'
import { AppBreadcrumb, AppHeaderDropdown } from './index'
import { logo } from '../../../src/assets/brand/logo'
import projectContext from '../../store/project-context'
import printContext from '../../store/print-context'

const AppHeader = (props) => {
  const projectItem = useContext(projectContext);
  const print = useContext(printContext);
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler className="ps-1" onClick={() => props.sideBarHandler(!props.sidebar)}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
          <NavLink className="nav-link" to="" active><h3 className="sidebar-brand-full" height={35} style={{paddingTop: '8px'}}>LS</h3></NavLink><br/>          
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          {/* <CNavItem>
            <CNavLink onClick={()=>projectItem.selectProject('react')} component={NavLink}>React</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={()=>projectItem.selectProject('springBoot')} component={NavLink}>SpringBoot</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={()=>projectItem.selectProject('java')} component={NavLink}>Java</CNavLink>
          </CNavItem>          
          <CNavItem>
            <CNavLink onClick={()=>projectItem.selectProject('javaScript')} component={NavLink}>JavaScript</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={()=>projectItem.selectProject('angular')} component={NavLink}>Angular</CNavLink>
          </CNavItem>   
          <CNavItem>
            <CNavLink onClick={()=>projectItem.selectProject('testing')} component={NavLink}>Testing</CNavLink>
          </CNavItem>                  
          <CNavItem></CNavItem> */}
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilPrint} size="lg" onClick={()=>print.print()}/>
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
