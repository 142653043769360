export const codeSnippet = {
  code1: `function add() {
  return (a, b) => a + b;
}
const add1 = add();
const add2 = add();
add1(4, 5);                      // => 9
add2(2, 2);                     // => 4
add1 === add2;            // => false
add1 === add1;           // => true`,
  code2: `import { useCallback } from 'react';

function ParentComponent({ data }) {
  const handleClick = useCallback(() => {
    // Do something with data
  }, [data]);

  return (
    <>
      {data.map(item => (
        <ChildComponent key={item.id} onClick={handleClick} />
      ))}
    </>
  );
}`
}

