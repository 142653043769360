export const codeSnippet = {
	code1: `@Configuration
public class MyConfiguration {
	@Bean
	public AccountService primaryAccountService() {
		// create and return an instance of AccountService
	}

	@Bean
	@Qualifier("secondary")
	public AccountService secondaryAccountService() {
		// create and return an instance of AccountService
	}
}`,
	code2: `@Autowired
@Qualifier("secondary")
private AccountService accountService;
`,
	code3: `@Bean
public UserService userService(@Qualifier("secondary") AccountService accountService) {
	// create and return an instance of UserService using the specified AccountService bean
}`,
	code4:`package com.example.demo;

public interface Animal {
	public void makeNoise();
}`,
	code5:`package com.example.demo;
import org.springframework.stereotype.Component;
	
@Component("Cow")
public class Cow implements Animal {
	public void makeNoise() {
		System.out.println("Moo");
	}
}`,
	code6:`package com.example.demo;
import org.springframework.stereotype.Component;

@Component("Pig")
public class Pig implements Animal {
	public void makeNoise() {
		System.out.println("Oink");
	}
}`,
	code7:`package com.example.demo;
import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.beans.factory.annotation.Qualifier;
import org.springframework.stereotype.Component;

@Component
public class Farm {
	@Autowired
	@Qualifier("Pig")
	Animal animal;

	public Farm(){
	}
}`,
	code8:`package com.example.demo;
import org.springframework.boot.SpringApplication;
import org.springframework.boot.autoconfigure.SpringBootApplication;

@SpringBootApplication
public class DemoApplication {
	public static void main(String[] args) {
		SpringApplication.run(DemoApplication.class, args);
	}
}`,
	code9:`org.springframework.beans.factory.UnsatisfiedDependencyException: Error creating bean with name 'farm': Unsatisfied dependency expressed through field 'animal'; 
nested exception is org.springframework.beans.factory.NoUniqueBeanDefinitionException: 
No qualifying bean of type 'com.example.demo.Animal' available: expected single matching bean but found 2: Cow,Pig`,
}

