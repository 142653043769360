export const codeSnippet = {
	code1: `// environment.ts environment variables
export const environment = {
  production: false,
  APIEndpoint: 'https://dev.endpoint.com'
};

// environment.prod.ts environment variables
export const environment = {
  production: true,
  APIEndpoint: 'https://prod.endpoint.com'
};`,
  code2: `import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getData() {
    return this.http.get(this.apiUrl + '/data');
  }
}`,
  code3: `ng build --configuration=production`,
  code4: `ng build --configuration=staging  `   
}