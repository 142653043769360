import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/angular/Forms';

function AngularForms () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Form</strong></CCardHeader>
      <CCardBody>           
        <p>Forms in Angular allow you to build complex and dynamic forms to collect data from users. Angular provides two ways to build forms: template-driven forms and reactive forms.</p>
        <p>Template-driven forms are built using directives and the template syntax in Angular. They rely on directives like ngModel and ngForm to bind data between the template and the component. This approach is easy to use and suitable for simple forms, but it is less flexible and harder to test compared to reactive forms.</p>
        <p>Reactive forms, on the other hand, are built using the reactive programming paradigm and the FormControl, FormGroup, and FormArray classes in the @angular/forms module. They provide more flexibility and control over the form's behavior and allow for easier testing.</p>
        <p>Here is an example of a template-driven form in Angular:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
        <p>In the component:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
        <p>Here is an example of a reactive form in Angular:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
        <p>Both of these examples create a simple form with two inputs for name and email and a submit button. The main difference is that the template-driven form uses directives to bind data between the template and the component, while the reactive form uses the FormControl, FormGroup, and FormArray classes to build the form in the component.</p>
        <p>Angular also has built-in validation features for forms such as 'required', 'minlength', 'maxlength' etc. It also has a feature of custom validation by creating custom validators.</p>
        <p>It is up to the developer to choose which approach to use based on the complexity and requirements of the form.</p>
      </CCardBody>
    </CCard>        
  )
}

export default AngularForms;