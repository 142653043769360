import React, { useState, useContext } from 'react'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { NavLink } from 'react-router-dom'
import { AppSidebarNav } from './AppSidebarNav'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import projectContext from '../../store/project-context'

// sidebar nav config
import navigation from '../../_nav'

const AppSidebar = (props) => {
  const projectItem = useContext(projectContext);

  const [unfoldable, setUnfoldable] = useState({type: 'set', sidebarUnfoldable: false})

  const unfoldableHandler = (val) => {
    setUnfoldable((preState) => {
      return {
        ...preState,
        type: 'set',
        sidebarUnfoldable: val,
      }
    })
  }

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable.sidebarUnfoldable}
      visible={props.sidebar}
      onVisibleChange={(visible) => props.sideBarHandler(visible)}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <NavLink className="nav-link" to=""><h3 className="sidebar-brand-full" height={35} style={{paddingTop: '8px'}}>LS</h3></NavLink>
        <h3 className="sidebar-brand-narrow" height={35}>LS</h3>
        {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} /> */}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation[projectItem.project]} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => unfoldableHandler(!unfoldable.sidebarUnfoldable)}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
