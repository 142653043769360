export const codeSnippet = {
  code1: `@RestController
public class MyController {
  @GetMapping("/customers/{id}")
  public ResponseEntity<Customer> getCustomer(@PathVariable long id) {
    Customer customer = customerService.findById(id);
    if (customer == null) {
      return new ResponseEntity<>(HttpStatus.NOT_FOUND);
    }
    return new ResponseEntity<>(customer, HttpStatus.OK);
  }
}`,
  code2: `@RestController
public class MyController {
  @PostMapping("/customers")
  public ResponseEntity<Void> createCustomer(@RequestBody Customer customer) {
    customerService.save(customer);
    HttpHeaders headers = new HttpHeaders();
    headers.setLocation(URI.create("/customers/" + customer.getId()));
    return new ResponseEntity<>(headers, HttpStatus.CREATED);
  }
}`,
  code3:`@RestController
@RequestMapping("/customers")
public class CustomerController {

  @GetMapping
  public List<Customer> getAllCustomers() {
    return customerService.findAll();
  }

  @GetMapping("/{id}")
  public Customer getCustomerById(@PathVariable long id) {
    return customerService.findById(id);
  }

  @PostMapping
  public Customer createCustomer(@RequestBody Customer customer) {
    return customerService.save(customer);
  }

}`,
  code4:`@GetMapping(value = "/search", params = "name")
public List<Customer> searchCustomersByName(@RequestParam String name) {
  return customerService.findByName(name);
}`
}

