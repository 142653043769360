export const codeSnippet = {
  code1: `<dependency>
  <groupId>org.asynchttpclient</groupId>
  <artifactId>async-http-client</artifactId>
  <version>2.2.0</version>
</dependency>`,
  code2: `AsyncHttpClient client = Dsl.asyncHttpClient();`,
  code3: `request.execute(new AsyncHandler<Object>() {
    @Override
    public State onStatusReceived(HttpResponseStatus responseStatus)
      throws Exception {
        return null;
    }

    @Override
    public State onHeadersReceived(HttpHeaders headers)
      throws Exception {
        return null;
    }

    @Override
    public State onBodyPartReceived(HttpResponseBodyPart bodyPart)
      throws Exception {
        return null;
    }

    @Override
    public void onThrowable(Throwable t) {

    }

    @Override
    public Object onCompleted() throws Exception {
        return null;
    }
});`,
  code4: `AsyncHttpClient client = new DefaultAsyncHttpClient();
  String url = "https://api.example.com/data";
  client.prepareGet(url).execute(new AsyncHandler<Response>() {
      private final ByteArrayOutputStream bytes = new ByteArrayOutputStream();
  
      @Override
      public State onStatusReceived(HttpResponseStatus responseStatus) {
          int statusCode = responseStatus.getStatusCode();
          // handle the status code
          return State.CONTINUE;
      }
  
      @Override
      public State onHeadersReceived(HttpHeaders headers) {
          // handle the headers
          return State.CONTINUE;
      }
  
      @Override
      public State onBodyPartReceived(HttpResponseBodyPart bodyPart) throws Exception {
          bytes.write(bodyPart.getBodyPartBytes());
          return State.CONTINUE;
      }
  
      @Override
      public Response onCompleted(Response response) throws Exception {
          byte[] responseBody = bytes.toByteArray();
          // handle the completed response
          return response;
      }
  
      @Override
      public void onThrowable(Throwable t) {
          // handle the exception
      }
  });
  `
}

