import React from "react";
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/Miscellaneous';
import { NavLink } from 'react-router-dom'

function Miscellaneous() {
    return (
      <>
        <CCard className="mb-4">
            <CCardHeader><strong>Miscellaneous</strong></CCardHeader>
            <CCardBody>                
              <CAccordion flush>
              <CAccordionItem itemKey={0}>
                  <CAccordionHeader>React Challenging</CAccordionHeader>
                  <CAccordionBody>                
                    <ol>
                      <li><p>Steep Learning Curve: React has a learning curve, especially for developers who are new to the concept of component-based architecture and the virtual DOM. Understanding React's concepts and ecosystem may take some time and effort.</p></li>
                      <li><p>Boilerplate Code: React applications can involve writing boilerplate code for handling state management, routing, and other common functionalities. Although libraries like Redux and React Router can alleviate some of this boilerplate, it still requires additional setup and configuration.</p></li>
                      <li><p>Performance Optimization: React's virtual DOM diffing algorithm is efficient for most use cases. However, in certain scenarios with large or deeply nested component trees, fine-grained performance optimizations may be required to ensure optimal rendering performance.</p></li>
                      <li><p>JSX Complexity: JSX, the syntax extension used in React, can be overwhelming for some developers, particularly those who are more comfortable with traditional HTML and CSS. You can mention how you initially found it challenging but eventually adapted to the JSX syntax by practicing and leveraging the benefits it offers, such as code reusability and component composition.</p></li>
                      <li><p>Managing State: As applications grow in complexity, managing state within React components can become more challenging. You can discuss how initially handling state within components might have been difficult to reason about or led to prop drilling (passing data through multiple components). Mention how you have overcome these challenges by adopting state management solutions like React Context, Redux, or React's useState and useEffect hooks.</p></li>
                      <li><p>JSX Complexity: JSX, the syntax extension used in React, can be overwhelming for some developers, particularly those who are more comfortable with traditional HTML and CSS. You can mention how you initially found it challenging but eventually adapted to the JSX syntax by practicing and leveraging the benefits it offers, such as code reusability and component composition.</p></li>
                      <li><p>Debugging: Identifying and resolving issues in React applications can be challenging, particularly when dealing with complex component hierarchies and asynchronous operations. Leveraging browser developer tools, React DevTools, and employing good debugging practices like console logging, error boundary components, and test-driven development can help in effective debugging.</p></li>
                      <li><p>Tooling Complexity: The React ecosystem has a wide range of tools and libraries available, which can sometimes make it challenging to choose the right ones for a specific project. It requires careful consideration and familiarity with the ecosystem to make informed decisions.</p></li>
                    </ol>
                  </CAccordionBody>
                </CAccordionItem>  
                <CAccordionItem itemKey={0.1}>
                  <CAccordionHeader>To use Sass in a React project (CSS preprocessor)</CAccordionHeader>
                  <CAccordionBody>   
                    <ul>
                      <li><p>Sass Compiler: You need to install a Sass compiler to transform your Sass code into CSS that the browser can understand. The most popular Sass compiler is node-sass.</p></li>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code17}</SyntaxHighlighter>
                      <li><p>In your React component's directory, create a Sass file with the .scss extension (e.g., MyComponent.scss) and write your Sass code in it.</p></li>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code18}</SyntaxHighlighter>
                      <li><p>In your component file, import the Sass file using the appropriate syntax. By convention, it's common to import the Sass file at the top of the component file.</p></li>                      
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code19}</SyntaxHighlighter>
                      This script uses the node-sass compiler to watch the src directory for any changes in Sass files and outputs the compiled CSS files to the same directory.
                      <li><p>You can now start your React application using the script command you defined. The Sass compiler will compile the Sass files into CSS, which will be used by your React components.</p></li>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code20}</SyntaxHighlighter>
                    </ul>
                    <strong>Sass in a React project</strong>
                    <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code21}</SyntaxHighlighter>
                    <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code22}</SyntaxHighlighter>
                    <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code23}</SyntaxHighlighter>
                    <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code24}</SyntaxHighlighter>
                    <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code25}</SyntaxHighlighter>
                  </CAccordionBody>
                </CAccordionItem> 
                <CAccordionItem itemKey={1}>
                  <CAccordionHeader>Count how many times the component is rendered</CAccordionHeader>
                  <CAccordionBody>                
                    <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
                  </CAccordionBody>
                </CAccordionItem>    
                <CAccordionItem itemKey={2}>
                  <CAccordionHeader>Code Splitting</CAccordionHeader>
                  <CAccordionBody> 
                    <p>React.lazy currently only supports default exports. If the module you want to import uses named exports, you can create an intermediate module that reexports it as the default. This ensures that tree shaking keeps working and that you don’t pull in unused components.</p>
                    <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
                    <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
                    <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
                    <p><strong>There are several ways to implement code splitting in a React application:</strong></p>  
                    <ol>
                      <li>
                        <p>Using the import() syntax: You can use the import() syntax to dynamically import a module at runtime. This will create a new chunk that will be loaded on demand.</p>
                        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
                        </li>  
                      <li>
                        <p>Using the React.lazy function: The React.lazy function lets you render a dynamic import as a regular component. This allows you to use code splitting with Suspense, which lets you specify a loading component to display while the chunk is being loaded.</p>
                        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
                      </li>  
                      <li>
                        <p>Using a bundler plugin: You can also use a bundler plugin, like babel-plugin-syntax-dynamic-import or react-loadable, to automatically split your code into smaller chunks that can be loaded on demand.</p>
                        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
                      </li>  
                    </ol>     
                    <strong>Route based code splitting</strong>      
                    <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>  
                  </CAccordionBody>
                </CAccordionItem>  
                <CAccordionItem itemKey={3}>
                  <CAccordionHeader>React.memo</CAccordionHeader>
                  <CAccordionBody>           
                    <p>React.memo is a higher-order component (HOC) in React that is used to wrap functional components. It works by memoizing the component, which means that it will only re-render the component if its props have changed.</p>     
                    <p>React.memo is similar to React.PureComponent but it's used for functional components. It can be used to improve the performance of your application by preventing unnecessary re-renders of functional components.</p>
                    <p>React.memo only performs a shallow comparison of props, which means that it will not detect changes to objects or arrays that are passed as props. This can lead to unexpected re-renders or missed re-renders if the objects or arrays are mutable.</p>
                    <p></p>
                    <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}
                    </SyntaxHighlighter>
                    <p>In this example, MyComponent is a functional component that takes a text prop and renders it inside a div. By wrapping MyComponent in React.memo, it will only re-render if the text prop changes.</p>
                    <p>You can also pass a custom comparison function to React.memo to specify how the props should be compared.</p>
                    <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}
                    </SyntaxHighlighter>
                    <p>In this example, MyComponent will only re-render if the text prop or the id prop changes.</p>
                    <p>It's important to keep in mind that React.memo only does a shallow comparison of props, if your component receives an object or array as a prop, it will not detect any changes to it, you'll need to use a library like immutable.js or immer to make the comparison</p>
                  </CAccordionBody>
                </CAccordionItem>   
                <CAccordionItem itemKey={4}>
                  <CAccordionHeader>React Perfomence</CAccordionHeader>
                  <CAccordionBody>                
                    <ol>
                      <li>
                        <p>Use useMemo hook to memoize expensive calculations.</p>
                        <p>Use the useCallback hook to prevent unnecessary re-renders of child components.</p>
                        <p>Use PureComponent or memo instead of a regular component to prevent unnecessary re-renders.</p>
                        <p>Use the useEffect hook to control when and how often your component updates.</p>
                        <p>Minimize the number of state updates and use the useReducer hook instead of the useState hook when possible.</p>
                        <p>Use code splitting to load only the code that is needed for a particular page or feature.</p>
                      </li>
                    </ol>
                  </CAccordionBody>
                </CAccordionItem>     
                <CAccordionItem itemKey={5}>
                  <CAccordionHeader>Higher Order Component</CAccordionHeader>
                  <CAccordionBody>       
                    <p>A Higher Order Component (HOC) is a design pattern in React that allows you to reuse component logic. A HOC is a function that takes a component as an argument and returns a new component that is enhanced with additional functionality.</p>         
                    <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter>
                    <p>n this example, the withLoading HOC takes a component as an argument (WrappedComponent) and returns a new component that displays a loading message while a certain prop (isLoading) is true.</p>
                    <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter>
                    <p>HOCs are a powerful way to reuse component logic and add additional functionality to existing components without modifying their source code. They can be used to add features like authentication, data loading, and error handling to multiple components throughout your application.</p>
                    <p>It's important to note that when using HOCs, you need to be careful with component nesting, as it can make your component tree deep and hard to debug, also you should use HOCs only when you need it, creating unnecessary HOCs can make your codebase more complex and harder to understand.</p>
                    <p>Example 2</p>
                    <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code13}</SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code14}</SyntaxHighlighter>
                  </CAccordionBody>
                </CAccordionItem> 
                <CAccordionItem itemKey={5}>
                  <CAccordionHeader>Controlled and uncontrolled components</CAccordionHeader>
                  <CAccordionBody>       
                    <ol>
                      <li>
                        <p>Controlled Components:
                          <ul>
                            <li><p>A controlled component is one where React has full control over the state of the component. The component's value is controlled by React through its props, and any changes to the value are handled by event handlers. The state of a controlled component is stored in the parent component's state or a state management library like Redux. When the user interacts with a controlled component, it triggers an event that updates the state, which in turn re-renders the component with the updated value.</p></li>
                            <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code15}</SyntaxHighlighter>
                            <p>In this example, the value of the input field is controlled by the value state variable. Whenever the user types in the input field, the handleChange function is called, updating the state with the new value.</p>
                          </ul>
                        </p>
                      </li>
                      <li>
                        <p>Uncontrolled Components:
                          <ul>
                            <li><p>An uncontrolled component is a form element that maintains its own internal state. React does not control or track the state of an uncontrolled component. Instead, you can retrieve the value of the component using other methods, such as accessing the DOM directly or using a ref.</p></li>
                            <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code16}</SyntaxHighlighter>
                            <p>In this example, the value of the input field is not controlled by React. Instead, the inputRef ref is used to access the value of the input field directly when the button is clicked.</p>
                            <p>Controlled components provide a more centralized and predictable way to manage form state in React. They are typically recommended because they allow React to be the "single source of truth" for the state, making it easier to manage and manipulate the data. However, uncontrolled components can be useful in certain scenarios, such as when working with legacy code or integrating with third-party libraries that require direct access to the DOM.</p>
                          </ul>
                        </p>
                      </li>
                    </ol>                    
                  </CAccordionBody>
                </CAccordionItem>          
              </CAccordion>
          </CCardBody>
        </CCard>
        <CCard className="mb-4">
          <CCardHeader><strong>Others</strong></CCardHeader>
          <CCardBody>                
            <CListGroup>
              <CListGroupItem >
                <NavLink  to="/react/abortController" active>AbortController</NavLink>
              </CListGroupItem>
            </CListGroup>
          </CCardBody>
        </CCard>
      </>
        
    )
}

export default Miscellaneous;