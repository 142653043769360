import React from "react";
import { CCard, CCardHeader, CCardBody} from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/java/List'
	
function List () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>List (interface)</strong></CCardHeader>
			<CCardBody>
				<p>Inherits the Collection interface. It contains the index-based methods to insert, update, delete and search the elements. It can have the <b>duplicate elements</b> also. We can also store the <b>null</b> elements in the list.</p>			
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
				<ul>
					<li>Child interface of the collection interface</li>
					<li>Insertion order will be preserved, when you add the objects.</li>
					<li>Duplicates allowed.</li>
					<li>We can implement these classes ArrayList, LinkedList, Vector</li>
				</ul>

				<h5><strong><u>ArrayList</u></strong></h5>
				<p>The main advantages of ArrayList are, if we declare an array then it's needed to mention the size but in ArrayList, it is not needed to mention the size of ArrayList if you want to mention the size then you can do it.</p>
				<p>ArrayList stores its elements in a contiguous block of memory, and accesses them using an index. This makes it very efficient for random access, but inefficient for inserting and deleting elements in the middle of the list.</p>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

				<p>In an ArrayList, the dynamic resizing process involves creating a new array with a larger size and copying the elements from the old array to the new one. This process is known as "resizing" or "re-allocation."</p>
				<ul>
					<li><p><b>Initial Allocation:</b> When you create an ArrayList, it is initially allocated with a certain capacity, often more than the initial number of elements you add. This capacity can vary based on the specific implementation of ArrayList.</p></li>
					<li><p><b>Adding Elements:</b> As you add elements to the ArrayList, it keeps track of the number of elements and the capacity. When you reach the capacity limit, and you attempt to add another element, the ArrayList needs more space to accommodate the new element.</p></li>
					<li><p><b>Resizing:</b> At this point, the ArrayList performs resizing by creating a new array with a larger capacity, often double the current capacity. Then, it copies all the elements from the old array to the new one. This copying process can take time, especially if the list is large.</p></li>
					<li><p><b>Switching Arrays:</b> After the copying is complete, the ArrayList switches to using the new array as its internal data structure, and the old array becomes eligible for garbage collection.</p></li>
				</ul>

				<b>Pros:</b>
				<ul>
					<li><p><b>Fast Access Time:</b> ArrayList provides constant-time access to elements using their index.</p></li>
					<li><p><b>Less Memory Overhead:</b> ArrayList has lower memory overhead per element compared to Linked List.</p></li>
					<li><p><b>Random Access:</b> ArrayList supports efficient random access to elements.</p></li>
				</ul>
				<b>Pros:</b>
				<ul>
					<li><p><b>Dynamic Resizing:</b> Resizing the ArrayList involves copying elements to a new array, which can be time-consuming for large lists.</p></li>
					<li><p><b>Insertions and Deletions:</b> Insertions and deletions in the middle of the list are less efficient as elements need to be shifted.</p></li>
					<li><p><b>Fixed Size:</b> ArrayList has a fixed capacity, and resizing requires memory re-allocation, which can be expensive.</p></li>
					<li><p><b>Memory Allocation:</b> Memory allocation for elements is done statically, and resizing may lead to unused memory if not managed properly.</p></li>
				</ul>

				<h5><strong><u>LinkedList</u></strong></h5>
				<p>LinkedList is a class in Java that also implements the List interface, but it stores its elements in a doubly-linked list. It means that each element in the list contains a reference to the previous and next elements. This makes it efficient for inserting and deleting elements, but less efficient for random access.</p>

				<p>Some key features of LinkedList include:</p>

				<ol>
					<li><p>It allows for null elements</p></li>
					<li><p>It is not thread-safe, but can be made thread-safe by using the Collections.synchronizedList method</p></li>
					<li><p>It is implemented as a linked list, and does not need to resize itself when elements are added or removed</p></li>
					<li><p>It has linear-time complexity for get and set operations by index</p></li>
					<li><p>It has a constant-time complexity for adding and removing elements from anywhere in the list</p></li>
				</ol>
				<p><b>LinkedList is useful when the number of elements is large or when the number of elements is not known in advance, and when the elements need to be inserted or removed frequently in the middle of the list.</b></p>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
				
				<b>Pros:</b>
				<ul>
					<li><p><b>Insertions and Deletions:</b> Insertions and deletions are efficient in a Linked List as they involve adjusting pointers.</p></li>
					<li><p><b>Sequential Access:</b> Iterating through the elements of a Linked List is relatively efficient.</p></li>
					<li><p><b>Dynamic Size:</b> Linked Lists can dynamically grow or shrink in size without much overhead.</p></li>
					<li><p><b>Memory Allocation:</b> Memory is allocated dynamically, making it suitable for scenarios where memory consumption is uncertain.</p></li>
					<li><p><b>No Re-allocation:</b> Unlike ArrayList, Linked Lists do not require re-allocation when resizing.</p></li>
				</ul>
				<b>Cons:</b>
				<ul>
					<li><p><b>Slower Access Time:</b> Accessing elements in a Linked List takes more time compared to ArrayList due to pointer traversal.</p></li>
					<li><p><b>No Random Access:</b> Direct access to elements by index is not as efficient as in ArrayList.</p></li>
					<li><p><b>Memory Overhead:</b> Each element in a Linked List requires extra memory for the reference/pointer to the next element.</p></li>
				</ul>
			</CCardBody>
			<CCard className="mb-4">
				<CCardHeader><strong>CopyOnWriteArrayList - thread-safe</strong></CCardHeader>
				<CCardBody>
					<p>CopyOnWriteArrayList is a class in Java that provides a thread-safe variant of the ArrayList data structure. It is part of the Java Collections Framework and is designed to be used in multi-threaded scenarios where you need to ensure thread safety when reading from a list while minimizing the overhead of synchronization.  It is part of the java.util.concurrent package</p>
					<p>The key characteristic of CopyOnWriteArrayList is that it creates a new copy of the underlying array every time a modification operation is performed (such as adding or removing an element). This copy-on-write behavior ensures that concurrent reads can proceed without locks or synchronization, as they always read from an immutable snapshot of the list. This makes it very efficient for read-heavy workloads.</p>
					<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
				</CCardBody>
		</CCard>
	</CCard>
			
	)
}

export default List