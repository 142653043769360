export const codeSnippet = {
    code1: `import { useState, useEffect, useRef  } from "react"
function App() {
  const [name, setName] = useState("")
  const renderCount = useRef(0)
  
  useEffect(() => {
    renderCount.current = renderCount.current + 1
  })
  return (
    <div className="App">
        <input ref={renderCount} type="text" name="name" onChange={e=>setName(e.target.value)} />
        <div> Name is: {name} </div>
        <div> I rendred {renderCount.current} times </div>
    </div>
  )
}`,
    code2: `function Component() {
const inputRef = useRef(null)

const focusInput = () => {
  inputRef.current.focus()
}

return (
  <>
    <input ref={inputRef} />
    <button onClick={focusInput}>Focus Input</button>
  </>
)
}`,
    code4: `const myRef = useRef(0)
console.log(myRef)
// { current: 0 }`
}

