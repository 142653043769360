export const codeSnippet = {
    code1: `const memoizedValue = React.useMemo(() => computeExpensiveValue(a, b), [a, b]);`,
    code2: `const List = React.useMemo(() => 
listOfItems.map(item => ({
  ...item,
  itemProp1: expensiveFunction(props.first),
  itemProp2: anotherPriceyFunction(props.second) 
})), [listOfItems])`,
	code3: `const arr = [1, 2, 3]`,
	code4: `const themeStyles = {
  backgroundColor: dark ? "black" : "white",
  color: dark ? "white" : "black"
}
useEffect(() => {}, [themeStyles])`,
code5: `const themeStyles = usememo(() => {
 return {
   backgroundColor: dark ? "black" : "white",
   color: dark ? "white" : "black"
  }
}, [dark])

useEffect(() => {}, [themeStyles])`,
  code6: `import { useMemo } from 'react';

function ItemList({ items, filter }) {
  // Use the useMemo hook to memoize the filtered items
  const filteredItems = useMemo(() => {
    return items.filter(item => item.name.includes(filter));
  }, [items, filter]);

  return (
    <ul>
      {filteredItems.map(item => (
        <li key={item.id}>{item.name}</li>
      ))}
    </ul>
  );
}`
}

