export const codeSnippet = {
  code1: `public interface List<E> extends Collection<E>`,
  code2: `ArrayList<String> fruits = new ArrayList<String>();
        
// Add elements to the ArrayList
fruits.add("Apple");
fruits.add("Banana");
fruits.add("Cherry");

// Print the ArrayList
System.out.println("Fruits: " + fruits); // Fruits: [Apple, Banana, Cherry]

// Get the element at index 1
String secondFruit = fruits.get(1);
System.out.println("Second fruit: " + secondFruit); // Second fruit: Banana

// Remove the element at index 2
fruits.remove(2);
System.out.println("Fruits: " + fruits); // Fruits: [Apple, Banana]

// Check if the ArrayList contains "Banana"
boolean hasBanana = fruits.contains("Banana");
System.out.println("Has Banana: " + hasBanana); // Has Banana: true

// Get the size of the ArrayList
int size = fruits.size();
System.out.println("Size: " + size); // Size: 2`,
  code3: `LinkedList<String> animals = new LinkedList<String>();
        
// Add elements to the LinkedList
animals.add("Lion");
animals.add("Tiger");
animals.add("Bear");

// Print the LinkedList
System.out.println("Animals: " + animals);

// Add an element at index 1
animals.add(1, "Elephant");
System.out.println("Animals: " + animals);

// Remove the first element
animals.removeFirst();
System.out.println("Animals: " + animals);

// Get the last element
String lastAnimal = animals.getLast();
System.out.println("Last animal: " + lastAnimal);

// Get the size of the LinkedList
int size = animals.size();
System.out.println("Size: " + size);`,
  code4: `CopyOnWriteArrayList<String> list = new CopyOnWriteArrayList<>();

// Add elements
list.add("Apple");
list.add("Banana");
list.add("Cherry");

// Create a thread to read elements
Thread readerThread = new Thread(() -> {
    for (String fruit : list) {
        System.out.println("Read: " + fruit);
    }
});

// Create a thread to modify the list
Thread writerThread = new Thread(() -> {
    list.add("Date");
    list.remove("Banana");
});

readerThread.start();
writerThread.start();

// Wait for threads to finish
try {
    readerThread.join();
    writerThread.join();
} catch (InterruptedException e) {
    e.printStackTrace();
}`
}

