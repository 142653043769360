import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/UseReducer';

function UseReducer () {
    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>UseReducer</strong></CCardHeader>
        <CCardBody>
          <CCard>
            <CCardBody>
              <p>Introduction to <NavLink  to="/react/hooks" active>hooks</NavLink></p>
            </CCardBody>
          </CCard><br/>
          <p>An alternative to <NavLink  to="/react/hooks" active>useState</NavLink>. Accepts a reducer of type (state, action) => newState, and returns the current state paired with a dispatch method.</p>
          <p>Before the release of hooks, nearly every React project used Redux to manage complex state interactions. Redux is great for managing complex state transitions and sharing state globally, but with the introduction of the Context API and the useReducer hook Redux is no longer necessary for handling complex shared state.</p>
          <p>useState is a great way to setup simple state inside of a component. When state starts to get more complex, though, and is shared between multiple components it is generally best to switch to useReducer since useReducer makes it easier to write complex state interactions without creating a large complex mess of code.</p>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
          <p><strong>Example 2:</strong></p>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
        </CCardBody>
      </CCard>        
    )
}

export default UseReducer