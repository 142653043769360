import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/javascript/Javascript';

function Javascript () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Javascript</strong></CCardHeader>
      <CCardBody>                
        <CAccordion flush>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>Introduction</CAccordionHeader>
            <CAccordionBody> 
              <p>By default, JavaScript is single-threaded and executes code synchronously, meaning it executes one statement at a time in the order they appear in the code. Synchronous code blocks the execution until a task is completed before moving on to the next line of code.</p>
              <p>However, JavaScript also provides asynchronous capabilities through mechanisms like callbacks, promises, and async/await. These allow you to perform non-blocking operations such as network requests, file operations, or timers, without blocking the execution of the entire program. Asynchronous operations are scheduled to run in the background while other code continues to execute, and their results are typically handled through callbacks or promises.</p>
              <p>Asynchronous JavaScript allows for better handling of time-consuming tasks, avoids blocking the user interface (in the case of browser-based JavaScript), and enables concurrent processing of multiple tasks. It helps in building responsive and efficient applications by leveraging the event-driven nature of JavaScript and its ability to handle I/O operations asynchronously.</p>     
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>--Object Creation</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={3}>
            <CAccordionHeader>--JavaScript is Synchronous</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={4}>
            <CAccordionHeader>CallBack</CAccordionHeader>
            <CAccordionBody> 
              <p>A callback function in JavaScript is a function that is passed as an argument to another function, which is then invoked inside the outer function to complete some kind of action. Callbacks are used to handle asynchrony in JavaScript, allowing a program to continue running while a long-running task completes in the background. They are a key feature of JavaScript and are commonly used with event listeners, timers, and AJAX requests.</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>        
              <p>These functions are used to handle asynchronous operations in JavaScript. For example, if a function has to wait for a response from a server or a function has to wait for a certain amount of time to pass, you can use a callback function to execute the next step after the previous step is completed. This way, the program can continue running without having to wait for the previous step to finish.</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter> 
              <p>Alternatives to callbacks</p>
              <p><strong>Promises</strong></p>
              <p><strong>Async await</strong></p>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={5}>
            <CAccordionHeader>Promises</CAccordionHeader>
            <CAccordionBody> 
              <p>A promise in JavaScript is an object that represents the eventual completion (or failure) of an asynchronous operation and its resulting value. Promises are a way to handle asynchronous operations in a more elegant and consistent way than using callbacks.</p>
              <p>Promises have three states:</p>
              <ul>
                <li><p>Pending: The initial state; the promise is neither fulfilled nor rejected.</p></li>
                <li><p>Fulfilled: The operation completed successfully, and the promise has a resulting value.</p></li>
                <li><p>Rejected: The operation failed, and the promise has a reason for the failure.</p></li>
              </ul>
              <p>A promise is said to be settled if it is either fulfilled or rejected.</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter> 
              <p>Promises have two main methods for handling the completion of an asynchronous operation:</p>
              <ul>
                <li><p>.then(): This method is called when the promise is fulfilled, and it is passed a function that will be called with the resulting value of the promise.</p></li>
                <li><p>.catch(): This method is called when the promise is rejected, and it is passed a function that will be called with the reason for the rejection.</p></li>
              </ul>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={6}>
            <CAccordionHeader>Async await</CAccordionHeader>
            <CAccordionBody> 
              <p>async/await is a modern way of handling asynchronous operations in JavaScript, introduced in ECMAScript 2017. It provides a more intuitive and readable way of handling asynchronous code compared to callbacks or promises.</p>
              <p>An async function is a function that is declared with the async keyword. It returns a promise, and any value returned from an async function is wrapped in a resolved promise.</p>
              <p>The await keyword is used inside an async function to wait for a promise to be fulfilled before moving on to the next line of code. When an await expression is evaluated, the program execution is paused until the promise is settled (fulfilled or rejected).</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter> 
              <p>In this example, the getData function is declared as async and the function axios.get(url) returns a promise. The await keyword is used to wait for the promise to be fulfilled before moving on to the next line of code. The response.data is logged to the console. If there is any error, it will be caught and logged to the console as well.</p>
              <p>async/await makes it easy to write code that reads like synchronous code, which can make it easier to understand and debug. It is also possible to use async/await with try/catch blocks to handle errors, similar to synchronous code. However, it's important to note that async/await still uses Promises under the hood, so you will need to be familiar with Promises to fully understand async/await.</p>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={7}>
            <CAccordionHeader>Closure</CAccordionHeader>
            <CAccordionBody> 
              <p>A closure in JavaScript is a function that has access to the variables in its outer scope, even after the outer function has returned. It is created when a function is defined inside another function, and the inner function retains access to the variables in the outer function's scope. This allows the inner function to "remember" the state of its parent function, even after the parent function has completed execution.</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
              <p>example 2:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter> 
              <p>In this example, the outerFunction is defined with an argument x, and it returns the innerFunction. When the outerFunction is invoked, it creates a new scope with a variable x set to the value passed as an argument. The inner function is returned and assigned to the variable add. The inner function has access to the x variable in the outer function's scope, so when the inner function is invoked with an argument y, it is able to add the value of x and y and return the result.</p>
              <p>Closures are useful for encapsulating variables and creating private variables, which can't be accessed from outside of the closure. They are also useful for creating function factories, where a function generates and returns another function with specific behavior.</p>
              <p>It's important to note that closures can cause memory leaks if they hold references to variables that are no longer in use, so</p>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={8}>
            <CAccordionHeader>Call and Apply</CAccordionHeader>
            <CAccordionBody> 
              <p>In JavaScript, the call and apply methods are used to invoke a function and set the value of this inside the function.</p>
              <p>The call method is used to invoke a function and pass in arguments one by one. The syntax for the call method is:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter> 
              <p>The apply method is similar to call, but it is used to pass in arguments as an array. The syntax for the apply method is:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter> 
              <p>Both call and apply methods are useful when you want to reuse a function with different objects or want to change the context of this inside a function.</p>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={9}>
            <CAccordionHeader>Hoisting</CAccordionHeader>
            <CAccordionBody> 
              <p>Hoisting in JavaScript is the behavior of moving variable and function declarations to the top of their scope. In JavaScript, variable declarations and function declarations are hoisted to the top of their scope, but the assignment of a value is not.</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter> 
              <p>The above code is equivalent to:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter> 
              <p>This behavior applies to both variables declared with the var keyword and variables declared with let and const in the latest versions of javascript.</p>
              <p>When it comes to function declarations, they are also hoisted to the top of their scope, and can be called before they are declared.</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter>
              <p>However, function expressions are not hoisted.</p> 
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code13}</SyntaxHighlighter>
              <p>It's important to keep in mind that hoisting can make it difficult to understand the flow of a program and can lead to unexpected behavior if not used carefully. To avoid confusion, it's best practice to always declare variables and functions at the top of their scope.</p>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Function Declarations vs Function Expressions</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>IIFE Immediately Invoked Function Expression</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Functional Scope vs Block Scope</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Prototypal Inheritance</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Browser Object Model (BOM)</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>undefined and not defined</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Lexical scoping</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>tilde and caret in package.json</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>async vs deffer</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Immutable Object and Array</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Higher-Order Functions in JavaScript</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Primitive data types and Reference types</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Event bubbling</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Shadow DOM</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Virtual DOM</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Cookie vs Local Storage vs Session Storage</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Document.ready vs onload</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Iterators</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Generators</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Web Components</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>for…of</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Array Methods</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Factorial</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>SimpleAdding</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>LongestWord</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>LetterChanges</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Reverse a string</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Fist Letter Capitalize</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Return the string in alphabetical order</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Vowel Count</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Word Count</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Letter Count</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Prevent user to add more properties in object</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Translate object into an array. array would only contain values [1,2]</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Reverse the string</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>Sum of array each items ([1,2,3,4]) result should be 10</CAccordionHeader>
            <CAccordionBody> 
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CCardBody>
    </CCard>        
  )
}

export default Javascript;