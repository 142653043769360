import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  // CTableHead,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
	
import { NavLink } from "react-router-dom";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/java/Exception'

function Exceptions () {
	return (
		<>
			<CCard className="mb-4">
				<CCardHeader><strong>Exception</strong></CCardHeader>
				<CCardBody>
					<p><strong>throw </strong>– We know that if an error occurs, an exception object is getting created and then Java runtime starts processing to handle them. Sometimes we might want to generate exceptions explicitly in our code. For example, in a user authentication program, we should throw exceptions to clients if the password is null. The throw keyword is used to throw exceptions to the runtime to handle it.</p>
					<p><strong>throws </strong>– When we are throwing an exception in a method and not handling it, then we have to use the throws keyword in the method signature to let the caller program know the exceptions that might be thrown by the method. The caller method might handle these exceptions or propagate them to its caller method using the throws keyword. We can provide multiple exceptions in the throws clause, and it can be used with the main() method also.</p>
					<p><strong>try-catch </strong>– We use the try-catch block for exception handling in our code. try is the start of the block and catch is at the end of the try block to handle the exceptions. We can have multiple catch blocks with a try block. The try-catch block can be nested too. The catch block requires a parameter that should be of type Exception.</p>
					<p><strong>finally </strong>– the finally block is optional and can be used only with a try-catch block. Since exception halts the process of execution, we might have some resources open that will not get closed, so we can use the finally block. The finally block always gets executed, whether an exception occurred or not.
					finally is a block used to execute important code such as <strong>closing the connection</strong>, etc.
					</p>

					<h5><strong><u>Try-Catch</u></strong></h5>
					<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
					<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
					<h5><strong><u>finally</u></strong></h5>
					<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
					<h5><strong><u>throw</u></strong></h5>
					<p>In Java, the throw keyword is used to throw an exception. An exception is an abnormal event that occurs during the execution of a program, and it disrupts the normal flow of instructions.</p>

					<p>Here's an example of how to use the throw keyword in Java:</p>
					<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
					<p>In this example, the program throws an ArithmeticException if the value of y is zero, which would cause a division by zero error. The exception is caught by the catch block, which prints an error message.</p>

					<p>It's important to note that when an exception is thrown, the normal flow of the program is interrupted and the program will terminate unless the exception is caught and handled.</p>

					<h5><strong><u>Custom Exceptions</u></strong></h5>
					<p>In Java, you can create custom exceptions by defining a new class that extends the Exception class or one of its subclasses.</p>

					<p>Here's an example of how to create a custom exception in Java:</p>
					<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
					<p>To throw the custom exception, you can use the throw keyword as follows:</p>
					<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
					<p>It's a good practice to provide descriptive error messages when throwing exceptions, as it can help you troubleshoot and debug your code.</p>

					<p>You can also create custom exceptions that extend one of the built-in exception classes, such as IOException or SQLException, if the exception you want to create is related to input/output operations or database operations.</p>

					<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
				</CCardBody>
		</CCard>
	</>
	)
}

export default Exceptions