import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Mockito';

function Mockito () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>@Mockito</strong></CCardHeader>
      <CCardBody>
        <CAccordion flush>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>Example</CAccordionHeader>
            <CAccordionBody> 
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>@Before</CAccordionHeader>
            <CAccordionBody> 
              <p>The "@Before" annotation in JUnit is used to indicate a method that should be run before each test case in a test class. This method is executed before every test method and is usually used to set up common objects or resources that are needed by multiple test cases. </p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={3}>
            <CAccordionHeader>Exception</CAccordionHeader>
            <CAccordionBody>               
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
              <p><b>Custom Exception</b></p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={4}>
            <CAccordionHeader>@Mock and @InjectMocks</CAccordionHeader>
            <CAccordionBody>               
              <p>In Mockito, @Mock and @InjectMocks are two annotations used to create mock objects and inject them into test classes, respectively.</p>
              <p>@Mock is used to create mock objects. When a field in a test class is annotated with @Mock, Mockito will automatically create a mock object of the specified type and assign it to the annotated field</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
              <p>@InjectMocks is used to automatically inject mock objects into an instance of a test class. When a test class is annotated with @InjectMocks, Mockito will automatically inject all mock objects created with the @Mock annotation into the annotated instance.</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter>
              <strong>Example</strong>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={5}>
            <CAccordionHeader>Access Modifier</CAccordionHeader>
            <CAccordionBody>               
              <p>Test <b>private method</b> access modifier</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter>     
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code13}</SyntaxHighlighter>  
              <p>Test <b>private field</b> access modifier</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code14}</SyntaxHighlighter>     
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code15}</SyntaxHighlighter>              
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
        
      </CCardBody>
    </CCard>        
    )
}

export default Mockito