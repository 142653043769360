import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/Fragments';


function Fragments () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Fragments and Portals</strong></CCardHeader>
			<CCardBody>
				<p><strong>Fragments</strong></p>
				<p>In React, fragments are a feature that allows you to group multiple elements together without adding an additional wrapper element to the DOM. Fragments are useful when you need to return multiple elements from a component, such as in a component rendering a list of items.</p>
				<p>Prior to the introduction of fragments, if you wanted to return multiple elements from a component, you had to wrap them in a single parent element. Fragments provide a cleaner and more concise syntax for achieving the same result.</p>
				<p>Here's an example of using fragments in React:</p>
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>		
				<p>In the example above, the &#60;React.Fragment&#62; tag is used to wrap the multiple elements (&#60;h1&#62; and &#60;p&#62;) within the component. The use of &#60;React.Fragment&#62; allows you to return these elements without introducing an additional DOM node.</p>
				<p>Alternatively, you can use the shorthand syntax of fragments by using an empty tag &#60;&#62; and &#60;/&#62;	:</p>	
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>		
				<p>Fragments provide a way to structure your component's JSX code without impacting the HTML structure unnecessarily. They are especially useful when you want to avoid unnecessary div wrappers or when you need to return an array of elements without a parent container.</p>
				<p><h3>Portals</h3></p>
				<p>Portals in React provide a way to render children components into a different part of the DOM hierarchy, outside the parent component's DOM tree. This allows you to render components at a different location in the DOM, such as a higher-level container or a separate React root.</p>
				<p>Portals are useful in scenarios where you need to render a component that should visually appear as a child of a different DOM element, even though it is logically part of another component's hierarchy.</p>
				<p>To create a portal in React, you can use the ReactDOM.createPortal() method. Here's an example:</p>
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>		
				<p>In the example above, we have a Modal component that uses ReactDOM.createPortal() to render its children into a separate DOM element with the ID modal-root. The Modal component can be placed anywhere within the component hierarchy, but its content will be rendered outside the main component's DOM tree.</p>
				<p>To use portals, you need to create a target DOM element in your HTML file where the portal content will be rendered. In this case, we have an element with the ID modal-root where the modal content will be appended.</p>
				<p>Using portals, you can control the rendering position of components and separate concerns between different parts of your application. It's commonly used for modal dialogs, tooltips, dropdown menus, or any scenario where you need to render content outside the regular component hierarchy.</p>
			</CCardBody>
		</CCard>			
	)
}

export default Fragments