import React from "react";
import { CCard, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/UseMemoUseCallback';

function UseMemoUseCallback ({reference}) {
	return (
		<CCard ref={reference}>
			<CCardBody>
					<p><strong>Diffrence between UseMemoUse and UseCallback</strong></p>
					<p>The key difference is that useMemo returns a memoized value, whereas useCallback returns a memoized function. That means that useMemo is used for storing a computed value, while useCallback returns a function that you can call later on.</p>
					<p>These hooks will give you back a cached version unless one of their dependencies (e.g. state or props) changes.</p>
					<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>  
					<p>The code snippet above is a contrived example but shows the difference between the two callbacks:</p>
					<ul>
						<li>memoizedValue will become the array <em>[1, 2, 3, 4, 6, 9]</em>. As long as the values variable stays, so will memoizedValue, and it’ll never recompute.</li>
						<li>memoizedFunction will be a function that will return the array <em>[1, 2, 3, 4, 6, 9]</em>.</li>
					</ul>
			</CCardBody>
		</CCard>
	)

}

export default UseMemoUseCallback;