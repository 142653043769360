import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/EnableCaching';

function EnableCaching () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>@EnableCaching</strong></CCardHeader>
        <CCardBody>
          <p>@EnableCaching is a Spring annotation that can be used to enable caching in a Spring Boot application. Caching can help improve the performance of a web application by temporarily storing frequently accessed data in memory, reducing the need to access the database or other data sources for this information.</p>
          <p>To use @EnableCaching in a Spring Boot application, you need to do the following:</p>
          <ol>
            <li><p>Add the Spring Boot Starter Cache dependency to your project's build configuration. This dependency will provide the necessary support for caching in your application.</p></li>
            <li><p>Enable caching by adding the @EnableCaching annotation to one of your configuration classes. For example:</p></li>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

            <li><p>Configure the cache manager to be used by your application. You can use the SimpleCacheManager or the more advanced EhCacheCacheManager, among others.</p></li>
            <li><p>Use the @Cacheable annotation on the methods that you want to cache. For example:</p></li>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
          </ol>

          <p>This will cause the method's return value to be cached in a cache named "products". The next time the method is called with the same arguments, the cached value will be returned instead of executing the method again.</p>
        </CCardBody>
      </CCard>        
    )
}

export default EnableCaching