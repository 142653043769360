import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/angular/Core';

function Core () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Core</strong></CCardHeader>
      <CCardBody>                
        <CAccordion flush>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>NgModule</CAccordionHeader>
            <CAccordionBody> 
              <p>In Angular, an NgModule is a class marked with the @NgModule decorator that tells Angular how to compile and run the component templates. It can also specify other details, such as what components, directives, and pipes belong to the module, and which services the module should provide.</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
              <p>This NgModule has the following properties:</p>
              <ul>
                <li><p>imports: An array of other modules that the module depends on. In this example, the MyModule module depends on the CommonModule, which provides common directives such as ngIf and ngFor.</p></li>
                <li><p>declarations: An array of components, directives, and pipes that belong to the module. In this example, the MyModule module has a component called MyComponent.</p></li>
                <li><p>exports: An array of components, directives, and pipes that should be available for use in other modules. In this example, the MyComponent component is exported, which means that it can be used in other modules.</p></li>
                <li><p>providers: An array of services that the module should provide. In this example, the MyModule module provides the MyService service.</p></li>
              </ul>
              <p>An NgModule is a useful way to organize and manage the components, directives, pipes, and services in an Angular application. It helps to keep the code organized and easy to maintain, and it also makes it easy to reuse components and services in multiple parts of the application.</p>
              <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>BrowserModule</CAccordionHeader>
            <CAccordionBody> 
            <p>In Angular, the BrowserModule is a module that is included in every application that runs in a browser. It is responsible for setting up the basic application environment and providing common services such as the LocationStrategy service, which is used to configure the application's URL handling.</p>

            <p>To use the BrowserModule in your Angular application, you need to import it in the root AppModule:</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
            <p>The BrowserModule is a necessary part of any Angular application that runs in a browser, as it provides the basic services and infrastructure needed to run the application. It should only be imported once in the root AppModule, as importing it in other modules can cause problems with the application's behavior.</p>

          </CAccordionBody>
        </CAccordionItem>
        <CAccordionItem itemKey={3}>
          <CAccordionHeader>Binding</CAccordionHeader>
          <CAccordionBody> 
            <p>In Angular, data binding is the connection between a component and its template. It allows you to communicate between the component class and the template, by enabling the component to send data to the template and the template to send events to the component.</p>

            <p>There are two types of data binding in Angular:</p>
            <ol>
              <li><p><strong>Property binding</strong> - allows you to set the value of a property in the component and bind it to an element in the template.</p></li>
              <p>For example:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
              <p>In this example, the value of the title property in the component is bound to the innerText property of the p element in the template.</p>
              <li>
                <p><strong>Event binding</strong> - allows you to bind an event in the template to a method in the component.</p>
                <p>For example:</p>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
                <p>In this example, the click event of the button element is bound to the onClick method in the component. When the button is clicked, the onClick method will be called.</p>
                <p>You can also use two-way data binding, which combines <strong>property binding</strong> and <strong>event binding</strong>. This allows you to bind a property in the component to an element in the template, and update the property whenever the element's value changes.</p>
                <p>For example:</p>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
                <p>In this example, the value of the title property is bound to the value of the input element, and the title property will be updated whenever the value of the input element changes.</p>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
              </li>
            </ol>
          </CAccordionBody>
        </CAccordionItem>
        <CAccordionItem itemKey={4}>
          <CAccordionHeader>Directives</CAccordionHeader>
          <CAccordionBody> 
            <p>n Angular, a directive is a class with a @Directive decorator that allows you to attach behavior to an element in the DOM.</p>
            <p>There are three types of directives in Angular:</p>
            <ol>
              <li>
                <p><strong>Component directive</strong> - a directive with a template. It is used to create reusable components.</p>
              </li>
              <li>
                <p><strong>Structural directive</strong> - a directive that changes the structure of the DOM by adding, removing, or manipulating elements. Structural directives have a * prefix. Examples include *ngIf and *ngFor.</p>
              </li>
              <li>
                <p><strong>Attribute directive</strong> - a directive that changes the appearance or behavior of an element.</p>
              </li>
            </ol>
            <p>Here is an example of a component directive:</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
            <p>And here is an example of a structural directive:</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
            <p>In this example, the *ngFor directive is used to iterate over the items array and create a new li element for each item.</p>
            <p>Here is an example of an attribute directive:</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
            <p>In this example, the HighlightDirective directive adds a mouseenter and mouseleave event listener to the element it is applied to, and changes the background color of the element when the mouse enters or leaves.</p>
          </CAccordionBody>
        </CAccordionItem>
        <CAccordionItem itemKey={5}>
          <CAccordionHeader>Safe Navigation Operator</CAccordionHeader>
          <CAccordionBody> 
            <p>In Angular, the safe navigation operator (also known as the Elvis operator) is a way to handle situations where you need to access a property of an object, but the object may be null or undefined.</p>
            <p>The safe navigation operator allows you to access a property of an object without having to check if the object is null or undefined first. If the object is null or undefined, the safe navigation operator returns null or undefined instead of throwing an error.</p>
            <p>The safe navigation operator is represented by a question mark (?).</p>
            <p>Here is an example of using the safe navigation operator:</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter>
            <p>In this example, the name property of the user object will be displayed if the user object is not null or undefined. If the user object is null or undefined, nothing will be displayed.</p>
            <p>You can also use the safe navigation operator to access a nested property:</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter>
            <p>In this example, the city property of the address property of the user object will be displayed if both the user object and the address object are not null or undefined. If either the user object or the address object is null or undefined, nothing will be displayed.</p>

            <p>The safe navigation operator can also be used with function calls:</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter>
            <p>In this example, the getName function of the user object will be called if the user object is not null or undefined. If the user object is null or undefined, nothing will happen.</p>
          </CAccordionBody>
        </CAccordionItem>
        <CAccordionItem itemKey={6}>
          <CAccordionHeader>Change detection mechanism</CAccordionHeader>
          <CAccordionBody> 
            <p>Change detection is a mechanism in Angular that allows the framework to detect and update the changes in the application's state and reflect those changes in the user interface (UI).</p>
            <p>Angular uses a unidirectional data flow, meaning that the data flows from the top-level component down to the child components through inputs and events. When there's a change in the data, Angular runs a change detection cycle to propagate these changes down the component tree.</p>
            <p>The change detection mechanism in Angular is triggered in two ways:</p>
            <p>The change detection mechanism runs in several situations:</p>
            <ul>
              <li><p>When an event occurs: Whenever an event occurs in the component (e.g., a button is clicked, an input value changes), the change detection mechanism is triggered.</p></li>
              <li><p>When a component's input changes: If a component has any input properties, the change detection mechanism will be triggered whenever the value of one of those input properties changes.</p></li>
              <li><p>Asynchronous operations: If your component performs any asynchronous operations (e.g., making an HTTP request, using a timer), the change detection mechanism will be triggered when those operations complete.</p></li>
              <li><p>Angular Lifecycle hooks: Certain Angular lifecycle hooks like ngAfterViewInit, ngAfterContentInit, ngAfterViewChecked, ngAfterContentChecked, etc. can also trigger the change detection mechanism.</p></li>
            </ul>
            <ol>
              <li><p>By default, Angular runs change detection on the entire application every time there's an event, such as a user interaction, a network response, or a timer event. This is known as the default change detection strategy.</p></li>
              <li><p>Change detection can also be triggered manually by calling the detectChanges method on a specific component or the ApplicationRef class. This is known as the on-push change detection strategy and is typically used when performance is a concern and it's necessary to limit the number of change detection cycles.</p></li>
            </ol>
            <p>During the change detection cycle, Angular compares the current value of a component's properties with the previous value and updates the view if there are any changes.</p>
            <p>It's important to note that change detection can be a performance-critical aspect of an Angular application, and it's necessary to be aware of the change detection strategy and how to optimize it.</p>

            <strong><p>detectChanges</p></strong>
            <p>detectChanges() is a method provided by the Angular framework that allows developers to manually trigger the change detection cycle on a specific component or the entire application.</p>
            <p>The detectChanges() method is typically used in situations where the default change detection strategy doesn't cover all the necessary cases, such as when working with a 3rd-party library or when performance is a concern.</p>
            <p>Here is an example of how to use the detectChanges() method on a specific component:</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter>
            <p>In this example, the MyComponent class has a message property that is initially empty. A setTimeout function is used to update the message property after 5 seconds. After updating the property, the detectChanges() method is called on the ChangeDetectorRef instance to manually trigger the change detection cycle and update the view.</p>
            <p>It's important to note that manually triggering the change detection cycle can have an impact on the performance of the application, so it should be used sparingly and only when necessary.</p>
            <p>Also, it's important to note that the detectChanges() method can be also be called on the ApplicationRef class to trigger change detection on the entire application.</p>

            <strong><p>onPush</p></strong>
            <p>onPush is a change detection strategy in Angular that can be set on a component to optimize the change detection process. The onPush strategy tells Angular to only check for changes in the component's input properties and the component's content projected from a parent component. This means that Angular will not check for changes in the component's internal state or in the services that the component is using.</p>
            <p>Here is an example of how to set the onPush change detection strategy on a component:</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code13}</SyntaxHighlighter>
            <p>In this example, the MyComponent component uses the onPush change detection strategy by setting the changeDetection property of the @Component decorator to ChangeDetectionStrategy.OnPush.</p>
            <p>Using onPush strategy can improve performance in large or frequently updated data sets, as Angular will not check for changes in the component's internal state or in the services that the component is using, thus reducing the number of change detection cycles.</p>
            <p>It's important to note that when using the onPush strategy, it's necessary to manually trigger change detection using the markForCheck() method on the ChangeDetectorRef class or by using the detectChanges() method on the ChangeDetectorRef class or the ApplicationRef class.</p>
            <strong><p>Diffrence B/W onPush and detectChanges()</p></strong>
            <p>onPush and detectChanges() are two different techniques that are related to the change detection mechanism in Angular.</p>
            <p>onPush is a change detection strategy that can be set on a component by changing the changeDetection property of the component's @Component decorator to ChangeDetectionStrategy.OnPush. When a component uses the onPush strategy, Angular will only check for changes in the component's input properties and the component's content projected from a parent component. It will not check for changes in the component's internal state or in the services that the component is using. This can be a useful optimization technique when working with large data sets or when a component is not frequently updated.</p>
            <p>detectChanges() is a method provided by the Angular framework that allows developers to manually trigger the change detection cycle on a specific component or the entire application. This method can be called on an instance of the ChangeDetectorRef class, which is typically injected into a component's constructor. The detectChanges() method is typically used in situations where the onPush strategy doesn't cover all the necessary cases, such as when working with a 3rd-party library or when performance is a concern.</p>
            <p>While both onPush and detectChanges() are related to change detection, they are used in different situations and have different purposes. onPush is used to optimize change detection by limiting the number of change detection cycles, while detectChanges() is used to manually trigger change detection when the default change detection strategy doesn't cover all necessary cases.</p>
          </CAccordionBody>
        </CAccordionItem>
        <CAccordionItem itemKey={7}>
          <CAccordionHeader>Create Global variables</CAccordionHeader>
          <CAccordionBody> 
            <p>In Angular, you can create a global variable by using a shared service or by utilizing Angular's built-in AppModule and environment files. Here are the steps for each approach:</p>
            <ol>
              <li><p>Shared Service Approach:</p></li>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code14}</SyntaxHighlighter>
              <p>In the component where you want to set the global variable, inject the GlobalService and use its setGlobalVariable() method:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1402}</SyntaxHighlighter>
              <p>In the component where you want to use the global variable, inject the GlobalService and use its getGlobalVariable() method:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1403}</SyntaxHighlighter>
              <li><p>AppModule and environment Files Approach:</p></li>
              <p>Open the src/environments/environment.ts file and add a property to store the global variable. For example:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1404}</SyntaxHighlighter>
              <p>Open the src/app/app.module.ts file and import the environment object:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1405}</SyntaxHighlighter>
              <p>In any component, you can import the environment object and access the global variable:</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1406}</SyntaxHighlighter>
            </ol>
          </CAccordionBody>
        </CAccordionItem>
        <CAccordionItem itemKey={8}>
          <CAccordionHeader>ViewChild and ViewChildren</CAccordionHeader>
          <CAccordionBody> 
            <p>In Angular, @ViewChild and @ViewChildren are decorators used to access child components or elements from within a parent component. They allow you to obtain references to child components or elements in order to interact with them, retrieve data, or invoke methods.</p>
              <p>Here's an explanation of how @ViewChild and @ViewChildren work:</p>
              <b>@ViewChild:</b>
              <ol>
                <li><p>@ViewChild is used to obtain a reference to the first occurrence of a child component or element.</p></li>
                <li><p>It takes two parameters: the type of the child component or the name of the template reference variable associated with an element, and an optional configuration object.</p></li>
                <li><p>The reference obtained using @ViewChild is available in the parent component's ngAfterViewInit lifecycle hook or any subsequent lifecycle hooks</p></li>
                <li><SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code15}</SyntaxHighlighter></li>
                <li><SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1501}</SyntaxHighlighter></li>
              </ol>
              <b>@ViewChildren:</b>
              <ol>
                <li><p>@ViewChildren is used to obtain a reference to multiple occurrences of child components or elements.</p></li>
                <li><p>It takes the same parameters as @ViewChild but returns an instance of QueryList.</p></li>
                <li><p>The QueryList is a collection that is updated whenever the child components or elements change dynamically.</p></li>
                <li><SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code16}</SyntaxHighlighter></li>
                <li><SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1601}</SyntaxHighlighter></li>
              </ol>
            </CAccordionBody>
        </CAccordionItem>
        <CAccordionItem itemKey={9}>
          <CAccordionHeader>ContentChild and ContentChildren</CAccordionHeader>
          <CAccordionBody> 
            <p>In Angular, @ContentChild and @ContentChildren are decorators used to access projected content within a component that uses <b>ng-content</b> to project content from its parent component.</p>
            <ol>
              <li><p>@ContentChild decorator:</p></li>
              <ul>
                <li><p>@ContentChild allows you to access the first matching projected element or component.</p></li>
                <li><p>It takes a selector as a parameter to specify which content to select.</p></li>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code19}</SyntaxHighlighter>
                <p>In this example, @ContentChild selects the first projected element with the local template variable name myElement using ElementRef. The ngAfterContentInit lifecycle hook is used to access the projected element's text.</p>
                <li><p></p></li>
              </ul>
              <li><p>@ContentChildren decorator:</p></li>
              <ul>
                <li><p>@ContentChildren allows you to access multiple matching projected elements or components.</p></li>
                <li><p>It takes a selector as a parameter to specify which content to select.</p></li>
                <li><p>It returns a QueryList containing the selected projected elements or components.</p></li>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code20}</SyntaxHighlighter>
                <p>In this example, @ContentChildren selects all projected elements with the local template variable name myElement using QueryList and ElementRef. The ngAfterContentInit lifecycle hook is used to iterate through the selected elements and log their text.</p>
                <p>Note that @ContentChild and @ContentChildren decorators should be used inside lifecycle hooks like ngAfterContentInit to ensure that the projected content is available.</p>
              </ul>
            </ol>
          </CAccordionBody>
        </CAccordionItem>
        <CAccordionItem itemKey={10}>
          <CAccordionHeader>ng-content</CAccordionHeader>
          <CAccordionBody> 
            <p>ng-content is a feature provided by Angular that allows the insertion of content into a component from its parent component. It provides a way to pass arbitrary content, such as HTML, components, or text, to a component and have that content rendered within the component's template.</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code17}</SyntaxHighlighter>
            <p>In the above example, the ParentComponent includes the ChildComponent and provides some content within it using ng-content. The content within the opening and closing tags of the ChildComponent tag in the ParentComponent template will be inserted into the ng-content placeholder of the ChildComponent template.</p>
            <p>ng-content can also accept attributes and select specific content based on selectors. By using selector-based content projection, you can have more control over which content is projected into specific slots within the component.</p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code18}</SyntaxHighlighter>
          </CAccordionBody>
        </CAccordionItem>
        </CAccordion>
      </CCardBody>
    </CCard>        
  )
}

export default Core;