import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import theta from '../../assets/images/theta1.png'


function Theta () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Theta - Time</strong></CCardHeader>
			<CCardBody>
				<p>Theta represents the time value decline of an options contract.
Before expiration, the time value of an option is at least 0. The longer the time until an options contract expires, the greater the opportunity for the underlying security’s price to move and increase its intrinsic value, so the contract has more time value. Basically, with more time on the clock, more price movement can happen.</p>
				<p>A theta value of -0.02 means the option will lose $0.02 ($2) per day. Theta is always represented in negative terms because the portion of an option’s premium related to time is always going down.</p>
				<strong>Theta is an advantage for the option seller and a disadvantage for the option buyer.</strong><br/>
				<img src={theta} width="50%" />
			</CCardBody>
	</CCard>
			
	)
}

export default Theta