export const codeSnippet = {
	code1: `function callbackExample(name, callback) {
  console.log('Hello \$\{name}');
  callback();
}

callbackExample("John", function() {
  console.log("Callback function has been invoked.");
});

Output
Hello John
Callback function has been invoked.`,
  code2: `function getData(url, callback) {
  fetch(url)
    .then(response => response.json())
    .then(data => callback(data))
    .catch(error => console.log(error))
}

getData("https://api.example.com/data", function(data) {
  console.log(data);
});`,
  code3: `const delayedMessage = new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve("Hello, this message was delayed.");
  }, 3000);
});

delayedMessage.then(message => console.log(message));`,
  code4: `const fetchData = url => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(error => reject(error));
  });
};

fetchData("https://api.example.com/data")
  .then(data => console.log(data))
  .catch(error => console.log(error));`,
  code5: `async function getData(url) {
  try {
    const response = await axios.get(url);
    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}

getData("https://api.example.com/data");`,
  code6: `function outerFunction(x) {
  return function innerFunction(y) {
    return x + y;
  }
}

const add = outerFunction(5);
console.log(add(3)); // 8
console.log(add(4)); // 9`,
  code7: `function makeCounter() {
  let count = 0;
  return function() {
      return count++;
  };
}

// ES6

const makeCounter = () => {
  let count = 0;
  return () => count++;
}

let counter = makeCounter();
console.log(counter()); // prints 0
console.log(counter()); // prints 1
console.log(counter()); // prints 2`,
  code8: `let obj = {name: 'John'};
function sayName() {
    console.log(this.name);
}

sayName.call(obj); // prints 'John'`,
  code9: `let obj = {name: 'John'};
function sayName(age) {
    console.log(this.name + ' is ' + age + ' years old.');
}
let args = ['25'];
sayName.apply(obj, args); // prints 'John is 25 years old.'`,
  code10: `console.log(x); // undefined
var x = 5;`,
  code11: `var x;
console.log(x); // undefined
x = 5;`,
  code12: `sayHello(); // "Hello, World!"
function sayHello() {
    console.log("Hello, World!");
}`,
  code13: `sayHello(); // TypeError: sayHello is not a function
let sayHello = function() {
    console.log("Hello, World!");
}`

}