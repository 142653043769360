import React, { useContext } from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdownHeader,
  CBadge
} from '@coreui/react'
import {
  cilLockLocked,
  cilTask
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import AuthContext from '../../store/auth-context';
import projectContext from '../../store/project-context'
import profileImg from '../../assets/images/avatars/lindo.jpeg'
import reactImg from '../../assets/images/react.png'
import springBoot from '../../assets/images/springBoot.png'
import java from '../../assets/images/java.png'
import angular from '../../assets/images/angular.png'
import english from '../../assets/images/english.webp'
import javascript from '../../assets/images/javascript.png'
import cloud from '../../assets/images/cloud.jpeg'


const AppHeaderDropdown = () => {
  const authCtx = useContext(AuthContext);
  const projectItem = useContext(projectContext);
  const logoutHandler = () => {
    authCtx.logout();
    // optional: redirect the user
  };

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={profileImg} size="md" />
      </CDropdownToggle>    
      <CDropdownMenu className="pt-0" placement="bottom-end">
      <CDropdownHeader className="bg-light fw-semibold py-2">Project</CDropdownHeader>
        <CDropdownItem onClick={()=>projectItem.selectProject('react')} style={{cursor:'pointer'}}>
          <img src={reactImg} icon={cilTask} className="me-2" style={{width: '1rem',height: '1rem'}} />
          React 
          <CBadge color="info" className="ms-2"></CBadge>         
        </CDropdownItem>
        <CDropdownItem onClick={()=>projectItem.selectProject('springBoot')} style={{cursor:'pointer'}}>
          <img src={springBoot} style={{width: '1rem',height: '1rem'}} className="me-2" />
          SpringBoot          
        </CDropdownItem>
        <CDropdownItem onClick={()=>projectItem.selectProject('java')} style={{cursor:'pointer'}}>
          <img src={java} style={{width: '1rem',height: '1rem'}} className="me-2" />
          Java          
        </CDropdownItem>
        <CDropdownItem onClick={()=>projectItem.selectProject('javaScript')} style={{cursor:'pointer'}}>
          <img src={javascript} style={{width: '1rem',height: '1rem'}} className="me-2" />
          JavaScript          
        </CDropdownItem>
        <CDropdownItem onClick={()=>projectItem.selectProject('options')} style={{cursor:'pointer'}}>
          <img src={javascript} style={{width: '1rem',height: '1rem'}} className="me-2" />
          Options          
        </CDropdownItem>
        <CDropdownItem onClick={()=>projectItem.selectProject('angular')} style={{cursor:'pointer'}}>
          <img src={angular} style={{width: '1rem',height: '1rem'}} className="me-2" />
          Angular          
        </CDropdownItem>
        <CDropdownItem onClick={()=>projectItem.selectProject('kafka')} style={{cursor:'pointer'}}>
        <img src={cloud} style={{width: '1rem',height: '1rem'}} className="me-2" />
          Kafka          
        </CDropdownItem>
        <CDropdownItem onClick={()=>projectItem.selectProject('english')} style={{cursor:'pointer'}}>
          <img src={english} style={{width: '1rem',height: '1rem'}} className="me-2" />
          English          
        </CDropdownItem>
        <CDropdownItem onClick={()=>projectItem.selectProject('testing')} style={{cursor:'pointer'}}>
          <CIcon icon={cilTask} className="me-2" />
          Testing          
        </CDropdownItem>
        <CDropdownItem onClick={()=>projectItem.selectProject('cloud')} style={{cursor:'pointer'}}>
        <img src={cloud} style={{width: '1rem',height: '1rem'}} className="me-2" />
          Cloud          
        </CDropdownItem>
        <CDropdownItem onClick={()=>projectItem.selectProject('behavioral')} style={{cursor:'pointer'}}>
        <img src={cloud} style={{width: '1rem',height: '1rem'}} className="me-2" />
          Behavioral          
        </CDropdownItem>
        <CDropdownHeader className="bg-light fw-semibold py-2">Settings</CDropdownHeader>
        <CDropdownItem href="#" onClick={logoutHandler}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
