import React from "react";
import { NavLink } from 'react-router-dom'
import { CRow, CCol, CCard, CContainer,CCardBody, CCardHeader, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem, CCallout } from '@coreui/react'
import roadMap from '../assets/images/roadmap.jpeg'
import smartwork from '../assets/images/smartwork.jpeg'
import getoutComfortZone from '../assets/images/getoutComfortZone.jpeg'
import dreamCar from '../assets/images/Benz-AMG-GLE-Coupe.jpg'
import happyFamily from '../assets/images/happy-family.jpg'
import healthyLifeStyle from '../assets/images/healthyLifeStyle.jpg'
import keralaHome from '../assets/images/keralaHome.jpg'
import newHome from '../assets/images/newHome.jpg'
import myHome from '../assets/images/myHome.jpg'
import TeslaY from '../assets/images/TeslaY.jpg'
import RayDalio from '../assets/images/raydalio.jpg'
import travelAroundTheWorld from '../assets/images/travel-around-the-world.jpg'


function Dashboard () {
	return (
		<>
			<CRow>
				<CCol col="12" sm="6">
					<CCallout color="info" className={'bg-secondary'}>
						<small className="text-muted">Current 2024</small><br />
						<strong className="h4">$130k + $12k = $142K</strong>
					</CCallout>
				</CCol>
				<CCol col="12" sm="6">
					<CCallout color="danger" className={'bg-white'}>
						<small className="text-muted">2024</small><br />
						<strong className="h4">$170k + $10k (bonus) + $20k (stock) = $200K</strong>
					</CCallout>
				</CCol>
			</CRow>
			<CCard className="mb-12">
				<CCardBody>
					<CRow>
						<CCol>
							<CCard>
								<CListGroup flush>
									<CListGroupItem>
										<h5>To Achieve Goals</h5>
										<ol>																															
											<li>
												<p><b>Deep Desire:</b></p>
												<ul>
													<li>
														<p><b>Why You Need This:</b> Articulate the reasons behind your strong desire for this.</p>
													</li>
													<li>
														<p><b>What You Will Get By This:</b> Specify the anticipated outcomes or benefits associated with fulfilling this deep desire.</p>
													</li>											
												</ul>										
											</li>
											<li>
												<p><b>Skip Perfectionism.</b></p>
												<ul>
													<li>
														<p><b>Action Over Perfection:</b> Perfectionism can lead to overthinking and excessive focus on details, delaying action. By skipping perfectionism, you prioritize taking action over waiting for everything to be perfect.</p>
													</li>
													<li>	
														<p><b>Risk-Taking:</b> Perfectionism may lead to a fear of taking risks due to the fear of failure. Skipping perfectionism encourages a mindset that embraces calculated risks, essential for growth and innovation.</p>
													</li>
													<li>
														<p><b>Focus on Learning:</b> Embracing imperfections allows for a focus on learning from experiences. Mistakes become opportunities for growth and improvement rather than reasons for self-criticism.</p>
													</li>											
												</ul>										
											</li>	
											<li>											
												<p><s>Did i win / Did i lose?</s> Did i make my <b>best effort?</b>. If so, you may be outscored but <b>you'll never lose</b></p>
											</li>	
											<li>											
												<p><b>Learn from the experts:</b> Learn from the people who are already demonstrating the results that you want to achieve. Read their books, listen to their programs, etc.</p>
											</li>	
											<li>											
												<p><b>Don't make decisions based on fear.</b> ex: Job change, Starting business, Real estate investment etc</p>
											</li>	
											<li>											
												<p><b>SAY YES (Yes and.....)</b> ex: YES and i need to study before doing that. YES and the delivery date for that will be slightly extended or take a bit more time.</p>
											</li>									
										</ol>
									</CListGroupItem>
								</CListGroup>
							</CCard>
						</CCol>	
					</CRow>
					<br/>
					<CRow>
						<CCol sm={6} lg={3}>
							<img src={smartwork} width="100%" height="100%" />
						</CCol>
						<CCol sm={6} lg={3}>
							<img src={getoutComfortZone} width="100%" height="100%" />
						</CCol>
						<CCol sm={6} lg={3}>
							<img src={RayDalio} width="100%" height="100%" />
						</CCol>					
					</CRow>
					</CCardBody>
				</CCard>	
				<br/>					
				<CCard className="mb-12">
					<CCardBody>
						<CContainer>
							<CRow className="align-items-start">
								<CCol>
									<CCard style={{ width: '20rem' }}>
										<CListGroup flush>
											<CListGroupItem active>Speak English Fluently</CListGroupItem>
											<CListGroupItem>
												<p><i>Why You Need This</i></p>
												<ul>
													<li><p>To become confident while speaking to others.</p></li>
													<li><p>In the office, it is important to communicate with confidence and authority.</p></li>
													<li><p>In business, it's very important to convince people with confidence.</p></li>
												</ul>	
												<p><i>What You Will Get By This</i></p>
												<ul>
													<li><p>Respect.</p></li>
													<li><p>To reach in top positions.</p></li>
													<li><p>People appreciate good English.</p></li>
												</ul>			
												<p><i>Design / Methods</i></p>
												<ul>
													<li><p>Listen english.</p></li>
													<li><p>Improve fluency and style.</p></li>
													<li><p>Learn 5 sentenses in evey weak.</p></li>
												</ul>																				
											</CListGroupItem>
										</CListGroup>
									</CCard>
								</CCol>
								<CCol>
									<CCard style={{ width: '20rem' }}>
										<CListGroup flush>
											<CListGroupItem active>Get Hight Salaried Job</CListGroupItem>
											<CListGroupItem>
												<p><i>Why You Need This</i></p>
												<ul>
													<li><p>Need money for Buying a new home</p></li>
													<li><p>Need money for Starting business</p></li>
												</ul>	
												<p><i>What You Will Get By This</i></p>
												<ul>
													<li><p>Happiness.</p></li>
													<li><p>Dream home.</p></li>
													<li><p>Investment and side business</p></li>
												</ul>	
												<p><i>Design / Methods</i></p>
												<ul>
													<li><p>Learn AWS, Angular, React, SpringBoot, Kubernetes, Jmeter, Selenium.</p></li>
													<li><p>Spend 30 minutes for leaning.</p></li>
												</ul>																				
											</CListGroupItem>
										</CListGroup>
									</CCard>
								</CCol>								
								<CCol>
									<CCard style={{ width: '20rem' }}>
										<CListGroup flush>
											<CListGroupItem active>Options Trading</CListGroupItem>
											<CListGroupItem>
												<p><i>Why You Need This</i></p>
												<ul>
													<li><p>Make passive income.</p></li>
													<li><p>Improve your financial health.</p></li>
													<li><p>Backup income for your primary income.</p></li>
													<li><p>Diversified Jobs.</p></li>
												</ul>	
												<p><i>What You Will Get By This</i></p>
												<ul>
													<li><p>Steady Income.</p></li>
													<li><p>Financial Freedom.</p></li>
												</ul>	
												<p><i>Problems / Obstacles</i></p>
												<ul>
													<li><p>Learning curve.</p></li>
													<li><p>Need to allocate some time daily.</p></li>
													<li><p>Find the right stock.</p></li>
												</ul>		
												<p><i>Design / Methods</i></p>
												<ul>
													<li><p>Learn option.</p></li>
													<li><p>Spend 30 minutes every morning and evening.</p></li>
												</ul>									
											</CListGroupItem>
										</CListGroup>
									</CCard>
								</CCol>													
							</CRow>
						</CContainer>
					</CCardBody>
				</CCard>
				<br/>
				<CCard className="mb-12">
					<CCardBody>
						<CRow>
							<CCol xs={2} sm={2} lg={2} style={{backgroundColor: '#2eb85c', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '20px', paddingRight:'0px'}}>
								<img src={myHome} width="100%" height="100%" />
							</CCol>
							<CCol xs={2} sm={2} lg={2}  style={{backgroundColor: '#2eb85c', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '20px', paddingRight:'20px'}}>
								<img src={TeslaY} width="100%" height="100%" />
							</CCol>
							<CCol xs={2} sm={2} lg={2}  style={{backgroundColor: '#f9b115', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '20px', paddingRight:'0px'}}>
								<img src={newHome} width="100%" height="100%" />
							</CCol>   						
							<CCol xs={2} sm={2} lg={2}  style={{backgroundColor: '#f9b115', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '20px', paddingRight:'0px'}}>
								<img src={keralaHome} width="100%" height="100%" />
							</CCol>
							<CCol xs={2} sm={2} lg={2}  style={{backgroundColor: '#f9b115', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '20px', paddingRight:'20px'}}>
								<img src={dreamCar} width="100%" height="100%" />
							</CCol>      	
						</CRow>	
						<br/>
						<CRow>
						<CCol xs={2} sm={2} lg={2} style={{backgroundColor: '#2eb85c', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '20px', paddingRight:'0px'}}>
								<img src={happyFamily} width="100%" height="100%" />
							</CCol>
							<CCol xs={2} sm={2} lg={2} style={{backgroundColor: '#2eb85c', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '20px', paddingRight:'20px'}}>
								<img src={healthyLifeStyle} width="100%" height="100%" />
							</CCol>
							<CCol xs={2} sm={2} lg={2} style={{backgroundColor: '#f9b115', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '20px', paddingRight:'20px'}}>
								<img src={travelAroundTheWorld} width="100%" height="100%" />
							</CCol>
						</CRow>
					</CCardBody>
				</CCard>
				<br/>				
				<CCard className="mb-4">
					<CCardBody>
						<CRow>
							<CCol sm={8}>
								<h4 id="traffic" className="card-title mb-0">
									Road Map 2023
								</h4>
								<div>
									<img src={roadMap} width="100%" height="100%" />
								</div>						
							</CCol>
						</CRow>
					</CCardBody>
				</CCard>
				<CCard className="mb-4">
					<CCardHeader><strong>Pending</strong></CCardHeader>
					<CCardBody>                
						<CAccordion flush>
							<CAccordionItem itemKey={1}>
								<CAccordionHeader>Java</CAccordionHeader>
								<CAccordionBody>                
									<ol>
										<li><p>Try Catch - Throw - Exception</p></li>
										<li><p>Stack Memory and Heap</p></li>
										<li><p>Simple thread pool factory</p></li>
										<li><p>Websocket</p></li>
									</ol>
								</CAccordionBody>
							</CAccordionItem>
							<CAccordionItem itemKey={2}>
								<CAccordionHeader>React</CAccordionHeader>
								<CAccordionBody>                
									<ol>
										<li><p>Redux Toolkit</p></li>

									</ol>
								</CAccordionBody>						
							</CAccordionItem>            
						</CAccordion>
					</CCardBody>
				</CCard>
		</>
			// <CRow>
			// 	<NavLink className="nav-link" to="" active>forwardRef</NavLink><br/>
			// 	<CCol sm={6} lg={3}>
			// 		<CCard style={{ width: '18rem' }}>
			// 			<CCardBody>
			// 				<CCardTitle><NavLink className="nav-link" to="/react/useState" active>useState</NavLink></CCardTitle>
			// 			</CCardBody>
			// 		</CCard>	
			// 	</CCol>
			// 	<CCol sm={6} lg={3}>
			// 		<CCard style={{ width: '18rem' }}>
			// 			<CCardBody>
			// 				<CCardTitle><NavLink className="nav-link" to="/react/useEffect" active>useEffect</NavLink></CCardTitle>
			// 			</CCardBody>
			// 		</CCard>	
			// 	</CCol>
			// 	<CCol sm={6} lg={3}>
			// 		<CCard style={{ width: '18rem' }}>
			// 			<CCardBody>
			// 				<CCardTitle><NavLink className="nav-link" to="/react/useRef" active>useRef</NavLink></CCardTitle>
			// 			</CCardBody>
			// 		</CCard>	
			// 	</CCol>
			// 	<CCol sm={6} lg={3}>
			// 		<CCard style={{ width: '18rem' }}>
			// 			<CCardBody>
			// 				<CCardTitle><NavLink className="nav-link" to="/react/useReducer" active>useReducer</NavLink></CCardTitle>
			// 			</CCardBody>
			// 		</CCard>	
			// 	</CCol>			
			// 	<CCol sm={6} lg={3}>
			// 		<CCard style={{ width: '18rem' }}>
			// 			<CCardBody>
			// 				<CCardTitle><NavLink className="nav-link" to="/react/useContext" active>useContext</NavLink></CCardTitle>
			// 			</CCardBody>
			// 		</CCard>	
			// 	</CCol>
			// 	<CCol sm={6} lg={3}>
			// 		<CCard style={{ width: '18rem' }}>
			// 			<CCardBody>
			// 				<CCardTitle><NavLink className="nav-link" to="/react/abortController" active>AbortController</NavLink></CCardTitle>
			// 			</CCardBody>
			// 		</CCard>	
			// 	</CCol>
			// </CRow>
	)
}

export default Dashboard