export const codeSnippet = {
  code1: `@Configuration
public class MyConfiguration {
  @Bean
  @Primary
  public DataSource primaryDataSource() {
    // configure and return a DataSource bean
  }

  @Bean
  @Qualifier("secondary")
  public DataSource secondaryDataSource() {
    // configure and return a DataSource bean
  }
}

@Component
public class MyComponent {
  private final DataSource dataSource;

  @Autowired
  public MyComponent(@Qualifier("secondary") DataSource dataSource) {
    this.dataSource = dataSource;
  }
}`
}

