import React from "react";
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../../utils/codeSnippet/cloud/Aws';


function Ecs() {

  return (
    <CCard className="mb-4">
      <CCardHeader><strong>ECS</strong></CCardHeader>
			<CCardBody>
				<p></p>
				<ul>
					<li><p><b>ECS Cluster:</b></p>
						<ul>
							<li>
								<p>An ECS Cluster is a grouping of one or more Amazon Elastic Compute Cloud (EC2) instances or AWS Fargate tasks.</p>
							</li>
							<li>
								<p>Clusters can span multiple Availability Zones within a region.</p>
							</li>
						</ul>
					</li>
					<li><p><b>ECS Service:</b></p>
						<ul>
							<li>
								<p>An ECS Service is used to run and maintain a specified number of instances of a task definition.</p>
							</li>
							<li>
								<p>It ensures that the desired number of tasks are running and restarts tasks if they fail or become unhealthy.</p>
							</li>
							<li>
								<p>Services are used for long-running applications or microservices.</p>
							</li>
						</ul>
					</li>
					<li><p><b>ECS Task:</b></p>
						<ul>
							<li>
								<p>A Task is the basic scheduling unit in ECS.</p>
							</li>
							<li>
								<p>It represents a single running container instance in your cluster.</p>
							</li>
							<li>
								<p>A task is based on a task definition, which defines how the containers should run.</p>
							</li>
						</ul>
					</li>
					<li><p><b>ECS Task Definition:</b></p>
						<ul>
							<li>
								<p>A Task Definition is a blueprint that describes how a task should run.</p>
							</li>
							<li>
								<p>It specifies various parameters such as which Docker image to use, CPU and memory requirements, environment variables, ports to expose, and how the containers in the task communicate with each other.</p>
							</li>
							<li>
								<p>Task definitions are used to create new tasks in a service or to run individual tasks.</p>
							</li>
						</ul>
					</li>
				</ul>			
				<CAccordion flush>
				<CAccordionItem itemKey={1}>
						<CAccordionHeader>Example 1</CAccordionHeader>
						<CAccordionBody>   							
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter> 	
							<p><b>container_definitions.json</b></p>	
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter> 						
						</CAccordionBody>
					</CAccordionItem>
				</CAccordion>
			</CCardBody>
    </CCard>        
  )
}

export default Ecs;