export const codeSnippet = {
    code1: `function ContactForm() {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  function handleSubmit(event) {
    event.preventDefault();
    console.log('name:', name);
    console.log('email:', email);
    console.log('message:', message);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
  }
`,
  code2: `function ContactForm() {
  const nameRef = React.useRef();
  const emailRef = React.useRef();
  const messageRef = React.useRef();

  function handleSubmit(event) {
    event.preventDefault();
    console.log('name:', nameRef.current.value);
    console.log('email:', emailRef.current.value);
    console.log('message:', messageRef.current.value);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          ref={nameRef}
        />
      </div>
      <div>
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          ref={emailRef}
        />
      </div>
      <div>
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          ref={messageRef}
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
}`,
  code3: `function MultipleInputs() {
  const [values, setValues] = useState({
    email: '',
    name: ''
  });

  const handleChange = e => {
    setValues(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value
    }));
  }

  return (
    <>
      <div>
        <label htmlFor="email">Email address</label>
        <input
          id="email"
          name="email"
          value={values.email}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="name">Full Name</label>
        <input
          id="name"
          name="name"
          value={values.name}
          onChange={handleChange}
        />
      </div>
    </>
  );
}`,
  code4: `function App() {
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    console.log(
      form.email,
      form.elements.email,
      form.userEmail,
      form.elements.userEmail);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="userEmail">Email address</label>
        <input id="userEmail" name="email" />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
}`
}

