export const codeSnippet = {
  code1: `try {    
  int a[]=new int[5];    
  a[5]=30/0;    
} catch(ArithmeticException e)  {  
  System.out.println("Arithmetic Exception occurs");  
}    
catch(ArrayIndexOutOfBoundsException e) {  
  System.out.println("ArrayIndexOutOfBounds Exception occurs");  
}    
catch(Exception e) {  
  System.out.println("Parent Exception occurs");  
} finally {  
  System.out.println("finally block is always executed");  
} `,
  code2: `Arithmetic Exception occurs
rest of the code
finally block is always executed`,
  code3: `try {    
    String json = "";
    JsonReader reader = Json.createReader(new StringReader(json));
    JsonObject JSONObject = reader.readObject();
} catch(Exception e) {  
  System.out.println("Parent Exception occurs");  
} finally {  
  reader.close();
} `,
  code4: `public class Main {
  public static void main(String[] args) {
    try {
      int x = 10;
      int y = 0;
      if (y == 0) {
        // throw an exception if y is equal to zero
        throw new ArithmeticException("y cannot be zero");
      }
      int z = x / y;
      System.out.println(z);
    } catch (ArithmeticException e) {
      // catch the exception and print an error message
      System.out.println("Error: " + e.getMessage());
    }
  }
}`,
  code5: `public class MyCustomException extends Exception {
  // constructor
  public MyCustomException(String message) {
    super(message);
  }
}`,
  code6: `try {
  // code that may throw the exception
  if (someCondition) {
    throw new MyCustomException("Some error message");
  }
} catch (MyCustomException e) {
  // handle the exception
  System.out.println("Error: " + e.getMessage());
}`,
code7: `public class InsufficientFundsException extends Exception {
  private double amount;

  public InsufficientFundsException(double amount) {
      this.amount = amount;
  }

  @Override
  public String getMessage() {
      return "Insufficient funds. Required amount: " + amount;
  }
}`,
}

