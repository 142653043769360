import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function One () {

  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Behavioral</strong></CCardHeader>
      <CCardBody>
        <CCardBody>
          <CAccordion flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader>Wolverine</CAccordionHeader>
              <CAccordionBody>     
                <p>Wolverine is one stop shop to empower application to became more resilient and hyginic via self service automation to reduce toil and save cost</p>
                <p>In the wolverine users can create schedule and in the schdule they can add multiple task with 25-30 self service actions.</p>
                <p>for example, GKP, users can do scale up the pods.... etc. or in the GAIA, users can stop or start or repave the gaia application. or in the GOS, users can start, stop, switchover functionalities...</p>
                <p>In GAIA, we are using leveraging the package (cloudfoundry) for accessing GAIA application.... Same like we have taks called jule (jenkins), there uses can add bitbucket name branch name commit it, we will build and deploy the app based on scheduled time, here we are leveraging package.</p>
                <p>Out backend application is not micro service for UI. for those kind of functionality is handle by our UI team. There job is based on UI action, updating and fetching from DB.</p>
                <p>Our application, we called it as wolverine SK, that will keep monitoring the DB entries, like whether its time for next scheudle ran.</p>
                <p>for example user schedule the task for next next day 10 pm. so our application will keep monitoring and once the time met, we will kickoff that task.</p>
                <p>We had a lot of perfomence issue in backend application like out of memory exception, becuae at a time, more schedule is kicked off, and each schedule have n number of task. some task is more time consuming like stopping the GOS DB, or scaling up the pod. (some tasks take more than 4-6 hrs). our appl is multi thread application, each task is running each therad. so if the task is taking more time, the thread will keep open.</p>
                <p>we are running this app in multiple pool. increaese the pool size wont solve this issue.</p>
                <p>so we are creating multiple microservices for each task ( like gkp, GOS).. and also adding asynchronous (@Async) proccess for each request. so that will reduce number of thread keep open of each task.</p>           
              </CAccordionBody>
            </CAccordionItem>  
            <CAccordionItem itemKey={2}>
              <CAccordionHeader>How to improve the perfomance of microservice</CAccordionHeader>
              <CAccordionBody>
                <ol>
                  <li><p>Load balancing:</p></li>
                  <li><p>Caching: By caching frequently accessed data, you can reduce the number of requests that need to be handled by the microservice</p></li>
                  <li><p>Asynchronous processing: By using asynchronous processing, you can ensure that incoming requests are processed quickly and do not block the main thread. This can help to reduce response time and improve overall performance.</p></li>
                  <li><p>Horizontal scaling: By adding more instances of a microservice, you can increase its capacity to handle more requests. This can be done dynamically based on incoming traffic patterns, or as a pre-emptive measure to ensure that the microservice can handle anticipated traffic spikes.</p></li>
                  <li><p>Circuit breaker: A circuit breaker pattern can be used to prevent a failing microservice from overloading the system and causing cascading failures. The circuit breaker monitors the health of the microservice and automatically switches to a fallback mechanism if the microservice becomes unavailable.</p></li>
                </ol> 
              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={3}>
              <CAccordionHeader>Describe a time when you had to complete a challenging action activity on your own without guidance, what action did u take and what was your outcome?</CAccordionHeader>
              <CAccordionBody> 
                <ul>
                  <li><p>Delivers with high</p></li>
                  <li><p>Demonstrate attention to quality</p></li>
                  <li><p>Set and achive hight goals</p></li>
                  <li><p>Demonstrate a strong work enthic</p></li>
                </ul>
                <p>----</p>
                <ul>
                  <li><p>Research and gather information</p></li>
                  <li><p>Break the task down into smaller parts:</p></li>
                  <li><p>Experiment</p></li>
                  <li><p>Debugging</p></li>
                  <li><p>Collaborate with peers:</p></li>
                </ul>              
              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={4}>
              <CAccordionHeader>Tell a time when you had to perform under pressure. how did you manage the situation and what was the outcome?</CAccordionHeader>
              <CAccordionBody> 
                <ul>
                  <li><p>Delivers with high</p></li>
                  <li><p>Demonstrate attention to quality</p></li>
                  <li><p>Set and achive hight goals</p></li>
                  <li><p>Demonstrate a strong work enthic</p></li>
                </ul>
                <p>----</p>
                <ul>
                  <li><p>Prioritizing tasks: They prioritize tasks based on importance and deadline to help manage their time and ensure they complete the most critical tasks first.</p></li>
                  <li><p>Staying organized: They stay organized by keeping track of their progress, maintaining clear and concise documentation, and using tools and systems to help manage their workload.</p></li>
                  <li><p>Taking breaks: </p></li>
                  <li><p>Seeking support</p></li>
                </ul>
              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={5}>
              <CAccordionHeader>Provide an example of a project when you worked on a team where each member had different responsibilities. How did the team members keep each other updated? how did you ensure that you met the teams overall goal?</CAccordionHeader>
              <CAccordionBody> 
                <ul>
                  <li><p>Actively listens</p></li>
                  <li><p>Users questions to clarify what others are saying</p></li>
                  <li><p>Speak with clarity and confidence</p></li>
                  <li><p>Write succinctly using proper style and grammer</p></li>
                  <li><p>Adapt communcation style</p></li>
                </ul>
                <p>----</p>
                <ul>
                  <li><p>Establish clear roles and responsibilities: Each team member should have a clear understanding of their role and responsibilities, as well as those of their peers, to avoid duplicated efforts and misunderstandings.</p></li>
                  <li><p>Set regular meetings: Regular meetings, whether in person or virtual, provide an opportunity for team members to update each other on their progress, discuss challenges and solutions, and align on next steps.</p></li>
                  <li><p>Use project management tools: Project management tools such as Trello, Asana, or Jira can help team members keep track of tasks, deadlines, and progress, and ensure that everyone is on the same page.</p></li>
                  <li><p>Maintain open lines of communication: Encourage team members to ask questions, provide updates, and share their ideas and concerns to promote a culture of transparency and collaboration.</p></li>
                  <li><p>Hold each other accountable: Team members should hold themselves and each other accountable for meeting deadlines and deliverables, and work together to resolve any challenges that arise.</p></li>
                </ul>
              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={6}>
              <CAccordionHeader>Tell me about a time you had to explain a comples topic to someone. Describe the steps you took to explain the topic and the outcome?</CAccordionHeader>
              <CAccordionBody> 
                <ul>
                  <li><p>Actively listens</p></li>
                  <li><p>Users questions to clarify what others are saying</p></li>
                  <li><p>Speak with clarity and confidence</p></li>
                  <li><p>Write succinctly using proper style and grammer</p></li>
                  <li><p>Adapt communcation style</p></li>
                </ul>
                <p>----</p>
                <ul>
                  <li><p>Identify your audience: Understanding the knowledge level and background of your audience is key to determining the level of detail and complexity you should use in your explanation.</p></li>
                  <li><p>Break down the topic: Complex topics can be overwhelming, so breaking the topic down into smaller, manageable parts can make it easier for your audience to understand.</p></li>
                  <li><p>Use analogies and examples: Analogies and real-world examples can help illustrate complex concepts and make them more relatable to your audience.</p></li>
                  <li><p>Use visual aids: Visual aids such as diagrams, flowcharts, and graphs can help clarify complex information and make it easier to understand.</p></li>
                  <li><p>Check for understanding: Regularly check in with your audience to ensure they are following your explanation and ask for feedback on areas that may need further clarification.</p></li>
                </ul>
              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={7}>
              <CAccordionHeader>How do you ensure the person you are communicating to, understand fully what you are trying to relay? tell me about ta time whrere you used this approach?</CAccordionHeader>
              <CAccordionBody> 
                <ul>
                  <li><p>Actively listens</p></li>
                  <li><p>Users questions to clarify what others are saying</p></li>
                  <li><p>Speak with clarity and confidence</p></li>
                  <li><p>Write succinctly using proper style and grammer</p></li>
                  <li><p>Adapt communcation style</p></li>
                </ul>
                <p>----</p>
                <ul>
                  <li><p>Ask questions: Ask open-ended questions to check the person's understanding and to clarify any misunderstandings.</p></li>
                  <li><p>Repeat and paraphrase: Repeat the information back to the person in your own words to ensure you both have a common understanding.</p></li>
                  <li><p>Provide examples: Providing real-world examples or case studies can help illustrate complex concepts and make them more relatable to the person you are communicating with.</p></li>
                  <li><p>Encourage feedback: Encourage the person to ask questions and provide feedback on areas that may need further clarification.</p></li>                
                </ul>
              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={8}>
              <CAccordionHeader>Describe a project taht you had to work on that required the input of a team of people for it to be completed successfully. How did ht eteam work together to ensure the success and what were the challenges the team faced? what was your specific involment in the team and what actions did you take?</CAccordionHeader>
              <CAccordionBody> 
                <ul>
                  <li><p>Works collabratively with professionalism</p></li>
                  <li><p>Respect the ideas of others</p></li>
                  <li><p>Engage in productive dialogue</p></li>
                  <li><p>Demonstrate empathy and build relationships</p></li>
                  <li><p>Understand what others can offer</p></li>
                  <li><p>Aware of ones strength and partners ot complement these strengths</p></li>
                </ul>
                <p>----</p>
                <ul>
                  <li><p>Clearly defined roles and responsibilities: Each team member should have a clear understanding of what is expected of them, and what tasks they are responsible for completing.</p></li>
                  <li><p>Regular communication: Regular check-ins and progress updates can help keep everyone informed of the team's progress and identify potential roadblocks.</p></li>
                  <li><p>Collaboration and sharing of ideas: Teams that encourage collaboration and the sharing of ideas are more likely to find innovative solutions to challenges.</p></li>
                  <li><p>Flexibility: Teams should be prepared to adapt to changes and pivot their approach if necessary to ensure the success of the project.</p></li>
                  <li><p>Common goal: Teams should have a shared understanding of the project's goals and objectives, and work together to achieve them.</p></li>
                  <li><p></p></li>
                </ul>
              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={9}>
              <CAccordionHeader>Tell me about a time when you came into an initiative/assignment mid-stream. Describe the situation, action you took and the outcome. What would you do diffrently if give another oppurtunity</CAccordionHeader>
              <CAccordionBody> 
              <ul>
                  <li><p>Works collabratively with professionalism</p></li>
                  <li><p>Respect the ideas of others</p></li>
                  <li><p>Engage in productive dialogue</p></li>
                  <li><p>Demonstrate empathy and build relationships</p></li>
                  <li><p>Understand what others can offer</p></li>
                  <li><p>Aware of ones strength and partners ot complement these strengths</p></li>
                </ul>
                <p>----</p>
                <ul>
                  <li><p>Review the existing documentation: Review all available project documentation, including meeting notes, project plans, and status updates, to get a clear understanding of where the project stands.</p></li>
                  <li><p>Meet with the team: Schedule time with the project team to discuss their experiences, ask questions, and get clarification on any areas that are unclear.</p></li>
                  <li><p>Identify priorities: Review the project plan and prioritize tasks that need to be completed immediately.</p></li>
                  <li><p>Get up to speed on the technology: If the project involves new technologies, take the time to familiarize yourself with them to ensure you can contribute effectively.</p></li>
                  <li><p>Communicate with stakeholders: Keep stakeholders informed of your progress and any changes that may be necessary to ensure the project's success.</p></li>
                  <li><p></p></li>
                </ul>
              </CAccordionBody>
            </CAccordionItem>  
            <CAccordionItem itemKey={10}>
              <CAccordionHeader>Tell me about a time when you felt it was important to stand up for what you belived was right. Describe the situation and how you made the decision on the action to take</CAccordionHeader>
              <CAccordionBody> 
                <ul>
                  <li><p>Is honest</p></li>
                  <li><p>Is consistand in words and action</p></li>
                  <li><p>Acknowledge mistakes and failures</p></li>
                  <li><p>Demonstrates sound moral and ethical principles</p></li>
                  <li><p>Refues to benifit from unfair or immoral decisions and action</p></li>
                </ul>
                <p>----</p>
                <ul>
                  <li><p>Assess the situation: Take the time to gather all available information and understand the context of the situation.</p></li>
                  <li><p>Evaluate the options: Consider all possible options and weigh the pros and cons of each one.</p></li>
                  <li><p>Consider the impact: Think about the impact that your decision will have on others, including your colleagues, stakeholders, and the overall project.</p></li>
                  <li><p>Consult with trusted colleagues: Talk to trusted colleagues to get their perspective and to ensure that you have considered all angles.</p></li>
                  <li><p>Make an informed decision: Based on all of the information gathered, make an informed decision on the course of action to take.</p></li>
                  <li><p>Communicate clearly: Once you have made your decision, communicate it clearly and concisely, taking care to explain your reasoning and to address any concerns that others may have.</p></li>
                </ul>
              </CAccordionBody>
            </CAccordionItem> 
            <CAccordionItem itemKey={11}>
              <CAccordionHeader>Describe a time when you made a mistake at work. Describe the sitation, what actions you took, and what you learned</CAccordionHeader>
              <CAccordionBody> 
              <ul>
                  <li><p>Is honest</p></li>
                  <li><p>Is consistand in words and action</p></li>
                  <li><p>Acknowledge mistakes and failures</p></li>
                  <li><p>Demonstrates sound moral and ethical principles</p></li>
                  <li><p>Refues to benifit from unfair or immoral decisions and action</p></li>
                </ul>
                <p>----</p>
                <ul>
                  <li><p>Acknowledge the mistake: Take responsibility for the mistake and acknowledge it to those affected.</p></li>
                  <li><p>Evaluate the impact: Assess the impact of the mistake and determine what needs to be done to correct it.</p></li>
                  <li><p>Develop a plan of action: Develop a plan to address the mistake, taking into account the impact on others and the resources available.</p></li>
                  <li><p>Take corrective action: Take the necessary steps to correct the mistake and mitigate its impact.</p></li>
                  <li><p>Communicate the outcome: Communicate the outcome of your efforts to those affected, taking care to address any concerns they may have.</p></li>
                  <li><p>Reflect and learn: Take the time to reflect on the mistake and</p></li>
                </ul>
              </CAccordionBody>
            </CAccordionItem>  
            <CAccordionItem itemKey={12}>
              <CAccordionHeader>Tell me about a time when you were honest and frthcoming when it was difficult to do so. Please describe the sitation and yours action</CAccordionHeader>
              <CAccordionBody> 
              <ul>
                  <li><p>Is honest</p></li>
                  <li><p>Is consistand in words and action</p></li>
                  <li><p>Acknowledge mistakes and failures</p></li>
                  <li><p>Demonstrates sound moral and ethical principles</p></li>
                  <li><p>Refues to benifit from unfair or immoral decisions and action</p></li>
                </ul>
                <p>----</p>
                <ul>
                  <li><p>Assess the situation: Take the time to understand the context of the situation and the potential consequences of being honest.</p></li>
                  <li><p>Consider the impact: Think about how your honesty will impact others, including colleagues, stakeholders, and the overall project.</p></li>
                  <li><p>Prepare for the conversation: Take the time to prepare for the conversation, considering how to articulate your thoughts in a clear and concise manner.</p></li>
                  <li><p>Be transparent: Be transparent and straightforward in your communication, taking care to explain your reasoning and to address any concerns that others may have.</p></li>
                  <li><p>Follow up: After the conversation, follow up with the relevant parties to ensure that your message was received and understood.</p></li>
                </ul>
              </CAccordionBody>
            </CAccordionItem>   
          </CAccordion>
        </CCardBody>
      </CCardBody>
    </CCard>        
  )
}

export default One