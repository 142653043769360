import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/UseRef';

function Ioc () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>IOC</strong></CCardHeader>
        <CCardBody>
          <CCard>
            <CCardBody>
              <p>-----</p>
            </CCardBody>
          </CCard><br/>
          <p>Spring IoC container is the program that injects dependencies into an object and make it ready for our use</p>
      
        </CCardBody>
      </CCard>        
    )
}

export default Ioc