import React from "react";
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
	
function Meaning () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Meaning</strong></CCardHeader>
			<CCardBody>
				<CAccordion flush>
				<CAccordionItem itemKey={1111}>
					<CAccordionHeader>May</CAccordionHeader>
					<CAccordionBody>    
							<ol>
								<li><strong>To a certain extent</strong>: partially or to a limited degree. It suggests that while something may be true or valid to a certain extent, it is not entirely or completely true or valid.
										<ul>
											<li>To a certain extent, I agree with your argument about the benefits of technology, but I also think it has its drawbacks</li>	
											<li>To a certain extent, I enjoyed the movie, but the ending was disappointing.</li>	
											<li>To a certain extent, I like spicy food," meaning that you enjoy some level of spiciness, but there is a limit beyond which it becomes too much for you to handle.</li>
											<li>To a certain extent, I believe in the importance of hard work," suggesting that while they do value hard work, they may not view it as the sole determinant of success.</li>
										</ul>																	
									</li>
									<li><strong>in a sense</strong>: something is true or valid to some degree, but not necessarily in the most literal or straightforward way; Overall, the phrase "in a sense" is often used to qualify or contextualize a statement, indicating that there may be more to the situation than meets the eye.
										<ul>
											<li>In a sense, all art is political," meaning that while not all art has an explicit political message, it can still reflect or comment on social and political issues in some way.</li>											
										</ul>																	
									</li>
									<li><strong>I will now turn it over to Lindo; Let me turn it back over to Lindo</strong></li>		
									<li><strong>Little over a month ago: </strong>: that slightly more than one month has passed since a particular event or moment in time. It is a way of indicating that it has been just over a month since something occurred, without specifying an exact number of days or weeks.
										<ul>
											<li>Little over a month ago, I moved to this new city</li>	
											<li>Little over a month ago, I started a new job and so far it's been a great experience.</li>	
										</ul>																	
									</li>
									<li><strong>Significance</strong>: refers to the importance, meaning, or relevance of something. It is a measure of how much impact or value something has, either in a particular context or in a broader sense.
										<ul>
											<li>The significance of the new policy is that it will greatly impact the company's bottom line and overall profitability.</li>	
											<li>The significance of education in shaping the future of individuals and societies cannot be overstated.</li>	
										</ul>																	
									</li>
									<li><strong>until and unless</strong>: until / till = up to a particular point in time; unless = if not x, then y;
									We use unless to indicate that if something does not happen, something else will happen as a result of this:

										<ul>
											<li>I can't refund you your money until and unless you send me original receipts.</li>	
											<li>I can't start work on this project unless and until the plans are approved.</li>	
										</ul>																	
									</li>
									<li><strong>I wish</strong>:  is often used to express a desire or longing for something that is not currently true or possible.
										<ul>
											<li>I wish I could travel the world." (Expressing a desire to travel).</li>	
											<li>I wish it would stop raining." (Expressing a desire for the rain to stop).</li>	
											<li>I wish I had more free time." (Expressing a desire for more leisure time).</li>	
										</ul>																	
									</li>
									<li><strong>I am doing this quite a while</strong></li>
									<li><strong>I was speaking to someone else.</strong></li>
									<li><strong>Do you mind if I sit here?</strong></li>
									<li><strong>Feel free to interrupt me if you have any doubts or questions.</strong></li>
									<li><strong>YOu may not know whatever i know</strong></li>
									<li><strong>Not everbody knows everything</strong></li>
									<li><strong>Apologies for the delayed response.</strong></li>																
							</ol>         
						</CAccordionBody>
					</CAccordionItem> 
				<CAccordionItem itemKey={2222}>
					<CAccordionHeader>Mar</CAccordionHeader>
					<CAccordionBody>    
							<ol>
								<li><strong>Transient</strong>: lasting only for a short time; impermanent.</li>		
								<li><strong>We are out of milk</strong></li>		
								<li><strong>Rigid</strong>: unable to bend or be forced out of shape; not flexible.</li>				
								<li><strong>Repeat after me</strong></li>				
								<li><strong>Have you done yet</strong></li>		
								<li><strong>who hasn't gone yet?</strong></li>			
								<li><strong>resilient</strong>: able to be happy, successful, etc. again after something difficult or bad has happened; able to return quickly to a previous good condition after problems:
										<ul>
											<li>She's a resilient girl - she won't be unhappy for long.</li>	
											<li>Life is hard there, but the people are resilient.</li>	
											<li>The US economy remains amazingly resilient.</li>
										</ul>																	
									</li>
									<li><strong>i think that's pretty much it</strong>: Almost completely; very nearly; mostly; more or less; basically</li>
									<li><strong>as for me</strong>: Using “as for me” at the beginning of a sentence would only make sense if it is a follow-up, alternative, or response to something someone else has said; used to talk about how another person or thing is affected by something:
										<ul>
											<li>as for me, my task is completed (status)</li>	
											<li>As for Louise, well, who cares what she thinks.</li>
										</ul>																	
									</li>
									<li><strong>ruin</strong>:to spoil or destroy something completely:
										<ul>
											<li>This issue will ruin our reputation</li>	
											<li>Her injury ruined her chances of winning the race.</li>
										</ul>																	
									</li>
									<li><strong>cover-up</strong>:put something on top of or in front of something in order to protect or conceal it; try to hide the fact of illegal or illicit activity.
										<ul>
											<li>They’re trying to cover up the truth.</li>	
											<li>The company tried to cover up its employment of illegal immigrants.</li>
										</ul>																	
									</li>
									<li><strong>have to drop for another call</strong>; sorry, i need to jump to another call; sorry need to drop off for another call</li>
									<li><strong>longer than usual</strong></li>
							</ol>         
						</CAccordionBody>
					</CAccordionItem> 
					<CAccordionItem itemKey={33333}>
							<CAccordionHeader>Feb</CAccordionHeader>
							<CAccordionBody>    
								<ol>
									<li><strong>Nutshell</strong>: Using a few words possible / summed up briefly
										<ul>
											<li>Just tell me the story in a nutshell</li>	
											<li>Explained the situation in a nutshell</li>	
											<li>"What went wrong?" "In a nutshell, everything."</li>
										</ul>																	
									</li>								
									<li><strong>Either way</strong>: whichever of two given alternatives is the case; used for saying that it does not matter which of two things happens or is true, because the result will be the same
										<ul>
											<li>You can get dallas by train or plane, but either way its very expensive</li>	
										</ul>	
									</li>		
									<li><strong>whereas</strong>:compared with the fact that; but:
										<ul>
											<li>He must be about 60, whereas his wife looks about 30.</li>
											<li>You eat a huge plate of food for lunch, whereas I have just a sandwich.</li>	
											<li>He works slowly and precisely whereas I tend to rush things and make mistakes.</li>	
										</ul>	
									</li>	
									<li><strong>What is going on</strong>:
										<ul>
											<li>What is happening here0</li>	
										</ul>	
									</li>
									<li><strong>No disrespect by any means</strong>:
										<ul>
											<li>No disrespect to John Beck, but the club has been happier since he left</li>	
										</ul>	
									</li>	
									<li><strong>with all due respect</strong>:
										<ul>
											<li>With all due respect, Sir, I cannot agree with your last statement.</li>	
											<li>I’ve been thinking about what you said and, with all due respect, I think you’re mistaken.</li>	
										</ul>	
									</li>
									<li><strong>startle</strong>:to move or jump suddenly
										<ul>
											<li>I'm sorry that I startled you.</li>	
											<li>I'm sorry, i didnt mean to startled you.</li>	
										</ul>	
									</li>
									<li><strong>Your voice sounds little familer</strong></li>
									<li><strong>in fact</strong>:actually; You use in fact, in actual fact, or in point of fact to indicate that you are giving more detailed information about what you have just said.
										<ul>
											<li>That sounds rather simple, but in fact it's very difficult.</li>	
											<li>The weather forecast said it would be sunny today, but in fact, it's raining outside</li>	
											<li>I thought John was from England, but in fact, he's from Ireland.</li>	
										</ul>	
									</li>
									<li><strong>Just an fyi</strong></li>
									<li><strong>Call off</strong>: cancel or abandon a planned event, activity, or arrangement
										<ul>
											<li>The soccer match was called off due to heavy rain</li>	
											<li>I had to call off our dinner plans because I got sick</li>	
											<li>The union agreed to call off the strike after the company agreed to increase wages.</li>	
										</ul>	
									</li>
									<li><strong>I moved to dallas about a year and half ago</strong></li>
									<li><strong>WindSheild</strong>: Front Glass</li>
									<li><strong>Rear-view mirror, rear view camera</strong></li>
									<li><strong>Plunger</strong></li>
									<li><strong>Rain shower</strong></li>			
									<li><strong>Air vent</strong></li>	
									<li><strong>To whom are you talking right now</strong>
										<ul>
											<li>Who are you talking to</li>	
											<li>Who are you talking with</li>	
										</ul>	
									</li>	
									<li><strong>Its very kind of you</strong>: if you want to thanks somebody
										<ul>
											<li>Thank you so much for doing that</li>	
										</ul>	
									</li>		
								</ol>         
							</CAccordionBody>
						</CAccordionItem>   
					<CAccordionItem itemKey={100}>
						<CAccordionHeader>Nov, 22</CAccordionHeader>
						<CAccordionBody>    
							<ol>
								<li><strong>Anticipation</strong>: expectation or prediction.
									<p>We don't anticipate any trouble.</p>
									<p>At this stage we can't really anticipate what will happen.</p>
									<p>No job cuts are anticipated under the new ownership.</p>
								</li>
								<li><strong>Explicitly</strong>: in a clear and detailed manner.
									<p>We were explicitly warned about what could happen.</p>
									<p>The map shows explicitly where the fire alarms are located.</p>
									<p>I told you explicitly to be home by midnight.</p>
								</li>
								<li><strong>Warm</strong>: 
									<p>You have to put the food in the oven to keep it warm</p>
									<p>Are you warm enough or do you want me to put the heating on?</p>
									<p>I put my hands in my pockets to keep them warm.</p>
									<p>Those gloves look nice and warm.</p>
								</li>		
								<li><strong>Baggy</strong> (of clothing) loose and hanging in folds.
									<p>baggy pants</p>
								</li>	
								<li><strong>Intermittent</strong> occurring at irregular intervals; not continuous or steady.
									<p>intermittent rain</p>	
								</li>		
								<li>
									<strong>Ripped</strong>
									<p>She was wearing ripped jeans.</p>
									<p>I've ripped my trousers - can you stitch them up for me?</p>
								</li>	
								<li><strong>When u get off the lift, turn left</strong></li>		
							</ol>         
						</CAccordionBody>
					</CAccordionItem>   
					<CAccordionItem itemKey={101}>
						<CAccordionHeader>Dec, 22</CAccordionHeader>
						<CAccordionBody>    
							<ol>
								<li><strong>More or Less</strong>: ---
									<p>---</p>								
								</li>
								<li><strong>trivia</strong>: details
									<p>---</p>								
								</li>
								<li><strong>as long as</strong>: ---
									<p>---</p>								
								</li>
								<li><strong>Such as </strong>: for example.
									<p>You will need some form of identification, such as a driver's license.</p>				
								</li>
							</ol>         
						</CAccordionBody>
					</CAccordionItem>          
				</CAccordion>
			</CCardBody>
		</CCard>
		
	)
}

export default Meaning