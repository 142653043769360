export const codeSnippet = {
  code1: `@Service
public class MyService {

  @Async
  public void doSomethingAsync() {
    // task that takes a long time to complete
  }
}`,
  code2: `@Configuration
@EnableAsync
public class AsyncConfiguration {
  // ...
}`,
  code3: `spring.main.allow-bean-definition-overriding=true`,
  code4:`@Service
public class MyService {

  @Async
  public Future<String> doSomethingAsync() {
    // task that takes a long time to complete
    return new AsyncResult<>("result");
  }
}`,
  code5:`@Service
public class MyService {

  @Async
  public void doSomethingAsync() {
    // task that takes a long time to complete
  }
}

@RestController
public class MyController {

  private final MyService myService;

  @Autowired
  public MyController(MyService myService) {
    this.myService = myService;
  }

  @GetMapping("/doSomethingAsync")
  public void doSomethingAsync() {
    myService.doSomethingAsync();
  }
}`,
  code6: `spring.task.execution.pool.core-size=4
spring.task.execution.pool.max-size=8
spring.task.execution.pool.queue-capacity=100
spring.task.execution.thread-name-prefix=my-executor-`,
  code7: `spring:
task:
  execution:
    pool:
      core-size: 4
      max-size: 8
      queue-capacity: 100
      thread-name-prefix: my-executor-`,
  code8: `spring.task.execution.pool.keep-alive=30`,
  code9: `@Configuration
@EnableAsync
public class AsyncConfiguration {

  @Value("\${spring.task.execution.pool.size}")
  private int poolSize;

  @Value("\${spring.task.execution.pool.name}")
  private String poolName;

  @Value("\${spring.task.execution.thread-name-prefix}")
  private String threadNamePrefix;

  @Value("\${spring.task.execution.timeout}")
  private long timeout;

  @Bean(name = "taskExecutor")
  public Executor taskExecutor() {
    ThreadPoolTaskExecutor executor = new ThreadPoolTaskExecutor();
    executor.setCorePoolSize(poolSize);
    executor.setThreadNamePrefix(threadNamePrefix);
    executor.setWaitForTasksToCompleteOnShutdown(true);
    return executor;
  }
}`,
}

