export const codeSnippet = {
	code1: `const componentStyles = {
	backgroundColor: 'blue',
	color: 'white',
	fontSize: '16px',
};

function MyComponent() {
	return <div style={componentStyles}>Styled Component</div>;
}`,
	code2: `npm install react css-loader`,
	code3: `/* MyComponent.module.css */
.container {
	background-color: blue;
	color: white;
	font-size: 16px;
}`,
	code4: `import React from 'react';
import styles from './MyComponent.module.css';

function MyComponent() {
	return <div className={styles.container}>Styled Component</div>;
}`
}

