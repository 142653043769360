import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../../utils/codeSnippet/cloud/Docker';

function Commands () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>Commands</strong></CCardHeader>
        <CCardBody>
          <CCardBody>
            <p><b>Pull / Install the docker image</b></p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter> 

            <p><b>List all the docker image</b></p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter> 

            <p><b>Delete the docker image</b></p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter> 

            <p><b>Run the docker image</b></p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter> 

            <p><b>List of running Docker containers</b></p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter> 
            <p>By default, docker ps shows only the running containers. Here are some useful options you can use with the command:</p>
            <ul>
              <li><p>-a or --all: Show all containers, including both running and stopped containers.</p></li>
              <li><p>-q or --quiet: Display only the container IDs.</p></li>
              <li><p>--filter: Apply filters to the container list based on various criteria such as status, name, label, and more.</p>
              <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter> </li>
            </ul>
            <p><b>To stop a running Docker container</b></p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
            
            <p><b>Seel the logs</b></p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter> 

            <p><b>To access the shell or command line interface inside a Docker container</b></p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter>

            <p><b>To build an image from this Dockerfile</b></p>
            <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code15}</SyntaxHighlighter>

          </CCardBody>
        </CCardBody>
      </CCard>        
    )
}

export default Commands