import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/CustomHooks';

function CustomHooks () {
    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>Custom Hooks</strong></CCardHeader>
        <CCardBody>
          <CCard>
            <CCardBody>
              <p>Introduction to <NavLink  to="/react/hooks" active>hooks</NavLink></p>
            </CCardBody>
          </CCard><br/>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
          <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

        </CCardBody>
      </CCard>        
    )
}

export default CustomHooks