import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Mongodb';

function Mongodb () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>@Bean</strong></CCardHeader>
      <CCardBody>
        <p>Spring Bean annotation is usually declared in <NavLink  to="/springBoot/Configuration">@Configuration</NavLink> classes methods.</p>
        
        <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
        <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
        <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
        <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
        <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>  
      </CCardBody>
    </CCard>        
    )
}

export default Mongodb