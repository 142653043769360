export const codeSnippet = {
  code1: `import java.util.concurrent.Callable;
import java.util.concurrent.ExecutorService;
import java.util.concurrent.Executors;
import java.util.concurrent.Future;

public class CallableExample {
  public static void main(String[] args) {
    // Create an ExecutorService to manage the threads
    ExecutorService executor = Executors.newFixedThreadPool(1);

    // Create a Callable task
    Callable<String> callableTask = () -> {
        // Perform the task
        Thread.sleep(2000);
        return "Task completed";
    };

    // Submit the task to the ExecutorService
    Future<String> future = executor.submit(callableTask);

    // Do some other work while the task is running asynchronously

    try {
        // Retrieve the result of the task
        String result = future.get();
        System.out.println(result);
    } catch (Exception e) {
        e.printStackTrace();
    }

    // Shut down the ExecutorService
    executor.shutdown();
  }
}`,
  code2: `import java.util.concurrent.Callable;
import java.util.concurrent.ExecutorService;
import java.util.concurrent.Executors;
import java.util.concurrent.Future;

public class MyCallable implements Callable<String> {
    @Override
    public String call() throws Exception {
        // Do some long-running task and return a result
        return "Result of the task";
    }
}

public class Main {
    public static void main(String[] args) throws Exception {
        // Create a new thread pool with a fixed number of threads
        ExecutorService executor = Executors.newFixedThreadPool(1);

        // Submit the callable to the executor and get a Future object
        Future<String> future = executor.submit(new MyCallable());

        // Wait for the result and print it
        String result = future.get();
        System.out.println(result);

        // Shutdown the executor
        executor.shutdown();
    }
}`,
}

