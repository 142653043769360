import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Configuration';

function Configuration () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>@Configuration</strong></CCardHeader>
        <CCardBody>
          <p>In Spring Boot, @Configuration is a class-level annotation that is used to indicate that a class contains one or more <NavLink to="springBoot/bean">@Bean</NavLink> methods that provide beans to be used in the application context. These @Bean methods may be used to configure the application context with objects that are created and managed by the Spring framework. For example, you can use @Configuration to create and register beans that represent data sources, JDBC templates, and other objects that are needed by your application.</p>

          <p>Here is an example of a simple @Configuration class:</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
          <p>In this example, the MyConfiguration class contains two <NavLink to="springBoot/bean">@Bean</NavLink> methods: dataSource and jdbcTemplate. The dataSource method creates and configures a DataSource object, while the jdbcTemplate method creates and configures a JdbcTemplate object, using the DataSource object created by the dataSource method.</p>

          <p>When the Spring Boot application is started, the @Configuration class will be processed and its @Bean methods will be used to configure the application context with the objects that they create. These objects can then be injected into other components of the application, such as controllers and services, using the @Autowired annotation.</p>
        </CCardBody>
      </CCard>        
    )
}

export default Configuration