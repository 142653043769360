import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../../utils/codeSnippet/cloud/Kubernetes';
import k8s_1 from '../../../assets/images/k8s_1.jpg';
import k8s_2 from '../../../assets/images/k8s_2.jpg';
import k8s_3 from '../../../assets/images/k8s_3.jpg';
import k8s_4 from '../../../assets/images/k8s_4.jpg';
import k8s_5 from '../../../assets/images/k8s_5.jpg';
import k8s_6 from '../../../assets/images/k8s_6.jpg';

function KubernetesDashboard () {

  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Dashboard</strong></CCardHeader>
			<CCardBody>
				<p>Kubernetes is an open-source <b>container orchestration platform</b> that automates the deployment, scaling, and management of containerized applications.</p>

				<CAccordion flush>
				<CAccordionItem itemKey={1}>
						<CAccordionHeader>Introduction</CAccordionHeader>
						<CAccordionBody>   							
							<img src={k8s_1} width="50%" height="50%" /><br/><br/>  
							<img src={k8s_2} width="50%" height="50%" /><br/><br/>  
							<img src={k8s_3} width="50%" height="50%" /><br/><br/>  
							<img src={k8s_4} width="50%" height="50%" /><br/><br/>    
							<img src={k8s_5} width="50%" height="50%" /><br/><br/>
							<img src={k8s_6} width="50%" height="50%" /><br/><br/>
						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={2}>
						<CAccordionHeader>Node</CAccordionHeader>
						<CAccordionBody>   							
						<p>In Kubernetes, a "node" refers to a physical or virtual machine that is part of a cluster. It is a worker machine where containers are scheduled and run. Each node has the necessary components to communicate with the master and execute tasks assigned to it.</p>
						<p>Here are the main components of a Kubernetes node:</p>
						<ul>
							<li><p><b>Kubelet:</b> It is an agent that runs on each node and is responsible for managing the containers on that node. It communicates with the Kubernetes master and receives instructions on which containers to run, monitors their health, and reports back to the master.</p></li>
							<li><p><b>Container runtime:</b> Kubernetes supports multiple container runtimes, such as Docker, containerd, or CRI-O. The container runtime is responsible for pulling container images from a registry, creating and managing containers, and providing an interface to interact with the underlying operating system's capabilities.</p></li>
							<li><p><b>Kube-proxy:</b> It is a network proxy that runs on each node and enables communication between containers within the cluster. Kube-proxy maintains network rules and performs load balancing for services running on the node.</p></li>
							<li><p><b>Pods:</b> A pod is the smallest unit of deployment in Kubernetes. It represents a group of one or more containers that are deployed together on a node. Pods share the same network namespace and can communicate with each other using localhost. Each pod is assigned a unique IP address within the cluster.</p></li>
							<li><p><b>Node components:</b> Each node also has additional components that facilitate its operation, such as the kube-proxy, kube-dns, and the container runtime. These components are responsible for networking, DNS resolution, and managing the lifecycle of containers on the node.</p></li>
						</ul>
						<p>When you deploy a workload in Kubernetes, such as a deployment or a statefulset, it is scheduled onto one or more nodes in the cluster. The Kubernetes scheduler considers factors like resource availability, constraints, and affinity rules to determine the appropriate node for each workload.</p>
						<p>Nodes can be added or removed from a cluster dynamically, allowing the cluster to scale up or down based on workload demands. Kubernetes ensures that the desired state of your application, as defined in the deployment or other resources, is maintained by automatically managing the scheduling and execution of containers across the available nodes.</p>
						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={3}>
						<CAccordionHeader>Service</CAccordionHeader>
						<CAccordionBody> 
							<p>In Kubernetes, a "Service" is an abstract layer that provides a stable network endpoint for accessing a group of pods. It acts as a load balancer and provides a consistent way to reach the pods regardless of their underlying IP addresses or the number of replicas.</p>
							<p>A Service has the following key characteristics:</p>
							<ul>
								<li><p><b>Stable network endpoint:</b> A Service is assigned a stable IP address and hostname within the cluster. This IP address remains unchanged even if the underlying pods are added, removed, or rescheduled. This allows other components within or outside the cluster to access the Service using a consistent address.</p></li>
								<li><p><b>Load balancing:</b> The Service automatically distributes incoming network traffic across the pods that are part of the Service. It ensures that the traffic is evenly spread and provides a scalable way to handle client requests.</p></li>
								<li><p><b>Service discovery:</b> Kubernetes provides DNS-based service discovery, which allows other components within the cluster to discover and communicate with Services using their DNS name. Each Service is assigned a DNS name in the cluster's internal DNS, which resolves to the Service's IP address.</p></li>
								<li><p><b>Selectors and labels:</b> Services are associated with pods based on labels and selectors. A label is a key-value pair attached to Kubernetes resources (such as pods). By specifying the label selector, the Service determines which pods belong to its group and should receive traffic.</p></li>
								<li><p><b>Service types:</b> Kubernetes supports different types of Services, including:</p>
									<ul>
										<li><p><b>ClusterIP:</b> The default type. It exposes the Service on a cluster-internal IP address, and the Service is only accessible from within the cluster.</p></li>
										<li><p><b>NodePort:</b> The Service exposes the Service on a static port on each node's IP address. It allows external access to the Service by listening on a specific port on each node.</p></li>
										<li><p><b>LoadBalancer:</b> The Service provisions an external load balancer (e.g., a cloud provider load balancer) that distributes traffic to the Service.</p></li>
										<li><p><b>ExternalName:</b> Maps the Service to an external DNS name without any proxying or load balancing. It is useful when you want to give a Service a DNS name that's external to the cluster.</p></li>								
									</ul>
								</li>
							</ul>
							<p>Services are crucial for enabling communication between different components within a Kubernetes cluster. They provide a level of abstraction, allowing applications to connect to other services without needing to know the specific IP addresses or details of the underlying pods.</p>
						</CAccordionBody>
					</CAccordionItem> 
					<CAccordionItem itemKey={4}>
						<CAccordionHeader>Ingress</CAccordionHeader>
						<CAccordionBody>
							<p>In Kubernetes, an "Ingress" is an API object that serves as a configurable entry point for external traffic into the cluster. It acts as a reverse proxy and provides routing rules to direct incoming requests to the appropriate services within the cluster based on the request's host or path.</p>
							<p>Here are the key aspects of Ingress in Kubernetes:</p>
							<ul>
								<li><p><b>Routing rules:</b> Ingress defines a set of routing rules that determine how incoming requests should be forwarded to backend services. These rules are based on the request's host or path. For example, you can configure Ingress to direct requests for example.com to a specific service and requests for api.example.com to another service.</p></li>
								<li><p><b>Load balancing:</b> Ingress performs load balancing by distributing incoming traffic across the backend services specified in the routing rules. It ensures that requests are routed to healthy and available instances of the target services.</p></li>
								<li><p><b>TLS termination:</b> Ingress supports secure communication by enabling TLS termination. You can configure TLS certificates to be used for encrypted communication between clients and Ingress. Ingress can terminate TLS and forward the decrypted traffic to backend services or pass through encrypted traffic to the services for end-to-end encryption.</p></li>
								<li><p><b>Host and path-based virtual hosting:</b> Ingress allows you to configure multiple hostnames and paths within a single Ingress resource. This enables virtual hosting, where multiple services can be exposed on different hostnames or paths using the same Ingress controller.</p></li>
								<li><p><b>Ingress controller:</b> An Ingress controller is a component responsible for implementing the Ingress rules and managing the traffic flow. It typically runs as a pod within the cluster and watches for changes in the Ingress resources. Examples of popular Ingress controllers include Nginx Ingress Controller, Traefik, and HAProxy Ingress.</p></li>								
							</ul>
							<p>To use Ingress in your Kubernetes cluster, you need to have an Ingress controller deployed and running. The Ingress controller listens to the Ingress resources and configures the underlying load balancer or reverse proxy accordingly.</p>
							<p>Once the Ingress controller is set up, you can create Ingress resources that define the routing rules for your cluster. These resources can be created using YAML or JSON manifests and are applied to the cluster using kubectl or other Kubernetes client tools.</p>
							<p>In summary, Ingress in Kubernetes provides an abstraction layer for managing inbound traffic to services within the cluster. It simplifies the configuration and management of external access to services and allows you to define routing rules and SSL termination, making it an essential component for exposing services to the outside world.</p>
						</CAccordionBody>
					</CAccordionItem> 
					<CAccordionItem itemKey={5}>
						<CAccordionHeader>Namespace</CAccordionHeader>
						<CAccordionBody>

						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={6}>
						<CAccordionHeader>ConfigMap</CAccordionHeader>
						<CAccordionBody>

						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={7}>
						<CAccordionHeader>Secret</CAccordionHeader>
						<CAccordionBody>

						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={8}>
						<CAccordionHeader>Volume</CAccordionHeader>
						<CAccordionBody>
							<p>In Kubernetes, a volume is a directory accessible to containers running in a pod. It provides a way to store and share data between containers in a pod or persist data across pod restarts. Volumes in Kubernetes are decoupled from the container's lifecycle, meaning that they can exist independently of the containers using them.</p>
							<p>Kubernetes supports various types of volumes, including:</p>
							<ol>
								<li><p><b>EmptyDir</b>: This volume is created when a pod is assigned to a node and exists for the lifetime of that pod. It is initially empty and can be used to share data between containers within the same pod.</p></li>
								<li><p><b>HostPath</b>: This volume mounts a file or directory from the host node's filesystem into the pod. It allows containers to access and modify files on the node's filesystem.</p></li>
								<li><p><b>PersistentVolumeClaim (PVC)</b>: This volume is used to request a dynamically provisioned storage volume from a storage class in the cluster. It provides a way to abstract the underlying storage implementation and allows for data persistence across pod restarts.</p></li>
								<li><p><b>ConfigMap</b>: This volume allows for the consumption of configuration data stored in ConfigMaps. ConfigMaps are used to store non-confidential configuration data as key-value pairs.</p></li>
								<li><p><b>Secret</b>: This volume allows for the consumption of sensitive data stored in secrets. Secrets are used to store confidential information such as passwords, API keys, or TLS certificates.</p></li>
							</ol>
						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={9}>
						<CAccordionHeader>Deployments</CAccordionHeader>
						<CAccordionBody>
						<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter> 
						<ul>
								<li><p><b>apiVersion</b> and kind specify the Kubernetes API version and resource type.</p></li>
								<li><p><b>metadata</b> contains the name of the deployment (my-spring-boot-app in this example).</p></li>
								<li>
									<p><b>spec</b> defines the desired state of the deployment.</p>
									<ul>
										<li><p><b>replicas</b> specifies the number of replicas (pods) you want to create.</p></li>
										<li><p><b>selector</b> specifies the labels used to match the pods controlled by this deployment.</p></li>
										<li>
												<p><b>template</b> describes the pod template for creating new pods.</p>
											</li>
											<ul>
												<li><p><b>metadata</b> contains labels for the pods.</p></li>
												<li>
														<p><b>spec</b> defines the specification for the pod.</p>
													</li>
													<ul>
														<li>
																<p><b>containers</b> lists the containers within the pod.</p>
															</li>
															<ul>
																<li><p><b>name</b> is the name of the container</p></li>
																<li><p><b>image</b> specifies the Docker image for the Spring Boot application.</p></li>
																<li><p><b>runAsUser</b> sets the user ID under which the container should run.</p></li>
																<li><p><b>ports</b> specifies the container port that should be exposed (e.g., port 8080 in this example).</p></li>																	
																<li><p><b>livenessProbe</b> specifies the liveness probe configuration.</p>
																		<ul>
																			<li><p><b>httpGet</b> defines an HTTP check to be performed.</p></li>
																			<li><p><b>path</b> specifies the endpoint path to be checked. Typically, /actuator/health is used to leverage Spring Boot's Actuator health endpoint.</p></li>
																			<li><p><b>port</b> specifies the port on which the application is running.</p></li>
																			<li><p><b>initialDelaySeconds</b> defines the number of seconds to wait before initiating the first liveness probe check.</p></li>
																			<li><p><b>periodSeconds</b> specifies the interval between successive liveness probe checks.</p></li>
																	</ul>
																</li>
																<li><p><b>readinessProbe </b> specifies the readiness probe configuration..</p>
																		<ul>
																			<li><p><b>httpGet</b> defines an HTTP check to be performed.</p></li>
																			<li><p><b>path</b> specifies the endpoint path to be checked. Typically, /actuator/health is used to leverage Spring Boot's Actuator health endpoint.</p></li>
																			<li><p><b>port</b> specifies the port on which the application is running.</p></li>
																			<li><p><b>initialDelaySeconds</b> defines the number of seconds to wait before initiating the first readiness probe check.</p></li>
																			<li><p><b>periodSeconds</b> specifies the interval between successive readiness probe checks.</p></li>
																	</ul>
																</li>	
																<li>
																	<p><b>resources</b></p>
																	<ul>
																	<li><p><b>requests</b> specify the minimum amount of resources that the container requires.</p>
																	<ul>
																		<li><p><b>cpu</b> specifies the CPU request in CPU units (e.g., millicores).</p></li>
																		<li><p><b>memory</b> specifies the memory request in bytes or a human-readable format (e.g., "Mi" for mebibytes).</p></li>
																	</ul>
																</li>			
																<li><p><b>limits</b> specify the maximum amount of resources that the container can consume..</p>
																	<ul>
																		<li><p><b>cpu</b> specifies the CPU limit in CPU units.</p></li>
																		<li><p><b>memory</b> specifies the memory limit.</p></li>
																	</ul>
																</li>	
																	</ul>
																</li>											
															</ul>
													</ul>
											</ul>
									</ul>
								</li>
							</ul>
							<p>livenessProbe checks if a container is alive, while the readinessProbe checks if a container is ready to receive traffic.</p>
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code13}</SyntaxHighlighter> 
							<p>Advantages of using Deployment over ReplicaSet in Kubernetes:</p>
							<ul>
								<li><p>Higher-level abstraction: Deployment is a higher-level Kubernetes object that manages ReplicaSets, making it easier to manage and control the lifecycle of your application.</p></li>
								<li><p>Rolling updates and rollbacks: Deployment provides built-in support for rolling updates, allowing you to update your application gradually and avoid downtime. If any issues occur during the update, you can easily roll back to the previous version. This feature ensures seamless application updates and reduces the risk of service disruptions.</p></li>
								<li><p>Replicas management: Deployment allows you to specify the desired number of replicas for your application, and it takes care of managing and maintaining the desired state. It can scale the number of replicas automatically based on the defined scaling policies or manual intervention.</p></li>
								<li><p>Version control and history: Deployment keeps track of the revision history of your application, allowing you to view and manage different versions. This feature is useful for auditing purposes, troubleshooting, and reverting to a previous known good state if needed.</p></li>
								<li><p>Declarative configuration: With Deployment, you define the desired state of your application using declarative configuration files. Kubernetes then takes care of the implementation details and ensures that the desired state is achieved and maintained.</p></li>
								<li><p>Fine-grained control: Deployment offers more fine-grained control over the update process compared to ReplicaSet. You can specify update strategies, such as rolling updates, recreate updates, or custom strategies, depending on your application requirements.</p></li>
								<li><p>Health checks and readiness probes: Deployment allows you to define health checks and readiness probes for your application. This ensures that only healthy pods are considered ready to serve traffic, improving overall application reliability.</p></li>
							</ul>
						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={10}>
						<CAccordionHeader>Statefullset</CAccordionHeader>
						<CAccordionBody>

						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={11}>
						<CAccordionHeader>ReplicaSet</CAccordionHeader>
						<CAccordionBody>
							<p>In Kubernetes, a ReplicaSet is a higher-level abstraction that ensures a specified number of pod replicas are running at all times. It is responsible for maintaining the desired state of a set of pods. If a pod fails or is deleted, the ReplicaSet creates new pods to replace them.</p>
						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={12}>
						<CAccordionHeader>Minikube</CAccordionHeader>
						<CAccordionBody>

						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={13}>
						<CAccordionHeader>Port forwarding</CAccordionHeader>
						<CAccordionBody>
							<p>Port forwarding is a feature in Kubernetes that allows you to access services running inside a cluster from your local machine. It establishes a connection between a port on your local machine and a port on a pod or service within the Kubernetes cluster.</p>
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code14}</SyntaxHighlighter> 
							<ul>
								<li><p><b>&lt;pod-name&gt; &lt;service-name&gt;</b>: The name of the pod/service you want to forward the port to.</p></li>
								<li><p><b>&lt;local-port&gt;</b>: The port on your local machine that you want to use to access the forwarded service.</p></li>
								<li><p><b>&lt;pod-port&gt;</b>: The port on the pod that you want to forward.</p></li>
							</ul>
							<p>After running the command, you can access the service running inside the pod on your local machine by accessing localhost:8888 in your web browser or making requests to http://localhost:8888 using tools like cURL or Postman.</p>
						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={14}>
						<CAccordionHeader>Ingress</CAccordionHeader>
						<CAccordionBody>
							<p>In Kubernetes, you can define multiple paths or host rules within an Ingress resource to handle different routing configurations. Here's an example of an Ingress definition with multiple matches:</p>
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code15}</SyntaxHighlighter> 
						</CAccordionBody>
					</CAccordionItem>
					<CAccordionItem itemKey={15}>
						<CAccordionHeader>HTTPProxy  - <b>&nbsp;IMP</b></CAccordionHeader>
						<CAccordionBody>
							<p>In Kubernetes, an HTTPProxy is a custom resource definition (CRD) provided by certain Ingress controllers, such as <b>Contour</b> or <b>Gloo</b>, that allows you to define more advanced routing configurations and apply additional features beyond what is available with the standard Ingress resource.</p>

							<p>With an HTTPProxy, you can define routes, filters, and other HTTP-related configurations to control how traffic is routed to your services. It offers more flexibility and fine-grained control compared to the basic Ingress resource.</p>

							<p>Here's an example of an HTTPProxy definition using the <b>Contour Ingress controller</b>:</p>
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code16}</SyntaxHighlighter> 
						</CAccordionBody>
					</CAccordionItem>					
					<CAccordionItem itemKey={16}>
						<CAccordionHeader>Difference between HTTPProxy and Ingres</CAccordionHeader>
						<CAccordionBody>
							<b>Ingress</b>
							<p>The main difference between HTTPProxy and Ingress in Kubernetes lies in their functionality and the features they provide:</p>
							<b>HTTPProxy</b>
							<p>HTTPProxy is a custom resource definition (CRD) provided by specific Ingress controllers like Contour or Gloo. It offers more advanced routing capabilities and additional features beyond what is available with the standard Ingress resource. HTTPProxy allows you to define fine-grained routing rules, including path-based routing, header-based routing, TCP/UDP routing, and more. It offers a higher level of flexibility and control over traffic routing compared to Ingress.</p>
							<ol>
								<li><p>Features: HTTPProxy provides more advanced routing capabilities, including path-based routing, header-based routing, TCP/UDP routing, traffic splitting, retries, timeouts, and more. Ingress offers basic routing rules and load balancing features.</p></li>
								<li><p>Customization: HTTPProxy allows you to define complex routing configurations using a custom resource definition. Ingress, on the other hand, has a predefined structure and limited options for customization.</p></li>
								<li><p>Controller Dependency: Ingress relies on external Ingress controllers to implement and enforce the routing rules defined in the Ingress resource. HTTPProxy is specific to certain Ingress controllers like Contour or Gloo, which provide native support for the HTTPProxy resource.</p></li>
								<li><p>Compatibility: Ingress is a standard Kubernetes resource and works with most Ingress controllers. HTTPProxy, being a custom resource, is specific to the Ingress controller that supports it. Therefore, the availability and usage of HTTPProxy may vary depending on the Ingress controller you are using.</p></li>
							</ol>
							<p>In summary, if you require advanced routing capabilities and additional features beyond what Ingress provides, and you are using an Ingress controller that supports HTTPProxy (like Contour or Gloo), using HTTPProxy can give you more flexibility and control over your traffic routing configuration. However, if the features provided by Ingress are sufficient for your requirements, using the standard Ingress resource with a compatible Ingress controller is a simpler and widely supported option.</p>
							<p>Ingress</p>
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code17}</SyntaxHighlighter> 
							<p>HTTPProxy</p>
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code18}</SyntaxHighlighter> 
						</CAccordionBody>
					</CAccordionItem>				
					<CAccordionItem itemKey={17}>
						<CAccordionHeader>HorizontalPodAutoscaler - <b>&nbsp;IMP</b></CAccordionHeader>
						<CAccordionBody>
							<p>HorizontalPodAutoscaler is a Kubernetes feature that automatically scales the number of pods in a Deployment or ReplicaSet based on the observed CPU utilization or other custom metrics. It helps in automatically adjusting the number of replicas of a workload to meet the desired performance or resource utilization targets.</p>
							<p>To configure HorizontalPodAutoscaler, you need to define the following:</p>
							<ol>
								<li><p>Define a Deployment or ReplicaSet: This is the workload that you want to scale based on the resource utilization.</p></li>
								<li><p>Configure resource metrics or custom metrics: HPA can scale based on CPU utilization or custom metrics. For CPU utilization, you can specify the target average utilization percentage. For custom metrics, you need to configure the custom metrics API and define the metric and target values.</p></li>
								<li><p>Set the minimum and maximum number of replicas: Define the minimum and maximum number of replicas that the HPA can scale to.</p></li>
							</ol>
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code19}</SyntaxHighlighter> 
							<p>In this example, the HorizontalPodAutoscaler is targeting the Deployment named my-app-deployment. It is configured to maintain a minimum of 1 pod and a maximum of 5 pods. The autoscaling is based on CPU utilization, with a target of 50% average CPU utilization.</p>
						</CAccordionBody>
					</CAccordionItem>							
					<CAccordionItem itemKey={18}>
						<CAccordionHeader>CronJob</CAccordionHeader>
						<CAccordionBody>
							<p>CronJob is a Kubernetes resource that allows you to schedule and run Jobs at specified intervals using cron-like syntax. It is useful for running recurring tasks or batch jobs in a Kubernetes cluster.</p>							
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code20}</SyntaxHighlighter> 
							<p>Once the CronJob is created, Kubernetes will automatically create and schedule Jobs based on the specified schedule. Each Job will run the defined container command and perform the specified task at the scheduled intervals.</p>

							<p>You can view the status and logs of the Jobs created by the CronJob using the kubectl commands, such as ,<b>kubectl get jobs</b>, <b>kubectl describe job</b> <b>&lt;job-name&gt;</b>, and <b>kubectl logs &lt;pod-name&gt;</b>.</p>
						</CAccordionBody>
					</CAccordionItem>						
					<CAccordionItem itemKey={19}>
						<CAccordionHeader>NetworkPolicy - <b>&nbsp;IMP</b></CAccordionHeader>
						<CAccordionBody>
							<p>NetworkPolicy is a Kubernetes resource that allows you to define rules for network connectivity and traffic flow within your cluster. It provides a way to enforce network segmentation and control the communication between different pods or namespaces.</p>
							<p>With NetworkPolicy, you can specify which pods are allowed to communicate with each other based on various criteria such as labels, namespaces, IP addresses, ports, and protocols. It helps you enforce security policies and restrict access to sensitive services or resources.</p>							
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code21}</SyntaxHighlighter> 	
							<p>In this example, the NetworkPolicy allows incoming traffic to pods labeled with app: my-app only from pods labeled with role: backend on port 8080 using the TCP protocol. It restricts access to the pods based on labels and port specifications.</p>
							<p>By applying NetworkPolicy to your Kubernetes cluster, you can enhance the security and isolation of your applications. It allows you to define fine-grained rules for network traffic and control the communication between different components of your application.</p>
							<p>Note that for NetworkPolicy to work, you need to have a network plugin that supports network policies installed in your Kubernetes cluster, such as Calico or Cilium.</p>		
							<p></p>
							<p>In Kubernetes Network Policies, there is a concept of controlling egress (outgoing) traffic from Pods using Egress Network Policies. Egress Network Policies allow you to define rules that specify which Pods can initiate outgoing connections to external destinations, such as external services, APIs, or databases. However, Egress Network Policies do not directly provide egress IP addresses.</p>
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code21_01}</SyntaxHighlighter> 	
			
						</CAccordionBody>
					</CAccordionItem>		
					<CAccordionItem itemKey={20}>
						<CAccordionHeader>Role</CAccordionHeader>
						<CAccordionBody>
							<p>In Kubernetes, a Role is a set of permissions that define what actions a user or service account can perform within a specific namespace. It is a fundamental resource for role-based access control (RBAC) in Kubernetes.</p>
							<p>Roles are used to grant access to specific resources and operations within a namespace. They define a set of rules that determine what a user or service account can do, such as creating, reading, updating, or deleting Kubernetes resources. By assigning different roles to different users or service accounts, you can control and limit their privileges within the cluster.</p>
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code22}</SyntaxHighlighter> 
							<p>In this example, the Role named "my-role" grants permissions to perform various operations on pods, deployments, and services within the "my-namespace" namespace. The verbs specified in the verbs field determine which actions are allowed, such as get, list, create, update, and delete. The apiGroups and resources fields define the Kubernetes API groups and resources to which the permissions apply.</p>
							<p>Roles can be associated with users or service accounts using RoleBindings or ClusterRoleBindings. A RoleBinding links a Role to a specific user or group within a namespace, while a ClusterRoleBinding associates a Role with a user or group across the entire cluster.</p>
							<p>By using Roles and RoleBindings, you can enforce fine-grained access control and ensure that users or service accounts only have the necessary permissions to perform their required tasks in Kubernetes.</p>
						</CAccordionBody>
					</CAccordionItem>			
					<CAccordionItem itemKey={21}>
						<CAccordionHeader>RoleBinding</CAccordionHeader>
						<CAccordionBody>
							<p>In Kubernetes, a RoleBinding is used to associate a Role with a user or group, granting them the permissions defined in the Role. It is a way to bind a set of permissions to a specific user or group within a namespace..</p>
							<p>A RoleBinding consists of two main components: the RoleRef and the subjects.</p>
							<ol>
								<li><p>RoleRef: This refers to the Role that the RoleBinding is binding to. It specifies the name of the Role and the type of the Role (Role or ClusterRole). The RoleRef determines the set of permissions that will be granted to the subjects.</p></li>
								<li><p>Subjects: These are the entities to which the RoleBinding is granting the permissions. Subjects can be users, groups, or service accounts. Each subject is defined by its kind, name, and namespace (if applicable).</p></li>
							</ol>
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code23}</SyntaxHighlighter> 
							<p>In this example, the RoleBinding named "my-role-binding" associates the Role named "my-role" with three subjects: a User with the email address "john@example.com", a Group named "marketing-team", and a ServiceAccount named "my-service-account" in the "my-namespace" namespace. The RoleBinding grants these subjects the permissions defined in the Role.</p>
							<p>By using RoleBindings, you can effectively manage access control in Kubernetes, assigning specific Roles to users, groups, or service accounts based on their responsibilities and needs within the cluster.</p>
						</CAccordionBody>
					</CAccordionItem>			
					<CAccordionItem itemKey={22}>
						<CAccordionHeader>Kustomization - <b>&nbsp;IMP</b></CAccordionHeader>
						<CAccordionBody>
							<p>A kustomization.yaml file is used with Kustomize, a tool for customizing Kubernetes configurations. Kustomize allows you to manage and customize Kubernetes resources without directly modifying the original resource manifests.</p>							
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code24}</SyntaxHighlighter> 	
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code25}</SyntaxHighlighter> 								
						</CAccordionBody>
					</CAccordionItem>	
					<CAccordionItem itemKey={23}>
						<CAccordionHeader>Service Mesh - <b>&nbsp;IMP</b></CAccordionHeader>
						<CAccordionBody>
							<p>In Kubernetes, a service mesh is a dedicated infrastructure layer that facilitates communication between microservices within a containerized application. It provides a set of tools and services for managing, monitoring, and securing the interactions between individual services, making it easier to build and maintain complex, distributed architectures.</p>			
							<ul>
								<li><p><b>Proxy Sidecar:</b></p>
									<p>A service mesh typically uses a sidecar proxy deployed alongside each microservice instance. The proxy intercepts and manages communication between microservices.</p>
								</li>
								<li>
									<p><b>Data Plane and Control Plane:</b></p>
									<p>The components responsible for handling actual data traffic are part of the data plane. This includes the sidecar proxies.</p>
									<p>The control plane is responsible for configuring and managing the proxies. It includes components for service discovery, routing rules, and policy enforcement.</p>
								</li>
								<li>
									<p><b>Service Discovery:</b></p>
									<p>Service mesh solutions enhance Kubernetes' built-in service discovery mechanisms. They provide dynamic service discovery, allowing services to locate and communicate with each other.</p>
								</li>
								<li>
									<p><b>Load Balancing:</b></p>
									<p>Service mesh solutions implement load balancing to distribute traffic evenly across instances of a microservice. This ensures efficient resource utilization and high availability.</p>
								</li>
								<li>
									<p><b>Traffic Management:</b></p>
									<p>Service mesh enables sophisticated traffic management strategies, including canary deployments, A/B testing, and gradual rollouts. It allows for fine-grained control over how traffic is directed between microservices.</p>
								</li>
								<li>
									<p><b>Observability:</b></p>
									<p>Service mesh solutions provide built-in observability tools for monitoring and troubleshooting. This includes metrics, logs, and distributed tracing to gain insights into the behavior of microservices.</p>
								</li>
								<li>
									<p><b>Security</b></p>
									<p>Service mesh enhances security by handling tasks such as encryption/decryption of traffic between microservices, authentication, and authorization. It helps enforce security policies consistently.</p>
								</li>
								<li>
									<p><b>Dynamic Configuration:</b></p>
									<p>Service mesh solutions support dynamic configuration, allowing them to adapt to changes in the environment. This includes automatic updates of routing rules, service discovery, and other configurations.</p>
								</li>								
							</ul>		
							<p>Popular service mesh implementations for Kubernetes include Istio, Linkerd, and Consul. These solutions provide a set of abstractions and features that simplify the development, deployment, and maintenance of microservices-based applications.</p>		
						</CAccordionBody>
					</CAccordionItem>				
					<CAccordionItem itemKey={24}>
						<CAccordionHeader>Istio - Serevice Mesh</CAccordionHeader>
						<CAccordionBody>
							<p><b>Review Application:</b></p>			
							<p>Application Code (ReviewController.java):</p>		
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code26}</SyntaxHighlighter> 
							<p>application.yaml for Review App:</p>	
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code27}</SyntaxHighlighter> 		
							<p><b>Comment Application:</b></p>					
							<p>Application Code (CommentController.java):</p>	
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code28}</SyntaxHighlighter> 	
							<p>application.yaml for Comment App:</p>	
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code29}</SyntaxHighlighter> 	
							<p><b>Istio Setup:</b></p>
							<p>Istio VirtualService for Routing:</p>
							<SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code30}</SyntaxHighlighter> 	
						</CAccordionBody>
					</CAccordionItem>			
				</CAccordion>
			</CCardBody>
    </CCard>        
  )
}

export default KubernetesDashboard;