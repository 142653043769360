import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'


function UseState () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Hooks</strong></CCardHeader>
			<CCardBody>
				<p>A Hook is a special function that lets you “hook into” React features. For example, useState is a Hook that lets you add React state to function components.</p>
				<strong><ins>Rules of Hooks</ins></strong><br/><br/>
				<strong>1. Only Call Hooks at the Top Level</strong>
				<p>Don't call Hooks inside loops, conditions, or nested functions. Instead, always use Hooks at the top level of your React function, before any early returns. By following this rule, you ensure that Hooks are called in the same order each time a component renders. That's what allows React to correctly preserve the state of Hooks between multiple useState and useEffect calls.</p>

				<strong>2. Only Call Hooks from React Functions</strong>
				<p>Don't call Hooks from regular JavaScript functions. Instead, you can:</p>
				<em>Call Hooks from React function components.<br/>Call Hooks from custom Hooks</em>
				<p></p>
			</CCardBody>
	</CCard>
			
	)
}

export default UseState