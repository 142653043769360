export const codeSnippet = {
    code1: `function Greet({ name }) {
const message = 'Hello, '+ name; // Calculates output
// Bad!
document.title = 'Greetings to '+ name; // Side-effect!
return <div>{message}</div>;       // Calculates output
}`,
    code2: `import { useEffect } from 'react';
function Greet({ name }) {
  const message = 'Hello, '+name;   // Calculates output
  useEffect(() => {
    // Good!
    document.title = 'Greetings to '+name; // Side-effect!
  }, [name]);
  return <div>{message}</div>;         // Calculates output
}`,
    code3: `useEffect(callback[, dependencies]);`,
    code4: `import { useEffect } from 'react';
function MyComponent() {
  useEffect(() => {
    // Runs after EVERY rendering
  });  
}`,
    code5: `import { useEffect } from 'react';
function MyComponent() {
  useEffect(() => {
    // Runs ONCE after initial rendering
  }, []);
}`,
    code6: `import { useEffect, useState } from 'react';
function MyComponent({ prop }) {
  const [state, setState] = useState('');
  useEffect(() => {
    // Runs ONCE after initial rendering
    // and after every rendering ONLY IF 'prop' or 'state' changes
  }, [prop, state]);
}`,
    code7: `import { useEffect } from 'react';
function Greet({ name }) {
  const message = 'Hello, '+name;
  useEffect(() => {
    // Runs once, after mounting
    document.title = 'Greetings page';
  }, []);
  return <div>{message}</div>;
}`,
    code8: `// First render
<Greet name="Eric" />   // Side-effect RUNS
// Second render, name prop changes
<Greet name="Stan" />   // Side-effect DOES NOT RUN
// Third render, name prop changes
<Greet name="Butters"/> // Side-effect DOES NOT RUN`,
    code9: `import { useEffect } from 'react';
function MyComponent({ prop }) {
  const [state, setState] = useState();
  useEffect(() => {
    // Side-effect uses 'prop' and 'state'
  }, [prop, state]);
  return <div>....</div>;
}`,
    code10: `import { useEffect } from 'react';
function Greet({ name }) {
  const message = 'Hello, '+name;
  useEffect(() => {
    document.title = 'Greetings to '+name; 
  }, [name]);
  return <div>{message}</div>;
}`,
    code11: `// First render
<Greet name="Eric" />   // Side-effect RUNS
// Second render, name prop changes
<Greet name="Stan" />   // Side-effect RUNS
// Third render, name prop doesn't change
<Greet name="Stan" />   // Side-effect DOES NOT RUN
// Fourth render, name prop changes
<Greet name="Butters"/> // Side-effect RUNS`,
    code12: `useEffect(() => {
  const abortCont = new AbortController();

  setTimeout(() => {
    fetch(url, { signal: abortCont.signal })
    .then(res => {
      if (!res.ok) { // error coming back from server
        throw Error('could not fetch the data for that resource');
      } 
      return res.json();
    })
    .then(data => {
      setIsPending(false);
      setData(data);
      setError(null);
    })
    .catch(err => {
      if (err.name === 'AbortError') {
        console.log('fetch aborted')
      } else {
        // auto catches network / connection error
        setIsPending(false);
        setError(err.message);
      }
    })
  }, 1000);

  // abort the fetch
  return () => abortCont.abort();
  }, [url])
}`,
}

