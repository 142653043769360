import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Async';

function Async () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>@Async</strong></CCardHeader>
        <CCardBody>
          <p>In Spring, the @Async annotation can be used to mark a method as asynchronous, meaning it will be executed in a separate thread. This can be useful for tasks that take a long time to complete, as it allows the main thread to continue processing without being blocked.</p>

          <p>Here's an example of how you might use @Async in a Spring Boot application:</p>

          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

          <p>To use @Async, you must also enable asynchronous processing in your Spring configuration. This can be done by adding the @EnableAsync annotation to your configuration class:</p>

          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

          <p>Alternatively, you can enable asynchronous processing by adding the <strong><i>spring.main.allow-bean-definition-overriding=true</i></strong> property to your application's application.properties file.</p>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>

          <p>Once asynchronous processing is enabled, you can use the @Async annotation to mark methods as asynchronous. When an asynchronous method is called, it will be executed in a separate thread, and the calling thread will continue without being blocked.</p>

          <p>It's important to note that @Async methods must be <strong><i>void</i></strong> or return a <strong><i>java.util.concurrent.Future</i></strong>. If a return value is needed, you can use the java.util.concurrent.Future object to retrieve the result of the asynchronous method once it has completed.</p>

          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>

          <p>There are several properties that can be configured when using @EnableAsync in a Spring Boot application:</p>
          <ol>
            <li><p>spring.task.execution.pool.size: This property specifies the size of the thread pool to be used for executing asynchronous tasks. By default, the thread pool size is equal to the number of available processors on the system.</p></li>
            <li><p>spring.task.execution.pool.name: This property specifies the name of the thread pool to be used for executing asynchronous tasks.</p></li>
            <li><p>spring.task.execution.thread-name-prefix: This property specifies the prefix to be used for the names of threads created for executing asynchronous tasks.</p></li>
            <li><p>spring.task.execution.timeout: This property specifies the maximum amount of time that an asynchronous task is allowed to run before it is cancelled.</p></li>
          </ol>
          <p>You can configure these properties in your application's <u>application.properties</u> file:</p>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
          <p>Alternatively, you can configure these properties using the spring.task.execution.pool prefix in the application.yml file:</p>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
          <p>You can also use the spring.task.execution.pool.keep-alive property to specify the time (in seconds) that idle threads should be kept alive before being terminated. By default, this is set to 60.</p>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter>
          
        </CCardBody>
      </CCard>        
    )
}

export default Async