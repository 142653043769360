import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/UseRef';

function SpringBootApplication () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>@SpringBootApplication</strong></CCardHeader>
        <CCardBody>
          <CCard>
            <CCardBody>
              <p>-----</p>
            </CCardBody>
          </CCard><br/>
          <p>The <strong>@SpringBootApplication</strong> annotation is a convenience annotation that is equivalent to declaring @Configuration, @EnableAutoConfiguration, and @ComponentScan annotations. These annotations are all part of the Spring Framework and are used to enable various features in a Spring Boot application.</p>

          <p><strong><NavLink to="/springBoot/configuration">@Configuration</NavLink></strong> is used to indicate that a class contains bean definitions for the application context. These bean definitions can be used to specify dependencies and other configuration details for the application.</p>

          <p><strong><NavLink to="/springBoot/enableAutoConfiguration">@EnableAutoConfiguration</NavLink></strong> tells Spring Boot to automatically configure the application based on the dependencies that have been added to the classpath. This means that the developer does not have to explicitly configure every component in the application, which can save time and make it easier to get up and running quickly.</p>

          <p><strong><NavLink to="/springBoot/componentScan">@ComponentScan</NavLink></strong> is used to scan for Spring components, including @Component, @Service, and @Repository annotated classes. This allows Spring to automatically register these components with the application context, making them available for dependency injection and other Spring features.</p>

          <p>By using the @SpringBootApplication annotation, you can enable all of these features in a single step, which can make it easier to set up and configure a Spring Boot application.</p>
        
      
        </CCardBody>
      </CCard>        
    )
}

export default SpringBootApplication