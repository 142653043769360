import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react';
	import sellingCall1 from '../../assets/images/sellingCall1.png'
	import sellingCall2 from '../../assets/images/sellingCall2.png'
	import sellingCall3 from '../../assets/images/sellingCall3.png'

function SellingCall () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Selling a Call - Short Call - Credit Received</strong></CCardHeader>
			<CCardBody>
				<p><strong>Obligation</strong> to sell the underlying asset at a specific price on or before a specific date.
The price of the underlying security must fall below the strike price. If it does, the buyer won't exercise the option.</p>
				<p><strong>Sell 1 OTM Call</strong> (The more conservative you are, the further OTM you can sell the call option)
Note: <strong>Don't sell ITM, ATM Call</strong> (Bigger risk)</p>
				<ul>
					<li>Bearish</li>
					<li style={{color: 'green'}}>Positive Theta.</li>
					<li>Limited Profit.</li>
					<li>Unlimited Loss.</li>
					<li>High POP%. (Probability of profit).</li>
					<li><strong>Good for Indexes.</strong> (Individual stocks are more risky, because they fluctuate more.)</li>
					<li>Sell deep OTM with historical probabilities of success between 90-95%.</li>
					<li>Avoid selling close to the market and in low volatility.</li>
				</ul>
				<strong><p>Break-even point = Call Strike + Premium Received</p></strong>				
				<p>Let's take a look at a short call's Greek exposures to help explain the strategy: </p>

				<CTable bordered>
					<CTableBody>
						<CTableRow>
							<CTableHeaderCell scope="row">Delta</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'red'}}>Negative</CTableHeaderCell>
							<CTableDataCell>Call prices rise when the stock price increases, which is harmful to short call traders. However, call prices fall when the stock price falls, which benefits short call traders.</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">Gamma</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'red'}}>Negative</CTableHeaderCell>
							<CTableDataCell>A short call's position delta browses more towards -100 as the stock price rises, and falls towards 0 as the stock price decreases.</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">Theta</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'green'}}>Positive</CTableHeaderCell>
							<CTableDataCell>The extrinsic value of options decays over time, leading to decreasing option prices (great for call sellers).</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">Vega</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'red'}}>Negative</CTableHeaderCell>
							<CTableDataCell>Increases in IV indicate an increase in option prices, which is bad for short call traders. <strong>Decreases in IV indicate falling option prices</strong>, which is great for short call traders.</CTableDataCell>
						</CTableRow>
					</CTableBody>
				</CTable><br/>
				<img src={sellingCall1} width="32%" style={{padding: '5px'}}/>
				<img src={sellingCall2} width="32%" style={{padding: '5px'}}/>
				<img src={sellingCall3} width="32%" style={{padding: '5px'}}/>
				<br/><br/>
			</CCardBody>
	</CCard>	
	)
}

export default SellingCall