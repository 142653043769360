export const codeSnippet = {
  code1: `public class MyRunnable implements Runnable {
  public void run() {
      // code to be executed in the new thread
  }
}

public class Main {
  public static void main(String[] args) {
      Runnable runnable = new MyRunnable();
      Thread thread = new Thread(runnable);
      thread.start();
  }
}`,
  code2: `public class Main {
  public static void main(String[] args) {
      Runnable runnable = () -> {
          // code to be executed in the new thread
      };
      Thread thread = new Thread(runnable);
      thread.start();
  }
}`,
  code3: `public class MyThread extends Thread {
  @Override
  public void run() {
      // code to be executed in the new thread
  }
}

public class Main {
  public static void main(String[] args) {
      Thread thread = new MyThread();
      thread.start();
  }
}`,
  code4: `import java.util.concurrent.ExecutorService;
import java.util.concurrent.Executors;

public class Main {
    public static void main(String[] args) {
        ExecutorService executor = Executors.newFixedThreadPool(5);
        for (int i = 0; i < 10; i++) {
            int task = i;
            executor.execute(() -> {
                System.out.println("Running task " + task);
            });
        }
        executor.shutdown();
    }
}`,
  code5: `import java.util.concurrent.ExecutorService;
import java.util.concurrent.Executors;
import java.util.concurrent.Future;

public class Main {
  public static void main(String[] args) {
    ExecutorService executor = Executors.newSingleThreadExecutor();
    
    // Submit task using submit method
    Future<String> future = executor.submit(() -> {
        // code to be executed asynchronously
        return "Result of task";
    });
    
    // Submit task using execute method
    executor.execute(() -> {
        // code to be executed asynchronously
    });
    
    executor.shutdown();
  }
}`,
  code6: `import java.lang.Runtime;

public class Main {
  public static void main(String[] args) {
    int cores = Runtime.getRuntime().availableProcessors();
    System.out.println("Number of cores: " + cores);
  }
}`,
  code7: `import java.util.concurrent.*;

public class FutureExample {
  public static void main(String[] args) throws Exception {
    ExecutorService executor = Executors.newSingleThreadExecutor();
    Future<Integer> future = executor.submit(() -> {
      try {
          TimeUnit.SECONDS.sleep(2);
          return 123;
      } catch (InterruptedException e) {
          throw new IllegalStateException("Task interrupted", e);
      }
    });
    System.out.println("Do something while task is executing");
    System.out.println("Task result: " + future.get());
    executor.shutdown();
  }
}`
}

