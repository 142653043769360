import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/ComponentScan';

function ComponentScan () {

    return (      
        <CCard className="mb-4">
			  <CCardHeader><strong>@ComponentScan</strong></CCardHeader>
        <CCardBody>
          <p>@ComponentScan is a Spring annotation that is used to specify the packages to scan for annotated components. By default, the @SpringBootApplication annotation enables component scanning, so you do not need to use @ComponentScan unless you want to specify specific packages to scan or exclude certain packages from the scan.</p>
          <p>Here is an example of how you can use @ComponentScan to specify a package to scan:</p>
          
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

          <p>This will scan the com.example.mypackage package for annotated components, such as @Controller, @Service, and @Repository annotated classes.</p>

          <p>You can also use the basePackages attribute of @ComponentScan to specify multiple packages to scan, like this:</p>

          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

          <p>Additionally, you can use the basePackageClasses attribute to specify specific classes whose package will be used as the base for the component scan. This can be useful if you want to avoid hardcoding package names in your code.</p>
          
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
        </CCardBody>
      </CCard>        
    )
}

export default ComponentScan