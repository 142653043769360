export const codeSnippet = {
	code1: `<form #form="ngForm" (ngSubmit)="onSubmit(form)">
  <input type="text" name="name" [(ngModel)]="name">
  <input type="email" name="email" [(ngModel)]="email">
  <button type="submit">Submit</button>
</form>`,
  code2: `import { Component } from '@angular/core';

@Component({
  selector: 'app-template-driven-form',
  templateUrl: './template-driven-form.component.html',
})
export class TemplateDrivenFormComponent {
  name: string;
  email: string;

  onSubmit(form: NgForm) {
    console.log(form.value);
  }
}`,
  code3: `import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
selector: 'app-reactive-form',
template: '
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <input type="text" formControlName="name">
    <input type="email" formControlName="email">
    <button type="submit">Submit</button>
  </form>
',
})
export class ReactiveFormComponent implements OnInit {
  form: FormGroup;

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl(),
      email: new FormControl(),
    });
  }

  onSubmit() {
    console.log(this.form.value);
  }
}`
}