import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  // CTableHead,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
	
import javaCollection from '../../assets/images/java-collection-hierarchy.png'
import { NavLink } from "react-router-dom";

function Collection () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Collection (interface)</strong></CCardHeader>
			<CCardBody>
				<p>Collection is the interface where you group objects into a single unit. It is used to represent a group of individual objects as a single unit.</p>
				<p>The Collection interface is a member of the Java Collections Framework. It is a part of java.util package. It is one of the root interfaces of the Collection Hierarchy. The Collection interface is not directly implemented by any class. However, it is implemented indirectly via its subtypes or subinterfaces like List, Queue, and Set. </p>
				<p>student 1 obj, student 2 obj, student 2 obj -  is called 1 collection.</p>
				<p>Java Collection means a single unit of objects. Java Collection framework provides many interfaces (Set, List, Queue, Deque) and classes (ArrayList, Vector, LinkedList, PriorityQueue, HashSet, LinkedHashSet, TreeSet).</p>

				<img src={javaCollection} style={{width:'50%'}}/><br/><br/>

				<p><NavLink>List</NavLink>, <NavLink>Set</NavLink>, <NavLink>Queue</NavLink>, <NavLink>Map</NavLink> - map not realted to collection</p>

				<p><em>Methods avaliable in Collection interface,</em> all the methods can access by child interface</p>
				<CTable bordered>
					<CTableBody>
						<CTableRow>
							<CTableHeaderCell scope="row">add(Object)</CTableHeaderCell>
							<CTableDataCell>This method is used to add an object to the collection.</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">addAll(Collection c)</CTableHeaderCell>
							<CTableDataCell>This method adds all the elements in the given collection to this collection.</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">remove(Object o)</CTableHeaderCell>
							<CTableDataCell>This method is used to remove the given object from the collection. If there are duplicate values, then this method removes the first occurrence of the object</CTableDataCell>
						</CTableRow>				
						<CTableRow>
							<CTableHeaderCell scope="row">removeAll(Collection c)</CTableHeaderCell>
							<CTableDataCell>This method is used to remove all the objects mentioned in the given collection from the collection.</CTableDataCell>
						</CTableRow>		
						<CTableRow>
							<CTableHeaderCell scope="row">retainAll(Collection c)</CTableHeaderCell>
							<CTableDataCell>This method is used to retain only the elements in this collection that are contained in the specified collection.</CTableDataCell>
						</CTableRow>		
						<CTableRow>
							<CTableHeaderCell scope="row">size()</CTableHeaderCell>
							<CTableDataCell>This method is used to return the number of elements in the collection.</CTableDataCell>
						</CTableRow>		
						<CTableRow>
							<CTableHeaderCell scope="row">isEmpty()</CTableHeaderCell>
							<CTableDataCell>This method returns true if this collection contains no elements.</CTableDataCell>
						</CTableRow>		
						<CTableRow>
							<CTableHeaderCell scope="row">clear()</CTableHeaderCell>
							<CTableDataCell>This method removes all of the elements from this collection.</CTableDataCell>
						</CTableRow>		
						<CTableRow>
							<CTableHeaderCell scope="row">contains(Object o)</CTableHeaderCell>
							<CTableDataCell>This method returns true if the collection contains the specified element.</CTableDataCell>
						</CTableRow>		
						<CTableRow>
							<CTableHeaderCell scope="row">containsAll(Collection c)</CTableHeaderCell>
							<CTableDataCell>This method returns true if the collection contains all of the elements in the given collection.</CTableDataCell>
						</CTableRow>								
					</CTableBody>
				</CTable>				

			</CCardBody>
	</CCard>
			
	)
}

export default Collection