import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/Value';

function Value () {
    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>@Value</strong></CCardHeader>
        <CCardBody>
          <p>In Spring Boot, the @Value annotation is used to inject a property value from a configuration file (such as application.properties) into a field, method, or constructor argument.</p>
          <p>Here is an example of how you might use @Value in a Spring Boot application:</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>   
          <p>In this example, the myProperty field is annotated with @Value($&#123;my.property&#125;), which tells Spring to inject the value of the my.property property from the configuration file into the field.</p>   
          <p>You can also use the @Value annotation to inject property values into method arguments and constructor arguments. For example:</p>
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>   
        </CCardBody>
      </CCard>        
    )
}

export default Value