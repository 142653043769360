import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/AboutController';

function AbortController() {
    return (
        <CCard className="mb-4">
            <CCardHeader><strong>AbortController</strong></CCardHeader>
            <CCardBody>
                
                <p>As we know, fetch returns a promise. And JavaScript generally has no concept of “aborting” a promise. So how can we cancel an ongoing fetch? E.g. if the user actions on our site indicate that the fetch isn’t needed any more.</p>
                <p>There’s a special built-in object for such purposes: AbortController. It can be used to abort not only fetch, but other asynchronous tasks as well.</p>
                
                <p>The usage is very straightforward:</p>

                <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

                <p>A controller is an extremely simple object.</p>
                <ul>
                    <li>It has a single method abort(),</li>
                    <li>And a single property signal that allows to set event listeners on it.</li>
                </ul>
                
                <p>When abort() is called:</p>

                <ul>
                    <li>controller.signal emits the "abort" event.</li>
                    <li>controller.signal.aborted property becomes true.</li>
                </ul>

                <p>Generally, we have two parties in the process:</p>

                <ul>
                    <li>The one that performs a cancelable operation, it sets a listener on controller.signal.</li>
                    <li>The one that cancels: it calls controller.abort() when needed.</li>
                </ul>
                
                <p>Here’s the full example (without fetch yet):</p>
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

                <p>As we can see, AbortController is just a mean to pass abort events when abort() is called on it.</p>
                <p>We could implement the same kind of event listening in our code on our own, without the AbortController object.</p>
                <p>But what’s valuable is that fetch knows how to work with the AbortController object. It’s integrated in it.</p>

                <h5><strong>Using with fetch</strong></h5>
                <p>To be able to cancel fetch, pass the signal property of an AbortController as a fetch option:</p>
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
                <p>The fetch method knows how to work with AbortController. It will listen to abort events on signal.</p>
                <p>Now, to abort, call controller.abort():</p>
                <SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>

                <p>We’re done: fetch gets the event from signal and aborts the request.</p>
                <p>When a fetch is aborted, its promise rejects with an error AbortError, so we should handle it, e.g. in try..catch.</p>
                <p>Here’s the full example with fetch aborted after 1 second:</p>
                <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>

            </CCardBody>
        </CCard>
    )
}

export default AbortController;