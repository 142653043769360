import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/angular/Resolve';

function Resolve () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Resolve</strong></CCardHeader>
      <CCardBody>    
        <p>Resolve is a feature in Angular that allows you to retrieve data before a route is activated. It allows you to pre-fetch data that is needed for the component associated with a route, and make it available to the component as soon as it is activated. This can improve the user experience by allowing the component to display data immediately, rather than waiting for an HTTP request to complete.</p>
        <p>A Resolve is a class that implements the Resolve interface, which defines a single method resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) that should return an observable, a promise, or a value. The method is called by the router before the route is activated, and its purpose is to retrieve the data that the component needs to display.</p>
        <p>Here is an example of how to create a Resolve that fetches a product by its id:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
        <p>In this example, the ProductResolver class implements the Resolve interface, and its resolve() method is called by the router before the route is activated. The method uses the ProductService service to fetch a product by its id, and returns an observable of the product.</p>
        <p>To use the ProductResolver in a route, you need to add it to the resolve property of the route configuration. Here is an example of how to use the ProductResolver in the route configuration:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
        <p>In this example, the ProductResolver is added to the resolve property of the route, and the data that it returns is made available to the ProductComponent component via the ActivatedRoute service as an observable.</p>
        <p>It's important to note that if the resolve() method returns an error or an empty observable, the router will not activate the route, and instead will redirect to a path specified in the Router configuration or show a 404 page.</p>
      </CCardBody>
    </CCard>        
  )
}

export default Resolve;