import React from "react";
import { CCard, CCardHeader, CCardBody} from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/java/Polymorphism'
	
function Polymorphism () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Polymorphism</strong></CCardHeader>
			<CCardBody>
				<p>Polymorphism means "many forms", and it occurs when we have many classes that are related to each other by inheritance. (In Java, polymorphism refers to the ability of an object to take on multiple forms.)</p>			
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
			</CCardBody>
	</CCard>
			
	)
}

export default Polymorphism