export const codeSnippet = {
	code1: `Optional<String> optional = Optional.ofNullable("Hello");
if (optional.isPresent()) {
  System.out.println(optional.get());  // prints "Hello"
}`,
	code2: `Optional<String> optional1 = Optional.of("value");
Optional<String> optional2 = Optional.ofNullable(null); //empty optional

if (optional1.isPresent()) {
  String value = optional1.get();
  System.out.println("Value is present: " + value);
} else {
  System.out.println("Value is not present");
}

String defaultValue = "default";
String value = optional2.orElse(defaultValue);
System.out.println(value);  //print default`,
  code3: `public Optional<String> lookupValue(int id) {
  //...lookup code 
  if (value != null) {
    return Optional.of(value);
  } else {
    return Optional.empty();
  }
}

Optional<String> result = lookupValue(5);
if (result.isPresent()) {
  String value = result.get();
  System.out.println("Value is present: " + value);
} else {
  System.out.println("Value is not present");
}`,
  code4: `Thread t = new Thread(new Runnable() {
  public void run() {
      System.out.println("Running");
  }
});`,
  code5: `Thread t = new Thread(()->System.out.println("Running"));
t.start();
or
t.run();`,
  code6: `String str1 = new String("hello");
String str2 = new String("hello");
System.out.println(str1 == str2); // false
System.out.println(str1.equals(str2)); // true`,
  code7: `@Service
public class CounterService {
    private int count = 0;

    public void increment() {
      synchronized(this) {
          count++;
      }
    }

    public int getCount() {
      return count;
    }
}`,
  code8: `public class SynchronizedExample {
  private int count = 0;

  // Synchronized method
  public synchronized void incrementCount() {
      count++;
  }

  // Synchronized block
  public void incrementCount2() {
      synchronized (this) {
          count++;
      }
  }
}`,
  code9: `class MyThread extends Thread {
  public void run() {
    System.out.println("Running in a separate thread");
  }
}

public class Main {
  public static void main(String[] args) {
    System.out.println("Running in the main thread");
    MyThread myThread = new MyThread();
    myThread.start();
  }
}`,
code031: `public interface MyInterface {
  // Method signatures
  void myMethod();

  // Constant variables
  int MAX_VALUE = 10;

  // Default method
  default void myDefaultMethod() {
      // Method implementation
  }
}`,code032: `public class MyClass implements MyInterface {
  // Implementing the method from the interface
  public void myMethod() {
      // Method implementation
  }
}`,code033: `public class MyClass implements Interface1, Interface2 {
  // Implementing methods from Interface1 and Interface2
}`, code0304: `interface Vehicle {
  void start();
  
  default void stop() {
      System.out.println("Vehicle stopped");
  }
}

class Car implements Vehicle {
  @Override
  public void start() {
      System.out.println("Car started");
  }
  
  // No need to implement the default stop() method here
}

public class Main {
  public static void main(String[] args) {
      Car car = new Car();
      car.start(); // Output: Car started
      car.stop();  // Output: Vehicle stopped
  }
}`,code034: `public interface MyInterface2 extends MyInterface1 {
  // Inherits method declarations from MyInterface1
  // Can declare additional methods
}`, code041: `public interface MyInterface2 extends MyInterface1 {
  // Inherits method declarations from MyInterface1
  // Can declare additional methods
}`, code041:`public abstract class MyAbstractClass {
  // Abstract method declaration
  public abstract void myAbstractMethod();

  // Concrete method
  public void myConcreteMethod() {
      // Method implementation
  }
}`, code042:`public abstract class MyAbstractClass {
  // Abstract method declaration
  public abstract void myAbstractMethod();
}

public class MyConcreteClass extends MyAbstractClass {
  // Implementation of the abstract method
  public void myAbstractMethod() {
      // Method implementation
  }
}`, code043:`public abstract class MyAbstractClass {
  public abstract void myAbstractMethod();
}

public class MyConcreteClass extends MyAbstractClass {
  // Missing implementation for myAbstractMethod
  // This class must be declared as abstract
}
`, code044:`public abstract class MyAbstractClass implements MyInterface {
  // Implementation of interface methods
  // Additional abstract and concrete methods
}`,
  code10:`public class VolatileExample {
private volatile boolean flag = false;

public void toggleFlag() {
    flag = !flag;
}

public boolean isFlagSet() {
    return flag;
}
}`,
  code11: `import java.util.ArrayList;
import java.util.Collections;
import java.util.Comparator;
import java.util.List;

class Employee {
  private int id;
  private String name;

  public Employee(int id, String name) {
    this.id = id;
    this.name = name;
  }

  public int getId() {
    return id;
  }

  public String getName() {
    return name;
  }
}

public class EmployeeSortExample {
  public static void main(String[] args) {
    List<Employee> employees = new ArrayList<>();
    employees.add(new Employee(101, "John"));
    employees.add(new Employee(102, "Alice"));
    employees.add(new Employee(103, "Bob"));
    employees.add(new Employee(104, "Eva"));

    // Sort employees by name using a custom comparator
    Collections.sort(employees, new Comparator<Employee>() {
      @Override
      public int compare(Employee emp1, Employee emp2) {
          return emp1.getName().compareTo(emp2.getName());
      }
    });

    // Print sorted employees
    for (Employee employee : employees) {
        System.out.println(employee.getId() + ": " + employee.getName());
    }
  }
}`,
  code12: `import java.util.ArrayList;
import java.util.Collections;
import java.util.List;

public class SortExample {
  public static void main(String[] args) {
    List<Integer> numbers = new ArrayList<>();
    numbers.add(5);
    numbers.add(2);
    numbers.add(8);
    numbers.add(1);
    numbers.add(10);

    // Sort the list in natural order
    Collections.sort(numbers);

    System.out.println("Sorted numbers in natural order:");
    for (Integer number : numbers) {
      System.out.println(number);
    }
  }
}`,
  code13: `import java.util.*;

class Person {
  String name;
  int age;

  public Person(String name, int age) {
    this.name = name;
    this.age = age;
  }
}

class AgeComparator implements Comparator<Person> {
  @Override
  public int compare(Person p1, Person p2) {
    return p1.age - p2.age;
  }
}

public class ComparatorExample {
  public static void main(String[] args) {
    List<Person> people = new ArrayList<>();
    people.add(new Person("Alice", 28));
    people.add(new Person("Bob", 22));
    people.add(new Person("Charlie", 25));

    // Using the AgeComparator to sort the list
    Collections.sort(people, new AgeComparator());

    for (Person person : people) {
      System.out.println(person.name + " - " + person.age);
    }
  }
}`,
  code14: `public class Singleton {

private static Singleton instance;

// Private constructor to prevent instantiation from outside
private Singleton() {
}

// Public static method to get the instance
public static Singleton getInstance() {
  if (instance == null) {
    instance = new Singleton();
  }
  return instance;
}

// Other methods of the Singleton class
}`,
  code15: `public class ThreadSafeSingleton {

private static ThreadSafeSingleton instance;

private ThreadSafeSingleton() {
}

public static synchronized ThreadSafeSingleton getInstance() {
  if (instance == null) {
    instance = new ThreadSafeSingleton();
  }
  return instance;
}

// Other methods of the ThreadSafeSingleton class
}`,
  code16: `import org.springframework.scheduling.annotation.Scheduled;
import org.springframework.stereotype.Service;

@Service
public class MyScheduledService {

  @Scheduled(fixedRate = 5000) // Run every 5 seconds
  public void performScheduledTask() {
    // Your task logic here
    System.out.println("Scheduled task executed!");
  }
}`,
  code17: `# Enable scheduling
spring.task.scheduling.enabled=true

# Configure the pool size for the scheduler
spring.task.scheduling.pool.size=5`,
  code18: `@Scheduled(cron = "0 0 * * * ?") // Run at the top of every hour
public void performCronTask() {
  // Your task logic here
  System.out.println("Cron task executed!");
}`,
  code19: `import org.springframework.boot.SpringApplication;
import org.springframework.boot.autoconfigure.SpringBootApplication;
import org.springframework.scheduling.annotation.EnableScheduling;

@SpringBootApplication
@EnableScheduling
public class MyApplication {

  public static void main(String[] args) {
      SpringApplication.run(MyApplication.class, args);
  }
}`,
  code20: `public class StringExample {
  public static void main(String[] args) {
    String firstName = "John";
    String lastName = "Doe";
    String fullName = firstName + " " + lastName; // Concatenating strings

    System.out.println(fullName); // Output: John Doe

    StringBuilder stringBuilder = new StringBuilder();
    stringBuilder.append("Hello");
    stringBuilder.append(" ");
    stringBuilder.append("World");

    String result = stringBuilder.toString();
    System.out.println(result); // Output: Hello World

    StringBuffer stringBuffer = new StringBuffer();
    stringBuffer.append("Java");
    stringBuffer.append(" ");
    stringBuffer.append("is");
    stringBuffer.append(" ");
    stringBuffer.append("great");

    String result = stringBuffer.toString();
    System.out.println(result); // Output: Java is great
  }
}`

}

