import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/react/Forms';


function Forms () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Forms</strong></CCardHeader>
			<CCardBody>
				<p><strong>Controlled Inputs</strong></p>
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

				<p><strong>Combined Inputs</strong></p>
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>

				<p><strong>Uncontrolled Inputs</strong></p>
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

				<p><strong>Without Ref</strong></p>
				<SyntaxHighlighter language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>


			</CCardBody>
		</CCard>			
	)
}

export default Forms