export const codeSnippet = {
  code1: `import java.util.HashMap;
import java.util.Map;

public class MapExample {
  public static void main(String[] args) {
    // Create a map
    Map<String, Integer> map = new HashMap<>();

    // Add key-value pairs to the map
    map.put("apple", 1);
    map.put("banana", 2);
    map.put("cherry", 3);
    map.put("apple", 4); // This will replace the previous value for the "apple" key

    // Get the value for a given key
    System.out.println(map.get("apple")); // Outputs 4
    System.out.println(map.get("orange")); // Outputs null

    // Check if the map contains a given key
    System.out.println(map.containsKey("apple")); // Outputs true
    System.out.println(map.containsKey("orange")); // Outputs false

    // Remove a key-value pair from the map
    map.remove("banana");

    // Iterate over the key-value pairs in the map
    for (Map.Entry<String, Integer> entry : map.entrySet()) {
      System.out.println(entry.getKey() + ": " + entry.getValue());
    }
  }
}`,
  code2: `4
null
true
false
apple: 4
cherry: 3`,
  code3: `import java.util.concurrent.ConcurrentHashMap;

public class ConcurrentHashMapExample {
    public static void main(String[] args) {
        ConcurrentHashMap<String, Integer> concurrentMap = new ConcurrentHashMap<>();

        concurrentMap.put("one", 1);
        concurrentMap.put("two", 2);
        concurrentMap.put("three", 3);

        int value = concurrentMap.get("two");
        System.out.println("Value for key 'two': " + value);
    }
}`,
  code4: `import java.util.LinkedHashMap;
import java.util.Map;

public class LinkedHashMapExample {
    public static void main(String[] args) {
        Map<String, Integer> linkedHashMap = new LinkedHashMap<>();
        linkedHashMap.put("one", 1);
        linkedHashMap.put("two", 2);
        linkedHashMap.put("three", 3);

        for (Map.Entry<String, Integer> entry : linkedHashMap.entrySet()) {
            System.out.println(entry.getKey() + ": " + entry.getValue());
        }
    }
}`
}

