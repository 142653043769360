import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/angular/Environment';

function Environment () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Router</strong></CCardHeader>
      <CCardBody>   
        <p>In Angular, environment variables can be used to configure different settings for different environments, such as development, staging, and production. These variables can be defined in a separate file, such as environment.ts, and can be imported and used throughout the application.</p>
        <p>To use environment variables in Angular, you can first create a new file, such as environment.ts, in the src/environments folder. This file should export an object containing the environment variables and their values. For example:</p>         
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
        <p>Then, you can import the environment object in any component, service, or module that needs to use the variables. For example:</p>      
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
        <p>You can also create different environment files, such as environment.prod.ts, environment.dev.ts and environment.staging.ts and then use them accordingly during building the project.</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
        <p>It will use environment.prod.ts file and if you use</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
        <p>It will use environment.staging.ts file.</p>
      </CCardBody>
    </CCard>        
  )
}

export default Environment;