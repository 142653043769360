import React from "react";
import { NavLink } from 'react-router-dom'
import { CRow, CCol, CCard, CCardBody, CCardHeader, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import roadMap from '../assets/images/roadmap.jpeg'
import smartwork from '../assets/images/smartwork.jpeg'
import getoutComfortZone from '../assets/images/getoutComfortZone.jpeg'

function CommonDashboard () {
	return (
		<>
		  <CRow>
      	<CCol sm={6} lg={3}>
					<img src={smartwork} width="100%" height="100%" />
				</CCol>
      	<CCol sm={6} lg={3}>
					<img src={getoutComfortZone} width="100%" height="100%" />
				</CCol>
      	<CCol sm={6} lg={3}>
				<CCard style={{ width: '30rem' }}>
					<CListGroup flush>
						<CListGroupItem>You should be flexible in how you should achieve your goals and you should leap at opportunities when its arrive. You are not set on a fixed way of getting to their goal</CListGroupItem>
						<CListGroupItem>You should be trust your gut and you follow your intuition.</CListGroupItem>
						<CListGroupItem>You should be  optimists and you belive that its all going to work out in the end.</CListGroupItem>
						<CListGroupItem>You should be resilient and its bounce back even when things dont go their way</CListGroupItem>
					</CListGroup>
				</CCard>
				</CCol>
			</CRow>	
			<br/>	
		<CCard className="mb-4">
			<CCardBody>
				<CRow>
					<CCol sm={8}>
						<h4 id="traffic" className="card-title mb-0">
							Road Map 2023
						</h4>
						<div>
							<img src={roadMap} width="100%" height="100%" />
						</div>						
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</>
	)
}

export default CommonDashboard