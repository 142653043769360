export const codeSnippet = {
  code1: `@SpringBootApplication
@ComponentScan("com.example.mypackage")
public class MyApplication {
  // ...
}`,
  code2: `@SpringBootApplication
@ComponentScan(basePackages = {"com.example.package1", "com.example.package2"})
public class MyApplication {
  // ...
}`,
  code3: `@SpringBootApplication
@ComponentScan(basePackageClasses = {MyClass1.class, MyClass2.class})
public class MyApplication {
  // ...
}`
}

