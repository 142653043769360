import React from "react";
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/java/Miscellaneous';
import { NavLink } from 'react-router-dom'
import accessModifier from '../../assets/images/access-modifiers-in-java.webp'


function JavaMiscellaneous() {
  return (
    <>
      <CCard className="mb-4">
          <CCardHeader><strong>Miscellaneous</strong></CCardHeader>
          <CCardBody>                
            <CAccordion flush>
            <CAccordionItem itemKey={0.1}>
                <CAccordionHeader>JDK, JRE, and JVM </CAccordionHeader>
                <CAccordionBody> 
                  <p>JDK, JRE, and JVM are three essential components of the Java platform. Here's an explanation of each:</p>
                  <ol>
                    <li><p>JDK (Java Development Kit):</p>
                      <ul>
                        <li><p>The JDK is a software development kit that provides tools, libraries, and documentation for developing Java applications.</p></li>
                        <li><p>It includes the Java compiler (javac) to compile Java source code into bytecode, and the Java runtime environment (java) to execute Java programs.</p></li>
                        <li><p>The JDK also includes other development tools such as debuggers, profilers, and application packaging utilities.</p></li>
                        <li><p>Developers use the JDK to write, compile, and package Java applications.</p></li>
                      </ul>
                    </li>
                    <li><p>JRE (Java Runtime Environment):</p>
                      <ul>
                        <li><p>The JRE is a runtime environment that is required to run Java applications.</p></li>
                        <li><p>It includes the Java Virtual Machine (JVM) and a set of core libraries and components.</p></li>
                        <li><p>The JRE does not include the development tools like compilers and debuggers, so it is used by end-users who want to run Java applications but do not need to develop or compile Java code.</p></li>
                      </ul>
                    </li>
                    <li><p>JVM (Java Virtual Machine):</p>
                      <ul>
                        <li><p>The JVM is an abstract computing machine that provides the runtime environment for executing Java bytecode.</p></li>
                        <li><p>It is a crucial component of the Java platform as it ensures that Java programs are platform-independent and can run on any system with a compatible JVM implementation.</p></li>
                        <li><p>The JVM interprets the compiled Java bytecode and translates it into machine-specific instructions for the underlying hardware.</p></li>
                        <li><p>It manages memory, handles garbage collection, and provides various runtime services such as exception handling and security.</p></li>
                      </ul>
                    </li>
                  </ol>
                  <p>To summarize, the JDK is used by developers to write, compile, and package Java applications. The JRE is required by end-users to run Java applications. The JVM is responsible for executing Java bytecode and providing the runtime environment for Java programs.</p>
                </CAccordionBody>
              </CAccordionItem> 
              <CAccordionItem itemKey={0.1}>
                <CAccordionHeader>Stack And Heap</CAccordionHeader>
                <CAccordionBody>  
                  <ol>
                    <li><p>Stack:</p>
                      <ul>
                        <li><p>The stack is a region of memory used for method invocations and local variable storage.</p></li>
                        <li><p>Each thread in a Java program has its own stack.</p></li>
                        <li><p>When a method is called, a new frame is pushed onto the stack, containing the method's parameters, local variables, and return address.</p></li>
                        <li><p>As the method execution completes, its frame is popped off the stack.</p></li>
                        <li><p>The stack is managed automatically by the JVM, and memory for method invocations and local variables is allocated and deallocated automatically.</p></li>
                      </ul>
                    </li>
                    <li><p>Heap</p>
                      <ul>
                        <li><p>The heap is a region of memory used for dynamic memory allocation.</p></li>
                        <li><p>It is where objects are allocated and deallocated during the execution of a Java program.</p></li>
                        <li><p>Objects created with the new keyword are stored in the heap.</p></li>
                        <li><p>The heap is shared among all threads in a Java application.</p></li>
                        <li><p>The memory allocated in the heap needs to be explicitly managed by the Java garbage collector, which automatically frees up memory occupied by objects that are no longer referenced.</p></li>
                      </ul>
                    </li>
                  </ol>
                  <p>Key Differences:</p>
                  <ul>
                    <li><p>The heap is used for dynamic memory allocation and stores objects, while the stack is used for method invocations and local variable storage.</p></li>
                    <li><p>The heap is shared among all threads, while each thread has its own stack.</p></li>
                    <li><p>Memory in the heap is managed by the garbage collector, while the stack memory is automatically allocated and deallocated by the JVM.</p></li>
                    <li><p>Objects in the heap can have varying lifetimes, while stack memory is allocated and deallocated on a per-method basis.</p></li>
                  </ul>
                  <p>In summary, <b>the heap is used for storing objects and is managed by the garbage collector, while the stack is used for method invocations and local variables and is managed automatically by the JVM.</b> Understanding the differences between the heap and stack is important for efficient memory management in Java programs.</p>
                </CAccordionBody>
              </CAccordionItem>  
              <CAccordionItem itemKey={0.2}>
                <CAccordionHeader>GC</CAccordionHeader>
                <CAccordionBody> 
                  <p>In Java, objects and references are cleaned up by the garbage collector (GC) when they are no longer reachable. The garbage collector is responsible for reclaiming memory occupied by objects that are no longer needed, freeing up resources and preventing memory leaks.</p>
                  <p>System.gc()</p>
                  <p>Runtime.getRuntime().gc()</p>
                </CAccordionBody>
              </CAccordionItem> 
              <CAccordionItem itemKey={0.3}>
                <CAccordionHeader>Access modifiers</CAccordionHeader>
                <CAccordionBody> 
                  <ol>
                    <li><p>Public: The public modifier allows unrestricted access from anywhere, both within the same class and from other classes in the same or different packages. Public members are part of the public API of a class.</p></li>
                    <li><p>Protected: The protected modifier allows access within the same class, subclasses (inheritance), and other classes in the same package. It provides a level of access between the public and default (package-private) access. Protected members are typically used for providing access to subclasses while still limiting access from unrelated classes.</p></li>
                    <li><p>Default (No Modifier): If no access modifier is specified, it is considered the default access level. Default access, also known as package-private, allows access within the same package but restricts access from classes in different packages. This access level is useful for classes and members that should only be accessible within a specific package.</p></li>
                    <li><p>Private: The private modifier restricts access to only within the same class. Private members are not accessible from any other class, including subclasses and classes in the same package. This access level is useful for encapsulation, allowing classes to hide internal implementation details.</p></li>
                  </ol>
                  <img src={accessModifier} width="100%" height="100%" />
                </CAccordionBody>
              </CAccordionItem> 
              <CAccordionItem itemKey={0.3}>
                <CAccordionHeader>Inteface</CAccordionHeader>
                <CAccordionBody> 
                  <p>Interface is a reference type that defines a contract of methods that a class implementing the interface must implement. It serves as a blueprint for classes to follow, ensuring a consistent set of methods and behaviors. Here are some key points about interfaces in Java:</p>
                  <ol>
                    <li>
                      <p>Declaration: An interface is declared using the interface keyword followed by the interface name. It can contain method signatures, constant variables, and default methods (introduced in Java 8) without any method implementation.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code031}</SyntaxHighlighter>
                    </li>
                    <li>
                      <p>Implementation: A class implements an interface using the implements keyword. It must provide an implementation for all the methods declared in the interface.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code032}</SyntaxHighlighter>
                    </li>
                    <li>
                      <p>Multiple Interfaces: A class can implement multiple interfaces, separated by commas. This allows a class to inherit the behavior and contracts of multiple interfaces.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code033}</SyntaxHighlighter>
                    </li>
                    <li><p>Abstract Methods: By default, methods in an interface are abstract and do not have an implementation. Implementing classes must provide the method implementation.</p></li>
                    <li><p>Constant Variables: Interface can also contain constant variables, which are implicitly public, static, and final. These variables can be accessed using the interface name.</p></li>
                    <li><p>Default Methods: Since Java 8, interfaces can have default methods, which provide a default implementation for a method. Classes implementing the interface can use this default implementation or override it.</p></li>
                    <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code0304}</SyntaxHighlighter>
                    <li><p>Inheritance: Interfaces can extend other interfaces using the extends keyword. This allows interfaces to inherit method declarations from other interfaces.</p>
                    <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code034}</SyntaxHighlighter>
                    </li>
                    <p>Interfaces play a vital role in achieving abstraction, promoting loose coupling, enabling polymorphism, and defining contracts in Java. They are widely used in Java development, especially when designing APIs, frameworks, and defining behavioral contracts that multiple classes can adhere to.</p>
                  </ol>
                </CAccordionBody>
              </CAccordionItem> 
              <CAccordionItem itemKey={0.4}>
                <CAccordionHeader>Abstract class</CAccordionHeader>
                <CAccordionBody> 
                  <p>Abstract class is a class that cannot be instantiated on its own but serves as a blueprint for creating derived classes. It is designed to be extended by subclasses, which provide implementations for the abstract methods defined in the abstract class. Here are some key points about abstract classes in Java:</p>
                  <ol>
                    <li>
                      <p>Declaration: An abstract class is declared using the abstract keyword. It can contain a mixture of abstract and non-abstract (concrete) methods, as well as fields, constructors, and instance variables.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code041}</SyntaxHighlighter>
                    </li>
                    <li>
                      <p>Abstract Methods: Abstract methods are declared in an abstract class without providing any implementation. Subclasses that extend the abstract class must provide an implementation for these abstract methods.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code042}</SyntaxHighlighter>
                    </li>
                    <li>
                      <p>Instantiation: An abstract class cannot be directly instantiated using the new keyword. It serves as a base class for subclasses, providing common methods, fields, and behaviors that can be inherited and extended.</p>
                    </li>
                    <li>
                      <p>Inheritance: Subclasses of an abstract class must either provide implementations for all the abstract methods or be declared as abstract themselves. If a subclass does not implement all the abstract methods, it must be declared as abstract.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code043}</SyntaxHighlighter>
                    </li>
                    <li>
                      <p>Abstract Class Usage: Abstract classes are often used to provide a common interface, define default behaviors, or enforce certain method contracts among related classes. They are useful for creating class hierarchies and promoting code reusability.</p>
                    </li>
                    <li>
                      <p>Combination with Interface: Abstract classes can also implement interfaces, providing a combination of abstract and non-abstract methods. This allows for flexibility in defining contracts and providing default implementations.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code044}</SyntaxHighlighter>
                    </li>                  
                  </ol>
                  <p>Abstract classes provide a way to create class hierarchies and define common behaviors while allowing subclasses to provide specific implementations. They are widely used in Java to achieve abstraction, modularity, and code reuse in object-oriented programming.</p>
                </CAccordionBody>
              </CAccordionItem>              
              <CAccordionItem itemKey={1}>
                <CAccordionHeader>Optional</CAccordionHeader>
                <CAccordionBody> 
                  <p>In Java, the java.util.Optional class is a container object which may or may not contain a non-null value. It is introduced in Java 8 as a way to handle null values more effectively, and to improve readability and maintainability of code.</p>
                  <p>The Optional class provides a way to express the absence of a value in a clear and concise manner. It has several methods for working with the contained value, such as <strong>isPresent()</strong> to check if a value is present, <strong>get()</strong> to retrieve the value if it is <strong>present</strong>, <strong>orElse()</strong> to provide a default value if the Optional is <strong>empty</strong>, and <strong>orElseThrow()</strong> to throw an exception if the Optional is empty.</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>

                  <p>Optional can be used in return types, or function arguments to indicate that the value may be absent, and it makes it clear that the method or constructor is able to handle the case where the value is not present.</p>
                  <p>It's important to note that Optional is not a replacement for null checks, it's a way to express the absence of value in a more clear and readable way, and it should be used in combination with proper null checking to improve the overall quality and readability of the code.</p>
                </CAccordionBody>
              </CAccordionItem>      
              <CAccordionItem itemKey={2}>
                <CAccordionHeader>Anonymous Inner Class</CAccordionHeader>
                <CAccordionBody><p>An anonymous inner class is a type of inner class in Java that does not have a name. It is used to define a class and create an instance of it at the same time, without having to give it a name. Anonymous inner classes are often used to define a one-time use class, such as an event listener or a comparator.</p>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
                <p>Lambda</p>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter>
                <p>In this example, the Thread constructor takes an instance of Runnable, and we are creating an instance of Runnable interface right inside the constructor call, without giving it a name.</p>
                </CAccordionBody>
              </CAccordionItem>	
              <CAccordionItem itemKey={3}>
                <CAccordionHeader>Difference between equals() and ==</CAccordionHeader>
                <CAccordionBody>
                 <p>==: The == operator is used to compare primitive data types (such as int, char, etc.) and reference variables in Java. It returns true if both operands refer to the same object in memory, otherwise false.</p>
                <p>equals(): The equals() method is a method of the Object class, which is the parent class of all Java classes. It compares the values of two objects and returns true if they are equal, otherwise false. In most cases, it is overridden in custom classes to provide a class-specific definition of equality.</p>                
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter>
                <p>In the example, str1 and str2 are different objects, but they have the same value, so equals() returns true. But == compares the references, and str1 and str2 refer to different objects in memory, so == returns false.</p>
                </CAccordionBody>
              </CAccordionItem>		
              <CAccordionItem itemKey={4}>
                <CAccordionHeader>Synchronized Block</CAccordionHeader>
                <CAccordionBody>
                 <p>A synchronized block in Java is a block of code that can be accessed by only one thread at a time. This helps to avoid race conditions and ensures that only one thread is executing the block of code at a time. The synchronized keyword is used to declare a synchronized block. A synchronized block can be synchronized on any object, and only one thread can execute the code that is synchronized on the same object.</p>                
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter>
                <p>In this example, the CounterService class is annotated with @Service to indicate that it is a service bean managed by the Spring framework. The increment method is declared as a synchronized block, just as in the previous example. This ensures that only one thread can update the count at a time, preventing race conditions. The synchronized block can be synchronized on any object, but in this example, it is synchronized on this, which refers to the current instance of the CounterService class.</p>
                <h3>Difference between a synchronized method and a synchronized block</h3>
                <p>The main difference between a synchronized method and a synchronized block is their scope and the level of granularity they provide for controlling access to a shared resource.</p>
                <p>A synchronized method is a method that is declared with the synchronized keyword. When a thread invokes a synchronized method, it acquires the lock for the entire object. This means that no other thread can access any other synchronized method of the same object until the first thread releases the lock.</p>
                <p>On the other hand, a synchronized block is a block of code that is enclosed in synchronized keyword followed by the object that provides the lock. The block of code acquires the lock only for the specific section of code and not for the entire object. This allows you to control the access to a shared resource with more granularity.</p>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
                </CAccordionBody>
              </CAccordionItem>		
              <CAccordionItem itemKey={5}>
                <CAccordionHeader>Main thread vs thread </CAccordionHeader>
                <CAccordionBody>
                 <p>The main thread and a thread are both execution contexts in a Java program.</p>
                 <p>The main thread is the thread that is created by the JVM when your program starts and is responsible for executing the main method of your program. The main method is the entry point of your program, and the code executed in the main method is executed in the main thread.</p>
                  <p>A thread, on the other hand, is a separate flow of execution within your program. You can create additional threads in Java by extending the Thread class or implementing the Runnable interface. The additional threads are created using the Thread class and can run concurrently with the main thread, allowing multiple tasks to be executed simultaneously.</p>
                  <p>The advantage of using threads is that they allow you to run multiple tasks concurrently, improving the performance and responsiveness of your program. For example, you can use a separate thread to run a long-running task without blocking the main thread, so the user can continue interacting with the application while the task is being processed.</p>
                  <p>However, you need to be careful when using threads as they can introduce synchronization and concurrency issues, such as race conditions and deadlocks. You need to use appropriate synchronization techniques, such as synchronized blocks or locks, to ensure that your threads are accessing shared resources in a thread-safe manner.</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter>
                </CAccordionBody>
              </CAccordionItem>			
              <CAccordionItem itemKey={6}>
                <CAccordionHeader>final</CAccordionHeader>
                <CAccordionBody>
                 <ul>
                  <li><p>final is a keyword in Java that can be applied to variables, methods, and classes.
</p></li>
                  <li><p>When applied to a variable, it indicates that the variable's value cannot be changed once assigned (it becomes a constant).</p></li>
                  <li><p>When applied to a method, it prevents the method from being overridden in subclasses.</p></li>
                  <li><p>When applied to a class, it prevents the class from being subclassed.</p></li>
                  <li><p>For example, final int x = 10; declares a constant variable x that cannot be modified.</p></li>
                 </ul>
                </CAccordionBody>
              </CAccordionItem>			
              <CAccordionItem itemKey={7}>
                <CAccordionHeader>Thread safety</CAccordionHeader>
                <CAccordionBody>
                <p>Thread safety in Java refers to the ability of a program or a piece of code to function correctly and produce consistent results when accessed by multiple threads concurrently. In a multi-threaded environment, where multiple threads are executing code simultaneously, thread safety ensures that the program behaves as expected without data corruption, race conditions, or other concurrency-related issues..</p>
                <p>Thread safety is important because multiple threads accessing shared resources, such as variables, objects, or data structures, can lead to unpredictable behavior if not properly managed. Without proper synchronization or coordination, threads might interfere with each other's operations, leading to incorrect results or exceptions.</p>
                <p>To achieve thread safety, developers use various synchronization mechanisms and techniques, including:</p>
                <ul>
                  <li><p><b>Synchronized Methods and Blocks:</b> Using the synchronized keyword to protect critical sections of code, ensuring that only one thread can access them at a time.</p></li>
                  <li><p><b>Volatile Keyword:</b> Using the volatile keyword to ensure that changes to a variable are immediately visible to all threads.</p></li>
                  <li><p><b>Thread-Safe Collections:</b> Using thread-safe collections from the java.util.concurrent package to safely manage concurrent access to data structures.</p></li>
                </ul>
                </CAccordionBody>
              </CAccordionItem>			
              <CAccordionItem itemKey={8}>
                <CAccordionHeader>volatile</CAccordionHeader>
                <CAccordionBody>
                  <p>The volatile keyword in Java is used as a modifier for variables to indicate that they can be accessed by multiple threads and that changes made to them by one thread are immediately visible to other threads. It ensures that reads and writes to a volatile variable are atomic and that the variable's value is always retrieved from the main memory rather than a thread's local cache.</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter>
                  <p>In this example, the flag variable is declared as volatile. This ensures that any changes made to the flag variable are immediately visible to other threads. However, it's important to note that the individual operations like toggleFlag are not atomic by themselves. If atomicity is required, additional synchronization mechanisms need to be employed.</p>
                </CAccordionBody>
              </CAccordionItem>	
              <CAccordionItem itemKey={9}>
                <CAccordionHeader>Sort a list of employees by their names</CAccordionHeader>
                <CAccordionBody>
                  <p></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter>                  
                  <p><b>Collections.sort()</b> is a method provided by the Java Collections framework to sort a list of elements in their natural order or using a custom comparator.</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter>     
                  <p><b>Comparator</b> interface is used to define custom ordering of objects. It's commonly used for sorting collections of objects, such as lists or sets, in a specific way. The Comparator interface provides methods to compare two objects and determine their order. This is particularly useful when the natural ordering of objects (using their default compareTo method) doesn't fit your sorting requirements.</p>
                  <ul>
                    <li><p><b>Implementing the Comparator Interface:</b> To define a custom ordering, you need to create a class that implements the Comparator interface. This class should provide the implementation for the compare method, which takes two objects and returns an integer indicating their order.</p></li>
                    <li><p>Sorting with Collections.sort or Arrays.sort: Once you have a Comparator implementation, you can use it to sort collections of objects using methods like Collections.sort (for lists) or Arrays.sort (for arrays).</p></li>
                  </ul>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code13}</SyntaxHighlighter>     
                </CAccordionBody>
              </CAccordionItem>	
              <CAccordionItem itemKey={10}>
                <CAccordionHeader>Singleton pattern</CAccordionHeader>
                <CAccordionBody>
                 <p>Singleton pattern is a design pattern that restricts the instantiation of a class to only one instance and provides a global point of access to that instance. This can be useful when you want to ensure that there's only one instance of a class throughout the application's lifecycle.</p>
                 <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code14}</SyntaxHighlighter>     
                 <p>In this example, the Singleton class has a private constructor to prevent direct instantiation. The getInstance() method provides the global point of access to the single instance of the class. If the instance doesn't exist, it's created. This implementation is not thread-safe and might lead to issues in a multi-threaded environment.</p>
                 <p>For thread-safe Singleton implementations, you can use various techniques such as using synchronized blocks, using the "double-checked locking" technique, or using the Enum approach (which guarantees thread safety in Java). Here's an example of a thread-safe Singleton using synchronized block:</p>
                 <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code15}</SyntaxHighlighter>     

                </CAccordionBody>
              </CAccordionItem>		
              <CAccordionItem itemKey={11}>
                <CAccordionHeader>Spring scheduler</CAccordionHeader>
                <CAccordionBody>
                  <p>In Spring Boot, the @Scheduled annotation is used to create scheduled tasks that run at specific intervals or times. This feature allows you to automate tasks like data synchronization, cleanup, and other repetitive actions. Here's how you can use the @Scheduled annotation to create scheduled tasks in Spring Boot:</p>
                  <ol>
                    <li>
                      <p><b>Create a Spring Component or Service:</b></p>
                      <p>Start by creating a class and annotate it with @Service or @Component.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code16}</SyntaxHighlighter>     
                    </li>
                    <li>
                      <p><b>Configure Scheduling Properties:</b></p>
                      <p>By default, Spring Boot's scheduler uses a single-threaded executor to run tasks. You can customize the scheduling behavior by configuring properties in your application.properties or application.yml file.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code17}</SyntaxHighlighter>   
                    </li>
                    <li>
                      <p><b>Using Cron Expressions:</b></p>
                      <p>You can also use cron expressions to schedule tasks at specific times or intervals.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code18}</SyntaxHighlighter>  
                    </li>
                    <li>
                      <p><b>Enable Scheduling:</b></p>
                      <p>Make sure that you have enabled scheduling in your Spring Boot application. You can do this by adding the @EnableScheduling annotation to your main application class.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code19}</SyntaxHighlighter>  
                    </li>
                  </ol>
                </CAccordionBody>
              </CAccordionItem>	
              <CAccordionItem itemKey={12}>
                <CAccordionHeader>Checked and Unchecked exceptions</CAccordionHeader>
                <CAccordionBody>
                  <b>Checked Exceptions:</b>
                  <p>Checked exceptions are checked by the compiler at compile-time. This means that the compiler enforces that these exceptions must be either caught using try-catch blocks or declared in the method's throws clause.</p>                  
                  <p><b>IOException, SQLException, FileNotFoundException.</b></p>
                  <b>Unchecked Exceptions:</b>
                  <p>Unchecked exceptions, also known as runtime exceptions, are not checked by the compiler during compile-time. You are not required to catch or declare them in the method signature.</p>
                  <p><b>NullPointerException, ArrayIndexOutOfBoundsException, IllegalArgumentException.</b></p>
                </CAccordionBody>
              </CAccordionItem>	  
              <CAccordionItem itemKey={13}>
                <CAccordionHeader>String, StringBuilder, and StringBuffer</CAccordionHeader>
                <CAccordionBody>
                  <b>String</b>
                  <ol>
                    <li><p><b>Immutable:</b> Strings in Java are immutable, meaning once a string is created, its value cannot be changed. Any operation that appears to modify a string actually creates a new string object.</p></li>
                    <li><p><b>Thread-Safe:</b> Strings are inherently thread-safe because of their immutability. They can be safely shared among multiple threads without synchronization concerns.</p></li>
                    <li><p><b>Memory Efficiency:</b> The immutability of strings can lead to memory overhead when performing frequent string manipulations, as each operation creates a new string object.</p></li>
                    <li><p><b>Use Cases:</b> Use String when you need to work with constant values or immutable text. They are suitable for situations where you don't need to modify the string frequently, like storing constant values or representing textual data.</p></li>
                  </ol>
                  <b>StringBuilder</b>
                  <ol>
                    <li><p><b>Mutable:</b> StringBuilder is mutable, meaning you can modify its contents directly without creating new objects.</p></li>
                    <li><p><b>Not Thread-Safe:</b> StringBuilder is not thread-safe, so it's not suitable for multi-threaded environments without proper synchronization.</p></li>
                    <li><p><b>Memory Efficiency:</b> StringBuilder is more memory-efficient than string concatenation because it allows in-place modifications without creating multiple string objects.</p></li>
                    <li><p><b>Use Cases:</b> Use StringBuilder when you need to build or manipulate strings in a single-threaded environment and want to avoid the memory overhead and inefficiency of repeated string concatenation.</p></li>
                  </ol>
                  <b>StringBuffer</b>   
                  <ol>
                    <li><p><b>Mutable:</b> Like StringBuilder, StringBuffer is mutable and supports in-place modifications.</p></li>
                    <li><p><b>Thread-Safe:</b> StringBuffer is thread-safe because it is synchronized. This means it can be used safely in multi-threaded environments.</p></li>
                    <li><p><b>Memory Efficiency:</b> Similar to StringBuilder, StringBuffer is more memory-efficient than creating multiple string objects.</p></li>
                    <li><p><b>Use Cases:</b> Use StringBuffer when you need to perform string manipulations in a multi-threaded environment where thread safety is required. However, note that synchronization can introduce a performance overhead.</p></li>
                  </ol>   
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code20}</SyntaxHighlighter>            
                </CAccordionBody>
              </CAccordionItem>	    
              <CAccordionItem itemKey={14}>
                <CAccordionHeader>Java 8 features</CAccordionHeader>
                <CAccordionBody>
                  <ol>
                    <li><p><b>Streams API:</b> The Streams API provides a more functional approach to processing collections of data. It allows you to perform operations on collections like filtering, mapping, and reducing in a concise and readable manner.</p></li>
                    <li><p><b>Default Methods:</b> Interfaces can now have method implementations using the default keyword. This feature was introduced to allow adding methods to interfaces without breaking the classes that implement them.</p></li>
                    <li><p><b>Optional:</b> The Optional class is used to represent an object that may or may not contain a non-null value. It helps to avoid null pointer exceptions and encourages more robust handling of potential null values.</p></li>
                    <li><p><b>Lambda Expressions:</b> Lambda expressions allow you to express instances of single-method interfaces (functional interfaces) more concisely. They enable you to treat functionality as a method argument or code as data.</p></li>
                    <li><p><b>Functional Interfaces:</b> Java 8 introduced a new concept called functional interfaces. These interfaces have exactly one abstract method and can have multiple default methods.</p></li>
                    <li><p><b>Method References:</b> Method references provide a shorthand syntax for referring to methods by their names. They are often used when working with lambda expressions and functional interfaces.</p></li>                   
                  </ol>
                 
                </CAccordionBody>
              </CAccordionItem>	            	
          </CAccordion>
        </CCardBody>
      </CCard>
    </>    
  )
}

export default JavaMiscellaneous;
