export const codeSnippet = {
  code1: `@Component
public class ServiceA {
  private final DataSource dataSource;

  @Autowired
  public ServiceA(DataSource dataSource) {
    this.dataSource = dataSource;
  }
}

@Component
public class ServiceB {
  private final DataSource dataSource;

  @Autowired
  public ServiceB(DataSource dataSource) {
    this.dataSource = dataSource;
  }
}`,
  code2: `@Component
@Primary
public class ServiceA {
  private final DataSource dataSource;

  @Autowired
  public ServiceA(@Primary DataSource dataSource) {
    this.dataSource = dataSource;
  }
}

@Component
public class ServiceB {
  private final DataSource dataSource;

  @Autowired
  public ServiceB(DataSource dataSource) {
    this.dataSource = dataSource;
  }
}`,
  code3:  `@Configuration
public class MyConfiguration {
  @Bean
  @Primary
  public DataSource primaryDataSource() {
    // configure and return a DataSource bean
  }

  @Bean
  public DataSource secondaryDataSource() {
    // configure and return a DataSource bean
  }
}`
}

