import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
import buyingPut1 from '../../assets/images/buyingPut1.png'
import buyingPut2 from '../../assets/images/buyingPut2.png'
import buyingPut3 from '../../assets/images/buyingPut3.png'

function BuyingPut () {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Buying a Put (Long Put)</strong></CCardHeader>
			<CCardBody>
				<p>Put options give holders of the option the right, but not the obligation
The buyer of the put option has the right to sell the underlying asset upon expiry at the strike price.</p>
				<p><strong>Buy 1 ATM / Slightly OTM PUT. The more bearish you are, further OTM you can buy the put option.</strong></p>
				<ui>
					<li>Bearish</li>
					<li>Limited Loss.</li>
					<li>Unlimited Profit.</li>
					<li style={{color: 'green'}}><strong>IV Increase</strong> is good for Long put</li>
					<li style={{color: 'red'}}><strong>Negative Theta</strong></li>
				</ui><br/>
				<p><strong>Potential Entry: </strong></p>
				<ui>
					<li>Breaking below resistance (with high volume)</li>
					<li>Bouncing off resistance (bear flags)</li>
				</ui><br/>
				<p><strong>Stock Selection:</strong></p>
				<ui>
					<li>Bearish</li>
					<li>Stock with higher trading volume (1m+)</li>
					<li>Option with higher trading volume (open interest/volume) (1m+)</li>
					<li>Option with ‘tight’ bid/ask price</li>
				</ui><br/>

				<p>Let's take a look at a long put's Greek exposures to help explain the strategy:</p>

				<CTable bordered>
					<CTableBody>
						<CTableRow>
							<CTableHeaderCell scope="row">Delta</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'red'}}>Negative</CTableHeaderCell>
							<CTableDataCell>Put prices fall when the stock price rises, which is harmful to put buyers. When the <strong>stock price falls, put prices increase</strong>, which is great for put buyers.</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">Gamma</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'green'}}>Positive</CTableHeaderCell>
							<CTableDataCell>A long put's position delta grows more towards -100 as the stock price decreases, and more towards 0 as the stock price increases.</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">Theta</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'red'}}>Negative</CTableHeaderCell>
							<CTableDataCell>The extrinsic value of options decays over time, leading to decreasing option prices (bad for put buyers).</CTableDataCell>
						</CTableRow>
						<CTableRow>
							<CTableHeaderCell scope="row">Vega</CTableHeaderCell>
							<CTableHeaderCell style={{color: 'green'}}>Positive</CTableHeaderCell>
							<CTableDataCell><strong>Increases in IV</strong> indicate an increase in option prices, which is beneficial for put buyers. Decreases in IV indicate falling option prices, which is harmful to put owners.</CTableDataCell>
						</CTableRow>
					</CTableBody>
				</CTable><br/>
				
				<img src={buyingPut1} width="32%" style={{padding: '5px'}}/>
				<img src={buyingPut2} width="32%" style={{padding: '5px'}}/>
				<img src={buyingPut3} width="32%" style={{padding: '5px'}}/>
				<br/><br/>

			</CCardBody>
	</CCard>
			
	)
}

export default BuyingPut