export const codeSnippet = {
  code1: `@RestController
public class MyController {
  @GetMapping("/hello")
  public String sayHello() {
    return "Hello, World!";
  }
}`,
}

