export const codeSnippet = {
  code1: ``,
  code2: ``,
  code3: `import org.springframework.boot.ApplicationArguments;
import org.springframework.boot.ApplicationRunner;
import org.springframework.stereotype.Component;

@Component
public class MyApplicationRunner implements ApplicationRunner {

    @Override
    public void run(ApplicationArguments args) throws Exception {
        // Custom logic or initialization tasks to be executed after application startup
        System.out.println("ApplicationRunner is running!");
    }
}`,
  code4: `@Controller
@RequestMapping("/api")
public class MyController {

    @RequestMapping("/hello")
    public String hello() {
        return "Hello, World!";
    }

    @RequestMapping(value = "/user/{id}", method = RequestMethod.GET)
    public User getUser(@PathVariable("id") int id) {
        // Retrieve user by ID and return
    }
}`,
  code5: `@RestController
@RequestMapping("/api/users")
public class UserController {

    @GetMapping("/{id}")
    public User getUser(@PathVariable("id") int id) {
        // Retrieve user by ID and return
    }

    @PostMapping
    public User createUser(@RequestBody User user) {
        // Create a new user and return
    }

    @PutMapping("/{id}")
    public User updateUser(@PathVariable("id") int id, @RequestBody User user) {
        // Update the user with the given ID and return
    }

    @DeleteMapping("/{id}")
    public void deleteUser(@PathVariable("id") int id) {
        // Delete the user with the given ID
    }
}`,
  code6: `@GetMapping("/users/{id}")
public User getUser(@PathVariable("id") int id) {
    // Retrieve user by ID and return
}`,
  code7: `@GetMapping("/users")
public List<User> getUsers(@RequestParam("name") String name) {
    // Retrieve users by name and return
}`,
	code8: `import org.springframework.web.bind.annotation.PostMapping;
import org.springframework.web.bind.annotation.RequestParam;
import org.springframework.web.bind.annotation.RestController;
import org.springframework.web.multipart.MultipartFile;

import org.apache.commons.csv.CSVFormat;
import org.apache.commons.csv.CSVParser;
import org.apache.commons.csv.CSVRecord;

import java.io.IOException;
import java.io.InputStreamReader;
import java.io.Reader;
import java.util.Iterator;

@RestController
public class CsvController {

	@PostMapping("/upload")
	public String uploadCsv(@RequestParam("file") MultipartFile file) throws IOException {
		try (Reader reader = new InputStreamReader(file.getInputStream());
			CSVParser csvParser = new CSVParser(reader, CSVFormat.DEFAULT)) 

			int chunkSize = 1000; // Adjust the chunk size as needed
			Iterator<CSVRecord> recordIterator = csvParser.iterator();

			while (recordIterator.hasNext()) {
					processChunk(recordIterator, chunkSize);
			}
		}

		return "CSV upload and processing complete.";
	}

	private void processChunk(Iterator<CSVRecord> recordIterator, int chunkSize) {
		int count = 0;
		while (recordIterator.hasNext() && count < chunkSize) {
			CSVRecord record = recordIterator.next();
			// Process the CSV record
			System.out.println(record);
			count++;
		}
	}
}`,
	code9:`@RestController
@RequestMapping("/api")
public class DataStreamingController {

	@Autowired
	private DataService dataService;

	@GetMapping("/stream-data")
	public ResponseEntity<StreamingResponseBody> streamData() {
		StreamingResponseBody responseBody = outputStream -> {
			dataService.streamData(outputStream); // Stream the data from your service
			outputStream.flush();
		};

		return ResponseEntity.ok()
			.header(HttpHeaders.CONTENT_TYPE, MediaType.APPLICATION_JSON_VALUE)
			.body(responseBody);
	}
}`,
	code10:`@Service
public class DataService {

	public void streamData(OutputStream outputStream) {
		// Retrieve and stream your data here
		List<String> records = getDataRecords(); // Replace with your data retrieval logic

		try (BufferedWriter writer = new BufferedWriter(new OutputStreamWriter(outputStream))) {
			for (String record : records) {
				writer.write(record);
				writer.newLine();
			}
		} catch (IOException e) {
			// Handle exception
		}
	}

	private List<String> getDataRecords() {
		// Replace with your data retrieval logic
		List<String> records = new ArrayList<>();
		for (int i = 0; i < 1000; i++) {
			records.add("Record " + i);
		}
		return records;
	}
}`,
	code11:`<dependencies>
	<dependency>
		<groupId>com.fasterxml.jackson.core</groupId>
		<artifactId>jackson-databind</artifactId>
		<version>2.12.5</version>
	</dependency>
</dependencies>`,
	code12: `import com.fasterxml.jackson.annotation.JsonProperty;

public class Person {
	@JsonProperty("name")
	private String name;

	@JsonProperty("age")
	private int age;

	// Getters and setters (or use Lombok for auto-generating getters/setters)
}`,
	code13: `import com.fasterxml.jackson.databind.ObjectMapper;
import java.io.File;
import java.io.IOException;

public class JsonFileProcessing {
	public static void main(String[] args) {
		ObjectMapper objectMapper = new ObjectMapper();
		
		try {
			File jsonFile = new File("data.json"); // Replace with your JSON file path
			Person[] persons = objectMapper.readValue(jsonFile, Person[].class);
			
			for (Person person : persons) {
				System.out.println("Name: " + person.getName());
				System.out.println("Age: " + person.getAge());
				System.out.println();
			}
		} catch (IOException e) {
			e.printStackTrace();
		}
	}
}`,
	code14: `import com.fasterxml.jackson.core.JsonFactory;
import com.fasterxml.jackson.core.JsonParser;
import com.fasterxml.jackson.core.JsonToken;
import java.io.File;
import java.io.IOException;

public class LargeJsonProcessing {
	public static void main(String[] args) {
		JsonFactory jsonFactory = new JsonFactory();
		
		try {
			File jsonFile = new File("large_data.json"); // Replace with your JSON file path
			JsonParser jsonParser = jsonFactory.createParser(jsonFile);
			
			while (jsonParser.nextToken() != JsonToken.END_OBJECT) {
					String fieldName = jsonParser.getCurrentName();
					if ("name".equals(fieldName)) {
							jsonParser.nextToken();
							String name = jsonParser.getText();
							System.out.println("Name: " + name);
					} else if ("age".equals(fieldName)) {
							jsonParser.nextToken();
							int age = jsonParser.getIntValue();
							System.out.println("Age: " + age);
					}
			}
			
			jsonParser.close();
		} catch (IOException e) {
			e.printStackTrace();
		}
	}
}`

}

