export const codeSnippet = {
  code1: `@Configuration
@PropertySource("classpath:application.properties")
@PropertySource("file:\$\{spring.config.location}/application-\$\{spring.profiles.active}.properties")
public class AppConfig {
    // ...
}`,
  code2:`@Configuration
@PropertySource("file:/etc/app/config.properties")
public class AppConfig {
    // ...
}`,
  code3: `@Configuration
@PropertySources({
    @PropertySource("classpath:application.properties"),
    @PropertySource("file:/etc/app/config.properties")
})
public class AppConfig {
    // ...
}`,
  code4: `@Autowired
private Environment env;

public void someMethod() {
    String property = env.getProperty("propertyName");
    // do something with the property
}`
}

