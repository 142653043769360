import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../../utils/codeSnippet/cloud/Kubernetes';

function KubernetesCommands () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>Commands</strong></CCardHeader>
        <CCardBody>
          <CCardBody>
            <CAccordion flush>
              <CAccordionItem itemKey={1}>
                <CAccordionHeader>Minikube</CAccordionHeader>
                <CAccordionBody>
                  <p><b>Start Minikube:</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter> 
                  <p><b>Stop Minikube:</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter> 
                  <p><b>Delete Minikube:</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter> 
                  <p>This command deletes the Minikube cluster, removing all associated resources.</p>
                  <p><b>View Minikube Dashboard:</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter> 
                  <p>This command opens the Kubernetes dashboard in a web browser. The dashboard provides a graphical user interface to interact with your Minikube cluster.</p>
                  <p><b>Access Minikube Cluster:</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter> 
                  <p>This command sets the kubectl context to the Minikube cluster, allowing you to interact with the Minikube cluster using the kubectl command-line tool.</p>
                  <p><b>Get Minikube Logs:</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter> 
                  <p>This command retrieves the logs of the Minikube cluster, displaying information about the cluster's startup process and other related logs.</p>
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={2}>
                <CAccordionHeader>Kubectl</CAccordionHeader>
                <CAccordionBody>
                <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter> 
                  <p><b>List of nodes in the cluster:</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
                  <p><b>To list the available Kubernetes API resources:</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code666}</SyntaxHighlighter> 
                  <p><b>To create a Pod:</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code777}</SyntaxHighlighter> 
                  <p><b>Retrieve resource usage metrics for pods in a Kubernetes cluster:</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7777}</SyntaxHighlighter> 
                  <p><b>To create a deployment:</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter> 
                  <p><b>Get</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter> 
                  <p><b>Delete</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter> 
                  <p><b>Create or update Kubernetes resources based on the configuration defined in a YAML or JSON file.</b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
                  <p><b></b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
                  <p><b></b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
                  <p><b></b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
                  <p><b></b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
                  <p><b></b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
                  <p><b></b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
                  <p><b></b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
                  <p><b></b></p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
                  </CAccordionBody>
              </CAccordionItem>
              </CAccordion>
          </CCardBody>
        </CCardBody>
      </CCard>        
    )
}

export default KubernetesCommands