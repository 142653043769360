import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../../utils/codeSnippet/cloud/Terraform';

function Terraform () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>Terraform</strong></CCardHeader>
        <CCardBody>
          <CCardBody>
            <p>Terraform is a tool for provisioning infrastructure ( or managing insfrastructure code). Teffaform can be used to manage popular service providers including AWS, GCP etc</p>

            <CAccordion flush>
              <CAccordionItem itemKey={1}>
                <CAccordionHeader>Commands</CAccordionHeader>
                <CAccordionBody>   							
                  <ul>
                    <li>
                      <p><b>terraform init: </b>is used to initialize a Terraform working directory. It is typically the first command that should be run after writing a new Terraform configuration or cloning an existing one.</p>
                    </li>
                    <li>
                      <p><b>terraform plan: </b>command is used to create an execution plan. The command compares the current state of the infrastructure, as described in the Terraform state file, with the desired state, as described in the Terraform configuration files. The command then generates an execution plan that outlines the changes that will be made to the infrastructure in order to reach the desired state.</p>
                    </li>
                    <li>
                      <p><b>terraform apply: </b> is used to apply changes to the infrastructure. The command uses the execution plan generated by the terraform plan command and makes the necessary changes to the infrastructure to reach the desired state.</p>
                    </li>
                    <li>
                      <p><b>terraform state list:</b>is used to list resources in the Terraform state. The command lists all the resources that have been added to the state file and it can be used to check what resources are currently being managed by Terraform.</p>
                    </li>
                    <li>
                      <p><b>terraform state show: </b>command is used to display detailed information about a specific resource in the Terraform state. The command shows the current state of a resource, including all of its attributes, and it can be used to check the details of a specific resource that is being managed by Terraform.</p>
                    </li>
                    <li>
                      <p><b>terraform destroy: </b>is used to destroy the resources managed by Terraform in a specific environment or state. This command deletes all the resources that have been created by Terraform and updates the state file to reflect the changes.</p>
                    </li>
                    <li>
                      <p><b>terraform fmt: </b>is used to format the Terraform configuration files in a specific directory. It automatically updates the format of the configuration files to conform to the official style guide and makes it more readable.</p>
                    </li>
                  </ul>
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={2}>
                <CAccordionHeader>Provider</CAccordionHeader>
                <CAccordionBody>   							
                  <p>In Terraform, a provider is a plugin that allows Terraform to interact with a specific service or infrastructure. Providers are responsible for understanding the API of the service or infrastructure and translating Terraform's configuration language into actions that the service or infrastructure can understand.</p>
                  <p># Configure the AWS Provider</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter> 
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={3}>
                <CAccordionHeader>Variables</CAccordionHeader>
                <CAccordionBody>   							
                  <ol>
                    <li>
                      <p>Variable Definition:</p>
                      <p>In your Terraform configuration file (e.g., variables.tf), define the variables you want to use:</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter> 
                      <p>In this example, we define two variables: "region" and "instance_count". The "region" variable has a type of string and a default value of "us-west-2", while the "instance_count" variable has a type of number and a default value of 1.</p>
                    </li>
                    <li>
                      <p>Variable Usage:</p>
                      <p>In your Terraform configuration, you can reference the variables using the var syntax:</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter> 
                      <p>Here, we use the var prefix to access the values of the variables. The count attribute is set to the value of the "instance_count" variable, and the "region" attribute is set to the value of the "region" variable.</p>
                    </li>
                    <li>
                      <p>Variable Input:</p>
                      <p>You can provide input values for the variables in multiple ways:</p>
                      <ul>
                        <li>
                          <p>Inline: When running Terraform commands, you can pass the variable values inline:</p>
                          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter> 
                        </li>
                        <li>
                          <p>Command-line: Alternatively, you can provide variable values via command-line flags:</p>
                          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
                          <p>You would define the variable values in a separate file (variables.tfvars) in a key-value format:</p>
                          <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter> 
                          <p>The values in the variable file will be automatically picked up by Terraform.</p>
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <h4>Variable Types</h4>
                  <ol>
                    <li>
                      <p>string</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter> 
                    </li>
                    <li>
                      <p>number</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter> 
                    </li>
                    <li>
                      <p>bool</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter> 
                    </li>
                    <li>
                      <p>list</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter> 
                    </li>
                    <li>
                      <p>map</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code13}</SyntaxHighlighter> 
                    </li>
                    <li>
                      <p>object</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code14}</SyntaxHighlighter> 
                    </li>
                    <li>
                      <p>set</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code15}</SyntaxHighlighter> 
                    </li>
                    <li>
                      <p>tuple</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code16}</SyntaxHighlighter> 
                    </li>
                    <li>
                      <p>any</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code17}</SyntaxHighlighter> 
                    </li>
                  </ol>
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={4}>
                <CAccordionHeader>locals</CAccordionHeader>
                <CAccordionBody>   							
                  <p>In Terraform, the locals block allows you to define local variables within your configuration. Local variables are not meant to be exposed as input or output variables but are used to simplify complex expressions, reuse values, or improve readability. Here's an example of how to use the locals block in Terraform:</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code18}</SyntaxHighlighter> 
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code19}</SyntaxHighlighter> 
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={5}>
                <CAccordionHeader>Output</CAccordionHeader>
                <CAccordionBody>   							
                  <p>In Terraform, the output block allows you to define values that are exposed as outputs from your infrastructure configuration. These outputs can be useful for providing information to other parts of your infrastructure, integrating with external systems, or for informational purposes. Here's an example of how to use the output block in Terraform:</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code20}</SyntaxHighlighter> 
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={6}>
                <CAccordionHeader>module</CAccordionHeader>
                <CAccordionBody>   							
                  <p>Modules consist of a collection of Terraform configuration files that define resources, variables, outputs, and other elements necessary to create a specific infrastructure component. They can be used to represent various types of infrastructure components, such as a network module, database module, or application module.</p>
                  <ol>
                    <li><p>Create a directory called <b>modules</b> in your Terraform project directory.</p></li>
                    <li><p>Inside the <b>modules</b> directory, create a subdirectory called <b>s3_bucket.</b></p></li>
                    <li>
                      <p>In the <b>s3_bucket</b> directory, create a file named <b>main.tf</b>. This file will contain the configuration for creating an S3 bucket.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code21}</SyntaxHighlighter> 
                      <p>In this example, the module defines an <b>aws_s3_bucket</b> resource with a variable <b>bucket_name</b> that will be provided from the main configuration.</p>  
                    </li>
                    <li>
                      <p>Create a file named <b>variables.tf</b> in the <b>s3_bucket</b> directory. This file will define the input variables for the module.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code22}</SyntaxHighlighter>   
                      <p>This variable allows you to customize the bucket name when using the module.</p>
                    </li>
                    <li>
                      <p>Now, in your main Terraform configuration file (e.g., <b>main.tf</b>), you can use the module.</p>
                      <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code23}</SyntaxHighlighter>   
                      <p>In this example, we instantiate the <b>s3_bucket</b> module, providing the <b>bucket_name</b> variable with the value "example-bucket".</p>
                    </li>                    
                  </ol>
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={7}>
                <CAccordionHeader>data</CAccordionHeader>
                <CAccordionBody>   							
                  <p>In Terraform, the data block is used to retrieve information or query existing resources in order to use that data in your configuration. The data block allows you to fetch data from various sources, such as remote APIs, Terraform state, or external systems, and make that data available for use in your Terraform configuration.</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code24}</SyntaxHighlighter>  
                  <p>In this example, we use the <b>aws_instances</b> data source to retrieve information about EC2 instances that have the tag <b>Environment</b> set to "production".</p>
                  <p>The retrieved instance IDs are assigned to data.aws_instances.example.ids.</p> 
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={8}>
                <CAccordionHeader>required_providers</CAccordionHeader>
                <CAccordionBody>   							
                  <p>The required_providers block in a Terraform configuration is used to specify the required providers and their versions for your Terraform project. It ensures that the specified provider is available and compatible with your configuration..</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code25}</SyntaxHighlighter>  
                  <p>In this example, we have specified two required providers: aws and azure. Each provider definition consists of the provider's source (namespace/name) and the version constraint.</p>
                  <p>The <b>source</b> attribute specifies the provider's namespace and name, such as hashicorp/aws or hashicorp/azure, indicating the provider's official namespace and name on the Terraform Registry.</p>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
            

            {/* <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>  */}
           

          </CCardBody>
        </CCardBody>
      </CCard>        
    )
}

export default Terraform