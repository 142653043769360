export const codeSnippet = {
  code1: `docker pull node
docker pull IMAGE[:TAG]
docker pull nginx:latest`,
  code2: `docker images`,
  code3: `docker rmi <IMAGE_ID or IMAGE_TAG>`,
  code4: `docker run <IMAGE_ID or IMAGE_TAG>
docker run -d IMAGE // Detach mode
docker run -p 8080:80 IMAGE // Map ports
docker run -p 8080:80 --name <name> IMAGE`, 
  code5: `docker ps [OPTIONS]`,
  code6: `docker ps --filter status=running`,
  code7: `docker stop <CONTAINER_ID>
docker stop <CONTAINER_NAME>`,
  code8: `docker logs <container_name_or_id>
docker logs -f <container_name_or_id> // If you want to view the logs continuously, you can use the -f or --follow`, 
  code9: `docker exec -it <container_name_or_id> bash
docker exec -it <container_name_or_id> /bin/bash`,
  code10: `docker network create <network_name>`,
  code11: `docker network inspect <network_name_or_id>`,
  code12: `version: '3'
services:
  web:
    image: nginx
    ports:
      - 80:80
    volumes:
      - ./nginx.conf:/etc/nginx/conf.d/default.conf
    depends_on:
      - backend
  backend:
    build: .
    ports:
      - 3000:3000
`,
  code13: `docker-compose -f <fileName.yml> up`,
  code130000: `version: '3'
services:
  frontend:
    build:
      context: ./frontend
      dockerfile: Dockerfile
    ports:
      - 3000:3000
    depends_on:
      - backend
  backend:
    build:
      context: ./backend
      dockerfile: Dockerfile
    ports:
      - 8080:8080
    environment:
      - DB_HOST=db
      - DB_PORT=3306
      - DB_USER=root
      - DB_PASSWORD=secret
      - DB_NAME=mydb
  db:
    image: mysql:latest
    environment:
      - MYSQL_ROOT_PASSWORD=secret
      - MYSQL_DATABASE=mydb
      - MYSQL_USER=root
      - MYSQL_PASSWORD=secret`,
  code130001: `FROM node:14-alpine

WORKDIR /app

COPY package*.json ./
RUN npm install

COPY . .

CMD ["npm", "start"]`,
  code130002: `FROM node:14-alpine

WORKDIR /app

COPY package*.json ./
RUN npm install

COPY . .

CMD ["node", "server.js"]`,
  code130003: `docker-compose up`,
  code14: `# Specify the base image
FROM node:14

# Set the working directory inside the container
WORKDIR /app

# Copy package.json and package-lock.json to the working directory
COPY package*.json ./

# Install dependencies
RUN npm install

# Copy the application code to the working directory
COPY . .

# Expose a port (if needed) for communication with the container
EXPOSE 3000

# Specify the command to run when the container starts
CMD ["npm", "start"]`,
  code15: `docker build -t myapp .`,
  code16: `# Use an official OpenJDK runtime as the base image
FROM openjdk:11-jdk

# Set the working directory inside the container
WORKDIR /app

# Copy the packaged Spring Boot application JAR file into the container
COPY target/my-spring-boot-app.jar /app/app.jar

# Expose the port that the application will listen on
EXPOSE 8080

# Set the entrypoint command to run the application when the container starts
ENTRYPOINT ["java", "-jar", "/app/app.jar"]`,
  code17: `docker build -t my-spring-boot-app .`,
  code18: `docker run -p 8080:8080 my-spring-boot-app`,
  code19: ``,
  code20: ``,
  code21: ``,
  code22: ``,
}

