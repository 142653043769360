export const codeSnippet = {
  code1: `# application.properties
spring.profiles.active=dev

# application-dev.properties
server.port=8080

# application-prod.properties
server.port=9090`,
  code2: `@Configuration
@Profile("dev")
public class DevConfig {
  @Bean
  public DataSource dataSource() {
    // configure and return a data source for the dev environment
  }
}

@Configuration
@Profile("prod")
public class ProdConfig {
  @Bean
  public DataSource dataSource() {
    // configure and return a data source for the prod environment
  }
}`,
  code3:`java -jar myapp.jar --spring.profiles.active=dev`,
  code4:`@SpringBootApplication
public class MyApplication {
  public static void main(String[] args) {
    SpringApplication app = new SpringApplication(MyApplication.class);
    app.setDefaultProfiles("dev");
    app.run(args);
  }
}`,
  code5:`export SPRING_PROFILES_ACTIVE=dev
java -jar myapp.jar`,
  code6:`# spring.config.name=myapp
spring.profiles.active=dev`,
}

