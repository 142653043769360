import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/angular/LazyLoading';

function LazyLoading () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>LazyLoading</strong></CCardHeader>
      <CCardBody>    
        <p>Lazy loading is a technique in Angular that allows you to load modules only when they are needed, rather than loading all modules at the start of the application. This can improve the startup time and reduce the memory usage of the application.</p>
        <p>To implement lazy loading in Angular, you can use the loadChildren property of the Route configuration. This property allows you to specify the module and the component that should be loaded when the route is activated.</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
        <p>In this example, the lazy route is configured to load the LazyModule module. The module path and the module class name are separated by the # symbol. When a user navigates to the /lazy route, the router will load the LazyModule module and its components.</p>
        <p>You also can use RouterModule.forChild instead of RouterModule.forRoot when you configure routing in the lazy-loaded module.</p>
        <p>It's also important to note that when you use the loadChildren property, the module is loaded asynchronously, and the JavaScript bundle that contains the module is loaded as a separate file. This allows you to load the module only when it is needed, and not waste resources loading it when it's not.</p>
        <p>Lazy loading can greatly improve the performance of an application, especially when it contains a lot of modules that are not used frequently. It's a recommended practice to use lazy loading when your application has a lot of features and modules.</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>

      </CCardBody>
    </CCard>        
  )
}

export default LazyLoading;