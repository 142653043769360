export const codeSnippet = {
  code1: `List<Integer> numbers = Arrays.asList(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
numbers.stream()
  .filter(n -> n % 2 == 0) // filters even numbers
  .map(n -> n * n) // maps each number to its square
  .forEach(n -> System.out.println(n));`,
  code2: `List<String> words = Arrays.asList("Hello", "world");
Stream<String> stream = words.stream();
stream.forEach(System.out::println);`,
  code3: `int[] numbers = {1, 2, 3, 4, 5};
IntStream stream = Arrays.stream(numbers);`,
  code4: `List<String> names = Arrays.asList("Alice", "Bob", "Charlie", "David");
List<String> result = names.stream()
                          .filter(name -> name.length() > 4)
                          .collect(Collectors.toList());
// Result: ["Alice", "Charlie"]`,
  code5: `List<Integer> numbers = Arrays.asList(1, 2, 3, 4, 5);
List<Integer> squared = numbers.stream()
                            .map(n -> n * n)
                            .collect(Collectors.toList());
// Result: [1, 4, 9, 16, 25]`,
  code6: `List<String> fruits = Arrays.asList("apple", "banana", "orange", "grape");
List<String> sortedFruits = fruits.stream()
                                .sorted()
                                .collect(Collectors.toList());
// Result: ["apple", "banana", "grape", "orange"]`,
  code7: `List<Integer> numbers = Arrays.asList(1, 2, 3, 4, 5);
int sum = numbers.stream()
              .reduce(0, (acc, num) -> acc + num);
// Result: 15`,
  code8: `List<Person> people = // a list of Person objects
Map<String, List<Person>> peopleByCountry = people.stream()
                                              .collect(Collectors.groupingBy(Person::getCountry));
// Result: A map with countries as keys and lists of people from each country as values`,
  code9: `List<Integer> numbers = Arrays.asList(1, 2, 3, 4, 5);
int sum = numbers.parallelStream()
              .reduce(0, Integer::sum);
// Result: 15`,
  code10: ``,
  code11: ``,
}

