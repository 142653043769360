import React from "react";
import { CCard, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/PrimaryQualifier';


function PrimaryQualifier() {
    return (
      <CCard className="mb-4">
        <CCardBody>
          <p>@Primary and @Qualifier are both Spring annotations that are used to specify which bean should be injected into a particular dependency. However, they work in slightly different ways:</p>
          <ul>
            <li><p>@Primary is used to indicate that a bean should be given precedence over other beans of the same type when multiple beans are present in the application context. It is often used to specify a default bean that should be used when no other bean is explicitly specified.</p></li>
            <li><p>@Qualifier is used to specify a particular bean by its name or some other unique identifier. It is often used when multiple beans of the same type are present in the application context, and you need to differentiate between them.</p></li>
          </ul>
          <p>Here is an example of how you might use @Primary and @Qualifier together:</p>
          <SyntaxHighlighter  wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>    
          <p>In this example, the primaryDataSource bean is marked as @Primary, so it will be given precedence over the secondaryDataSource bean when both are eligible for injection. However, the MyComponent class explicitly specifies the @Qualifier("secondary") annotation, so it will be injected with the secondaryDataSource bean rather than the primaryDataSource bean.</p>
        </CCardBody>
      </CCard>  
    )
}

export default PrimaryQualifier