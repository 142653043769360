import React from "react";
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { NavLink } from 'react-router-dom'
import { codeSnippet } from '../../utils/codeSnippet/react/UseContext'

function UseContext() {
    return (
        <CCard className="mb-4">
			    <CCardHeader><strong>UseContext</strong></CCardHeader>
          <CCardBody>
            <CCard>
                <CCardBody>
                    <p>Introduction to <NavLink  to="/react/hooks" active>hooks</NavLink></p>                    
                </CCardBody>
            </CCard><br/>
            <p>The React context provides data to components no matter how deep they are in the components tree. The context is used to manage global data, e.g. global state, theme, services, user settings, and more.</p>

            <p>auth-context.js</p>
            <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
            <p>App.js</p>
            <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
            <p>Auth.js</p>
            <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
          </CCardBody>
        </CCard>        
    )
}

export default UseContext;