export const codeSnippet = {
  code1: `@Component
public class MyComponent {
    // ...
}`,
  code2: `@Component("myCustomName")
public class MyComponent {
    // ...
}`,
  code3: `@Autowired
private MyComponent myComponent;`
}

