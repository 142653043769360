export const codeSnippet = {
  code1: `import org.junit.Before;
import org.junit.Test;

import java.sql.SQLException;
import java.util.Arrays;
import java.util.List;

import static org.junit.Assert.assertFalse;
import static org.junit.Assert.assertTrue;
import static org.mockito.Mockito.mock;
import static org.mockito.Mockito.when;

public class BookingManagerTest {

  //Class to be tested
  private BookingManager bookingManager;

  //Dependencies
  private HotelDao hotelDaoMock;  

  @Before
  public void setup() throws SQLException {

      hotelDaoMock = mock(HotelDao.class);
      bookingManager = new BookingManager(hotelDaoMock);

      List<String> availableRooms = Arrays.asList("A");
      when(hotelDaoMock.fetchAvailableRooms()).thenReturn(availableRooms);

  }

  @Test
  public void checkAvailableRoomsTrue() throws SQLException {
      assertTrue(bookingManager.checkRoomAvailability("A"));
  }

  @Test
  public void checkAvailableRoomsFalse() throws SQLException {
      assertFalse(bookingManager.checkRoomAvailability("B"));
  }
}`,
  code2: `import org.junit.Before;
import org.junit.Test;
import java.util.ArrayList;

public class ListTest {
    private ArrayList<Integer> list;

    @Before
    public void setUp() {
      list = new ArrayList<>();
    }

    @Test
    public void testAdd() {
      list.add(10);
      assertEquals(1, list.size());
    }

    @Test
    public void testRemove() {
      list.add(10);
      list.remove(0);
      assertEquals(0, list.size());
    }
}`,
  code3: `public class Example {
  public void divide(int a, int b) {
    if (b == 0) {
      throw new ArithmeticException("Cannot divide by zero");
    }
    int result = a / b;
    System.out.println("Result: " + result);
  }
}`,
  code4: `import org.junit.Test;

public class ExampleTest {
    @Test(expected = ArithmeticException.class)
    public void testDivideByZero() {
      Example example = new Example();
      example.divide(1, 0);
    }

    @Test
    public void testDivide() {
      Example example = new Example();
      int result = example.divide(4, 2);
      assertEquals(2, result);
    }
}`,
  code5: `public class MyCustomException extends Exception {
  private static final long serialVersionUID = 1L;

  public MyCustomException(String message) {
      super(message);
  }
}`,
  code6: `if (someCondition) {
  throw new MyCustomException("Some error message");
}`,
  code7: `import org.junit.Test;
public class ExampleTest {
    @Test(expected = MyCustomException.class)
    public void testCustomException() {
      Example example = new Example();
      example.methodThrowingCustomException();
    }
}`,
  code8: `@Mock
private Database database;`,
  code9: `@InjectMocks
private Example example;`,
  code10: `public class Database {
  public int getData() {
      // some code to fetch data from the database
      return 42;
  }
}

public class Example {
  private Database database;

  public Example(Database database) {
      this.database = database;
  }

  public int getResult() {
      return database.getData() * 2;
  }
}`,
  code11: `import org.junit.Before;
import org.junit.Test;
import org.mockito.InjectMocks;
import org.mockito.Mock;
import org.mockito.MockitoAnnotations;

import static org.junit.Assert.assertEquals;
import static org.mockito.Mockito.when;

public class ExampleTest {

    @Mock
    private Database database;

    @InjectMocks
    private Example example;

    @Before
    public void setUp() {
      MockitoAnnotations.initMocks(this);
    }

    @Test
    public void testGetResult() {
      when(database.getData()).thenReturn(21);
      int result = example.getResult();
      assertEquals(42, result);
    }
}`,
  code12: `public class MyClass {
  private int add(int a, int b) {
      return a + b;
  }
}`,
  code13: `import java.lang.reflect.Method;
import static org.junit.Assert.assertEquals;

public class MyClassTest {
  @Test
  public void testAdd() throws Exception {
    MyClass myClass = new MyClass();

    // Get the private method using reflection
    Method privateAddMethod = MyClass.class.getDeclaredMethod("add", int.class, int.class);
    privateAddMethod.setAccessible(true);

    // Call the private method on the instance of MyClass
    int result = (int) privateAddMethod.invoke(myClass, 2, 3);

    // Assert the result
    assertEquals(5, result);
  }
}`,
  code14: `public class MyClass {
  private int privateField;

  public MyClass(int privateField) {
      this.privateField = privateField;
  }
}`,
  code15: `import java.lang.reflect.Field;
import static org.junit.Assert.assertEquals;

public class MyClassTest {
  @Test
  public void testPrivateFieldAccess() throws Exception {
      int expectedValue = 42;
      MyClass myClass = new MyClass(expectedValue);

      // Get the private field using reflection
      Field privateField = MyClass.class.getDeclaredField("privateField");
      privateField.setAccessible(true);

      // Get the value of the private field on the instance of MyClass
      int actualValue = (int) privateField.get(myClass);

      // Assert the value
      assertEquals(expectedValue, actualValue);
  }
}`,

}

