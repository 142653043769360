export const codeSnippet = {
  code1: `@Configuration
public class MyConfiguration {

  @Bean
  public DataSource dataSource() {
    // create and configure a DataSource object
    // and return it
  }

  @Bean
  public JdbcTemplate jdbcTemplate(DataSource dataSource) {
    // create and configure a JdbcTemplate object
    // using the DataSource object, and return it
  }
}`
}

