import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CListGroup, CListGroupItem } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../../utils/codeSnippet/cloud/KubernetesExample';
import envVariables from '../../../assets/images/envVariables.jpg';


function Example () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>Example</strong></CCardHeader>
        <CCardBody>
          <CCardBody>
            <CAccordion flush>
              <CAccordionItem itemKey={1}>
                <CAccordionHeader>Spring Boot 1</CAccordionHeader>
                <CAccordionBody>
                  <p>Start Minikube:</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter> 
                  <p>Docker</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter> 
                  <p>Check Minikube Docker environment: Minikube runs its own Docker daemon, separate from the one on your local machine. Verify that Minikube is using the correct Docker environment by running:</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2000}</SyntaxHighlighter>
                  <p>Build the docker</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
                  <p>Run the spring boot in docker container</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter> 
                  <p>See the container location in docker</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code5}</SyntaxHighlighter> 
                  <p>Set default namespace</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6}</SyntaxHighlighter> 
                  <p>application.yaml -> set the project name through env variable</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6000}</SyntaxHighlighter> 
                  <img src={envVariables} width="50%" height="50%" /><br/><br/>  
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6001}</SyntaxHighlighter> 
                  <p>configMap.yaml</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6002}</SyntaxHighlighter> 
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6003}</SyntaxHighlighter> 
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6004}</SyntaxHighlighter> 
                  <p>secret.yaml</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6005}</SyntaxHighlighter> 
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6006}</SyntaxHighlighter> 
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code6007}</SyntaxHighlighter> 
                  <p>deployment.yaml</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code7}</SyntaxHighlighter> 
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code8}</SyntaxHighlighter>
                  <p>If any error in deployment process</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code9}</SyntaxHighlighter>  
                  <p>Verify the deployment / Replicaset / Pod</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code10}</SyntaxHighlighter>  
                  <p>service.yaml</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code11}</SyntaxHighlighter> 
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code12}</SyntaxHighlighter> 
                  <p>Service Type: <b>LoadBalancer</b> (run the application in minikube server)</p>
                  <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code13}</SyntaxHighlighter> 
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </CCardBody>
        </CCardBody>
      </CCard>        
    )
}

export default Example