import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/angular/Ineterceptor';

function Ineterceptor () {
  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Interceptor</strong></CCardHeader>
      <CCardBody>    
        <p>An interceptor in Angular is a class that implements the HttpInterceptor interface. It allows you to add logic that is executed before and after an HTTP request is sent or a response is received. This logic can be used to add headers, handle errors, and modify requests and responses.</p>
        <p>Here is an example of how to create an interceptor that adds an authentication token to the headers of every HTTP request:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>
        <p>In this example, the AuthInterceptor class implements the HttpInterceptor interface and its intercept() method is called before every HTTP request. It gets the authentication token from the AuthService service and adds it to the headers of the request if it exists. If the token doesn't exist, the original request is passed along unchanged.</p>
        <p>To register an interceptor, you need to add it to the providers array of the @NgModule decorator. Here is an example of how to register the AuthInterceptor:</p>
        <SyntaxHighlighter wrapLines="true" language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>
        <p>In this example, the AuthInterceptor is added to the HTTP_INTERCEPTORS provider. This means that the intercept() method of the AuthInterceptor class will be called before every HTTP request, allowing it to add the authentication token to the headers of the request.</p>
      </CCardBody>
    </CCard>        
  )
}

export default Ineterceptor;