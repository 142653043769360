import React, { useState, useRef, useContext } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'

import AuthContext from '../store/auth-context';
import UserPool from '../UserPool';
import useForm from '../components/hooks/UseForm';


const Login = () => {

  // aws cognito-idp admin-set-user-password --user-pool-id us-east-2_KBHiIB27U --username lindosebastian@gmail.com --password "Lindo1985@" --permanent

  const authCtx = useContext(AuthContext);

  const [loginErr, setLoginErr] = useState();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const formLogin = (event) => {
    // event.preventDefault();

    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    const user = new CognitoUser({
      Username: enteredEmail,
      Pool: UserPool
    })
  
    const authDetails = new AuthenticationDetails({
      Username: enteredEmail,
      Password: enteredPassword
    })

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        // console.log("onSuccess", data)
        // const expirationTime = new Date(
        //   new Date().getTime() + +data.expiresIn * 1000
        // );
        authCtx.login();
        // authCtx.login(data.accessToken.jwtToken, expirationTime.toISOString());
      },
      onFailure: (err) => {
        console.log("onFailure", err);
        setLoginErr(err.message);
      },
      newPasswordRequired: (data) => {
        // console.log("newPasswordRequired", data)
      }
    })
  };

  const {handleChange, values,errors,handleSubmit} = useForm(formLogin);

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>   
                    { !errors.email && !errors.password && loginErr && <p style={{color:'red'}}>{loginErr}</p>}                  
                    { errors.email && <p style={{color:'red'}}>{errors.email}</p>}                  
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput 
                        placeholder="Username" 
                        name="email" 
                        autoComplete="username" 
                        ref={emailInputRef} 
                        onChange={handleChange} />
                    </CInputGroup>
                    { errors.password && <p style={{color:'red'}}>{errors.password}</p>} 
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        name="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        ref={passwordInputRef}
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" onClick={handleSubmit}>
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          {/* Forgot password? */}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
