import React from "react";
import { CCard, CCardHeader, CCardBody, CTable,
  CTableBody,
  CTableDataCell,
  // CTableHead,
  CTableHeaderCell,
  CTableRow, } from '@coreui/react'
	import { NavLink } from "react-router-dom";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/java/Set'


function Set() {
	return (
		<CCard className="mb-4">
			<CCardHeader><strong>Set</strong></CCardHeader>
			<CCardBody>
				<p>In Java, a Set is an interface in the java.util package that extends the Collection interface. It is an unordered collection of elements that does not allow duplicate values.</p>

				<p>Here is an example of how you might use a Set in Java:</p>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code2}</SyntaxHighlighter>

				<p>There are several implementation classes available for the Set interface in the Java Standard Library, including HashSet, TreeSet, and LinkedHashSet. Each of these implementation classes has its own characteristics and trade-offs in terms of performance and functionality.</p>

				<b>Pros:</b>
				<ul>
					<li><p><b>Fast Lookup:</b> HashSet offers fast O(1) lookup time for basic operations like add, remove, and contains.</p></li>
					<li><p><b>No Duplicates:</b> HashSet automatically ensures that no duplicate elements are allowed. It's ideal for situations where you want to store a collection of unique elements.</p></li>
					<li><p><b>No Order:</b> If the order of elements is not important, HashSet is a more memory-efficient choice.</p></li>
				</ul>

				<b>Cons:</b>
				<ul>
					<li><p><b>No Order:</b> HashSet does not maintain the order of elements as they are inserted. The order can be arbitrary.</p></li>
				</ul>

				<h5><strong>LinkedHashSet</strong></h5>
				<p>In Java, LinkedHashSet is a class that extends the HashSet class and implements the Set interface. It is an ordered collection of elements that does not allow duplicate values and uses a hash table with a linked list to maintain the insertion order of the elements.</p>

				<p>Here is an example of how you might use a LinkedHashSet in Java:</p>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code3}</SyntaxHighlighter>
				<SyntaxHighlighter  language="javascript" style={docco}>{codeSnippet.code4}</SyntaxHighlighter>
				<p>Like HashSet, LinkedHashSet has fast lookup and insertion times due to the use of a hash table for storage. However, it also maintains the insertion order of the elements, so the elements will be iterated over in the order in which they were added to the set.</p>

				<b><p>Diffrence b/w HashSet and LinkedHashSet</p></b>
				<p>HashSet stores its elements in a hash table, which means that it uses a hash function to map each element to a unique index in the table. This makes it very efficient for searching, adding, and removing elements, as these operations have a constant time complexity of O(1) on average, assuming a good hash function. However, HashSet does not maintain the order of elements, so they will not be returned in the same order they were added.</p>

				<p>LinkedHashSet, on the other hand, also stores its elements in a hash table, but it also maintains a doubly-linked list of the elements. This means that it has the same performance characteristics as HashSet, but it also preserves the order of elements. Elements are returned in the order they were added, or in the order they were accessed (if the accessOrder parameter is set to true during the creation of the object)</p>

				<p>So in summary, if you need to maintain the order of elements, you should use a LinkedHashSet. If you only need to store unique elements and do not care about their order, you should use a HashSet.</p>

				<b>Pros:</b>
				<ul>
					<li><p><b>Order Preserved:</b> LinkedHashSet maintains the order in which elements are inserted. It provides an ordered iteration over the elements.</p></li>
					<li><p><b>Unique Elements:</b> Similar to HashSet, LinkedHashSet also ensures uniqueness of elements.</p></li>
				</ul>

				<b>Cons:</b>
				<ul>
					<li><p><b>Slower Insertions and Deletions:</b> Compared to HashSet, LinkedHashSet might have slightly slower insertions and deletions due to the additional overhead of maintaining the order.</p></li>
					<li><p><b>Slightly Larger Memory Footprint:</b> LinkedHashSet has a slightly larger memory footprint compared to HashSet due to the additional pointers to maintain the order.</p></li>
					<li><p><b>Slightly Slower Lookup:</b> While the lookup time is still O(1), LinkedHashSet might have a slightly slower lookup compared to HashSet due to the linked structure.</p></li>
				</ul>
			</CCardBody>
	</CCard>
			
	)
}

export default Set;