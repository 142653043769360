import React from "react";
import { NavLink } from 'react-router-dom'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeSnippet } from '../../utils/codeSnippet/springBoot/EnableAutoConfiguration';

function EnableAutoConfiguration () {

    return (
      <CCard className="mb-4">
			  <CCardHeader><strong>@EnableAutoConfiguration</strong></CCardHeader>
        <CCardBody>
          <p>@EnableAutoConfiguration is a Spring Boot annotation that can be used to enable auto-configuration of certain parts of the Spring application. When this annotation is used, Spring Boot will automatically configure your application based on the dependencies that are present in the classpath.</p>
          <p>For example, if you have the spring-boot-starter-web dependency in your classpath, and you use the @EnableAutoConfiguration annotation, Spring Boot will automatically set up a Spring MVC configuration and configure a DispatcherServlet to handle web requests.</p>
          <p>You can also use the exclude attribute of the @EnableAutoConfiguration annotation to specify one or more specific auto-configuration classes that should not be applied. This can be useful if you want to override a specific auto-configuration with your own custom configuration.</p>

          <p>Here is an example of using the @EnableAutoConfiguration annotation in a Spring Boot application:</p>
          
          <SyntaxHighlighter showLineNumbers="true" wrapLines="true" language="javascript" style={docco}>{codeSnippet.code1}</SyntaxHighlighter>

          <p>In this example, the @SpringBootApplication annotation is used to enable auto-configuration and component scanning in the application. The @EnableAutoConfiguration annotation is used to enable auto-configuration of the application based on the dependencies that are present in the classpath.</p>
          
        </CCardBody>
      </CCard>        
    )
}

export default EnableAutoConfiguration