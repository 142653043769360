export const codeSnippet = {
	code1: `import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Product } from './product';
import { ProductService } from './product.service';
import { Observable } from 'rxjs';

@Injectable()
export class ProductResolver implements Resolve<Product> {
  constructor(private productService: ProductService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Product> {
    const id = route.paramMap.get('id');
    return this.productService.getProduct(id);
  }
}`,
  code2: `const routes: Routes = [
  { path: 'product/:id', component: ProductComponent, resolve: { product: ProductResolver } }
];`
}